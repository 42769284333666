/* eslint-disable multiline-ternary */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  TextField,
  CardMedia,
  Divider,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
} from "../../services/network";
import consts from "../../libs/consts";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import fonts from "../../libs/fonts";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    marginTop: "4px",
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  addBtn: {
    backgroundColor: "#fff",
    borderRadius: 2,
    width: "160px",
    "&:hover": {
      backgroundColor: "lightgray",
    },
  },
  deleteBtn: {
    backgroundColor: "#fff",
    borderRadius: 2,
    width: "100px",
    "&:hover": {
      backgroundColor: "lightgray",
    },
  },
  textfield: {
    "& input": {
      textAlign: "center",
      fontSize: "14px",
    },
  },
  saveBtn: {
    backgroundColor: "#40cf66",
    borderRadius: 2,
    width: "160px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
});

export default function GradeManagement() {
  const classes = useStyles();
  const [grades, setGrades] = useState([]);
  const inputRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchGrades();
  }, []);

  const fetchGrades = () => {
    requestGet({ url: consts.apiUrl + "/grades" })
      .then((x) => {
        setGrades([...x]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const insertGrade = async () => {
    try {
      const { gradeId } = await requestPost({ url: consts.apiUrl + "/grades" });
      setGrades([
        {
          gradeId,
          name: "",
          path: "",
          amount: 0,
          monthOrderCount: 0,
        },
        ...grades,
      ]);
    } catch (error) {
      dispatch(popupError(error));
    }
  };

  const handleDelete = async (item) => {
    try {
      await requestDelete({
        url: consts.apiUrl + "/grades/" + item.gradeId,
      });
      setGrades([...grades.filter((x) => x.gradeId !== item.gradeId)]);
      dispatch(popupMessage({ title: "삭제 완료." }));
    } catch (error) {
      dispatch(popupError(error));
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const temp = inputRef.current.data;
    if (!file) {
      return;
    }
    dispatch(loadingStart);
    try {
      const form = new FormData();
      form.append("file", file);

      const { path } = await requestPost({
        url: consts.apiUrl + "/grades/" + temp.gradeId + "/image",
        body: form,
      });
      setGrades([
        ...grades.map((x, i) => {
          if (x.gradeId === temp.gradeId) {
            x.path = path;
          }
          return x;
        }),
      ]);
      dispatch(popupMessage({ title: "이미지 변경 완료." }));
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
    e.target.value = "";
  };

  const save = async ({}) => {
    dispatch(loadingStart);
    try {
      await requestPut({
        url: consts.apiUrl + "/grades",
        body: {
          grades: grades.map((x) => {
            x.amount = parseInt(x.amount);
            x.monthOrderCount = parseInt(x.monthOrderCount);
            return x;
          }),
        },
      });
      fetchGrades();
      dispatch(popupMessage({ title: "저장 완료." }));
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "20px" }}
        >
          <Typography
            variant="h5"
            style={{
              fontSize: "28px",
              lineHeight: "32px",
              fontFamily: fonts.spoqaHanSansNeoBold,
              color: "#374553",
              paddingTop: "40px",
            }}
          >
            고객 등급관리
          </Typography>
        </Grid>
        <Divider
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#c5c8ce",
            marginBottom: "40px",
          }}
        />
        <Button
          onClick={insertGrade}
          variant="outlined"
          className={classes.addBtn}
        >
          <Typography variant="subtitle2">+ 추가</Typography>
        </Button>
        <Button
          onClick={save}
          className={classes.saveBtn}
          style={{ marginLeft: "10px" }}
        >
          <Typography variant="subtitle2">저장</Typography>
        </Button>
        <Typography
          variant="subtitle2"
          style={{ marginTop: "20px", marginBottom: "40px" }}
        >
          구매금액/월 구매횟수 중 한 개 충족 시 회원등급 적용됩니다.
        </Typography>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#f7f8f9",
            padding: "15px 30px",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "#f7f8f9",
              padding: "15px 30px",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              overflow: "auto",
            }}
          >
            <Grid
              item
              className={classes.dfsc}
              style={{
                flexDirection: "row",
                padding: "15px 0px",
                justifyContent: "flex-start",
              }}
            >
              <Grid item style={{ alignSelf: "flex-start" }}>
                <Typography
                  variant="subtitle2"
                  style={{
                    width: "140px",
                    marginRight: "20px",
                    marginTop: "70px",
                  }}
                >
                  등급이미지
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    width: "140px",
                    marginRight: "20px",
                    marginTop: "80px",
                  }}
                >
                  등급
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    width: "140px",
                    marginRight: "20px",
                    marginTop: "35px",
                  }}
                >
                  구매금액
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    width: "140px",
                    marginRight: "20px",
                    marginTop: "35px",
                  }}
                >
                  월 구매횟수
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    width: "140px",
                    marginRight: "20px",
                    backgroundColor: "gray",
                  }}
                >
                  {" "}
                </Typography>
              </Grid>
              {grades.map((x, i) => {
                return (
                  <Grid>
                    <div
                      key={i.toString()}
                      onClick={() => {
                        inputRef.current.data = { ...x };
                        inputRef.current.click();
                      }}
                      style={{
                        width: 140,
                        height: 140,
                        cursor: "pointer",
                        backgroundColor: "#eee",
                        marginRight: "20px",
                      }}
                    >
                      {Boolean(x.path) && (
                        <CardMedia
                          component="img"
                          height={140}
                          image={consts.fileApiUrl + "/" + x.path}
                        />
                      )}
                    </div>
                    <Grid key={i.toString()} item className={classes.dfcc}>
                      <TextField
                        className={classes.textfield}
                        variant="outlined"
                        size="small"
                        value={x.name}
                        onChange={(e) => {
                          grades[i].name = e.target.value;
                          setGrades([...grades]);
                        }}
                        placeholder="등급명을 입력하세요"
                        style={{
                          width: "140px",
                          backgroundColor: "#fff",
                          marginRight: "20px",
                          marginTop: "20px",
                        }}
                      />
                    </Grid>
                    <Grid key={i.toString()} item className={classes.dfcc}>
                      <TextField
                        className={classes.textfield}
                        variant="outlined"
                        size="small"
                        type="number"
                        value={x.amount}
                        onChange={(e) => {
                          grades[i].amount = parseInt(e.target.value);
                          setGrades([...grades]);
                        }}
                        placeholder="금액"
                        style={{
                          width: "140px",
                          backgroundColor: "#fff",
                          marginRight: "20px",
                          marginTop: "20px",
                        }}
                      />
                    </Grid>
                    <Grid key={i.toString()} item className={classes.dfcc}>
                      <TextField
                        className={classes.textfield}
                        variant="outlined"
                        size="small"
                        type="number"
                        value={x.monthOrderCount}
                        onChange={(e) => {
                          grades[i].monthOrderCount = parseInt(e.target.value);
                          setGrades([...grades]);
                        }}
                        placeholder="횟수"
                        style={{
                          width: "140px",
                          backgroundColor: "#fff",
                          marginRight: "20px",
                          marginTop: "20px",
                        }}
                      />
                    </Grid>
                    <Grid key={i.toString()} item className={classes.dfcc}>
                      <Button
                        onClick={() => {
                          handleDelete(x);
                        }}
                        variant="outlined"
                        className={classes.deleteBtn}
                        style={{
                          width: "140px",
                          marginRight: "20px",
                          marginTop: "20px",
                        }}
                      >
                        <Typography variant="subtitle2">삭제</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
