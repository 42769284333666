import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../components/table-layout/TableLayout";
import useDistribution from "../../hooks/useDistribution.js";
import useListPayload from "../../hooks/useListPayload.js";
import { popupError } from "../redux/popup/PopupActions";
import { formatPhoneNumber } from "../services/utils";
import DashBoard from "../components/dashboard/DashBoard";
import { requestDelete, requestGet } from "../services/network";
import consts from "../libs/consts";
import qs from "query-string";
import useMember from "../../hooks/useMember";
import { useDispatch } from "react-redux";
import { Button, Dialog, Grid, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dialogTitleCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "lightgrey",
  },
  dialogContentCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "2px solid #656565",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none",
    },
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
});

export default function Review({ history, location }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [distributions] = useDistribution();
  const [cancelId, setCancelId] = useState(null);
  const { isPartner, distributionId } = useMember();
  const { data, query, loading, dataLength, fetchData, fetchExcel } =
    useListPayload({
      excelName: "리뷰 조회",
      endpoint: "/reviews",
      triggers: ["state"],
    });

  return (
    <>
      <TableLayout
        label="리뷰 조회"
        onSearch={() => {
          fetchData();
        }}
        onExcel={fetchExcel}
        data={[...data]}
        dataLoading={loading}
        dataLength={dataLength}
        onClick={(col) => {}}
        defaultQuery={{
          page: 1,
          limit: 10,
          start: "today",
          end: "today",
          keyword: "",
          distributionId: isPartner ? distributionId : "",
        }}
        search={[
          [
            {
              label: "리뷰 구분",
              type: "radio",
              key: "type",
              data: [
                { label: "상품", value: "product" },
                { label: "배송", value: "delivery" },
              ],
            },
          ] /*
          [
            {
              label: "조회기간",
              type: "date",
              key: "start",
              key2: "end"
            }
          ],
          [
            {
              label: "검색",
              type: "input",
              key: "keyword",
              placeholder: "받는분, 보내는분, 배송기사, 상품명"
            },
            !isPartner && {
              label: "유통사",
              type: "menu",
              key: "distributionId",
              data: distributions
            }
          ]*/,
        ]}
        columns={
          [] || [
            {
              label: "주문번호",
              clickable: true,
              key: "orderNumber",
            },
            {
              clickable: true,
              label: "배송번호",
              key: "customOrderNumber",
            },
            {
              label: "주문접수시간\n배송완료시간",
              render: (col) => (
                <span>
                  {col.date}
                  <br />
                  {col.deliveryCompleteDate || ""}
                </span>
              ),
            },
            {
              label: "보내는분\n배송기사",
              render: (col) => (
                <span>
                  {col.sName} ({formatPhoneNumber(col.sPhone)})
                  <br />
                  {col.driverNickName} ({col.driverName})
                </span>
              ),
            },
            {
              label: "받는분(연락처)\n주소",
              render: (col) => (
                <span>
                  {col.name} ({formatPhoneNumber(col.phone)})
                  <br />
                  {col.address} ({col.postCode})
                </span>
              ),
            },
            {
              label: "상품명",
              render: (col) => (
                <span>
                  {col.products
                    ? col.products?.split(",").map((x, i) => {
                        return (
                          <span key={i.toString()}>
                            {x}
                            {i !== col.length - 1 && <br />}
                          </span>
                        );
                      })
                    : ""}
                </span>
              ),
            },
            {
              label: "접수상태",
              render: (col) => (
                <span>{col.ordered ? "접수완료" : "접수대기"}</span>
              ),
            },
            {
              label: "배송상태",
              render: (col) => (
                <span>
                  {col.orderState === "cancel"
                    ? "주문 취소"
                    : col.ordered
                    ? col.orderState === "order" || col.orderState === "payment"
                      ? "배송대기"
                      : col.otherOrderComplete
                      ? "배송완료"
                      : "배송중"
                    : ""}
                </span>
              ),
            },
            {
              label: "주문취소",
              render: (col) =>
                col.otherOrderComplete || col.orderState === "cancel" ? (
                  "-"
                ) : (
                  <Button
                    onClick={() => {
                      setCancelId(col.otherOrderId);
                    }}
                    variant="text"
                    color="secondary"
                  >
                    주문취소
                  </Button>
                ),
            },
          ]
        }
      />
    </>
  );
}
