import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

export default function useMember() {
  const user = useSelector((s) => s.user, shallowEqual);
  const [isPartner, setIsPartner] = useState(false);
  const [partnerHost, setPartnerHost] = useState(null);
  useEffect(() => {
    if (
      [
        "partner.hoodtruck.co.kr",
        "dev-partner.hoodtruck.co.kr",
        "dev.partner.hoodtruck.co.kr",
        "dev.partner.hoodtruck.co.kr.s3-website.ap-northeast-2.amazonaws.com",
        "dev-partner.hoodtruck.co.kr.s3-website.ap-northeast-2.amazonaws.com",
      ].includes(window.location.hostname)
    ) {
      setPartnerHost(true);
    } else {
      setPartnerHost(false);
    }
  }, []);

  useEffect(() => {
    if (partnerHost && user.distributionId) {
      setIsPartner(true);
    }
  }, [user, partnerHost]);

  return {
    isPartner,
    distributionId: user.distributionId,
    partnerHost,
  };
}
