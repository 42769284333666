import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid, Typography, Paper, Tabs, Tab } from "@material-ui/core";
import AuthUnconfirm from "./sub/AuthUnconfirm.js";
import AuthTurnBack from "./sub/AuthTurnBack.js";
import AuthApprove from "./sub/AuthApprove.js";
import { requestGet } from "../../services/network.js";
import consts from "../../libs/consts.js";
import TitleBar from "../../components/titlebar/TitleBar.js";

const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  titleGrid: {},
  divider: {
    width: "100%",
    height: "2px",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dffec: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  test: {
    borderRadius: 15,
    // border: "5px solid green"
  },
});

export default function DriverAuth() {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    requestGet({
      url: consts.apiUrl + "/drivers",
      query: {
        page: 1,
        limit: 1,
        state: "approve",
      },
    })
      .then((x) => {
        setCount3(x.totalCount);
      })
      .catch((e) => {
        console.log(e);
      });
    requestGet({
      url: consts.apiUrl + "/drivers",
      query: {
        page: 1,
        limit: 1,
        state: "reject",
      },
    })
      .then((x) => {
        setCount2(x.totalCount);
      })
      .catch((e) => {
        console.log(e);
      });
    requestGet({
      url: consts.apiUrl + "/drivers",
      query: {
        page: 1,
        limit: 1,
        state: "register",
      },
    })
      .then((x) => {
        setCount1(x.totalCount);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          className={classes.titleGrid}
          style={{ marginBottom: "30px" }}
        >
          <TitleBar label="기사회원 인증요청" />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Paper elevation={0} square>
              <Tabs
                value={tabValue}
                TabIndicatorProps={{
                  style: {
                    color: "black",
                    backgroundColor: "black",
                    borderColor: "black",
                  },
                }}
                indicatorColor="primary"
                textColor="inherit"
                onChange={handleTabValue}
              >
                <Tab
                  label={
                    <Grid item xs={12} className={classes.df}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        미확인
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold", marginLeft: "5px" }}
                      >
                        {count1}
                      </Typography>
                    </Grid>
                  }
                />
                <Tab
                  label={
                    <Grid item xs={12} className={classes.df}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        반려
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold", marginLeft: "5px" }}
                      >
                        {count2}
                      </Typography>
                    </Grid>
                  }
                />
                <Tab
                  label={
                    <Grid item xs={12} className={classes.df}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        승인
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold", marginLeft: "5px" }}
                      >
                        {count3}
                      </Typography>
                    </Grid>
                  }
                />
              </Tabs>
              <Divider className={classes.divider} />
            </Paper>
          </Grid>
          <Grid container className={classes.test}>
            {tabValue === 0 ? (
              <AuthUnconfirm onCount={setCount1} />
            ) : tabValue === 1 ? (
              <AuthTurnBack onCount={setCount2} />
            ) : tabValue === 2 ? (
              <AuthApprove onCount={setCount3} />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
