import moment from "moment";

export const getGenderLabel = gender => {
  switch (gender) {
    case "female":
      return "여자";
    case "female":
      return "남자";
    default:
      return "-";
  }
};

export const formatBirthday = birthday => {
  if (!birthday) {
    return "-";
  }

  return moment(birthday, "YYYYMMDD").format("YYYY-MM-DD");
};
