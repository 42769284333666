import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import useMember from "../../../hooks/useMember.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  const [distributions] = useDistribution();
  const { isPartner, distributionId } = useMember();
  const { data, loading, dataLength, fetchData } = useListPayload({
    endpoint: "/receiptsets",
  });
  return (
    <TableLayout
      label="입고SET관리"
      onSearch={fetchData}
      data={[...data]}
      insertUrl="/receiving_management/setEditor"
      dataLoading={loading}
      dataLength={dataLength}
      onClick={(col) => {
        history.push("/receiving_management/setEditor", {
          receiptSetId: col.receiptSetId,
        });
      }}
      defaultQuery={{
        page: 1,
        limit: 10,
        start: "",
        end: "",
        keyword: "",
        distributionId: isPartner ? distributionId : "",
      }}
      search={[
        [
          {
            label: "조회기간",
            type: "date",
            key: "start",
            key2: "end",
          },
        ],
        [
          {
            label: "검색",
            type: "input",
            key: "keyword",
            placeholder: "SET명, 코드",
          },
          !isPartner && {
            label: "유통사",
            type: "menu",
            key: "distributionId",
            data: distributions,
          },
        ],
      ]}
      columns={[
        {
          label: "SET명",
          clickable: true,
          key: "name",
        },
        {
          label: "유통사 코드",
          key: "dCode",
        },
        {
          label: "상품수",
          key: "productLength",
        },
        {
          label: "연결 등록차량",
          key: "driverLength",
        },
        {
          label: "연결 스토어",
          key: "dName",
        },
        {
          label: "최종 수정일시",
          key: "updatedAt",
        },
      ]}
    />
  );
}
