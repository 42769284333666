/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Paper,
  Button,
  Collapse,
  TextField,
  Divider,
  FormControlLabel,
  Radio,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  ButtonBase,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  requestDelete,
  requestFile,
  requestGet,
  requestPost,
  requestPut,
} from "../../services/network";
import consts from "../../libs/consts";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { getFileUri } from "../../services/utils";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import TableTitle from "../../components/table/TableTitle";

export default function StoreCategoryChild({
  categories,
  setCategories,
  onAdd,
  setOnAdd,
}) {
  const classes = useStyles();
  const [data, setData] = useState();
  const dispatch = useDispatch();

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fs = new FileReader();
      fs.onloadend = () => {
        file.uri = fs.result;
        setData({ ...data, file });
      };
      fs.readAsDataURL(file);
      e.target.value = "";
    }
  };

  useEffect(() => {
    categories.filter((x) => x.select)[0]
      ? setData({ ...categories.filter((x) => x.select)[0] })
      : onAdd
      ? setData({ name: "" })
      : setData(null);
  }, [categories]);

  const deleteCategory = async () => {
    const d = data.items.filter((x) => x.select)[0];
    if (!d) {
      dispatch(
        popupMessage({
          message: "삭제할 카테고리를 선택해주세요.",
          title: "삭제",
        })
      );
      return;
    }
    requestDelete({
      url: consts.apiUrl + "/storecategories/" + d.storeCategoryId,
    })
      .then((x) => {
        setCategories([
          ...categories.map((x) => {
            if (x.storeCategoryId === data.storeCategoryId) {
              x.items = x.items.filter(
                (y) => y.storeCategoryId !== d.storeCategoryId
              );
            }
            return x;
          }),
        ]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const save = () => {
    const file = new FormData();

    if (data.file) {
      file.append("file", data.file);
    } else if (data.path) {
      file.append("path", data.path);
    }
    file.append("name", data.name);
    requestFile({
      put: true,
      url: consts.apiUrl + "/storecategories/" + data.storeCategoryId,
      form: file,
    })
      .then((x) => {
        requestGet({
          url: consts.apiUrl + "/storecategories",
          query: { privacy: "all" },
        })
          .then((d) => {
            setCategories([...d]);
          })
          .catch((e) => {
            dispatch(popupError(e));
          });
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };
  const add = () => {
    try {
      if (data.name === "") {
        throw "분류명을 입력해 주세요.";
      } else if (!data.file) {
        throw "아이콘을 추가해 주세요.";
      }
      const file = new FormData();

      file.append("file", data.file);
      file.append("name", data.name);
      requestFile({
        url: consts.apiUrl + "/storecategories",
        form: file,
      })
        .then((x) => {
          requestGet({
            url: consts.apiUrl + "/storecategories",
          })
            .then((d) => {
              setCategories([...d]);
            })
            .catch((e) => {
              dispatch(popupError(e));
            });
          setOnAdd(false);
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    } catch (error) {
      dispatch(popupError(error));
    }
  };

  const saveChild = () => {
    if (!data.items.length) {
      return;
    } else {
      requestPut({
        url: consts.apiUrl + `/storecategories`,
        body: {
          data: data.items.map((x) => ({
            name: x.name,
            storeCategoryId: x.storeCategoryId,
          })),
        },
      })
        .then((d) => {
          setCategories([
            ...categories.map((x) => {
              if (x.storeCategoryId === data.storeCategoryId) {
                x.items = [...data.items];
              }
              return x;
            }),
          ]);
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <Grid item xs={5}>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <Typography variant="subtitle1">카테고리 정보</Typography>
        </Grid>
        <Grid item xs={12} className={classes.boxHeader}>
          <ButtonBase
            variant="outlined"
            className={classes.button}
            onClick={onAdd ? add : save}
            style={{ marginLeft: "auto" }}
          >
            저장
          </ButtonBase>
        </Grid>
        <Grid item xs={12} className={classes.boxContent}>
          <Grid item xs={12} className={classes.df}>
            <Grid item xs={2} className={classes.dfcc}>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: fonts.malgunGothicSemiLight,
                  fontSize: 13,
                }}
              >
                분류명
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              className={classes.dfsc}
              style={{ padding: "10px" }}
            >
              <TextField
                inputProps={{ style: { fontSize: 13 } }}
                variant="outlined"
                size="small"
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12} className={classes.df}>
            <Grid item xs={2} className={classes.dfcc}>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: fonts.malgunGothicSemiLight,
                  fontSize: 13,
                }}
              >
                아이콘
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              className={classes.dfsc}
              style={{ padding: "10px" }}
            >
              <div style={{ width: "70px", marginRight: "20px" }}>
                {(data.file || data.path) && (
                  <CardMedia
                    component="img"
                    height={70}
                    style={{ objectFit: "contain" }}
                    image={data.file ? data.file.uri : getFileUri(data.path)}
                  />
                )}
              </div>
              <Button variant="outlined" component="label">
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: fonts.malgunGothicSemiLight,
                    fontSize: 13,
                  }}
                >
                  업로드
                </Typography>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleChangeImage}
                />
              </Button>
            </Grid>
          </Grid>
          <Divider />
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  divider: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#fff",
    height: 40,
    borderRadius: 2,
    border: "1px solid #dddddd",
    marginRight: "10px",
    width: "100px",
    fontSize: 13,
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoMedium,
  },
  boxHeader: {
    border: "1px solid #dddddd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f7f8f9",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  boxContent: {
    height: "600px",
    borderLeft: "1px solid #dddddd",
    overflowY: "auto",
    borderRight: "1px solid #dddddd",
    borderBottom: "1px solid #dddddd",
    padding: "10px",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  addBtn: {
    borderRadius: 2,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  deleteBtn: {
    marginLeft: "10px",
    borderRadius: 2,
    color: "red",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    width: 100,
    borderRadius: 2,
    color: "#fff",
    backgroundColor: "#656565",
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  listItem: {
    display: "flex",
    border: "1px solid #dddddd",
  },
  secondListItem: {
    height: 50,
    marginTop: "10px",
    border: "1px solid #dddddd",
  },
  thirdListItem: {
    marginTop: "10px",
    marginLeft: "40px",
    border: "1px solid #dddddd",
  },
  radioBtn: {
    color: "#40cf66 !important",
  },
  radioBtn2: {
    marginLeft: "30px",
    color: "#40cf66 !important",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  DialogSaveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  resize: { fontSize: "13px" },
});
