import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@material-ui/core";
import { requestGet, requestPut } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import fonts from "../../libs/fonts";
import TableTitle from "../../components/table/TableTitle";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    paddingTop: "50px",
    overflow: "auto",
    paddingRight: "30px",
    paddingBottom: "30px",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#c5c8ce",
    marginBottom: "40px",
    marginTop: "27px",
  },
  addBtn: {
    backgroundColor: "#656565",
    borderRadius: 0,
    width: "120px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  deleteBtn: {
    backgroundColor: "#000",
    marginLeft: "10px",
    borderRadius: 0,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  textfield: {
    "& input": {
      textAlign: "center",
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    marginTop: "50px",
    backgroundColor: "#000",
    borderRadius: 0,
    width: "120px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  cb: { color: "#40cf66 !important" },
});

export default function DeliveryCharge({
  distributionId,
  data,
  setData,
  noEvent,
  setNoEvent,
  noMin,
  setNoMin,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (distributionId) {
      requestGet({
        url:
          consts.apiUrl + "/distributions/" + distributionId + "/deliveryfees",
      })
        .then((x) => {
          setData({ ...x });
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    }
  }, [distributionId]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "30px" }}
        >
          <Typography
            variant="h5"
            style={{
              fontSize: "28px",
              lineHeight: "32px",
              fontFamily: fonts.spoqaHanSansNeoBold,
              color: "#374553",
              paddingTop: "40px",
            }}
          >
            배송비
          </Typography>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12} className={classes.dfsc}>
          <Grid item xs={6}>
            <Grid item xs={6} className={classes.dfsc}>
              <TableTitle xs={6}>주문</TableTitle>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.dfsc}
              style={{
                justifyContent: "space-between",
                marginBottom: "20px",
                marginRight: "40px",
                backgroundColor: "#f7f8f9",
                padding: "30px",
                paddingBottom: "90px",
              }}
            >
              <Typography variant="subtutle2">주문 배송비</Typography>
              <Grid className={classes.dfsc}>
                <TextField
                  className={classes.textfield}
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: "20px" }}
                  type="number"
                  onChange={(e) => {
                    data.order = Math.abs(parseInt(e.target.value));
                    setData({ ...data });
                  }}
                  value={data.order}
                />
                <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
                  원
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={6} className={classes.dfsc}>
              <TableTitle xs={6}>배송비 무료</TableTitle>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "20px",
                marginRight: "40px",
                backgroundColor: "#f7f8f9",
                padding: "30px",
              }}
            >
              <Grid
                item
                xs={12}
                className={classes.dfsc}
                style={{
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography variant="subtutle2">기준 배송비</Typography>
                <Grid className={classes.dfsc}>
                  <TextField
                    disabled={noEvent}
                    className={classes.textfield}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: "20px" }}
                    type="number"
                    onChange={(e) => {
                      data["event"] = Math.abs(parseInt(e.target.value));
                      setData({ ...data });
                    }}
                    value={data["event"]}
                  />
                  <Typography
                    variant="subtitle1"
                    style={{ marginLeft: "20px" }}
                  >
                    원
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.dfsc}
                style={{ justifyContent: "flex-end", marginRight: "20px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.cb}
                      color="default"
                      style={{ color: "black" }}
                      checked={noEvent}
                      onChange={(e) => {
                        if (e.target.checked) {
                          data["event"] = "0";
                          setData({ ...data });
                        }
                        setNoEvent(e.target.checked);
                      }}
                    />
                  }
                  label="무료 배송비 설정하지 않음"
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.dfsc}
                style={{
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography variant="subtutle2">최소 주문가능 금액</Typography>
                <Grid className={classes.dfsc}>
                  <TextField
                    disabled={noMin}
                    className={classes.textfield}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: "20px" }}
                    type="number"
                    onChange={(e) => {
                      data["min"] = Math.abs(parseInt(e.target.value));
                      setData({ ...data });
                    }}
                    value={data["min"]}
                  />
                  <Typography
                    variant="subtitle1"
                    style={{ marginLeft: "20px" }}
                  >
                    원
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.dfsc}
                style={{ justifyContent: "flex-end", marginRight: "20px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.cb}
                      color="default"
                      style={{ color: "black" }}
                      checked={noMin}
                      onChange={(e) => {
                        if (e.target.checked) {
                          data["min"] = "0";
                          setData({ ...data });
                        }
                        setNoMin(e.target.checked);
                      }}
                    />
                  }
                  label="최소 주문가능 금액 설정하지 않음"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "10px" }}
        >
          <Grid item xs={6} className={classes.dfsc}>
            <TableTitle xs={6}>구매자 부담</TableTitle>
          </Grid>
          <Grid item xs={6} className={classes.dfsc}>
            <TableTitle xs={6}>판매자 부담</TableTitle>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.dfsc}>
          <Grid
            item
            xs={12}
            style={{
              justifyContent: "space-between",
              marginBottom: "20px",
              marginRight: "40px",
              backgroundColor: "#f7f8f9",
              padding: "30px",
            }}
          >
            {/* 구매자 부담 */}
            <Grid
              item
              xs={12}
              className={classes.dfsc}
              style={{ justifyContent: "space-between", marginTop: "10px" }}
            >
              <Typography variant="subtutle2">교환 배송비</Typography>
              <Grid className={classes.dfsc}>
                <TextField
                  className={classes.textfield}
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: "20px" }}
                  type="number"
                  onChange={(e) => {
                    data["buyer-exchange"] = Math.abs(parseInt(e.target.value));
                    setData({ ...data });
                  }}
                  value={data["buyer-exchange"]}
                />
                <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
                  원
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.dfsc}
              style={{ justifyContent: "space-between", marginTop: "10px" }}
            >
              <Typography variant="subtutle2">반품 배송비</Typography>
              <Grid className={classes.dfsc}>
                <TextField
                  className={classes.textfield}
                  variant="outlined"
                  size="small"
                  type="number"
                  onChange={(e) => {
                    data["buyer-refund"] = Math.abs(parseInt(e.target.value));
                    setData({ ...data });
                  }}
                  value={data["buyer-refund"]}
                  style={{ marginLeft: "20px" }}
                />
                <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
                  원
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              justifyContent: "space-between",
              marginBottom: "20px",
              marginRight: "40px",
              backgroundColor: "#f7f8f9",
              padding: "30px",
            }}
          >
            {/* 판매자 부담 */}
            <Grid
              item
              xs={12}
              className={classes.dfsc}
              style={{ justifyContent: "space-between", marginTop: "10px" }}
            >
              <Typography variant="subtutle2">교환 배송비</Typography>
              <Grid className={classes.dfsc}>
                <TextField
                  className={classes.textfield}
                  variant="outlined"
                  size="small"
                  type="number"
                  onChange={(e) => {
                    data["seller-exchange"] = Math.abs(
                      parseInt(e.target.value)
                    );
                    setData({ ...data });
                  }}
                  value={data["seller-exchange"]}
                  style={{ marginLeft: "20px" }}
                />
                <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
                  원
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.dfsc}
              style={{ justifyContent: "space-between", marginTop: "10px" }}
            >
              <Typography variant="subtutle2">반품 배송비</Typography>
              <Grid className={classes.dfsc}>
                <TextField
                  className={classes.textfield}
                  variant="outlined"
                  size="small"
                  type="number"
                  onChange={(e) => {
                    data["seller-refund"] = Math.abs(parseInt(e.target.value));
                    setData({ ...data });
                  }}
                  value={data["seller-refund"]}
                  style={{ marginLeft: "20px" }}
                />
                <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
                  원
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
