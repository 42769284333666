import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import consts from "../admin/libs/consts";
import { popupError } from "../admin/redux/popup/PopupActions";
import { requestGet } from "../admin/services/network";

function useDistribution() {
  const dispatch = useDispatch();
  const [distributions, setDistributions] = useState(null);

  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/distributions" })
      .then(x => {
        setDistributions([
          ...x.results.map(x => ({
            ...x,
            label: x.companyName,
            value: x.distributionId.toString()
          }))
        ]);
      })
      .catch(x => {
        dispatch(popupError(x));
      });
  }, []);

  return [distributions];
}

export default useDistribution;
