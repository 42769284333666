import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  Avatar,
  Icon,
  Dialog,
  CardMedia,
} from "@material-ui/core";
import { requestDelete, requestGet, requestPut } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { formatPhoneNumber, formatTime } from "../../services/utils";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dfss: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
  },
  divider: {
    width: "100%",
    height: "3px",
    backgroundColor: "#7C7878",
    marginBottom: "20px",
  },
  dashboardItem: {
    marginBottom: "30px",
  },
  bold: {
    fontWeight: "bold",
  },
  detailInfoPaper: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  cell: {
    height: "50px",
    paddingLeft: "10px",
    borderBottom: "1px solid #7C7878",
  },
  cellTop: {
    borderTop: "1px solid #7C7878",
  },
  lastCell: {
    height: "100px",
    paddingLeft: "10px",
    borderBottom: "1px solid #7C7878",
  },
  firstCell: {
    borderRight: "1px solid #7C7878",
  },
  attributeCell: {
    backgroundColor: "#e9e9e9",
  },
  postalCodeField: {
    width: "200px",
    "& input": {
      textAlign: "center",
    },
  },
  detailInfoBtnGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  detailInfoSaveBtn: {
    width: "120px",
    borderRadius: 2,
    backgroundColor: "#40cf66",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  detailInfoListBtn: {
    width: "120px",
    marginLeft: "10px",
    borderRadius: 2,
    backgroundColor: "#fff",
    border: "1px solid #e9e9e9",
  },
  deleteBtn: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#e9e9e9",
    },
    alignSelf: "flex-end",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  titleCell: {
    borderTop: "1px solid #000",
    borderRight: "1px solid #000",
    backgroundColor: "lightgrey",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  contentCell: {
    borderTop: "1px solid #000",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  resize: { fontSize: "13px" },
});

export default function CustomerDetailInfo(props) {
  const classes = useStyles();
  const { history, location } = useReactRouter();
  const inquiryId = location.state.inquiryId;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  useEffect(() => {
    if (inquiryId) {
      requestGet({ url: consts.apiUrl + "/inquiries/" + inquiryId })
        .then((x) => {
          setData({ ...x });
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    }
  }, [inquiryId]);

  const onClickDeleteBtn = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const onClickDeleteDialogSaveBtn = () => {
    setDeleteDialogOpen(false);
    requestDelete({
      url: consts.apiUrl + "/inquiries/" + inquiryId,
    })
      .then((x) => {
        dispatch(popupMessage({ title: "삭제 완료." }));
        history.goBack();
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const onClickSaveBtn = () => {
    if (!data.reply) {
      dispatch(popupError({ message: "답변을 입력해주세요." }));
      return;
    }
    requestPut({
      url: consts.apiUrl + "/inquiries/" + inquiryId + "/reply",
      body: {
        reply: data.reply,
      },
    })
      .then((x) => {
        dispatch(popupMessage({ title: "답변 완료." }));
        requestGet({ url: consts.apiUrl + "/inquiries/" + inquiryId })
          .then((x) => {
            setData({ ...x });
          })
          .catch((e) => {
            dispatch(popupError(e));
          });
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const onClickListBtn = () => {
    history.goBack();
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.detailInfoPaper} elevation={0} square>
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={10} className={classes.df}>
                <Grid item xs={5} className={classes.dfsc}>
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: "28px",
                      lineHeight: "32px",
                      fontFamily: fonts.spoqaHanSansNeoBold,
                      color: "#374553",
                      paddingTop: "40px",
                    }}
                  >
                    고객문의 상세보기
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={classes.df}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.deleteBtn}
                    onClick={onClickDeleteBtn}
                  >
                    <Typography variant="subtitle1">삭제하기</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Divider
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#c5c8ce",
                    marginBottom: "40px",
                    marginTop: "20px",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.dashboardItem}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid item xs={12} className={classes.df}>
                    <TableContainer first>
                      <Th topleft>등록일자</Th>
                      <Td topright xs={8}>
                        {formatTime(data.createdAt, "YYYY-MM-DD HH:mm")}
                      </Td>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} className={classes.df}>
                    <TableContainer>
                      <Th>회원구분</Th>
                      <Td xs={8}>
                        {data.memberType === "user" ? "고객회원" : "기사회원"}
                      </Td>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} className={classes.df}>
                    <TableContainer>
                      <Th>이름</Th>
                      <Td xs={8}>{data.name}</Td>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} className={classes.df}>
                    <TableContainer>
                      <Th>
                        {data.memberType === "user" ? "이메일(ID)" : "아이디"}
                      </Th>
                      <Td xs={8}>{data.username}</Td>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} className={classes.df}>
                    <TableContainer>
                      <Th>휴대폰</Th>
                      <Td xs={8}>{formatPhoneNumber(data.phone)}</Td>
                    </TableContainer>
                  </Grid>
                  {data.memberType === "user" && (
                    <Grid item xs={12} className={classes.df}>
                      <TableContainer>
                        <Th>문의유형</Th>
                        <Td xs={8}>[{data.label}]</Td>
                      </TableContainer>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    className={classes.df}
                    style={{ height: "300px" }}
                  >
                    <TableContainer>
                      <Th>문의 내용</Th>
                      <Td xs={8}>{data.description}</Td>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} className={classes.df}>
                    <TableContainer>
                      <Th>답변</Th>
                      <Td xs={8} textfield>
                        <TextField
                          InputProps={{
                            classes: {
                              input: classes.resize,
                            },
                          }}
                          fullWidth
                          multiline
                          value={data.reply}
                          onChange={(e) => {
                            setData({ ...data, reply: e.target.value });
                          }}
                          rows={10}
                          variant="outlined"
                          style={{ marginRight: "20px" }}
                        />
                      </Td>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} className={classes.df}>
                    <TableContainer last>
                      <Th bottomleft>답변완료일</Th>
                      <Td bottomright xs={8}>
                        {formatTime(data.replyAt, "YYYY-MM-DD HH:mm")}
                      </Td>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={10}></Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              className={[
                classes.detailInfoBtnGrid,
                classes.dashboardItem,
              ].join(" ")}
            >
              <Button
                variant="contained"
                className={classes.detailInfoSaveBtn}
                onClick={onClickSaveBtn}
              >
                저장
              </Button>
              <Button
                variant="contained"
                className={classes.detailInfoListBtn}
                onClick={onClickListBtn}
              >
                목록
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialog}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">삭제처리</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                삭제처리 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setDeleteDialogOpen(false)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={onClickDeleteDialogSaveBtn}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </Grid>
  );
}
