/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  CardMedia,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { formatPhoneNumber, numFormat } from "../../services/utils.js";
import { requestGet } from "../../services/network.js";
import consts from "../../libs/consts.js";
import { popupError } from "../../redux/popup/PopupActions.js";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts.js";
const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    overflow: "auto",
    paddingBottom: "50px",
  },
  titleGrid: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginBottom: "20px",
  },
  subtitle: {
    marginTop: "10px",
    marginLeft: "20px",
  },
  divider: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dffec: {
    display: "felx",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  infoGrid: {
    border: "1px solid #DFDFDF",
    backgroundColor: "#efefef",
    marginBottom: "20px",
  },
  infoContent: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
  },
  infocell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  select: {
    "& div": {
      backgroundColor: "#fff",
    },
  },
  searchBox: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  initializationBtn: {
    width: "120px",
    backgroundColor: "#fff",
    border: "1px solid #e9e9e9",
    borderRadius: 0,
  },
  searchBtn: {
    width: "120px",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  driverInfoGrid: {
    flex: 2,
    border: "1px solid #dddddd",
    borderLeft: 0,
    padding: "20px",
  },
  receivingTimeTextField: {
    "& input": {
      textAlign: "center",
    },
  },
  receivingStatusTextField: {
    textAlign: "center",
    "& input": {
      color: "blue",
      textAlign: "center",
    },
  },
  tableGrid: {
    overflow: "auto",
    maxHeight: "50vh",
  },
  tableCell: {
    textAlign: "center",
    border: "1px solid #B7ACAC",
  },

  productAddBtn: {
    width: "150px",
    borderRadius: 0,
    backgroundColor: "#000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#000",
    },
    marginLeft: "auto",
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  label: {
    color: "#646f7c",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontFamily: fonts.spoqaHanSansNeoBold,
    "& div": {
      width: 3,
      height: 3,
      borderRadius: 100,
      backgroundColor: "#374553",
      marginRight: 5,
    },
  },
  value: {
    marginLeft: 5,
    fontSize: 14,
    color: "#222222",
    fontFamily: fonts.malgunGothicBold,
  },
  table: {
    "& th": {
      border: "1px solid #dddddd",
      borderTop: "0px",
      padding: 0,

      "& h6": {
        fontSize: 13,
        color: "#374553",
        fontFamily: fonts.malgunGothicBold,
      },
      height: "50px",
      backgroundColor: "#f7f8f9",
    },
    "& td": {
      border: "1px solid #dddddd",
      fontSize: 13,
      color: "#374553",
      fontFamily: fonts.malgunGothicSemiLight,
    },
  },
});

export default function ReceiptSetDetail({ driverId, driverWorkId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [data, setData] = useState({
    name: "",
    phone: "",
    carNumber: "",
    companyName: "",
    address: "",
    receiptSet: "",
    receiptState: 0,
  });

  useEffect(() => {
    if (!driverWorkId) {
      setData({ receiptSet: "" });
      return null;
    }
    requestGet({
      url: consts.apiUrl + `/drivers/${driverId}/works/${driverWorkId}`,
    })
      .then((x) => {
        setData({ ...x });
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
    requestGet({
      url:
        consts.apiUrl +
        `/drivers/${driverId}/works/${driverWorkId}/receiptproducts`,
    })
      .then((products) => {
        setProducts([...products]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });

    return () => {
      setData({ receiptSet: "" });
      setProducts([]);
    };
  }, [driverId, driverWorkId]);

  if (!driverWorkId) {
    return <Grid item xs={7} className={classes.driverInfoGrid} />;
  }

  return (
    <Grid item xs={7} className={classes.driverInfoGrid}>
      <Grid item xs={12} className={classes.dfsc} style={{ marginBottom: 20 }}>
        <Grid item xs={2} className={classes.dfsc}>
          <span className={classes.label}>
            <div />
            입고 SET : <span className={classes.value}>{data.receiptSet}</span>
          </span>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.dfcc}
          style={{ marginLeft: "20px" }}
        >
          <span className={classes.label}>
            <div />
            품목 수 :{" "}
            <span className={classes.value} style={{ color: "#ff0808" }}>
              {products.length}
            </span>
          </span>
        </Grid>

        <Grid
          item
          xs={4}
          className={classes.dfcc}
          style={{ marginLeft: "20px" }}
        >
          <span className={classes.label}>
            <div />
            입고시간 : <span className={classes.value}> {data.createdAt}</span>
          </span>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.dfcc}
          style={{ marginLeft: "20px" }}
        >
          <span className={classes.label}>
            <div />
            입고상태 :{" "}
            <span
              className={classes.value}
              style={{ color: data.receiptSet ? "#39c15d" : "#ff7e00" }}
            >
              {" "}
              {data.receiptSet ? "입고완료" : "입고대기"}
            </span>
          </span>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />
      <Grid
        item
        xs={12}
        className={classes.tableGrid}
        style={{ textAlign: "center" }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">NO</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">상품코드</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} style={{ width: 100 }}>
                <Typography variant="subtitle1">상품이미지</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">상품명</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">공급가</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">입고수량</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((col, i) => {
              return (
                <TableRow key={i}>
                  <TableCell className={classes.tableCell}>
                    {products.length - i}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {col.code}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{
                      border: 0,
                      borderBottom: "1px solid #B7ACAC",
                    }}
                  >
                    <div className={classes.dfcc} style={{ width: 100 }}>
                      <CardMedia
                        component="img"
                        image={consts.fileApiUrl + "/" + col.path}
                      />
                    </div>
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {col.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {numFormat(col.price)}원
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {col.quantity}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      <Divider className={classes.divider} />
    </Grid>
  );
}
