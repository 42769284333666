import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import fonts from "../../libs/fonts";

export default function Td({
  children,
  xs,
  center,
  textfield,
  column,
  onClick,
  topleft,
  topright,
  bottomleft,
  bottomright,
  cb,
  img,
  style,
  post,
}) {
  const classes = useStyles();

  return (
    <Grid onClick={onClick} item xs={xs ? xs : 4} style={style}>
      <div
        className={
          classes.td +
          " " +
          (center && classes.center) +
          " " +
          (children === "판매중" && classes.colorGreen) +
          " " +
          (children === "판매중지" && classes.colorOrange) +
          " " +
          (topleft && classes.topleft) +
          " " +
          (topright && classes.topright) +
          " " +
          (bottomleft && classes.bottomleft) +
          " " +
          (bottomright && classes.bottomright) +
          " " +
          (cb && classes.cb) +
          " " +
          (img && classes.img) +
          " " +
          (textfield && classes.textfield) +
          " " +
          (column && classes.column) +
          " " +
          (post && classes.post)
        }
      >
        {children}
      </div>
    </Grid>
  );
}
const useStyles = makeStyles({
  td: {
    borderTop: "1px solid #dddddd",
    borderRight: "1px solid #dddddd",
    padding: "20px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    fontSize: 13,
    fontFamily: fonts.malgunGothicSemiLight,
    color: "#374553",
    height: "100%",
    maxWidth: "100%",
    wordBreak: "break-all",
  },
  center: {
    justifyContent: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  colorGreen: {
    color: "#39c15d",
  },
  colorOrange: {
    color: "orange",
  },
  topleft: {
    borderTopLeftRadius: "5px",
  },
  topright: {
    borderTopRightRadius: "5px",
  },
  bottomleft: {
    borderBottomLeftRadius: "5px",
  },
  bottomright: {
    borderBottomRightRadius: "5px",
  },
  cb: {
    padding: "0px",
  },
  img: {
    padding: "0px",
  },
  textfield: {
    padding: "5px 15px",
  },
  column: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  post: { padding: "10px 30px" },
});
