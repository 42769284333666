import React, { useState } from "react";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useListPayload from "../../../hooks/useListPayload.js";
import { formatPhoneNumber, formatTime } from "../../services/utils.js";
import Sorter from "./Sorter.js";
import SharedReview from "../../../components/review/SharedReview";

export default function Container({ history }) {
  const [sel, setSel] = useState(null);
  const { data, loading, dataLength, fetchData } = useListPayload({
    endpoint: "/distributions",
  });

  return (
    <>
      {sel && (
        <SharedReview
          open
          onClose={() => setSel(null)}
          type="distribution"
          id={sel.distributionId}
        />
      )}
      <TableLayout
        label="유통사관리"
        onSearch={fetchData}
        insertUrl="/partner_management/distributor_add"
        data={[...data]}
        dataLoading={loading}
        dataLength={dataLength}
        onClick={(col) => {
          history.push("/partner_management/distributor_add", {
            distributionId: col.distributionId,
          });
        }}
        defaultQuery={{
          page: 1,
          limit: 10,
          start: "",
          end: "",
          keyword: "",
          method: "",
        }}
        search={[
          [
            {
              label: "조회기간",
              type: "date",
              key: "start",
              key2: "end",
            },
          ],
          [
            {
              label: "검색",
              type: "input",
              key: "keyword",
              placeholder: "유통사명, 담당자명, 휴대폰번호",
            },
          ],
        ]}
        columns={[
          {
            label: "노출순서",
            width: 70,
            render: (col, i) => <Sorter {...col} onUpdate={fetchData} />,
          },
          {
            label: "등록일시",
            render: (col) => (
              <>{formatTime(col.createdAt, "YYYY-MM-DD HH:mm")}</>
            ),
          },
          {
            label: "유통사명",
            key: "companyName",
          },
          {
            label: "아이디",
            key: "username",
            clickable: true,
          },
          {
            label: "담당자명",
            key: "managerName",
          },
          {
            label: "휴대폰번호",
            render: (col) => <>{formatPhoneNumber(col.phone)}</>,
          },
          {
            label: "주소",
            key: "address",
          },
          {
            label: "취급카테고리\n(대분류)",
            render: (col) => <>{col.categoryLength}</>,
          },
          {
            label: "인증여부",
            render: (col) => <>{col.verified === 1 ? "인증" : "-"}</>,
          },
          {
            label: "리뷰 수",
            // key: "review",
            width: 50,
            render: (col) => (
              <span
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => {
                  if (Boolean(col.reviewLength)) {
                    setSel({ ...col });
                  }
                }}
              >
                {col.reviewLength}
              </span>
            ),
          },
        ]}
      />
    </>
  );
}
