/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Paper,
  FormControlLabel,
  Radio,
  Dialog,
  Checkbox,
  Icon,
  IconButton,
} from "@material-ui/core";
import { requestGet, requestPost } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  addBtn: {
    marginLeft: "auto",
    backgroundColor: "#656565",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "10px",
  },
  textfield: {
    "& div": {
      backgroundColor: "#fff",
    },
  },
  dialogTitleCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "lightgrey",
  },
  dialogContentCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "2px solid #656565",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none",
    },
  },
  cb: { color: "#40cf66 !important" },
  resize: { fontSize: "13px" },
});

export default function CouponAddDialog({ onClose, onAdd }) {
  const classes = useStyles();
  const [couponType, setCouponType] = useState("delivery");
  const [selGrades, setSelGrades] = useState([]);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [giveType, setGiveType] = useState("condition");
  const [useType, setUseType] = useState("used");
  const [prevDate, setPrevDate] = useState(moment().format("YYYY-MM-DD"));
  const [nextDate, setNextDate] = useState(moment().format("YYYY-MM-DD"));
  const [validDate, setValidDate] = useState(moment().format("YYYY-MM-DD"));
  const [grades, setGrades] = useState([]);
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("1");
  const dispatch = useDispatch();
  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/grades" })
      .then((x) => {
        setGrades([...x]);
      })
      .catch((e) => {
        onClose();
        dispatch(popupError(e));
      });
  }, []);
  const handleCouponAddDialog = () => {
    onClose(false);
  };

  const handleCouponType = (e) => {
    setDiscountPercent(0);
    setCouponType(e.target.value);
  };

  const handlePrevDate = (e) => {
    setPrevDate(e.target.value);
    if (giveType === "now") {
      setNextDate(e.target.value);
    }
  };
  const handleNextDate = (e) => {
    setNextDate(e.target.value);
  };
  const handleValidDate = (e) => {
    setValidDate(e.target.value);
  };

  const handleCheckValue = (e) => {
    if (selGrades.includes(e.target.value)) {
      setSelGrades([...selGrades.filter((x) => x !== e.target.value)]);
    } else {
      setSelGrades([...selGrades, e.target.value]);
    }
  };

  const handleGiveType = (e) => {
    if (e.target.value === "now") {
      setPrevDate(moment().format("YYYY-MM-DD"));
      setNextDate(moment().format("YYYY-MM-DD"));
    }
    setGiveType(e.target.value);
  };
  const handleUseType = (e) => {
    setUseType(e.target.value);
  };

  const onClickRegisterBtn = () => {
    onClose(false);
  };

  const onClickCancleBtn = () => {
    onClose(false);
  };

  const insertCoupon = async () => {
    dispatch(loadingStart);
    try {
      if (couponType !== "deivery") {
        if (isNaN(discountPercent) || parseFloat(discountPercent) > 100) {
          throw "올바른 할인율을 입력해주세요.";
        }
      }
      // if (!selGrades.length) {
      //   throw "적용대상을 선택해주세요.";
      // }
      const params = {
        start: prevDate,
        end: nextDate,
        last: validDate,
        name,
        type: couponType,
        discountPercent: discountPercent,
        quantity,
        grades: selGrades,
        setting: giveType,
        privacy: useType === "unused",
      };

      await requestPost({ url: consts.apiUrl + "/coupons", body: params });
      onClose();
      onAdd();
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      onClose={handleCouponAddDialog}
      className={classes.dialog}
    >
      <Paper elevation={0}>
        <Grid item xs={12} className={classes.dialogHeader}>
          <Typography
            variant="h5"
            style={{
              fontSize: "28px",
              lineHeight: "32px",
              fontFamily: fonts.spoqaHanSansNeoBold,
              color: "#374553",
              padding: "10px",
            }}
          >
            쿠폰 등록
          </Typography>
          <IconButton
            onClick={() => onClose(false)}
            style={{ marginLeft: "auto" }}
          >
            <Icon>close</Icon>
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ padding: "0px 20px" }}
        >
          <TableContainer first>
            <Th topleft xs={3}>
              쿠폰종류
            </Th>
            <Td xs={9} textfield topright>
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="delivery"
                    checked={couponType === "delivery"}
                    onChange={handleCouponType}
                  />
                }
                label={<Typography variant="subtitle2">무료배송</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="discount"
                    checked={couponType === "discount"}
                    onChange={handleCouponType}
                  />
                }
                label={<Typography variant="subtitle2">할인쿠폰</Typography>}
                labelPlacement="end"
              />
            </Td>
          </TableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ padding: "0px 20px" }}
        >
          <TableContainer>
            <Th xs={3}>쿠폰명</Th>
            <Td xs={9} textfield>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                value={name}
                onChange={(t) => {
                  setName(t.target.value);
                }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Td>
          </TableContainer>
        </Grid>
        {/* <Grid
          item
          xs={12}
          className={classes.df}
          style={{ padding: "0px 20px" }}
        >
          <TableContainer>
            <Th xs={3}>지급기간</Th>
            <Td xs={9} textfield>
              <input
                min={moment().format("YYYY-MM-DD")}
                type="date"
                value={prevDate}
                onChange={handlePrevDate}
              />
              <span> ~ </span>
              <input
                min={moment().format("YYYY-MM-DD")}
                disabled={giveType === "now"}
                type="date"
                value={nextDate}
                onChange={handleNextDate}
              />
            </Td>
          </TableContainer>
        </Grid> */}
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ padding: "0px 20px" }}
        >
          <TableContainer>
            <Th xs={3}>유효기간</Th>
            <Td xs={9} textfield>
              <input
                min={
                  prevDate
                    ? moment(prevDate, "YYYY-MM-DD").format("YYYY-MM-DD")
                    : ""
                }
                type="date"
                value={validDate}
                onChange={handleValidDate}
              />
            </Td>
          </TableContainer>
        </Grid>
        {couponType === "discount" && (
          <Grid
            item
            xs={12}
            className={classes.df}
            style={{ padding: "0px 20px" }}
          >
            <TableContainer>
              <Th xs={3}>할인률</Th>
              <Td xs={9} textfield>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={discountPercent}
                  onChange={(e) => {
                    setDiscountPercent(e.target.value);
                  }}
                />
                <div style={{ width: "12px" }} />%
              </Td>
            </TableContainer>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ padding: "0px 20px" }}
        >
          <TableContainer>
            <Th xs={3}>쿠폰수</Th>
            <Td xs={9} textfield>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                type="number"
                variant="outlined"
                size="small"
              />
            </Td>
          </TableContainer>
        </Grid>
        {/* <Grid
          item
          xs={12}
          className={classes.df}
          style={{ padding: "0px 20px" }}
        >
          <TableContainer>
            <Th xs={3}>적용대상</Th>
            <Td xs={9} textfield>
              {grades.map((x, i) => {
                return (
                  <FormControlLabel
                    key={i.toString()}
                    control={
                      <Checkbox
                        className={classes.cb}
                        color="default"
                        value={x.gradeId.toString()}
                        checked={selGrades.includes(x.gradeId.toString())}
                        onChange={handleCheckValue}
                      />
                    }
                    label={
                      <Typography variant="subtitle2">{x.name}</Typography>
                    }
                    labelPlacement="end"
                  />
                );
              })}
            </Td>
          </TableContainer>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          className={classes.df}
          style={{ padding: "0px 20px" }}
        >
          <TableContainer>
            <Th xs={3}>지급설정</Th>
            <Td xs={9} textfield>
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="condition"
                    checked={giveType === "condition"}
                    onChange={handleGiveType}
                  />
                }
                label={<Typography variant="subtitle2">등급 달성시</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="now"
                    checked={giveType === "now"}
                    onChange={handleGiveType}
                  />
                }
                label={<Typography variant="subtitle2">바로 지급</Typography>}
                labelPlacement="end"
              />
            </Td>
          </TableContainer>
        </Grid> */}
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ padding: "0px 20px" }}
        >
          <TableContainer last>
            <Th bottomleft xs={3}>
              사용여부
            </Th>
            <Td bottomright xs={9} textfield>
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="used"
                    checked={useType === "used"}
                    onChange={handleUseType}
                  />
                }
                label={<Typography variant="subtitle2">사용</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="unused"
                    checked={useType === "unused"}
                    onChange={handleUseType}
                  />
                }
                label={<Typography variant="subtitle2">미사용</Typography>}
                labelPlacement="end"
              />
            </Td>
          </TableContainer>
        </Grid>
        <Grid item xs={12} className={classes.dfcc} style={{ padding: "20px" }}>
          <Button
            variant="outlined"
            size="small"
            style={{
              width: "120px",
              backgroundColor: "#fff",
              borderRadius: 2,
            }}
          >
            <Typography variant="subtitle1" onClick={onClickCancleBtn}>
              취소하기
            </Typography>
          </Button>
          <Button
            onClick={insertCoupon}
            size="small"
            style={{
              marginLeft: "10px",
              width: "120px",
              backgroundColor: "#40cf66",
              color: "#fff",
              borderRadius: 2,
            }}
          >
            <Typography variant="subtitle1" onClick={onClickRegisterBtn}>
              등록하기
            </Typography>
          </Button>
        </Grid>
      </Paper>
    </Dialog>
  );
}
