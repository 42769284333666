/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Paper,
  Button,
  IconButton,
  Icon,
  Dialog,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  TextField,
  Divider,
  FormControlLabel,
  Radio,
  CardMedia,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { requestGet } from "../../services/network";
import consts from "../../libs/consts";
import StoreCategoryFirst from "./StoreCategoryFirst";
import StoreCategoryChild from "./StoreCategoryChild";
import { popupError } from "../../redux/popup/PopupActions";
import TitleBar from "../../components/titlebar/TitleBar";
const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    padding: "50px",
  },
  divider: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
    flex: 1,
    marginTop: 16,
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  boxHeader: {
    border: "1px solid lightgrey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "lightgrey",
  },
  boxContent: {
    height: "600px",
    borderLeft: "1px solid lightgrey",
    borderRight: "1px solid lightgrey",
    borderBottom: "1px solid lightgrey",
    padding: "10px",
  },
  addBtn: {
    borderRadius: 0,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  deleteBtn: {
    borderRadius: 0,
    color: "red",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#656565",
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  listItem: {
    display: "flex",
    border: "1px solid lightgrey",
  },
  secondListItem: {
    marginTop: "10px",
    marginLeft: "20px",
    border: "1px solid lightgrey",
  },
  thirdListItem: {
    marginTop: "10px",
    marginLeft: "40px",
    border: "1px solid lightgrey",
  },
  radioBtn: {
    marginLeft: "30px",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  DialogSaveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
});

export default function StoreCategoryManagement() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [onAdd, setOnAdd] = useState(false);

  const fetchData = () => {
    requestGet({
      url: consts.apiUrl + "/storecategories",
      query: { privacy: "all" },
    })
      .then((x) => {
        setCategories([...x]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <TitleBar label="스토어 카테고리관리" />
        <Grid item xs={12} className={classes.df}>
          <StoreCategoryFirst
            onFetch={fetchData}
            categories={categories}
            setCategories={setCategories}
            onAdd={onAdd}
            setOnAdd={setOnAdd}
          />
          <StoreCategoryChild
            categories={categories}
            setCategories={setCategories}
            onAdd={onAdd}
            setOnAdd={setOnAdd}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
