/* eslint-disable no-unreachable-loop */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  formatPhoneNumber,
  formatTime,
  numFormat,
} from "../../../services/utils";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import fonts from "../../../libs/fonts";
import TableContainer from "../../../components/table/TableContainer";
import Th from "../../../components/table/Th";
import Td from "../../../components/table/Td";
const useStyles = makeStyles({
  container: {
    alignSelf: "center",
    width: "90%",
    marginBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  titleGrid: {
    display: "flex",
    marginBottom: 10,
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchResultCountText: {
    color: "red",
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC",
  },
  detailCell: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  selector: {
    border: "1px solid #ddd",
    padding: "10px",
    marginLeft: 8,
    width: 100,
  },
  cb: { color: "#40cf66 !important" },
});

export default function TableSheet({
  list,
  onValueChange,
  type,
  total,
  data,
  onListInit,
  value,
  onChange,
}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.titleGrid}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            className={classes.bold}
            style={{ fontFamily: fonts.spoqaHanSansNeoBold, fontSize: 25 }}
          >
            목록
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              marginLeft: "30px",
              marginRight: "5px",
              fontFamily: fonts.spoqaHanSansNeoBold,
              fontSize: 13,
            }}
          >
            검색결과
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.searchResultCountText}
            style={{ fontFamily: fonts.spoqaHanSansNeoBold, fontSize: 13 }}
          >
            {total}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: fonts.malgunGothicSemiLight,
            fontSize: 13,
          }}
        >
          보기
          <select
            value={data.limit}
            onChange={(e) => {
              onChange({
                limit: e.target.value,
                page: 1,
              });
            }}
            className={classes.selector}
          >
            <option value="10" label="10개" />
            <option value="25" label="25개" />
            <option value="50" label="50개" />
            <option value="100" label="100개" />
            <option value="200" label="200개" />
          </select>
        </div>
      </Grid>
      <Grid item xs={12}>
        <TableContainer first>
          <Th xs={1} cb center topleft>
            <Checkbox
              onChange={(e) => {
                const c =
                  value.filter(
                    (x) =>
                      list.findIndex((y) => y.memberId === x.memberId) !== -1
                  ).length === list.length;

                if (c) {
                  onValueChange([
                    ...value.filter(
                      (x) =>
                        list.findIndex((y) => y.memberId === x.memberId) === -1
                    ),
                  ]);
                } else {
                  onValueChange([
                    ...value.filter(
                      (x) =>
                        list.findIndex((y) => y.memberId === x.memberId) === -1
                    ),
                    ...list,
                  ]);
                }
              }}
              className={classes.cb}
              color="default"
              checked={
                list.length > 0 &&
                value.filter(
                  (x) => list.findIndex((y) => y.memberId === x.memberId) !== -1
                ).length === list.length
              }
            />
          </Th>
          {type === "driver" ? (
            <>
              <Th xs={3} center>
                <Typography variant="subtitle2">가입일시</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">닉네임</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">배송기사ID</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">이름</Typography>
              </Th>
              <Th xs={3} center>
                <Typography variant="subtitle2">휴대폰번호</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">차량번호</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">상품SET</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">유통사</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">서비스지역</Typography>
              </Th>
              <Th xs={3} center topright>
                <Typography variant="subtitle2">근무상태</Typography>
              </Th>
            </>
          ) : (
            <>
              <Th xs={3} center>
                <Typography variant="subtitle2">가입일시</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">가입유형</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">이름</Typography>
              </Th>
              <Th xs={4} center>
                <Typography variant="subtitle2">이메일 (ID)</Typography>
              </Th>
              <Th xs={3} center>
                <Typography variant="subtitle2">휴대폰번호</Typography>
              </Th>
              <Th xs={7} center>
                <Typography variant="subtitle2">주소지</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">주문건수</Typography>
              </Th>
              <Th xs={2} center>
                <Typography variant="subtitle2">총 주문금액</Typography>
              </Th>
              <Th xs={3} center topright>
                <Typography variant="subtitle2">최근 주문일시</Typography>
              </Th>
            </>
          )}
        </TableContainer>
        {list.map((col, idx) => {
          return (
            <TableContainer
              key={idx}
              last={list.length === idx + 1 ? true : false}
            >
              <Td
                xs={1}
                center
                cb
                bottomleft={list.length === idx + 1 ? true : false}
              >
                <Checkbox
                  onChange={(e) => {
                    const c =
                      value.findIndex((x) => x.memberId === col.memberId) !==
                      -1;
                    if (c) {
                      onValueChange([
                        ...value.filter((x) => x.memberId !== col.memberId),
                      ]);
                    } else {
                      onValueChange([...value, col]);
                    }
                  }}
                  className={classes.cb}
                  color="default"
                  checked={
                    value.findIndex((x) => x.memberId === col.memberId) !== -1
                  }
                />
              </Td>
              {type === "driver" ? (
                <>
                  <Td xs={3} center>
                    {formatTime(col.createdAt, "YYYY-MM-DD HH:mm")}
                  </Td>

                  <Td
                    xs={2}
                    center
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      onClickRow(col.driverId);
                    }}
                  >
                    {col.nickname}
                  </Td>
                  <Td
                    xs={2}
                    center
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      onClickRow(col.driverId);
                    }}
                  >
                    {col.username}
                  </Td>
                  <Td
                    xs={2}
                    center
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      onClickRow(col.driverId);
                    }}
                  >
                    {col.name}
                  </Td>
                  <Td
                    xs={3}
                    center
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      onClickRow(col.driverId);
                    }}
                  >
                    {formatPhoneNumber(col.phone)}
                  </Td>
                  <Td xs={2} center>
                    {col.carNumber}
                  </Td>
                  <Td xs={2} center>
                    {col.receiptSet}
                  </Td>
                  <Td xs={2} center>
                    {col.companyName}
                  </Td>
                  <Td xs={2} center>
                    {col.workingArea}
                  </Td>
                  <Td
                    xs={3}
                    center
                    bottomright={list.length === idx + 1 ? true : false}
                  >
                    {col.workState === "on"
                      ? "근무중"
                      : col.workState === "off"
                      ? "퇴근"
                      : "출근안함"}
                  </Td>
                </>
              ) : (
                <>
                  <Td xs={3} center>
                    {formatTime(col.createdAt, "YYYY-MM-DD HH:mm")}
                  </Td>
                  <Td xs={2} center>
                    {col.method === "email"
                      ? "이메일"
                      : col.method === "kakaotalk"
                      ? "카카오톡"
                      : col.method === "naver"
                      ? "네이버"
                      : col.method === "google"
                      ? "구글"
                      : "애플"}
                  </Td>
                  <Td xs={2} center>
                    {col.name}
                  </Td>
                  <Td xs={4} center>
                    {col.email}
                  </Td>

                  <Td xs={3} center>
                    {formatPhoneNumber(col.phone)}
                  </Td>
                  <Td xs={7} center>
                    {col.address}
                  </Td>
                  <Td xs={2} center>
                    {numFormat(col.orderCount)}
                  </Td>
                  <Td xs={2} center>
                    {numFormat(col.orderAmount) + "원"}
                  </Td>
                  <Td
                    xs={3}
                    center
                    bottomright={list.length === idx + 1 ? true : false}
                  >
                    {formatTime(col.lastOrderAt, "YYYY-MM-DD HH:mm")}
                  </Td>
                </>
              )}
            </TableContainer>
          );
        })}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Pagination
            variant="outlined"
            shape="rounded"
            count={Math.ceil(total / parseInt(data.limit))}
            page={parseInt(data.page)}
            onChange={(e, p) => {
              if (parseInt(p) !== parseInt(data.page)) {
                onListInit();
              }
              onChange({ page: p });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
