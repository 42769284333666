/* eslint-disable multiline-ternary */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  Paper,
  RadioGroup,
  Radio,
  IconButton
} from "@material-ui/core";
import Editor from "../../components/editor/Editor";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut
} from "../../services/network";
import consts from "../../libs/consts";
import useRouter from "use-react-router";
import { formatPhoneNumber, makeHtmlFile } from "../../services/utils";
import { Add, Close } from "@material-ui/icons";
import AreaPopup from "../../components/editor/areapopup/AreaPopup";
import MemberPopup from "../../components/editor/member_popup/MemberPopup";
import moment from "moment";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px"
  },
  bold: {
    fontWeight: "bold"
  },
  deleteBtn: {
    marginLeft: "auto",
    backgroundColor: "lightgrey",
    color: "#000",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "lightgrey"
    }
  },
  agreeBtn: {
    width: "120px",
    backgroundColor: "#40cf66",
    color: "#fff",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#40cf66"
    }
  },
  cancelBtn: {
    width: "120px",
    marginLeft: "10px",
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "lightgrey"
    }
  },
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  ckeditor: {
    marginTop: "30px",
    marginBottom: "30px",
    "& .ck-editor__editable_inline": {
      minHeight: "300px"
    }
  },
  tableTitleCell: {
    backgroundColor: "lightgrey",
    borderTop: "2px solid #656565",
    borderRight: "2px solid #656565",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px"
  },
  tableCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    borderTop: "2px solid #656565"
  },
  dialogCancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  dialogSaveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B"
    }
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff"
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column"
  },
  inputSelect: {
    border: "1px solid rgba(0,0,0,0.23)",
    width: "80px",
    marginRight: 6,
    padding: "10px"
  },
  inputDate: {
    marginRight: 6,
    width: "150px",
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px"
  },
  dateRow: {
    flexDirection: "row",
    display: "flex",
    padding: "0px 10px",
    alignItems: "stretch"
  },
  label: {
    marginRight: 10,
    alignSelf: "center"
  },
  imageWrap: {
    cursor: "pointer",
    width: "250px",
    border: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "250px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  },
  fi: {
    marginRight: 10,
    marginTop: 10,
    border: "1px solid #ddd",
    borderRadius: 3,
    paddingLeft: "10px",
    whiteSpace: "nowrap"
  },
  cb: { color: "#40cf66 !important" },
  resize: { fontSize: "13px" }
});

export default function PopupAdd() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { history, location } = useRouter();
  const isLoginPopup = location.pathname.split("/").pop().startsWith("login");

  const [isAll, setIsAll] = useState("1");
  const inputRef = useRef();
  const [areaPopup, setAreaPopup] = useState(false);
  const [memberPopup, setMemberPopup] = useState(false);
  const [image, setImage] = useState(null);
  const [filterData, setFilterData] = useState([]);

  const [s1, setS1] = useState("");
  const [s2, setS2] = useState("0");
  const [s3, setS3] = useState("0");
  const [e1, setE1] = useState("");
  const [e2, setE2] = useState("0");
  const [e3, setE3] = useState("0");

  const [popupId, setPopupId] = useState();

  useEffect(() => {
    if (location.state?.popupId) {
      setPopupId(location.state.popupId);
    }
  }, [location.state]);

  useEffect(() => {
    if (popupId) {
      requestGet({
        url: consts.apiUrl + "/popups/" + popupId,
        query: {
          type: isLoginPopup ? "login" : "home"
        }
      })
        .then(x => {
          const start = moment(x.start)
            .subtract(9, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          const end = moment(x.end)
            .subtract(9, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          setS1(start.split(" ")[0]);
          setS2(parseInt(start.split(" ")[1].split(":")[0]).toString());
          setS3(parseInt(start.split(" ")[1].split(":")[1]).toString());
          setE1(end.split(" ")[0]);
          setE2(parseInt(end.split(" ")[1].split(":")[0]).toString());
          setE3(parseInt(end.split(" ")[1].split(":")[1]).toString());

          setIsAll(x.isAll ? "1" : "0");
          if (!x.isAll) {
            setFilterData([...x.members]);
          }
          setImage({ path: x.path });
        })
        .catch(e => {
          dispatch(popupError(e));
          history.goBack();
        });
    }
  }, [popupId]);

  const handleDelete = () => {
    requestDelete({
      url: consts.apiUrl + "/popups/" + popupId,
      query: {
        type: isLoginPopup ? "login" : "home"
      }
    })
      .then(x => {
        history.goBack();
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  };
  const save = async () => {
    dispatch(loadingStart);
    try {
      if (!s1 || !s2 || !s3 || !e1 || !e2 || !e3) {
        throw "기간을 설정해 주세요.";
      } else if (!popupId && !image) {
        throw "이미지를 선택해 주세요.";
      } else if (isAll === "0" && filterData.length < 1) {
        throw "핕터링 설정 값을 선택해 주세요.";
      }

      const form = new FormData();

      if (image.file) {
        form.append("file", image.file);
      } else if (popupId && !image.file) {
        form.append("path", image.path);
      }
      form.append(
        "start",
        `${s1} ${parseInt(s2) < 10 ? "0" + s2 : s2}:${
          parseInt(s3) < 10 ? "0" + s3 : s3
        }:00`
      );
      form.append(
        "end",
        `${e1} ${parseInt(e2) < 10 ? "0" + e2 : e2}:${
          parseInt(e3) < 10 ? "0" + e3 : e3
        }:00`
      );
      form.append("isAll", isAll);
      if (isAll === "0") {
        filterData.forEach(x => {
          form.append("memberIds", x.memberId);
        });
      }

      form.append("type", isLoginPopup ? "login" : "home");

      if (popupId) {
        await requestPut({
          url: consts.apiUrl + "/popups/" + popupId,
          body: form
        });
      } else {
        await requestPost({ url: consts.apiUrl + "/popups", body: form });
      }
      history.goBack();
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };
  return (
    <Grid container className={classes.container}>
      {areaPopup && (
        <AreaPopup
          onClose={() => {
            setAreaPopup(false);
          }}
          onPress={e => {
            setFilterData(d =>
              d.includes(e.postCode) ? d : [...d, e.postCode]
            );
          }}
        />
      )}
      {memberPopup && (
        <MemberPopup
          value={filterData}
          onChange={setFilterData}
          onClose={() => {
            setMemberPopup(false);
          }}
          onPress={e => {
            setFilterData(d =>
              d.findIndex(x => x.userId === d.userId) === -1 ? [...d, e] : d
            );
          }}
        />
      )}
      <Grid item xs={12}>
        <Grid item xs={12} style={{ marginBottom: "30px" }}>
          <Grid
            item
            xs={12}
            className={classes.dfsc}
            style={{ marginBottom: "10px", justifyContent: "space-between" }}>
            <Typography
              variant="h5"
              style={{
                fontSize: "28px",
                lineHeight: "32px",
                fontFamily: fonts.spoqaHanSansNeoBold,
                color: "#374553",
                paddingTop: "40px"
              }}>
              팝업 등록(수정)
            </Typography>
            {Boolean(popupId) && (
              <Button
                onClick={handleDelete}
                style={{
                  backgroundColor: "#ff4747",
                  color: "#fff",
                  width: 100
                }}
                variant="contained">
                삭제
              </Button>
            )}
          </Grid>
          <Divider
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#c5c8ce",
              marginBottom: "40px",
              marginTop: "27px"
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "30px" }}>
          <Grid item xs={12} className={classes.df}>
            <TableContainer first>
              <Th topleft>기간설정</Th>
              <Td topright xs={10} textfield>
                <div className={classes.dateRow}>
                  <span className={classes.label}>시작</span>
                  <input
                    value={s1}
                    onChange={e => {
                      setS1(e.target.value);
                    }}
                    type="date"
                    className={classes.inputDate}
                  />
                  <select
                    value={s2}
                    onChange={e => {
                      setS2(e.target.value);
                    }}
                    className={classes.inputSelect}>
                    {[...Array(24)]
                      .map((x, i) => i)
                      .map(x => {
                        let label = x;
                        if (x < 10) {
                          label = "0" + x;
                        }
                        return (
                          <option value={x} key={x} label={label + "시"} />
                        );
                      })}
                  </select>
                  <select
                    value={s3}
                    onChange={e => {
                      setS3(e.target.value);
                    }}
                    className={classes.inputSelect}>
                    {[...Array(6)]
                      .map((x, i) => i * 10)
                      .map(x => {
                        let label = x;
                        if (x < 10) {
                          label = "0" + x;
                        }
                        return (
                          <option value={x} key={x} label={label + "분"} />
                        );
                      })}
                  </select>
                </div>
                ~
                <div className={classes.dateRow}>
                  <span className={classes.label}>종료</span>
                  <input
                    value={e1}
                    onChange={e => {
                      setE1(e.target.value);
                    }}
                    type="date"
                    className={classes.inputDate}
                  />
                  <select
                    value={e2}
                    onChange={e => {
                      setE2(e.target.value);
                    }}
                    className={classes.inputSelect}>
                    {[...Array(24)]
                      .map((x, i) => i)
                      .map(x => {
                        let label = x;
                        if (x < 10) {
                          label = "0" + x;
                        }
                        return (
                          <option value={x} key={x} label={label + "시"} />
                        );
                      })}
                  </select>
                  <select
                    value={e3}
                    onChange={e => {
                      setE3(e.target.value);
                    }}
                    className={classes.inputSelect}>
                    {[...Array(6)]
                      .map((x, i) => i * 10)
                      .map(x => {
                        let label = x;
                        if (x < 10) {
                          label = "0" + x;
                        }
                        return (
                          <option value={x} key={x} label={label + "분"} />
                        );
                      })}
                  </select>
                </div>
              </Td>
            </TableContainer>
          </Grid>
          {
            //
          }
          <Grid item xs={12} className={classes.df}>
            <TableContainer>
              <Th>팝업 이미지</Th>
              <Td xs={10} textfield>
                <div
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                  className={classes.imageWrap}>
                  {image ? (
                    <img
                      src={
                        image.path
                          ? consts.fileApiUrl + "/" + image.path
                          : image.uri
                      }
                    />
                  ) : (
                    <Add />
                  )}
                </div>
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={e => {
                    const file = e.target.files[0];
                    if (file) {
                      const fs = new FileReader();
                      fs.onloadend = () => {
                        setImage({
                          uri: fs.result,
                          file
                        });
                      };
                      fs.readAsDataURL(file);
                    }

                    e.target.value = "";
                  }}
                />
              </Td>
            </TableContainer>
          </Grid>
          <Grid item xs={12} className={classes.df}>
            <TableContainer last={isAll !== "0" ? true : false}>
              <Th bottomleft={isAll !== "0" ? true : false}>
                필터링 사용 여부
              </Th>
              <Td bottomright={isAll !== "0" ? true : false} xs={10} textfield>
                <RadioGroup
                  value={isAll}
                  onChange={e => {
                    setFilterData([]);
                    setIsAll(e.target.value);
                  }}
                  row>
                  <FormControlLabel
                    control={
                      <Radio
                        className={classes.cb}
                        color="default"
                        style={{ color: "black" }}
                        value={"0"}
                      />
                    }
                    label={<Typography variant="subtitle2">사용</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        className={classes.cb}
                        color="default"
                        style={{ color: "black" }}
                        value={"1"}
                      />
                    }
                    label={<Typography variant="subtitle2">미사용</Typography>}
                  />
                </RadioGroup>
              </Td>
            </TableContainer>
          </Grid>

          {isAll === "0" && (
            <Grid item xs={12} className={classes.df}>
              <TableContainer>
                <Th>노출 필터링</Th>
                <Td xs={10} textfield>
                  <RadioGroup value={"member"} row>
                    <FormControlLabel
                      control={
                        <Radio
                          className={classes.cb}
                          color="default"
                          style={{ color: "black" }}
                          value={"member"}
                        />
                      }
                      label={
                        <Typography variant="subtitle2">고객 선택</Typography>
                      }
                    />
                    <Button
                      onClick={() => {
                        setMemberPopup(true);
                      }}
                      variant="outlined"
                      style={{ marginRight: 20 }}>
                      조회/추가
                    </Button>
                  </RadioGroup>
                </Td>
              </TableContainer>
            </Grid>
          )}
          {isAll === "0" && (
            <Grid item xs={12} className={classes.df}>
              <TableContainer last>
                <Th bottomleft>필터링 설정 값</Th>
                <Td bottomright xs={10} textfield>
                  {filterData.map(x => {
                    return (
                      <div key={x.toString()} className={classes.fi}>
                        {`${x.name} (${formatPhoneNumber(x.phone)})`}
                        <IconButton
                          onClick={() => {
                            setFilterData(d => d.filter(y => x !== y));
                          }}>
                          <Close />
                        </IconButton>
                      </div>
                    );
                  })}
                </Td>
              </TableContainer>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.ckeditor}
          style={{ flexDirection: "column", height: "500px" }}>
          <Grid
            item
            xs={12}
            className={classes.dfcc}
            style={{ marginTop: "30px" }}>
            <Button onClick={save} className={classes.agreeBtn}>
              <Typography variant="subtitle1">
                {popupId ? "수정" : "등록"}
              </Typography>
            </Button>
            <Button
              onClick={history.goBack}
              variant="outlined"
              className={classes.cancelBtn}>
              <Typography variant="subtitle1">취소</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
