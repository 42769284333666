/* eslint-disable no-unreachable-loop */
import React from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { formatTime, parseSearch, replaceQuery } from "../../services/utils";
import { useDispatch } from "react-redux";
import consts from "../../libs/consts";
import moment from "moment";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { requestPatch } from "../../services/network";
import { popupError } from "../../redux/popup/PopupActions";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import fonts from "../../libs/fonts";
const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "50%",
    marginBottom: "50px"
  },
  bold: {
    fontWeight: "bold"
  },
  titleGrid: {
    marginBottom: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  searchResultCountText: {
    color: "red",
    fontWeight: "bold"
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC"
  },
  detailCell: {
    cursor: "pointer",
    textDecoration: "underline"
  },
  btn: {
    borderRadius: 0,
    marginLeft: "auto"
  },
  selector: {
    border: "1px solid #ddd",
    padding: "10px",
    marginLeft: 8,
    width: 100
  }
});

export default function TableSheet({ listData, length, page, onFetch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { history, location, match } = useReactRouter();
  const query = parseSearch(location);
  const isLoginPopup = location.pathname.split("/").pop().startsWith("login");

  const onClickRow = notice => {
    history.push(
      "/customer_service/popup_add" + (isLoginPopup ? "/login" : ""),
      {
        popupId: notice.popupId
      }
    );
  };

  const handleSort = (id, action) => () => {
    requestPatch({
      url: consts.apiUrl + "/popups/" + id,
      body: {
        type: isLoginPopup ? "login" : "home",
        action: `sort-${action}`
      }
    })
      .then(x => {
        onFetch();
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.titleGrid}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            style={{
              fontSize: "25px",
              lineHeight: "32px",
              fontFamily: fonts.spoqaHanSansNeoBold,
              color: "#374553"
            }}>
            목록
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ marginLeft: "30px", marginRight: "5px" }}>
            검색결과
          </Typography>
          <Typography variant="subtitle2">{length}</Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TableContainer first>
            <Th topleft xs={4} center>
              이미지
            </Th>
            <Th xs={1} center>
              상태
            </Th>
            <Th xs={6} center>
              기간
            </Th>
            <Th xs={2} center>
              우선순위
            </Th>
            <Th xs={2} center>
              필터링 여부
            </Th>
            <Th topright xs={2} center>
              등록일자
            </Th>
          </TableContainer>
        </Grid>
        {listData.map((col, idx) => {
          return (
            <Grid item xs={12}>
              <TableContainer
                last={listData.length === idx + 1 ? true : false}
                onClick={e => onClickRow(col)}
                key={idx}>
                <Td
                  bottomleft={listData.length === idx + 1 ? true : false}
                  xs={4}
                  center>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex"
                    }}>
                    <div
                      style={{
                        border: "1px solid #ddd",
                        width: 150,
                        alignSelf: "center"
                      }}>
                      <img
                        alt="ar"
                        src={consts.fileApiUrl + "/" + col.path}
                        style={{
                          width: 150,
                          height: 150,
                          objectFit: "contain"
                        }}
                      />
                    </div>
                  </div>
                </Td>

                <Td xs={1} center>
                  {col.status === "finish"
                    ? "종료"
                    : col.status === "wait"
                    ? "대기"
                    : "진행"}
                </Td>

                <Td xs={6} center>
                  {moment(col.start)
                    .subtract(9, "hours")
                    .format("YYYY-MM-DD HH:mm")}{" "}
                  ~{" "}
                  {moment(col.end)
                    .subtract(9, "hours")
                    .format("YYYY-MM-DD HH:mm")}
                </Td>

                <Td
                  xs={2}
                  center
                  onClick={e => {
                    e.stopPropagation();
                  }}>
                  <IconButton onClick={handleSort(col.popupId, "down")}>
                    <ArrowDropDown />
                  </IconButton>
                  <IconButton onClick={handleSort(col.popupId, "up")}>
                    <ArrowDropUp />
                  </IconButton>
                </Td>
                <Td xs={2} center>
                  {col.isAll ? "전체" : "선택"}
                </Td>
                <Td
                  bottomright={listData.length === idx + 1 ? true : false}
                  xs={2}
                  center>
                  {formatTime(col.createdAt, "YYYY-MM-DD HH:mm")}
                </Td>
              </TableContainer>
            </Grid>
          );
        })}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px"
          }}>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={page}
            onChange={(e, p) => {
              replaceQuery(history, location, { page: p });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
