/* eslint-disable no-unreachable-loop */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Grid,
  Dialog,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Button,
  TableCell,
  Checkbox,
  CardMedia,
  TextField,
  Paper,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { requestGet } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import { getFileUri, numFormat } from "../../services/utils";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "50%",
    marginBottom: "100px",
  },
  bold: {
    fontWeight: "bold",
  },
  titleGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid #B7ACAC",
    height: "50px",
    padding: "10px",
  },
  tableGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid #B7ACAC",
    padding: "10px",
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC",
  },
  headerBtn: {
    backgroundColor: "#1C1B1B",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  initializationBtn: {
    width: "150px",
    backgroundColor: "#fff",
    border: "1px solid #dddddd",
    borderRadius: 2,
  },
  whiteBtn: {
    width: "100px",
    backgroundColor: "#fff",
    border: "1px solid #dddddd",
    borderRadius: 2,
  },
  searchBtn: {
    width: "150px",
    backgroundColor: "#40cf66",
    color: "#fff",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#195227",
    },
  },
  searchBox: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  divider: {
    width: "100%",
    // marginTop: "10px",
    // marginBottom: "10px",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  btn: {
    width: "100px",
    border: "1px solid #B7ACAC",
    borderRadius: 0,
    marginRight: "10px",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    backgroundColor: "#40cf66",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#40cf66",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  select: {
    width: "150px",
    "& div": {
      backgroundColor: "#fff",
    },
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  text: {
    fontFamily: fonts.spoqaHanSansNeoBold,
    fontSize: "15px",
    color: "#374553",
  },
  cb: { color: "#40cf66 !important" },
});

const initQuery = {
  keyword: "",
  categoryId: "",
  childCategoryId: "",
  limit: 5,
  page: 1,
};
export default function SetEditorProduct({
  distributionId,
  products,
  setProduts,
}) {
  const classes = useStyles();
  const [query, setQuery] = useState({
    ...initQuery,
  });
  const dispatch = useDispatch();
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const sels = data
    .filter((x) => x.checked)
    .filter((x) => {
      if (products.filter((y) => y.productId === x.productId).length) {
        return false;
      }
      return true;
    });

  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/products/categories" })
      .then((x) => {
        setCategories([
          ...x.categories.map((y) => {
            y.categories = x.childCategories.filter(
              (z) => y.productCategoryId === z.parentId
            );
            return y;
          }),
        ]);
      })
      .catch((x) => {
        dispatch(popupError(x));
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [query.page, products]);

  const fetchData = () => {
    requestGet({
      url: consts.apiUrl + "/products",
      query: {
        ...query,
        state: "on",
        distributionId,
        withouts: `${products.map((x) => x.productId).join(",")}`,
      },
    })
      .then((x) => {
        setData([
          ...x.results.filter((x) => {
            if (
              products.filter((z) => z.productId === x.productId).length >= 1
            ) {
              return false;
            }
            return true;
          }),
        ]);
        setCount(x.totalCount);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const onClickRegisterBtn = () => {
    if (sels.length) {
      setRegisterDialogOpen(true);
    }
  };

  const handleRegisterDialogClose = () => {
    setRegisterDialogOpen(false);
  };

  const onClickRegisterDialogSaveBtn = () => {
    setRegisterDialogOpen(false);
    if (!sels.length) {
      return;
    }
    setProduts([
      ...products,
      ...sels.map((x) => ({ ...x, quantity: "", checked: false })),
    ]);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          padding: "10px",
          backgroundColor: "#f7f8f9",
          marginBottom: "30px",
        }}
      >
        <Grid item xs={12} className={classes.dfsc} style={{ padding: "20px" }}>
          <Grid item xs={1} className={classes.dfsc}>
            <Typography variant="subtitle1" className={classes.text}>
              카테고리
            </Typography>
          </Grid>
          <Grid item xs={11} className={classes.dfsc}>
            <TextField
              select
              variant="outlined"
              size="small"
              defaultValue={query.categoryId}
              value={query.categoryId}
              onChange={(e) => {
                setQuery({
                  ...query,
                  categoryId: e.target.value,
                  childCategoryId: "",
                });
              }}
              className={classes.select}
            >
              <option value={""} className={classes.selectForm}>
                전체
              </option>
              {categories &&
                categories.map((x) => {
                  return (
                    <option
                      value={x.productCategoryId}
                      className={classes.selectForm}
                    >
                      {x.name}
                    </option>
                  );
                })}
            </TextField>
            <TextField
              select
              variant="outlined"
              size="small"
              className={classes.select}
              style={{ marginLeft: "30px", marginRight: "30px" }}
              defaultValue={query.childCategoryId}
              value={query.childCategoryId}
              onChange={(e) => {
                setQuery({ ...query, childCategoryId: e.target.value });
              }}
            >
              <option value={""} className={classes.selectForm}>
                전체
              </option>
              {categories &&
                categories.filter(
                  (x) => x.productCategoryId === query.categoryId
                )[0] &&
                categories
                  .filter((x) => x.productCategoryId === query.categoryId)[0]
                  .categories.map((x) => {
                    return (
                      <option
                        value={x.productCategoryId}
                        className={classes.selectForm}
                      >
                        {x.name}
                      </option>
                    );
                  })}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.df} style={{ padding: "20px" }}>
          <Grid item xs={1} className={classes.dfsc}>
            <Typography variant="subtitle1" className={classes.text}>
              검색
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.dfsc}>
            <TextField
              className={classes.searchBox}
              variant="outlined"
              fullWidth
              size="small"
              placeholder="상품명, 상품코드"
              value={query.keyword}
              onChange={(e) => {
                setQuery({ ...query, keyword: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.dfsc}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                className={classes.initializationBtn}
                onClick={() => {
                  setQuery({ ...initQuery });
                }}
                style={{ marginLeft: "50px", color: "#555" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontFamily: fonts.spoqaHanSansNeoBold,
                    fontSize: "13px",
                  }}
                >
                  초기화
                </Typography>
              </Button>
              <Button
                variant="contained"
                className={classes.searchBtn}
                onClick={fetchData}
                style={{ marginLeft: "20px" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontFamily: fonts.spoqaHanSansNeoBold,
                    fontSize: "13px",
                  }}
                >
                  검색
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.dfsc}
        style={{ margin: "10px 0px" }}
      >
        <Button
          variant="contained"
          className={classes.whiteBtn}
          onClick={onClickRegisterBtn}
        >
          <Typography
            variant="subtitle1"
            style={{ fontFamily: fonts.spoqaHanSansNeoBold, fontSize: "13px" }}
          >
            등록
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.dfsc}>
        <TableContainer first>
          <Th topleft cb center xs={1}>
            <Checkbox
              className={classes.cb}
              color="default"
              checked={
                data.length &&
                data.filter((x) => x.checked).length === data.length
              }
              onChange={(e) => {
                const checked = e.target.checked;
                setData([...data.map((x) => ({ ...x, checked }))]);
              }}
            />
          </Th>
          {data.sort === "sort" && (
            <Th center xs={2}>
              노출순서
            </Th>
          )}
          <Th center xs={2}>
            유통사코드
          </Th>
          <Th center xs={2}>
            상품코드
          </Th>
          <Th center xs={2}>
            이미지
          </Th>
          <Th center xs={2}>
            카테고리
          </Th>
          <Th center xs={8}>
            상품명
          </Th>
          <Th center xs={4}>
            유통사
          </Th>
          <Th topright center xs={2}>
            판매가격
          </Th>
        </TableContainer>
      </Grid>
      {data.map((col, idx) => {
        return (
          <Grid item xs={12} className={classes.dfsc}>
            <TableContainer
              last={data.length === idx + 1 ? true : false}
              checked={col.checked ? true : false}
              key={idx}
            >
              <Td
                bottomleft={data.length === idx + 1 ? true : false}
                cb={
                  products.filter((x) => x.productId === col.productId)[0]
                    ? false
                    : true
                }
                center
                xs={1}
              >
                {Boolean(
                  products.filter((x) => x.productId === col.productId)[0]
                ) ? (
                  <div>등록됨</div>
                ) : (
                  <Checkbox
                    className={classes.cb}
                    color="default"
                    checked={col.checked || false}
                    onChange={(e) => {
                      if (
                        Boolean(
                          products.filter(
                            (x) => x.productId === col.productId
                          )[0]
                        )
                      ) {
                        return;
                      }
                      const checked = e.target.checked;
                      setData([
                        ...data.map((x, i) => ({
                          ...x,
                          checked: idx === i ? checked : x.checked,
                        })),
                      ]);
                    }}
                  />
                )}
              </Td>
              {data.sort === "sort" && (
                <Td center xs={2}>
                  <IconButton onClick={handleSort(col, "up")} size="small">
                    <Icon>arrow_drop_up</Icon>
                  </IconButton>
                  <IconButton onClick={handleSort(col, "down")} size="small">
                    <Icon>arrow_drop_down</Icon>
                  </IconButton>
                </Td>
              )}

              <Td center xs={2}>
                {col.dCode}
              </Td>
              <Td center xs={2}>
                {col.code}
              </Td>
              <Td center xs={2}>
                <CardMedia
                  component="img"
                  height="70"
                  image={getFileUri(col.path)}
                  style={{ borderRadius: "5px" }}
                />
              </Td>
              <Td center xs={2}>
                {col.category}
              </Td>
              <Td center xs={8}>
                {col.name}
              </Td>
              <Td center xs={4}>
                {col.dName}
              </Td>
              <Td
                bottomright={data.length === idx + 1 ? true : false}
                center
                xs={2}
              >
                {numFormat(col.price)}원
              </Td>
            </TableContainer>
          </Grid>
        );
      })}

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Pagination
          variant="outlined"
          shape="rounded"
          count={Math.ceil(count / query.limit)}
          onChange={(e, p) => {
            setQuery({ ...query, page: p });
          }}
        />
      </Grid>

      <Dialog open={registerDialogOpen} onClose={handleRegisterDialogClose}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">등록 처리</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                {sels.length}개 상품이 선택되었습니다.
              </Typography>
              <Typography variant="subtitle1">
                추가 / 등록처리 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setRegisterDialogOpen(false)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                onClick={onClickRegisterDialogSaveBtn}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
}
