/* eslint-disable multiline-ternary */
import React, { useEffect, useRef, useState } from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";
import qs from "query-string";
import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControlLabel,
  Radio,
  CardMedia,
  Icon
} from "@material-ui/core";
import { requestDelete, requestFile, requestGet } from "../../services/network";
import consts from "../../libs/consts";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import fonts from "../../libs/fonts";

const useStyles = makeStyles({
  container: {
    width: "calc(100% )",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px"
  },
  titleGrid: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px"
  },
  title: {
    display: "flex",
    alignItems: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginBottom: "20px"
  },
  subtitle: {
    marginTop: "10px"
    // marginLeft: "20px",
  },
  divider: {
    width: "100%"
  },
  titledivider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#c5c8ce",
    marginBottom: "40px",
    marginTop: "20px"
  },
  bold: {
    fontWeight: "bold"
  },
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  infoGrid: {
    // border: "1px solid #B7ACAC",
    backgroundColor: "#fff",
    marginBottom: "20px"
  },
  infoContent: {
    // paddingLeft: "20px",
    // paddingRight: "20px",
    // paddingBottom: "10px",
  },
  infocell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px"
  },
  radioBtn: {
    marginLeft: "20px",
    color: "#40cf66 !important"
  },
  imageAddBtn: {
    width: "100px",
    height: "100px",
    border: "2px dotted #efefef"
  },
  ckeditor: {
    // marginTop: "30px",
    marginBottom: "30px",
    "& .ck-editor__editable_inline": {
      minHeight: "300px"
    }
  },
  productAddBtn: {
    width: "120px",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#000"
    }
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)"
    },
    fontFamily: fonts.malgunGothic,
    fontSize: "13px"
  },
  withDrawalBtn: {
    // backgroundColor: "#e9e9e9",
    margin: "10px",
    width: "100px",
    "&:hover": {
      backgroundColor: "#e9e9e9"
    }
  },
  resize: { fontSize: "13px" }
});
import { useDispatch } from "react-redux";
import Editor from "../../components/editor/Editor";
import { getFileUri, makeHtmlFile } from "../../services/utils";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import TableTitle from "../../components/table/TableTitle";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import TableContainer from "../../components/table/TableContainer";
import useMember from "../../../hooks/useMember";
import InventoryDetail from "../list/InventoryDetail";
import SharedReview from "../../../components/review/SharedReview";

export default function ProductAdd() {
  const classes = useStyles();
  const { history, location } = useReactRouter();
  const query = qs.parse(location.search);
  const [distributor, setDistributor] = useState("선택하기");
  const [productName, setProductName] = useState("");
  const [productDescrition, setProductDescrition] = useState("");
  const [category1, setCategory1] = useState("선택하기");
  const [category2, setCategory2] = useState("선택하기");
  const [supplyPrice, setSupplyPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [review, setReview] = useState({ length: 0, rating: 0 });
  const [radioSelectValue, setRadioSelectValue] = useState("not_apply");
  const [salesStatus, setSalesStatus] = useState("on");
  const dispatch = useDispatch();
  const [orderCount, setOrderCount] = useState("");
  const [detail, setDetail] = useState("");
  const [testImageArr, setTestImageArr] = useState([]);
  const [testImageArr2, setTestImageArr2] = useState([]);
  const [testVideoArr, setTestVideoArr] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ds, setDs] = useState([]);
  const [htmlPath, setHtmlPath] = useState("");
  const [productId, setProductId] = useState();
  const er = useRef();
  const [productBarcode, setProductBarCode] = useState("");
  const [negaDesc, setNegaDesc] = useState("");
  const [content, setContent] = useState("");
  const [pri1, setPri1] = useState("");
  const [pri2, setPri2] = useState("");
  const [pri3, setPri3] = useState("");
  const [pri4, setPri4] = useState("");
  const [pri5, setPri5] = useState("");
  const [pri6, setPri6] = useState("");
  const [pri7, setPri7] = useState("");
  const { distributionId, isPartner } = useMember();
  const [sel, setSel] = useState(null);
  const [saleRadio, setSaleRadio] = useState(2);
  const [sale, setSale] = useState();
  const [reviewDialog, setReviewDialog] = useState(false);

  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/products/categories" })
      .then(x => {
        setCategories([
          ...x.categories.map(y => {
            y.categories = x.childCategories.filter(
              z => y.productCategoryId === z.parentId
            );
            return y;
          })
        ]);
      })
      .catch(x => {
        dispatch(popupError(x));
      });
    requestGet({ url: consts.apiUrl + "/distributions" })
      .then(x => {
        setDs([...x.results]);
      })
      .catch(x => {
        dispatch(popupError(x));
      });

    if (location.state?.productId) {
      setProductId(location.state?.productId);
    } else if (query?.productId) {
      setProductId(query?.productId);
    }
  }, []);

  useEffect(() => {
    if (productId) {
      requestGet({
        url: consts.apiUrl + "/products/" + productId,
        query: { review: "1" }
      })
        .then(data => {
          setReview({
            length: data.reviewLength,
            rating: data.reviewRating
          });
          setSale(data.discountPerc);
          setProductBarCode(data.barCode);
          setDistributor(data.distributionId);
          setProductName(data.name);
          setProductDescrition(data.description);
          setCategory1(data.categoryId);
          setCategory2(data.childCategoryId);
          setSellingPrice(data.price);
          setSupplyPrice(data.originPrice);
          setSalesStatus(data.state);
          setContent(data.content);
          setNegaDesc(data.negaDesc);
          setPri1(data.pri1);
          setPri2(data.pri2);
          setPri3(data.pri3);
          setPri4(data.pri4);
          setPri5(data.pri5);
          setPri6(data.pri6);
          setPri7(data.pri7);

          if (data.maxQuantity === null) {
            setRadioSelectValue("not_apply");
          } else {
            setRadioSelectValue("apply");
            setOrderCount(data.maxQuantity);
          }
          setHtmlPath(data.htmlPath);
          setTestImageArr([
            ...data.images.map(x => {
              return {
                uri: getFileUri(x.path),
                ...x
              };
            })
          ]);

          setTestImageArr2([
            ...data.introImages.map(x => {
              return {
                uri: getFileUri(x.path),
                ...x
              };
            })
          ]);
          data.videoImage ? setTestVideoArr([data.videoImage]) : null;
        })
        .catch(e => {
          dispatch(popupError(e));
          history.goBack();
        });
    }
  }, [productId]);

  useEffect(() => {
    if (categories.length !== 0 && category1 !== "선택하기") {
      let f = categories.filter(x => x.productCategoryId === category1)[0];
      if (pri1 === "") {
        setPri1(f.pri1);
      }
      if (pri2 === "") {
        setPri2(f.pri2);
      }
      if (pri3 === "") {
        setPri3(f.pri3);
      }
      if (pri4 === "") {
        setPri4(f.pri4);
      }
      if (pri5 === "") {
        setPri5(f.pri5);
      }
      if (pri6 === "") {
        setPri6(f.pri6);
      }
      if (pri7 === "") {
        setPri7(f.pri7);
      }
      if (negaDesc === "") {
        setNegaDesc(f.negaDesc);
      }
    }
  }, [categories, category1]);

  const handleProductBarcode = e => {
    if (e.target.value.length > 100) {
      return;
    }
    setProductBarCode(e.target.value);
  };

  const handleDistributor = e => {
    setDistributor(e.target.value);
  };

  const handleProductName = e => {
    setProductName(e.target.value);
  };

  const handleProductDescription = e => {
    setProductDescrition(e.target.value);
  };

  const handleCategory1 = e => {
    setCategory1(e.target.value);
  };

  const handleCategory2 = e => {
    setCategory2(e.target.value);
  };

  const handleSupplyPrice = e => {
    setSupplyPrice(e.target.value);
  };

  const handleSellingPrice = e => {
    setSellingPrice(e.target.value);
  };

  const handleRadioBtn = e => {
    setRadioSelectValue(e.target.value);
    setOrderCount("");
  };

  const handleSalesStatus = e => {
    setSalesStatus(e.target.value);
  };
  const handleNegaDesc = e => {
    setNegaDesc(e.target.value);
  };
  const handleContent = e => {
    setContent(e.target.value);
  };
  const handlePri1 = e => {
    setPri1(e.target.value);
  };
  const handlePri2 = e => {
    setPri2(e.target.value);
  };
  const handlePri3 = e => {
    setPri3(e.target.value);
  };
  const handlePri4 = e => {
    setPri4(e.target.value);
  };
  const handlePri5 = e => {
    setPri5(e.target.value);
  };
  const handlePri6 = e => {
    setPri6(e.target.value);
  };
  const handlePri7 = e => {
    setPri7(e.target.value);
  };

  const onClickCancelBtn = () => {
    history.goBack();
  };

  const delVideo = () => {
    requestDelete({
      url: consts.apiUrl + `/products/${productId}/video`
    });
  };

  const onClickSaveBtn = async () => {
    dispatch(loadingStart);
    // 세이브 로직구현
    // 모든 옵션 초기화 필요
    //history.push("/product_management/product_list");
    const params = {
      distributionId: isPartner ? distributionId : distributor,
      name: productName,
      description: productDescrition,
      categoryId: category1,
      barCode: productBarcode,
      childCategoryId: category2,
      price: sellingPrice,
      maxQuantity: radioSelectValue === "not_apply" ? null : orderCount,
      originPrice: supplyPrice,
      state: salesStatus,
      negaDesc: negaDesc,
      content: content,
      pri1: pri1,
      pri2: pri2,
      pri3: pri3,
      pri4: pri4,
      pri5: pri5,
      pri6: pri6,
      pri7: pri7,
      discountPerc:
        saleRadio === 1
          ? parseInt(
              sellingPrice - Math.floor((sellingPrice * (100 - sale)) / 100)
            )
          : parseInt(sale) || "0"
    };

    try {
      if (!distributionId && !params.distributionId) {
        throw "유통사를 선택해주세요.";
      } else if (!params.name) {
        throw "상품명을 입력해주세요.";
      } else if (!params.barCode) {
        throw "바코드를 입력해주세요.";
      } else if (!params.description) {
        throw "상품요약설명을 입력해주세요.";
      } else if (!params.categoryId) {
        throw "카테고리를 선택해주세요.";
      } else if (params.price === "" || params.originPrice === "") {
        throw "올바른 가격을 입력해주세요.";
      } else if (params.maxQuantity !== null && isNaN(params.maxQuantity)) {
        throw "주문 가능 수량은 숫자만 입력가능합니다.";
      } else if (!testImageArr.length) {
        throw "상품 소개 이미지를 등록해주세요.";
      } else if (testImageArr.length >= 10) {
        throw "상품 소개 이미지는 10개까지 등록 가능합니다.";
      } else if (params.content === "") {
        throw "상품설명을 입력해주세요.";
      } else if (params.negaDesc === "") {
        throw "상품 교환 반품 안내를 입력해주세요.";
      } else if (!testImageArr2.length) {
        throw "상품 설명 이미지를 등록해주세요.";
      } else if (testImageArr2.length >= 10) {
        throw "상품 설명 이미지는 10개까지 등록 가능합니다.";
      } // else if (saleRadio === 1 && sale > 100) {
      //throw "할인율은 100%를 초과할 수 없습니다.";
      // }
      else if (saleRadio === 2 && sale > sellingPrice) {
        throw "할인금액은 판매가격을 초과할 수 없습니다.";
      }
      const form = new FormData();
      const form2 = new FormData();

      Object.keys(params).map(key => {
        form.append(key, params[key] ? params[key] : "");
      });
      testImageArr
        .filter(x => !x.productImageId)
        .forEach(x => {
          form.append("files", x);
        });

      testImageArr
        .filter(x => x.productImageId)
        .forEach(x => {
          form.append("productImageIds", x.productImageId);
        });

      testImageArr2
        .filter(x => !x.productImageId)
        .forEach(x => {
          form.append("files2", x);
        });

      testImageArr2
        .filter(x => x.productImageId)
        .forEach(x => {
          form.append("productImageIds2", x.productImageId);
        });

      // form.append("html", makeHtmlFile(er.current.getHtml() || ""));
      testVideoArr
        .filter(x => !x?.productImageId)
        .forEach(x => {
          form2.append("video", x);
        });
      if (productId) {
        await requestFile({
          url: consts.apiUrl + "/products/" + productId,
          form: form,
          put: true
        }).then(x => {
          if (
            Boolean(
              testVideoArr.filter(x => Boolean(!x?.productImageId)).length
            )
          ) {
            requestFile({
              url: consts.apiUrl + `/products/${productId}/video`,
              form: form2,
              put: true
            });
          } else if (Boolean(!testVideoArr.length)) {
            delVideo();
          }
        });
      } else {
        await requestFile({
          url: consts.apiUrl + "/products",
          form: form
        }).then(x => {
          if (Boolean(testVideoArr.length)) {
            requestFile({
              url: consts.apiUrl + `/products/${x.productId}/video`,
              form: form2,
              put: true
            });
          }
        });
      }

      if (testVideoArr.length) {
        dispatch(popupMessage({ message: "저장완료" }));
      } else {
        dispatch(
          popupMessage({ message: "저장완료(동영상 미등록 되었습니다.)" })
        );
      }
      history.goBack();
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  const onChangeImage = e => {
    const file = e.target.files[0];
    if (file) {
      const f = new FileReader();
      f.onload = () => {
        file.uri = f.result;
        setTestImageArr([...testImageArr, file]);
      };
      f.readAsDataURL(file);
      e.target.value = "";
    }
  };

  const onChangeImage2 = e => {
    const file = e.target.files[0];
    if (file) {
      const f = new FileReader();
      f.onload = () => {
        file.uri = f.result;
        setTestImageArr2([...testImageArr2, file]);
      };
      f.readAsDataURL(file);
      e.target.value = "";
    }
  };

  const onChangeVideo = e => {
    const file = e.target.files[0];
    if (file) {
      const f = new FileReader();
      f.onload = () => {
        file.uri = f.result;
        setTestVideoArr([file]);
      };
      f.readAsDataURL(file);
      e.target.value = "";
    }
  };

  const handleOrderCount = e => {
    setOrderCount(e.target.value);
  };
  // console.log(htmlPath);
  return (
    <>
        <SharedReview
          open={reviewDialog}
          onClose={() => {
            setReviewDialog(false);
          }}
          type="product"
          id={productId}
        />
      {sel && (
        <InventoryDetail
          {...sel}
          onClose={() => {
            setSel(null);
          }}
        />
      )}
      <Grid container className={classes.container}>
        <Typography
          variant="h5"
          style={{
            fontSize: "28px",
            lineHeight: "32px",
            fontFamily: fonts.spoqaHanSansNeoBold,
            color: "#374553",
            paddingTop: "40px"
          }}>
          {productId ? "상품수정" : "상품등록"}
        </Typography>
        <Divider className={classes.titledivider} />
        <Grid item xs={12} style={{ flexDirection: "column" }}>
          <Grid item xs={12} className={classes.df}>
            <Grid
              item
              xs={5}
              className={[classes.dfsc, classes.subtitle].join(" ")}>
              <TableTitle>기본정보</TableTitle>
            </Grid>
            <Grid
              item
              xs={7}
              className={classes.df}
              style={{ justifyContent: "flex-end" }}>
              {productId && (
                <Button
                  onClick={() => setReviewDialog(true)}
                  variant="outlined"
                  color="primary"
                  className={classes.withDrawalBtn}>
                  리뷰 확인
                </Button>
              )}
              {productId && (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.withDrawalBtn}
                  onClick={() => {
                    requestDelete({
                      url: consts.apiUrl + "/products/" + productId
                    })
                      .then(x => {
                        history.replace("/product_management/product_list");
                        //window.close()
                      })
                      .catch(e => {
                        dispatch(popupError(e));
                      });
                  }}>
                  <Typography
                    style={{ color: "#ff0000", fontWeight: "bold" }}
                    variant="subtitle1">
                    삭제
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column" }}>
            <Grid item xs={12} className={classes.df}>
              <TableContainer first>
                {!isPartner && (
                  <>
                    <Th topleft xs={2}>
                      유통사/상품코드
                    </Th>
                    <Td textfield xs={4}>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={distributor}
                        onChange={handleDistributor}
                        InputProps={{
                          classes: {
                            input: classes.resize
                          }
                        }}>
                        <option
                          value={""}
                          selected={!distributor}
                          disabled
                          className={classes.selectForm}>
                          선택하기
                        </option>
                        {ds.map((item, idx) => {
                          return (
                            <option
                              key={idx}
                              value={item.distributionId}
                              selected={distributor === item.distributionId}
                              className={classes.selectForm}>
                              {item.companyName}
                            </option>
                          );
                        })}
                      </TextField>
                    </Td>
                  </>
                )}
                <Th xs={2}>바코드</Th>
                <Td topright textfield xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={productBarcode}
                    onChange={handleProductBarcode}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
              </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.df}>
              <TableContainer>
                <Th xs={2}>상품명</Th>
                <Td textfield xs={10}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={productName}
                    onChange={handleProductName}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
              </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.df}>
              <TableContainer>
                <Th xs={2}>상품요약설명</Th>
                <Td textfield xs={10}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    size="small"
                    variant="outlined"
                    value={productDescrition}
                    onChange={handleProductDescription}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
              </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.df}>
              <TableContainer>
                <Th xs={2}>카테고리</Th>
                <Td textfield xs={10}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={category1}
                    onChange={handleCategory1}
                    style={{ width: "300px" }}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}>
                    <option
                      value={"선택하기"}
                      selected
                      disabled
                      className={classes.selectForm}>
                      선택하기
                    </option>
                    {categories.map((item, idx) => {
                      return (
                        <option
                          key={idx}
                          value={item.productCategoryId}
                          className={classes.selectForm}>
                          {item.name}
                        </option>
                      );
                    })}
                  </TextField>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={category2.toString()}
                    onChange={handleCategory2}
                    style={{ marginLeft: "20px", width: "300px" }}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}>
                    <option
                      value={"선택하기"}
                      selected
                      disabled
                      className={classes.selectForm}>
                      선택하기
                    </option>
                    {categories.filter(
                      x => x.productCategoryId === category1
                    )[0] &&
                      categories
                        .filter(x => x.productCategoryId === category1)[0]
                        .categories.map((item, idx) => {
                          return (
                            <option
                              key={idx}
                              value={item.productCategoryId.toString()}
                              className={classes.selectForm}>
                              {item.name}
                            </option>
                          );
                        })}
                  </TextField>
                </Td>
              </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.df}>
              <TableContainer>
                <Th xs={2}>공급가격</Th>
                <Td textfield xs={4}>
                  <TextField
                    type="number"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={supplyPrice}
                    onChange={handleSupplyPrice}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                    inputProps={{
                      style: { textAlign: "right" }
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginLeft: "10px",
                      color: "#000",
                      width: "300px"
                    }}>
                    원
                  </Typography>
                </Td>
                <Th xs={2}>판매가격</Th>
                <Td textfield xs={4}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    variant="outlined"
                    value={sellingPrice}
                    onChange={handleSellingPrice}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                    inputProps={{
                      style: { textAlign: "right" }
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginLeft: "10px",
                      color: "#000",
                      width: "300px"
                    }}>
                    원
                  </Typography>
                </Td>
              </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.df}>
              <TableContainer>
                <Th xs={2}>주문가능수량</Th>
                <Td textfield xs={4}>
                  <FormControlLabel
                    control={
                      <Radio
                        color="default"
                        className={classes.radioBtn}
                        checked={radioSelectValue === "not_apply"}
                        onChange={handleRadioBtn}
                        value="not_apply"
                      />
                    }
                    label={<Typography variant="subtitle2">미적용</Typography>}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="default"
                        className={classes.radioBtn}
                        checked={radioSelectValue === "apply"}
                        onChange={handleRadioBtn}
                        value="apply"
                        style={{ fontSize: "13px" }}
                      />
                    }
                    label={<Typography variant="subtitle2">적용</Typography>}
                    labelPlacement="end"
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    value={orderCount}
                    disabled={radioSelectValue === "not_apply"}
                    onChange={handleOrderCount}
                    style={{ marginLeft: "10px", width: "120px" }}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                    inputProps={{
                      style: { textAlign: "right" }
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "10px" }}>
                    개
                  </Typography>
                </Td>
                <Th xs={2}>판매상태</Th>
                <Td textfield xs={4}>
                  <FormControlLabel
                    control={
                      <Radio
                        color="default"
                        className={classes.radioBtn}
                        checked={salesStatus === "on"}
                        onChange={handleSalesStatus}
                        value="on"
                      />
                    }
                    label={<Typography variant="subtitle2">판매중</Typography>}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="default"
                        className={classes.radioBtn}
                        checked={salesStatus === "out"}
                        onChange={handleSalesStatus}
                        value="out"
                      />
                    }
                    label={<Typography variant="subtitle2">품절</Typography>}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="default"
                        className={classes.radioBtn}
                        checked={salesStatus === "off"}
                        onChange={handleSalesStatus}
                        value="off"
                      />
                    }
                    label={
                      <Typography variant="subtitle2">판매중지</Typography>
                    }
                    labelPlacement="end"
                  />
                </Td>
              </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.df}>
              <TableContainer last={productId ? false : true}>
                <Th bottomleft={productId ? false : true} xs={2}>
                  할인적용
                </Th>
                <Td textfield xs={4}>
                  <FormControlLabel
                    disabled
                    control={
                      <Radio
                        color="default"
                        className={classes.radioBtn}
                        checked={saleRadio === 1}
                        onChange={() => setSaleRadio(1)}
                        value="not_apply"
                      />
                    }
                    label={<Typography variant="subtitle2">%</Typography>}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="default"
                        className={classes.radioBtn}
                        checked={saleRadio === 2}
                        onChange={() => setSaleRadio(2)}
                        value="apply"
                        style={{ fontSize: "13px" }}
                      />
                    }
                    label={<Typography variant="subtitle2">원</Typography>}
                    labelPlacement="end"
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    value={sale}
                    onChange={e => {
                      setSale(e.target.value);
                    }}
                    style={{ marginLeft: "10px", width: "120px" }}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                    inputProps={{
                      style: { textAlign: "right" }
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "10px" }}>
                    {saleRadio === 1 ? "%" : "원"}
                  </Typography>
                </Td>
                <Th bottomleft={productId ? false : true} xs={2}>
                  최종 판매 금액
                </Th>
                <Td xs={4} bottomright={productId ? false : true}>
                  {Boolean(!sale)
                    ? sellingPrice
                    : saleRadio === 1
                    ? Math.floor((sellingPrice * (100 - sale)) / 100)
                    : Math.floor(sellingPrice - sale)}
                </Td>
              </TableContainer>
            </Grid>
            {Boolean(productId) ? (
              <>
                <Grid item xs={12} className={classes.df}>
                  <TableContainer last>
                    <Th bottomleft xs={2}>
                      상품리뷰
                    </Th>
                    <Td bottomright xs={10}>
                      <span
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={() => {
                          if (Boolean(review.length)) {
                            setSel({ productId, name: productName });
                          }
                        }}>
                        {`${review.length}개 / ${parseFloat(
                          review.rating || 0
                        ).toFixed(2)}점`}
                      </span>
                    </Td>
                  </TableContainer>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ flexDirection: "column" }}>
          <Grid
            item
            xs={12}
            className={[classes.dfsc, classes.subtitle].join(" ")}>
            <TableTitle>상품 소개 이미지</TableTitle>
          </Grid>
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column", border: "1px solid #dddddd" }}>
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={12}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                {(() => {
                  return testImageArr.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className={classes.df}
                        style={{ flexDirection: "column" }}>
                        <div
                          className={classes.dfcc}
                          style={{ width: "100px", marginRight: "16px" }}>
                          <CardMedia
                            component="img"
                            image={item.uri}
                            width="100"
                            style={{ borderRadius: "5px" }}
                          />
                        </div>
                        <Grid
                          item
                          xs={10}
                          className={classes.dfcs}
                          style={{
                            position: "relative",
                            bottom: "25px",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderBottomRightRadius: "5px",
                            borderBottomLeftRadius: "5px"
                          }}>
                          <Button
                            onClick={() => {
                              setTestImageArr([
                                ...testImageArr.filter((x, i) => i !== idx)
                              ]);
                            }}
                            size="small">
                            <Typography
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                lineHeight: "15px"
                              }}
                              variant="subtitle2">
                              삭제
                            </Typography>
                          </Button>
                        </Grid>
                      </div>
                    );
                  });
                })()}
                <>
                  <input
                    accept="image/*"
                    type="file"
                    id="rasied-button-file"
                    multiple
                    style={{ display: "none" }}
                    onChange={onChangeImage}
                  />
                  <label htmlFor="rasied-button-file">
                    <Button className={classes.imageAddBtn} component="span">
                      <Icon fontSize="large">add</Icon>
                    </Button>
                  </label>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ flexDirection: "column" }}>
          <Grid
            item
            xs={12}
            className={[classes.dfsc, classes.subtitle].join(" ")}>
            <TableTitle>상품 설명 이미지</TableTitle>
          </Grid>
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column", border: "1px solid #dddddd" }}>
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={12}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                {(() => {
                  return testImageArr2.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className={classes.df}
                        style={{ flexDirection: "column" }}>
                        <div
                          className={classes.dfcc}
                          style={{ width: "100px", marginRight: "16px" }}>
                          <CardMedia
                            component="img"
                            image={item.uri}
                            width="100"
                            style={{ borderRadius: "5px" }}
                          />
                        </div>
                        <Grid
                          item
                          xs={10}
                          className={classes.dfcs}
                          style={{
                            position: "relative",
                            bottom: "25px",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderBottomRightRadius: "5px",
                            borderBottomLeftRadius: "5px"
                          }}>
                          <Button
                            onClick={() => {
                              setTestImageArr2([
                                ...testImageArr2.filter((x, i) => i !== idx)
                              ]);
                            }}
                            size="small">
                            <Typography
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                lineHeight: "15px"
                              }}
                              variant="subtitle2">
                              삭제
                            </Typography>
                          </Button>
                        </Grid>
                      </div>
                    );
                  });
                })()}
                <>
                  <input
                    accept="image/*"
                    type="file"
                    id="rasied-button-file2"
                    multiple
                    style={{ display: "none" }}
                    onChange={onChangeImage2}
                  />
                  <label htmlFor="rasied-button-file2">
                    <Button className={classes.imageAddBtn} component="span">
                      <Icon fontSize="large">add</Icon>
                    </Button>
                  </label>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ flexDirection: "column" }}>
          <Grid
            item
            xs={12}
            className={[classes.dfsc, classes.subtitle].join(" ")}>
            <TableTitle>상품 설명 동영상</TableTitle>
          </Grid>
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column", border: "1px solid #dddddd" }}>
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={12}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                {(() => {
                  return testVideoArr.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className={classes.df}
                        style={{ flexDirection: "column" }}>
                        <div
                          className={classes.dfcc}
                          style={{ width: "100px", marginRight: "16px" }}>
                          <CardMedia
                            component="video"
                            image={item.uri}
                            width="100"
                            style={{ borderRadius: "5px" }}
                          />
                        </div>
                        <Grid
                          item
                          xs={10}
                          className={classes.dfcs}
                          style={{
                            position: "relative",
                            bottom: "25px",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderBottomRightRadius: "5px",
                            borderBottomLeftRadius: "5px"
                          }}>
                          <Button
                            onClick={() => {
                              setTestVideoArr([
                                ...testVideoArr.filter((x, i) => i !== idx)
                              ]);
                            }}
                            size="small">
                            <Typography
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                lineHeight: "15px"
                              }}
                              variant="subtitle2">
                              삭제
                            </Typography>
                          </Button>
                        </Grid>
                      </div>
                    );
                  });
                })()}
                <>
                  <input
                    accept="video/mp4,video/mkv, video/x-m4v,video/*"
                    type="file"
                    id="rasied-button-video"
                    multiple
                    style={{ display: "none" }}
                    onChange={onChangeVideo}
                  />
                  <label htmlFor="rasied-button-video">
                    <Button className={classes.imageAddBtn} component="span">
                      <Icon fontSize="large">add</Icon>
                    </Button>
                  </label>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{
            flexDirection: "column",
            marginTop: "20px"
          }}>
          <Grid item xs={12}>
            <TableTitle>상품 설명</TableTitle>
          </Grid>
          <Grid
            item
            xs={12}
            className={[
              classes.df,
              classes.infoContent
              // classes.ckeditor
            ].join(" ")}
            style={{ flexDirection: "column" }}>
            {/* <Editor
            ref={er}
            htmlPath={htmlPath}
            html={detail}
            onHtmlChange={setDetail}
          /> */}
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={content}
              onChange={handleContent}
              multiline
              rows={15}
              InputProps={{
                classes: {
                  input: classes.resize
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid].join(" ")}
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
          <Grid item xs={6} style={{ paddingRight: 15 }}>
            <Grid item xs={12}>
              <TableTitle xs={12}>상품 고시 정보</TableTitle>
            </Grid>
            <Grid item xs={12}>
              <TableContainer first>
                <Th xs={3} topleft>
                  품목
                </Th>
                <Td topright xs={9} textfield>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={pri1}
                    onChange={handlePri1}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
              </TableContainer>
              <TableContainer>
                <Th xs={3}>상품 구성</Th>
                <Td xs={9} textfield>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={pri2}
                    onChange={handlePri2}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
              </TableContainer>
              {/* <TableContainer>
              <Th xs={3}>원산지</Th>
              <Td xs={9} textfield>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={pri3}
                  onChange={handlePri3}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Td>
            </TableContainer> */}
              <TableContainer>
                <Th xs={3}>생산지</Th>
                <Td xs={9} textfield>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={pri4}
                    onChange={handlePri4}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
              </TableContainer>
              <TableContainer>
                <Th xs={3}>보관방법</Th>
                <Td xs={9} textfield>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={pri5}
                    onChange={handlePri5}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
              </TableContainer>
              <TableContainer>
                <Th xs={3}>유통기한</Th>
                <Td xs={9} textfield>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={pri6}
                    onChange={handlePri6}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
              </TableContainer>
              <TableContainer last>
                <Th bottomleft xs={3}>
                  소비자 상담 문의
                </Th>
                <Td bottomright xs={9} textfield>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={pri7}
                    onChange={handlePri7}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 15 }}>
            <Grid item xs={12}>
              <TableTitle xs={12}>상품 교환 반품 안내</TableTitle>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                value={negaDesc}
                onChange={handleNegaDesc}
                multiline
                rows={18}
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.dfcc}
          style={{ marginBottom: "30px" }}>
          <Button
            variant="outlined"
            onClick={onClickCancelBtn}
            style={{ backgroundColor: "#fff", color: "#000", width: "120px" }}>
            <Typography variant="subtitle1">취소</Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={onClickSaveBtn}
            style={{
              marginLeft: "10px",
              backgroundColor: "#39c15d",
              color: "#fff",
              width: "120px"
            }}>
            <Typography variant="subtitle1">저장</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
