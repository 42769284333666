/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  CardMedia,
  Icon,
  Radio,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import { withRouter } from "react-router";
import {
  requestDelete,
  requestFile,
  requestGet,
  requestPost,
  requestPut,
  requestPatch,
} from "../../services/network";
import consts from "../../libs/consts";
import {
  popupAction,
  popupError,
  popupMessage,
} from "../../redux/popup/PopupActions";
import { useDispatch } from "react-redux";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import DeliveryCharge from "./Delivery";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import DaumPostCode from "./DaumPostcode";
import TableTitle from "../../components/table/TableTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import useListPayload from "../../../hooks/useListPayload";
import useMember from "../../../hooks/useMember";
import { getFileUri } from "../../services/utils";
import SharedReview from "../../../components/review/SharedReview";

const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  textfield: {
    borderRadius: 0,
  },
  cancelBtn: {
    borderRadius: 2,
    width: "120px",
  },
  addBtn: {
    borderRadius: 2,
    width: "120px",
    color: "#fff",
    backgroundColor: "#40cf66",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  withDrawalBtn: {
    // backgroundColor: "#e9e9e9",
    width: "100px",
    height: "36px",
    // marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#e9e9e9",
    },
    borderRadius: "2px",
    border: "1px solid #ddd",
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#c5c8ce",
    marginBottom: "40px",
    marginTop: "20px",
  },
  resize: { fontSize: "13px" },
  imageAddBtn: {
    width: "100px",
    height: "100px",
    border: "2px dotted #efefef",
  },
  infocell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
    fontFamily: fonts.malgunGothic,
    fontSize: "13px",
  },
  time: {
    border: "1px solid #dddddd",
    padding: "8 px",
    fontSize: 13,
    fontFamily: fonts.malgunGothicSemiLight,
    color: "#374553",
    marginTop: 10,
    width: "100%",
    minHeight: 36,
  },
  time2: {
    border: "1px solid #dddddd",
    padding: "8 px",
    fontSize: 13,
    fontFamily: fonts.malgunGothicSemiLight,
    color: "#374553",
    // marginTop: 10,
    width: "100%",
    minHeight: 38,
  },
  date: {
    border: "1px solid #dddddd",
    padding: "8px",
    fontSize: 13,
    fontFamily: fonts.malgunGothicSemiLight,
    color: "#374553",
    marginTop: 10,
    width: 220,
  },
  radioBtn: {
    marginLeft: "20px",
    color: "#40cf66 !important",
  },
});

export default withRouter(function DistributorAdd({ location, history }) {
  const classes = useStyles();
  const [companyName, setCompanyName] = useState("");
  const [managerName, setManageName] = useState("");
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [post, setPost] = useState("");
  const [password, setPassword] = useState("");
  const [distributionId, setDistributionId] = useState("");
  const dispatch = useDispatch();
  const [noEvent, setNoEvent] = useState(false);
  const [noMin, setNoMin] = useState(false);
  const [daum, setDaum] = useState(false);
  const [daum2, setDaum2] = useState(false);
  const [testImageArr, setTestImageArr] = useState([]);
  const [postcodes, setPostcodes] = useState([]);
  const [value, setValue] = useState(new Date());
  const [holidays, setHolidays] = useState([]);
  const [holiday, setHoliday] = useState("");
  const [test, setTest] = useState();
  const [time1, setTime1] = useState();
  const [time2, setTime2] = useState();
  const [data1, setData1] = useState([]);
  const [storeId, setStoreId] = useState();
  const [storeCategoryId, setStoreCategoryId] = useState("선택하기");
  const [storeCategory, setStoreCategory] = useState();
  const [introduce, setIntroduce] = useState();
  const [introducePath, setIntroducePath] = useState();
  const [receiptSetId, setReceiptSetId] = useState("선택하기");
  const [maxNum, setMaxNum] = useState([]);
  const [deleteImageIds, setDeleteImageIds] = useState([]);
  const [radioSelectValue, setRadioSelectValue] = useState("");
  const [orders, setOrders] = useState([
    { close: "", start: "", end: "", count: 10 },
    { close: "", start: "", end: "", count: 10 },
  ]);
  const [data, setData] = useState({
    order: 0,
    min: 0,
    "seller-refund": 0,
    "seller-exchange": 0,
    "buyer-refund": 0,
    "buyer-exchange": 0,
    event: 0,
  });
  const [postRadio, setPostRadio] = useState(0);

  const [reviewModal, setReviewModal] = useState(false);

  useEffect(() => {
    if (distributionId) {
      requestGet({
        url: consts.apiUrl + "/receiptsets",
        query: { distributionId: distributionId },
      }).then((x) => {
        setData1([...x.results]);
      });
    }
  }, [distributionId]);
  useEffect(() => {
    requestGet({
      url: consts.apiUrl + "/storecategories",
      query: {},
    }).then((x) => {
      setStoreCategory([...x]);
    });
  }, []);

  useEffect(() => {
    for (let i = 1; i < 501; i++) {
      maxNum.push(i);
    }
  }, []);

  useEffect(() => {
    if (location.state?.distributionId) {
      setDistributionId(location.state.distributionId);
    }
  }, []);

  useEffect(() => {
    if (distributionId) {
      requestGet({ url: consts.apiUrl + "/distributions/" + distributionId })
        .then((x) => {
          setCompanyName(x.companyName);
          setManageName(x.managerName);
          setUsername(x.username);
          setAddress(x.address.split(", ")[0]);
          setAddress2(x.address.split(", ")[1] ? x.address.split(", ")[1] : "");
          setPhone(x.phone);
          setCode(x.code);
          setStoreId(x.storeId);
          setRadioSelectValue(x.verified);
          if (x.storeId) {
            requestGet({ url: consts.apiUrl + "/stores/" + x.storeId })
              .then((y) => {
                setTestImageArr([
                  ...y.storeImages.map((x) => {
                    return {
                      uri: getFileUri(x.path),
                      ...x,
                    };
                  }),
                ]);
                setOrders([
                  ...y.storeOrders.map((d, i) => {
                    return {
                      close: new Date(
                        "Fri Feb 04 2022 " +
                          d.close.substring(0, 5) +
                          " GMT+0900 (한국 표준시)"
                      ),
                      end: new Date(
                        "Fri Feb 04 2022 " +
                          d.end.substring(0, 5) +
                          " GMT+0900 (한국 표준시)"
                      ),
                      start: new Date(
                        "Fri Feb 04 2022 " +
                          d.start.substring(0, 5) +
                          " GMT+0900 (한국 표준시)"
                      ),
                      count: d.count,
                    };
                  }),
                ]);
                if (y.storeWorkAreas.length) {
                  setPostRadio(1);
                }
                setPostcodes([
                  ...y.storeWorkAreas
                    .map((d, i) => {
                      return { keyword: d.keyword, postCode: d.postCode };
                    })
                    .sort(
                      (a, b) => parseInt(a.postCode) - parseInt(b.postCode)
                    ),
                ]);
                setTime1(
                  new Date(
                    "Fri Feb 04 2022 " +
                      y.salesTime.substring(0, 5) +
                      ":00 GMT+0900 (한국 표준시)"
                  )
                );
                setTime2(
                  new Date(
                    "Fri Feb 04 2022 " +
                      y.salesTime.substring(6, 11) +
                      ":00 GMT+0900 (한국 표준시)"
                  )
                );
                setIntroduce(y.introduce);
                setIntroducePath(y.introducePath);
                setReceiptSetId(y.receiptSetId);
                setStoreCategoryId(y.storeCategoryId);
                let newDay = [];
                for (let i = 0; i < y.holidays.split(",").length - 1; i++) {
                  if (y.holidays.split(",")[i].length === 4) {
                    newDay.push(
                      new Date(
                        2022,
                        parseInt(y.holidays.split(",")[i].substring(0, 2)) - 1,
                        y.holidays.split(",")[i].substring(2, 4)
                      )
                    );
                  } else {
                    newDay.push(
                      new Date(
                        y.holidays.split(",")[i].substring(0, 4),
                        parseInt(y.holidays.split(",")[i].substring(4, 6)) - 1,
                        y.holidays.split(",")[i].substring(6, 8)
                      )
                    );
                  }
                  if (i === y.holidays.split(",").length - 2) {
                    setHolidays(newDay);
                  }
                }
              })
              .catch((e) => {
                history.goBack();
                dispatch(popupError(e));
              });
          }
        })
        .catch((e) => {
          history.goBack();
          dispatch(popupError(e));
        });
    }
  }, [distributionId]);

  const handleOnBlur = ({ target: { value } }) => {
    const date = new Date(value);
    if (isValid(date)) {
      console.log("date: %s", format(date, "YYYY. MM. DD "));
    } else {
      console.log("value: %s", date);
    }
  };

  const handleDelete = () => {
    dispatch(
      popupAction({
        title: "삭제 처리",
        message: companyName + " 유통사를 삭제하시겠습니까?",
        labelCancel: "취소",
        labelConfirm: "삭제",
        onClickConfirm: () => {
          requestDelete({
            url: consts.apiUrl + "/distributions/" + distributionId,
          })
            .then(() => {
              dispatch(
                popupMessage({
                  title: "알림",
                  message: "삭제 완료되었습니다.",
                  onClick: () => {
                    history.goBack();
                  },
                })
              );
            })
            .catch((e) => {
              alert(e.message || e);
            });
        },
      })
    );
  };

  const onChangeImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const f = new FileReader();
      f.onload = () => {
        file.uri = f.result;
        setTestImageArr([...testImageArr, file]);
      };
      f.readAsDataURL(file);
      e.target.value = "";
    }
  };

  const handleRadioBtn = (e) => {
    setRadioSelectValue(parseInt(e.target.value));
  };
  useEffect(() => {
    let newHoliday = [];
    if (holidays) {
      holidays.map((d, i) => {
        if (holidays[i].toString().substring(4, 7) === "Jan") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "01" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Feb") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "02" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Mar") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "03" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Apr") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "04" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "May") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "05" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Jun") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "06" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Jul") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "07" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Aug") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "08" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Sep") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "09" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Oct") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "10" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Nov") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "11" +
              holidays[i].toString().substring(8, 10)
          );
        } else if (holidays[i].toString().substring(4, 7) === "Dec") {
          newHoliday.push(
            holidays[i].toString().substring(11, 15) +
              "12" +
              holidays[i].toString().substring(8, 10)
          );
        }
        setHoliday(newHoliday.join() + ",");
      });
    }
  }, [holidays]);

  const save = async () => {
    dispatch(loadingStart);
    try {
      if (!companyName) {
        throw "유통사를 입력해주세요.";
      } else if (!managerName) {
        throw "담당자를 입력해주세요.";
      } else if (!username) {
        throw "아이디를 입력해주세요.";
      } else if (!phone || isNaN(phone)) {
        throw "올바른 휴대폰번호를 입력해주세요.";
      } else if (!address) {
        throw "주소를 입력해주세요.";
      } else if (!code || code.length > 2) {
        throw "올바른 코드 2자리를 입력해주세요.";
      } else if (!distributionId && !password) {
        throw "비밀번호를 입력해주세요";
      } else if (testImageArr.length >= 10) {
        throw "상품 소개 이미지는 10개까지 등록 가능합니다.";
      }
      Object.keys(data).map((key) => {
        if (!data[key]) {
          if ((key === "event" && noEvent) || (key === "min" && noMin)) {
          } else {
            if (data[key] === "") {
              throw "배송비를 입력해 주세요.";
            }
          }
        }
        data[key] = parseInt(data[key]);
        if (key === "event" && noEvent) {
          data[key] = null;
        } else if (key === "min" && noMin) {
          data[key] = "0";
        }
      });
      if (distributionId) {
        await requestPut({
          url: consts.apiUrl + "/distributions/" + distributionId,
          body:
            password === ""
              ? {
                  columns: ["companyName", "managerName", "phone", "address"],
                  values: [
                    companyName,
                    managerName,
                    phone,
                    address + ", " + address2,
                  ],
                  deliveryFees: data,
                  realAddress: address,
                }
              : {
                  columns: [
                    "companyName",
                    "managerName",
                    "phone",
                    "address",
                    "password",
                  ],
                  values: [
                    companyName,
                    managerName,
                    phone,
                    address + ", " + address2,
                    password,
                  ],
                  deliveryFees: data,
                  realAddress: address,
                },
        }).then((x) => {
          //유통사 승인
          if (radioSelectValue !== "") {
            requestPatch({
              url:
                consts.apiUrl + "/distributions/" + distributionId + "/verify",
              body: {
                state: radioSelectValue,
              },
            });
          }
        });
      } else {
        await requestPost({
          url: consts.apiUrl + "/distributions",
          body: {
            companyName,
            managerName,
            username,
            phone,
            address: address + ", " + address2,
            code,
            password,
            deliveryFees: data,
          },
        }).then((x) => {
          //유통사 승인
          if (radioSelectValue !== "") {
            requestPatch({
              url: consts.apiUrl + "/distributions/" + x + "/verify",
              body: {
                state: radioSelectValue,
              },
            });
          }
        });
      }
      dispatch(
        popupMessage({
          title: "알림",
          message: "저장 되었습니다.",
          onClick: () => {
            history.goBack();
          },
        })
      );
    } catch (error) {
      dispatch(popupError(error));
    }

    dispatch(loadingStop);
  };

  const saveStore = async () => {
    dispatch(loadingStart);
    try {
      if (testImageArr.length >= 10) {
        throw "상품 소개 이미지는 10개까지 등록 가능합니다.";
      } else if (!time1 || !time2) {
        throw "영업 시간을 입력해주세요";
      } else if (receiptSetId === "선택하기") {
        throw "입고 SET를 선택해주세요";
      } else if (storeCategoryId === "선택하기") {
        throw "스토어 카테고리를 선택해주세요";
      } else if (!introduce) {
        throw "소개 문구를 입력해주세요";
      } else if (
        orders
          .map((d) => {
            if (d.close === "" || d.start === "" || d.end === "") {
              return false;
            }
          })
          .includes(false)
      ) {
        throw "배송 테이블을 설정해주세요.";
      }
      //  else if (
      //   orders.map((d, i) => {
      //     if (Boolean(orders[i + 1])) {
      //       if (moment(d.end).isAfter(orders[i + 1].start)) {
      //         throw "차수별 배송시간은 중복될 수 없습니다.";
      //       }
      //     }
      //   })
      // ) {
      // }
      Object.keys(data).map((key) => {
        if (!data[key]) {
          if ((key === "event" && noEvent) || (key === "min" && noMin)) {
          } else {
            if (data[key] === "") {
              throw "배송비를 입력해 주세요.";
            }
          }
        }
        data[key] = parseInt(data[key]);
        if (key === "event" && noEvent) {
          data[key] = null;
        } else if (key === "min" && noMin) {
          data[key] = "0";
        }
      });
      if (distributionId) {
        await requestPut({
          url: consts.apiUrl + "/distributions/" + distributionId,
          body: {
            deliveryFees: data,
            realAddress: address,
          },
        });
      }

      //유통사 승인
      if (radioSelectValue !== "" && distributionId) {
        await requestPatch({
          url: consts.apiUrl + "/distributions/" + distributionId + "/verify",
          body: {
            state: radioSelectValue,
          },
        });
      }

      //유통사 스토어 수정/등록

      orders.map((d, i) => {
        if (!d.count || d.count > 500) {
          throw "주문 가능 건 수는 1~500 사이의 값을 입력해주세요";
        } else if (
          moment(d.close) !== moment(d.start) &&
          moment(d.close).isAfter(d.start)
        ) {
          throw "주문 마감 시간은 배송 시작 시간보다 빨라야합니다";
        } else if (!moment(d.start).isBefore(d.end)) {
          throw "배송 시작 시간은 배송 마감 시간보다 빨라야합니다";
        } else if (
          moment.duration(moment(d.end).diff(d.start)).asMinutes() < 60
        ) {
          throw "배송시작 - 마감시간은 최소 1시간 이상이어야 합니다";
        }
      });
      let newOrders = [...orders];
      newOrders.map((d, i) => {
        d.close = moment(d.close).format("HH:mm:ss");
        d.start = moment(d.start).format("HH:mm:ss");
        d.end = moment(d.end).format("HH:mm:ss");
        return d;
      });

      const params = {
        distributionId: distributionId,
        salesTime:
          time1?.toString().substring(16, 21) +
          "," +
          time2?.toString().substring(16, 21),
        receiptSetId: receiptSetId,
        storeCategoryId: storeCategoryId,
        introducePath: introducePath,
        introduce: introduce,
        holidays: holiday,
        orders: JSON.stringify(newOrders),
        postcodes:
          postRadio === 0 ? JSON.stringify([]) : JSON.stringify(postcodes),
      };
      const form = new FormData();
      Object.keys(params).map((key) => {
        form.append(key, params[key] ? params[key] : "");
      });
      deleteImageIds.forEach((x) => {
        form.append("deleteImageIds", x);
      });
      testImageArr
        .filter((x) => !x.productImageId)
        .forEach((x) => {
          form.append("files", x);
        });
      testImageArr
        .filter((x) => x.productImageId)
        .forEach((x) => {
          form.append("productImageIds", x.productImageId);
        });
      if (distributionId) {
        await requestFile({
          url: consts.apiUrl + "/stores",
          form: form,
          put: true,
        });
      } else {
        await requestFile({
          url: consts.apiUrl + "/stores",
          form: form,
          put: true,
        });
      }
      dispatch(
        popupMessage({
          title: "알림",
          message: "저장 되었습니다.",
          onClick: () => {
            history.goBack();
          },
        })
      );
    } catch (error) {
      dispatch(popupError(error));
    }

    dispatch(loadingStop);
  };

  const CustomTime = ({ value, onClick }) => {
    return (
      <Button className={classes.time} onClick={onClick}>
        {value}
      </Button>
    );
  };
  const CustomTime2 = ({ value, onClick }) => {
    return (
      <Button className={classes.time2} onClick={onClick}>
        {value}
      </Button>
    );
  };

  const onDateChange = (date) => {
    var today = moment().format("YYYY-MM-DD");
    var objname = moment(date).format("YYYY-MM-DD");

    if (moment(objname).isSameOrBefore(today)) {
      dispatch(popupError("오늘 이전 날짜는 선택 불가능 합니다."));
      return;
    }

    requestGet({
      url: consts.apiUrl + `/stores/${storeId}/storework/statistics`,
      query: {
        date: objname,
      },
    })
      .then((x) => {
        dispatch(
          popupError("재고 동기화된 작업 일자는 휴무일 설정 불가합니다.")
        );
      })
      .catch((e) => {
        setHoliday("");
        setHolidays([...holidays, date]);
      });
  };
  const AddDate = ({ value, onClick }) => {
    return (
      <Button className={classes.date} onClick={onClick}>
        휴무일 설정하기
      </Button>
    );
  };
  const CustomDate = ({ value, onClick }) => {
    return (
      <Button className={classes.date} onClick={onClick}>
        {value ? moment(value).format("YYYY. MM. DD") : ""}
      </Button>
    );
  };

  return (
    <Grid container className={classes.container}>
      <SharedReview
        open={reviewModal}
        onClose={() => setReviewModal(false)}
        type="distribution"
        id={distributionId}
      />
      {daum && (
        <DaumPostCode
          onClose={() => {
            setDaum(false);
          }}
          onPress={({ address, postCode }) => {
            setAddress(address);
          }}
        />
      )}
      {daum2 && (
        <DaumPostCode
          onClose={() => {
            setDaum2(false);
          }}
          onPress={({ address, postCode }) => {
            setPostcodes([
              ...postcodes,
              { keyword: address, postCode: postCode },
            ]);
          }}
        />
      )}
      <Grid item xs={12}>
        <Grid item xs={12} style={{ marginBottom: "30px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontSize: "28px",
                lineHeight: "32px",
                fontFamily: fonts.spoqaHanSansNeoBold,
                color: "#374553",
                paddingTop: "40px",
              }}
            >
              유통사 등록
            </Typography>
            <Box>
              <Button
                onClick={() => {
                  setReviewModal(true);
                }}
                color="primary"
                variant="outlined"
                size="small"
                style={{ marginRight: "1rem" }}
              >
                <Typography variant="subtitle1">
                  {"배송 서비스 리뷰 확인"}
                </Typography>
              </Button>

              <Button
                variant="outlined"
                size="small"
                className={classes.withDrawalBtn}
                onClick={handleDelete}
              >
                <Typography variant="subtitle1">{"삭제처리"}</Typography>
              </Button>
            </Box>
          </div>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <TableContainer first>
            <Th topleft>유통사명</Th>
            <Td textfield>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Td>
            <Th>아이디</Th>
            <Td topright textfield>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                disabled={distributionId}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Td>
          </TableContainer>
          <TableContainer>
            <Th>담당자명</Th>
            <Td textfield>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                fullWidth
                value={managerName}
                onChange={(e) => {
                  setManageName(e.target.value);
                }}
                variant="outlined"
                size="small"
              />
            </Td>
            <Th>비밀번호</Th>
            <Td textfield>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                fullWidth
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                variant="outlined"
                size="small"
              />
            </Td>
          </TableContainer>
          <TableContainer>
            <Th>휴대폰번호</Th>
            <Td textfield>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                fullWidth
                variant="outlined"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                size="small"
              />
            </Td>
            <Th>코드2자리</Th>
            <Td textfield>
              <Grid item xs={6} className={classes.dfsc}>
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  disabled={distributionId}
                  fullWidth
                  value={code}
                  onChange={(e) => {
                    if (e.target.value.length > 2) {
                      return;
                    }
                    setCode(String(e.target.value).toUpperCase());
                  }}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={4}
                className={classes.dfsc}
                style={{ paddingLeft: "10px" }}
              >
                <Typography variant="subtitle2" style={{ color: "#4b5662" }}>
                  영문 또는 숫자 2자리
                </Typography>
              </Grid>
            </Td>
          </TableContainer>
          <TableContainer last>
            <Th bottomleft>주소</Th>
            <Td xs={4} textfield>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    fullWidth
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    variant="outlined"
                    size="small"
                  />
                  <Button
                    variant="outlined"
                    style={{
                      padding: "4px",
                      marginLeft: "20px",
                      borderRadius: 2,
                      width: 100,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                    onClick={() => setDaum(!daum)}
                  >
                    주소검색
                  </Button>
                </Grid>
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  fullWidth
                  value={address2}
                  onChange={(e) => {
                    setAddress2(e.target.value);
                  }}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Td>
            <Th>인증</Th>
            <Td bottomright textfield>
              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={radioSelectValue === 1}
                    onChange={handleRadioBtn}
                    value={1}
                  />
                }
                label={<Typography variant="subtitle2">승인</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={radioSelectValue === 0}
                    onChange={handleRadioBtn}
                    value={0}
                  />
                }
                label={<Typography variant="subtitle2">반려</Typography>}
                labelPlacement="end"
              />
            </Td>
          </TableContainer>
          <DeliveryCharge
            data={data}
            noEvent={noEvent}
            setNoEvent={setNoEvent}
            setData={setData}
            noMin={noMin}
            setNoMin={setNoMin}
            distributionId={distributionId}
          />
          <Button
            onClick={save}
            className={classes.addBtn}
            style={{ marginBottom: 80 }}
          >
            <Typography variant="subtitle1">
              유통사 {distributionId ? "저장" : "등록"}
            </Typography>
          </Button>

          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                fontSize: "28px",
                lineHeight: "32px",
                fontFamily: fonts.spoqaHanSansNeoBold,
                color: "#374553",
              }}
            >
              스토어 정보
            </Typography>
            <Divider className={classes.divider} />
            <Grid item xs={12}>
              <TableContainer first>
                <Th topleft>영업 시간</Th>
                <Td textfield>
                  <DatePicker
                    selected={time1}
                    onChange={(date) => setTime1(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    customInput={<CustomTime2 style={{ marginRight: 10 }} />}
                  />

                  <Typography style={{ margin: 10 }}>~</Typography>
                  <DatePicker
                    selected={time2}
                    onChange={(date) => setTime2(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    customInput={<CustomTime2 />}
                  />
                </Td>
                <Th>입고 SET</Th>
                <Td textfield topright>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={receiptSetId}
                    onChange={(e) => setReceiptSetId(e.target.value)}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  >
                    <option
                      value={"선택하기"}
                      selected
                      disabled
                      className={classes.selectForm}
                    >
                      선택하기
                    </option>
                    {data1.map((item, idx) => {
                      return (
                        <option
                          key={idx}
                          value={item.receiptSetId}
                          selected={receiptSetId === item.receiptSetId}
                          className={classes.selectForm}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </TextField>
                </Td>
              </TableContainer>
              <TableContainer>
                <Th>스토어 카테고리</Th>
                <Td textfield>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={storeCategoryId}
                    onChange={(e) => setStoreCategoryId(e.target.value)}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  >
                    <option
                      value={"선택하기"}
                      selected
                      disabled
                      className={classes.selectForm}
                    >
                      선택하기
                    </option>
                    {storeCategory &&
                      storeCategory.map((item, idx) => {
                        return (
                          <option
                            key={idx}
                            value={item.storeCategoryId}
                            selected={storeCategoryId === item.storeCategoryId}
                            className={classes.selectForm}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </TextField>
                </Td>
                <Th>(선택) 스토어 소개 영상 링크</Th>
                <Td textfield>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    value={introducePath}
                    onChange={(e) => {
                      setIntroducePath(e.target.value);
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                </Td>
              </TableContainer>
              <TableContainer last>
                <Th bottomleft>소개 문구</Th>
                <Td textfield bottomright xs={10}>
                  <TextField
                    multiline
                    rows={5}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    value={introduce}
                    onChange={(e) => {
                      setIntroduce(e.target.value);
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                </Td>
              </TableContainer>
              <Grid
                item
                xs={12}
                style={{ flexDirection: "column", marginTop: 20 }}
              >
                <Grid
                  item
                  xs={12}
                  className={[classes.dfsc, classes.subtitle].join(" ")}
                >
                  <TableTitle>스토어 소개 사진</TableTitle>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{
                    flexDirection: "column",
                    border: "1px solid #dddddd",
                  }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={12}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {(() => {
                        return testImageArr.map((item, idx) => {
                          return (
                            <div
                              key={idx}
                              className={classes.df}
                              style={{ flexDirection: "column" }}
                            >
                              <div
                                className={classes.dfcc}
                                style={{ width: "100px", marginRight: "16px" }}
                              >
                                <CardMedia
                                  component="img"
                                  image={item.uri}
                                  width="100"
                                  style={{ borderRadius: "5px" }}
                                />
                              </div>
                              <Grid
                                item
                                xs={10}
                                className={classes.dfcs}
                                style={{
                                  position: "relative",
                                  bottom: "25px",
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  borderBottomRightRadius: "5px",
                                  borderBottomLeftRadius: "5px",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setTestImageArr([
                                      ...testImageArr.filter(
                                        (x, i) => i !== idx
                                      ),
                                    ]);
                                    setDeleteImageIds([
                                      ...deleteImageIds,
                                      item.storeImageId,
                                    ]);
                                  }}
                                  size="small"
                                >
                                  <Typography
                                    style={{
                                      color: "#fff",
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                    }}
                                    variant="subtitle2"
                                  >
                                    삭제
                                  </Typography>
                                </Button>
                              </Grid>
                            </div>
                          );
                        });
                      })()}
                      <>
                        <input
                          accept="image/*"
                          type="file"
                          id="rasied-button-file"
                          multiple
                          style={{ display: "none" }}
                          onChange={onChangeImage}
                        />
                        <label htmlFor="rasied-button-file">
                          <Button
                            className={classes.imageAddBtn}
                            component="span"
                          >
                            <Icon fontSize="large">add</Icon>
                          </Button>
                        </label>
                      </>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ flexDirection: "column", marginTop: 20 }}
                >
                  <TableTitle>서비스 지역 설정</TableTitle>
                  <TableContainer first last>
                    <Th topleft bottomleft>
                      서비스 지역
                    </Th>
                    <Td topright bottomright xs={10}>
                      <Grid style={{ flexDirection: "column" }}>
                        <Grid>
                          <FormControlLabel
                            control={
                              <Radio
                                color="default"
                                className={classes.radioBtn}
                                checked={postRadio === 0}
                                onChange={() => {
                                  dispatch(
                                    popupAction({
                                      title: "서비스 지역 설정",
                                      message: `모든 고객에게 노출 설정시 추가된 모든 서비스 지역이 초기화됩니다. 진행하시겠습니까?`,

                                      labelCancel: "취소",
                                      labelConfirm: "확인",
                                      onClickConfirm: () => {
                                        setPostRadio(0);
                                      },
                                    })
                                  );
                                }}
                                value={0}
                              />
                            }
                            label={
                              <Typography variant="subtitle2">전체</Typography>
                            }
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                color="default"
                                className={classes.radioBtn}
                                checked={postRadio === 1}
                                onChange={() => {
                                  setPostRadio(1);
                                }}
                                value={1}
                              />
                            }
                            label={
                              <Typography variant="subtitle2">선택</Typography>
                            }
                            labelPlacement="end"
                          />
                        </Grid>
                        {postRadio === 1 && (
                          <>
                            <Button
                              variant="outlined"
                              style={{
                                padding: "4px",
                                borderRadius: 2,
                                width: 100,
                              }}
                              onClick={() => setDaum2(true)}
                            >
                              추가
                            </Button>
                            <Grid
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",

                                gridGap: "10px",
                              }}
                            >
                              {postcodes &&
                                postcodes.map((item, idx) => {
                                  return (
                                    <Grid
                                      key={idx}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        marginTop: 10,
                                      }}
                                    >
                                      <Td
                                        post
                                        xs={10}
                                        style={{
                                          borderLeft: "1px solid #dddddd",
                                          borderBottom: "1px solid #dddddd",
                                          borderRadius: 5,
                                        }}
                                        topleft
                                        topright
                                        bottomleft
                                        bottomright
                                      >
                                        {item.postCode}
                                      </Td>
                                      <Button
                                        variant="outlined"
                                        style={{
                                          padding: "4px",
                                          borderRadius: 2,
                                          width: 60,
                                          backgroundColor: "#000",
                                          color: "#fff",
                                          marginLeft: 10,
                                          marginRight: 20,
                                        }}
                                        onClick={() => {
                                          let newArea = postcodes.filter(
                                            (d, i) => idx !== i
                                          );
                                          setPostcodes(newArea);
                                        }}
                                      >
                                        삭제
                                      </Button>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Td>
                  </TableContainer>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 20,
                  }}
                >
                  <TableTitle>휴무일 설정</TableTitle>
                </Grid>
                <Grid xs={3}>
                  <DatePicker
                    selected={holidays[0]}
                    onChange={onDateChange}
                    onBlur={handleOnBlur}
                    customInput={<AddDate />}
                  />
                  {holidays.map((item, idx) => {
                    return (
                      <Grid
                        key={idx.toString()}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <DatePicker
                          selected={item}
                          onChange={(date) => {
                            setHoliday("");
                            let newDate = [...holidays];
                            newDate.splice(idx, 1, date);
                            setHolidays([...newDate]);
                          }}
                          onBlur={handleOnBlur}
                          customInput={<CustomDate />}
                        />
                        <Button
                          variant="outlined"
                          style={{
                            padding: "4px",
                            borderRadius: 2,
                            width: 60,
                            backgroundColor: "#000",
                            color: "#fff",
                            marginTop: 10,
                          }}
                          onClick={() => {
                            let newHolidays = [...holidays];
                            newHolidays.splice(idx, 1);
                            setHolidays([...newHolidays]);
                          }}
                        >
                          삭제
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <TableTitle>배송 테이블 설정</TableTitle>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 40 }}>
                  {orders.map((item, idx) => {
                    return (
                      <Grid
                        key={idx.toString()}
                        item
                        xs={12}
                        style={{ flexDirection: "column", marginTop: 20 }}
                      >
                        <Grid xs={12}>
                          <Grid
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography>{idx + 1} 차수</Typography>
                            <Button
                              variant="outlined"
                              style={{
                                padding: "4px",
                                borderRadius: 2,
                                width: 60,
                                backgroundColor: "#fff",
                                color: "#000",
                                marginLeft: 40,
                                marginRight: 10,
                              }}
                              onClick={() => {
                                let newTable = {
                                  close: "",
                                  start: "",
                                  end: "",
                                  count: 10,
                                };
                                setOrders([...orders, newTable]);
                              }}
                            >
                              추가
                            </Button>
                            {idx !== 0 && (
                              <Button
                                variant="outlined"
                                style={{
                                  padding: "4px",
                                  borderRadius: 2,
                                  width: 60,
                                  backgroundColor: "#000",
                                  color: "#fff",
                                }}
                                onClick={() => {
                                  let newTable = orders.filter(
                                    (d, i) => idx !== i
                                  );
                                  setOrders(newTable);
                                }}
                              >
                                삭제
                              </Button>
                            )}
                          </Grid>
                          <Divider
                            style={{ marginTop: 10, marginBottom: 10 }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Grid item xs={4}>
                            <Typography
                              style={{
                                fontSize: 13,
                                fontFamily: fonts.malgunGothicSemiLight,
                              }}
                            >
                              주문 마감 시간
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              style={{
                                fontSize: 13,
                                fontFamily: fonts.malgunGothicSemiLight,
                              }}
                            >
                              배송 시작 ~ 마감 시간
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              style={{
                                fontSize: 13,
                                fontFamily: fonts.malgunGothicSemiLight,
                              }}
                            >
                              주문 가능 건 수 (최대)
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Grid item xs={4} style={{ paddingRight: 20 }}>
                            <DatePicker
                              selected={orders[idx].close}
                              onChange={(date) => {
                                let newOrder = [...orders];
                                newOrder[idx].close = date;
                                setOrders([...newOrder]);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="hh:mm aa"
                              customInput={<CustomTime />}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingRight: 20,
                            }}
                          >
                            <DatePicker
                              selected={orders[idx].start}
                              onChange={(date) => {
                                let newOrder = [...orders];
                                newOrder[idx].start = date;
                                setOrders([...newOrder]);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="hh:mm aa"
                              customInput={<CustomTime />}
                            />
                            <Typography
                              style={{
                                paddingTop: 15,
                                paddingLeft: 20,
                                paddingRight: 20,
                              }}
                            >
                              {" "}
                              ~{" "}
                            </Typography>
                            <DatePicker
                              selected={orders[idx].end}
                              onChange={(date) => {
                                let newOrder = [...orders];
                                newOrder[idx].end = date;
                                setOrders([...newOrder]);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="hh:mm aa"
                              customInput={<CustomTime />}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              // select
                              fullWidth
                              style={{ marginTop: 10 }}
                              size="small"
                              variant="outlined"
                              value={orders[idx].count}
                              onChange={(e) => {
                                let newOrder = [...orders];
                                newOrder[idx].count = e.target.value;
                                setOrders([...newOrder]);
                              }}
                              InputProps={{
                                classes: {
                                  input: classes.resize,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <Grid item xs={5}>
              <Grid itme xs={8}>
                <Button
                  onClick={history.goBack}
                  variant="outlined"
                  className={classes.cancelBtn}
                >
                  <Typography variant="subtitle1">취소</Typography>
                </Button>
                <Button
                  onClick={saveStore}
                  className={classes.addBtn}
                  style={{ marginLeft: "10px" }}
                >
                  <Typography variant="subtitle1">
                    스토어 {distributionId ? "저장" : "등록"}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
