/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut
} from "../../services/network";
import consts from "../../libs/consts";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import fonts from "../../libs/fonts";
import DaumPostcode from "react-daum-postcode";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px"
  },
  bold: {
    fontWeight: "bold"
  },
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  addBtn: {
    backgroundColor: "#fff",
    borderRadius: 2,
    width: "160px",
    "&:hover": {
      backgroundColor: "lightgray"
    }
  },
  deleteBtn: {
    backgroundColor: "#fff",
    marginLeft: "10px",
    borderRadius: 2,
    width: "100px",
    "&:hover": {
      backgroundColor: "lightgray"
    }
  },
  textfield: {
    "& input": {
      textAlign: "center",
      fontSize: 14
    }
  },
  saveBtn: {
    backgroundColor: "#40cf66",
    borderRadius: 2,
    width: "160px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66"
    }
  }
});

export default function DefaultAddr() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [postCode, setpostCode] = useState("");
  const [address, setAddress] = useState("");
  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    requestGet({
      url: consts.apiUrl.replace("/adm", "") + "/defaultaddress"
    })
      .then(x => {
        setpostCode(x.postCode);
        setAddress(x.address);
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  };

  const save = async () => {
    dispatch(loadingStart);
    try {
      await requestPost({
        url: consts.apiUrl + "/admins/defaultaddress",
        body: {
          address,
          postCode
        }
      });
      dispatch(popupMessage({ title: "저장 완료." }));
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "20px" }}>
          <Typography
            variant="h5"
            style={{
              fontSize: "28px",
              lineHeight: "32px",
              fontFamily: fonts.spoqaHanSansNeoBold,
              color: "#374553",
              paddingTop: "40px"
            }}>
            비회원 기본주소 설정
          </Typography>
        </Grid>
        <Divider
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#c5c8ce",
            marginBottom: "40px"
          }}
        />
        <Grid item xs={5} style={{ marginBottom: "30px" }}>
          <Button
            onClick={save}
            className={classes.saveBtn}
            style={{ marginLeft: "10px" }}>
            <Typography variant="subtitle2">저장</Typography>
          </Button>
        </Grid>

        <Grid
          item
          xs={5}
          style={{
            backgroundColor: "#f7f8f9",
            padding: "30px",
            borderRadius: 5
          }}>
          <Grid
            item
            xs={12}
            className={classes.dfsc}
            style={{ marginBottom: "20px" }}>
            <Typography style={{ minWidth: 60 }} variant="subtitle2">
              우편번호
            </Typography>
            <TextField
              contentEditable={false}
              className={classes.textfield}
              variant="outlined"
              size="small"
              style={{ marginLeft: "20px", backgroundColor: "#fff" }}
              value={postCode}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.dfsc}
            style={{ marginBottom: "20px" }}>
            <Typography variant="subtitle2" style={{ minWidth: 60 }}>
              주소
            </Typography>
            <TextField
              contentEditable={false}
              className={classes.textfield}
              variant="outlined"
              fullWidth
              inputProps={{
                contentEditable: false
              }}
              size="small"
              style={{ marginLeft: "20px", backgroundColor: "#fff" }}
              value={address}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5} style={{ marginBottom: "30px" }}>
        <DaumPostcode
          style={{ border: "1px solid #cccccc" }}
          onComplete={data => {
            const { address, zonecode } = data;
            const postCode = zonecode;
            if (!address || !postCode) {
              dispatch(
                popupError(
                  "유효하지 않은 주소입니다. 다른 주소를 선택해 주세요."
                )
              );
            }
            setAddress(address);
            setpostCode(postCode);
            dispatch(
              popupMessage({
                title: "검색 완료",
                message: "상단 저장하기 버튼을 눌러서 저장해 주세요."
              })
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
