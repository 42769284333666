/* eslint-disable multiline-ternary */
import React, { useState, useRef } from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  sidePanel: {
    width: "200px",
    height: "100%",
    backgroundColor: "#000"
  },
  sideItem: {
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
    height: "50px",
    cursor: "pointer"
  },
  sideItemText: {
    color: "#fff"
  }
});

export default function SidePanel(props) {
  const classes = useStyles();
  const { history, location } = useReactRouter();
  const { selectNavItem, selectMenu } = props;

  const dashboardArr = [
    {
      title: "현황",
      value: "current_situation"
    }
  ];

  const deliveryArr = [
    {
      title: "주문조회",
      value: "order_search"
    },
    {
      title: "배송조회",
      value: "delivery_search"
    }
  ];
  const productArr = [
    {
      title: "상품목록",
      value: "product_list"
    },
    {
      title: "카테고리관리",
      value: "category_management"
    }
  ];
  const receivingArr = [
    {
      title: "오늘의 입고관리",
      value: "today_receiving_management"
    },
    {
      title: "재고 관리",
      value: "inventory_control"
    },
    {
      title: "발주 관리",
      value: "order_management"
    },
    {
      title: "입고 SET",
      value: "receiving_set"
    }
  ];
  const memberArr = [
    {
      title: "고객회원",
      value: "customer_member"
    },
    {
      title: "기사회원",
      value: "driver_member"
    },
    {
      title: "기사회원 인증요청",
      value: "driver_member_auth"
    },
    {
      title: "탈퇴회원",
      value: "withdrawal_member"
    }
  ];
  const partnerArr = [
    {
      title: "유통사관리",
      value: "distributor_management"
    } /*,
		{
			title: '물류사관리',
			value: 'distribution_management'
		}*/
  ];
  const othersArr = [
    {
      title: "수기주문 조회",
      value: "orders"
    },
    {
      title: "수기주문 등록",
      value: "list"
    } /*,

		{
			title: '물류사관리',
			value: 'distribution_management'
		}*/
  ];

  const salesArr = [
    {
      title: "매출관리",
      value: "sales_details"
    }
  ];

  const reviewArr = [
    {
      title: "리뷰 조회",
      value: "review"
    }
  ];
  const customerSeviceArr = [
    {
      title: "공지사항",
      value: "notice"
    },
    {
      title: "고객문의",
      value: "customer_enquiry"
    },
    {
      title: "알림발송",
      value: "notification"
    },
    {
      title: "쿠폰관리",
      value: "coupon_management"
    },
    {
      title: "팝업관리",
      value: "popup_management"
    }
  ];
  const settingArr = [
    {
      title: "자동차 제조사",
      value: "car_type"
    },
    {
      title: "자동차 모델",
      value: "car"
    },
    {
      title: "문의유형",
      value: "inquiry_type"
    },
    /*
        {
      title: "배송비",
      value: "delivery_charge",
    },
    */

    {
      title: "고객등급관리",
      value: "customer_grade_management"
    },
    {
      title: "약관",
      value: "terms"
    }
  ];

  const onClickMenu = (navItem, value, idx) => {
    if (
      location.pathname.split("?")[0] ===
      (navItem === "sales_details" ? `/${navItem}/` : `/${navItem}/${value}`)
    ) {
      return;
    }

    if (navItem === "sales_details") {
      history.push(`/${navItem}/`);
    } else {
      history.push(`/${navItem}/${value}`);
    }
  };

  return (
    <Grid container className={classes.sidePanel}>
      <Grid item xs={12}>
        {selectNavItem === "dashboard" ? (
          dashboardArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === dashboardArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "delivery_management" ? (
          deliveryArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === deliveryArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "product_management" ? (
          productArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === productArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "receiving_management" ? (
          receivingArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === receivingArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "member_management" ? (
          memberArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === memberArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "partner_management" ? (
          partnerArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === partnerArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "others_order" ? (
          othersArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === othersArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "sales_details" ? (
          salesArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === salesArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "reviews" ? (
          reviewArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === reviewArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "customer_service" ? (
          customerSeviceArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === customerSeviceArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : selectNavItem === "setting" ? (
          settingArr.map((item, idx) => {
            return (
              <div
                className={classes.sideItem}
                onClick={() => onClickMenu(selectNavItem, item.value, idx)}
                key={item.value}
                style={
                  selectMenu === settingArr[idx].value
                    ? { backgroundColor: "#615A5A" }
                    : null
                }>
                <Typography
                  variant="subtitle1"
                  className={classes.sideItemText}>
                  {item.title}
                </Typography>
              </div>
            );
          })
        ) : (
          <div> 데이터가 없습니다.</div>
        )}
      </Grid>
    </Grid>
  );
}
