/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardMedia,
  Paper,
  IconButton,
  Icon,
  Dialog,
  FormControlLabel,
  Radio,
  InputBase,
  ButtonBase,
} from "@material-ui/core";
import { requestGet } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import {
  popupAction,
  popupError,
  popupClose,
} from "../../redux/popup/PopupActions";
import { formatPhoneNumber, numFormat } from "../../services/utils";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  titleGrid: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  bold: {
    fontSize: 32,
    color: "#374553",
    fontFamily: fonts.spoqaHanSansNeoBold,
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dfcb: {
    display: "flex",
    justifyContent: "center",
    alignItems: "base-line",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      padding: "20px 25px",
      maxWidth: "1140px",
      border: "3px solid #b4bac3",
      borderRadius: 15,
    },
  },
  infoGrid: {
    border: "1px solid #DFDFDF",
    backgroundColor: "#efefef",
    marginBottom: "20px",
  },
  infoContent: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
  },
  infocell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  select: {
    "& div": {
      backgroundColor: "#fff",
    },
  },
  searchBox: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  initializationBtn: {
    width: "120px",
    backgroundColor: "#fff",
    border: "1px solid #e9e9e9",
    borderRadius: 0,
  },
  divider: {
    width: "100%",
  },
  searchBtn: {
    width: "120px",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  driverInfoGrid: {},
  receivingTimeTextField: {
    "& input": {
      textAlign: "center",
    },
  },
  receivingStatusTextField: {
    textAlign: "center",
    "& input": {
      color: "blue",
      textAlign: "center",
    },
  },
  popUpTableGrid: {
    height: "500px",
  },
  popupTableCell: {
    textAlign: "center",
    border: "1px solid #dddddd",
    color: "#374553",
    fontSize: 13,
    fontFamily: fonts.malgunGothicBold,
    "& span": {
      color: "#374553",
      fontSize: 13,
      fontFamily: fonts.malgunGothicBold,
    },
  },
  radioBtn: {
    marginLeft: "20px",
  },
  label2: {
    fontFamily: fonts.malgunGothicSemiLight,
    fontSize: 13,
    color: "#374553",
  },
  th: {
    "& th": { padding: 0, height: 50, borderTop: "0px" },
  },
  menu: {
    borderRadius: 2,
    border: "1px solid #e9ebee",
    height: 40,
    marginBottom: 10,
    padding: "10px",
    backgroundColor: "#fff",
  },
  label3: {
    fontSize: 13,
    color: "#374553",
    fontFamily: fonts.malgunGothicBold,
  },
  ib: {
    flex: 1,
    borderRadius: 2,
    display: "flex",
    paddingLeft: 14,
    border: "1px solid #e9ebee",
    height: 36,
    alignItems: "center",
    backgroundColor: "#f7f8f9",
    fontSize: 13,
    color: "#2069e3",
    fontFamily: fonts.malgunGothicBold,
  },
  button: {
    backgroundColor: "#fff",
    // height: 40,
    borderRadius: 2,
    border: "1px solid #dddddd",
    // marginRight: "10px",
    // width: "100px",
    fontSize: 13,
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoMedium,
    padding: "5px",
  },
});

export default function InventoryDetail({
  onClose,
  driverId,
  driverWorkId,
  name,
  companyName,
  companyAddres,
  carNumber,
  phone,
}) {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const classes = useStyles();
  const [data, setData] = useState({});
  const [radioSelectValue, setRadioSelectValue] = useState("normal");
  const [num, setNum] = useState();
  const [show, setShow] = useState(false);
  useEffect(() => {
    requestGet({
      url: consts.apiUrl + "/drivers/" + driverId + "/works/" + driverWorkId,
    })
      .then((x) => {
        setData({ ...x });
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  }, []);

  useEffect(() => {
    requestGet({
      url: consts.apiUrl + `/drivers/${driverId}/works/${driverWorkId}/stocks`,
      query: {
        sort: radioSelectValue === "normal" ? "" : "stock-reverse",
      },
    })
      .then((d) => {
        setProducts([...d]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  }, [radioSelectValue]);

  const onClickClose = () => {
    onClose();
  };
  const handleRadioBtn = (e) => {
    setRadioSelectValue(e.target.value);
  };
  const NumPopup = (e) => {
    return (
      <Grid
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ButtonBase className={classes.button}>
          <Icon
            onClick={() => {
              if (e.data > 0) {
                let newNum = e.data - 1;
                setNum(newNum);
                dispatch(popupClose);
                dispatch(
                  popupAction({
                    title: "수량 변경",
                    message: <NumPopup data={newNum} />,
                  })
                );
              }
            }}
            style={{ color: "#b4bac3", cursor: "pointer" }}
          >
            remove
          </Icon>
        </ButtonBase>
        <Grid style={{ padding: "0px 20px" }}>{e.data}</Grid>
        <ButtonBase className={classes.button}>
          <Icon
            onClick={() => {
              let newNum = e.data + 1;
              setNum(newNum);
              dispatch(popupClose);
              dispatch(
                popupAction({
                  title: "수량 변경",
                  message: <NumPopup data={newNum} />,
                })
              );
            }}
            style={{ color: "#b4bac3", cursor: "pointer" }}
          >
            add
          </Icon>
        </ButtonBase>
      </Grid>
      // </Grid>
    );
  };

  const onNumChange = (e) => {
    dispatch(
      popupAction({
        title: "수량 변경",
        message: <NumPopup data={e} />,
      })
    );
    return <NumPopup data={e} />;
  };
  return (
    <Dialog
      className={[classes.dfcc, classes.dialog].join(" ")}
      open
      onClose={onClickClose}
    >
      <Grid container xs={12} justify="flex-end">
        <Icon
          onClick={onClickClose}
          style={{ color: "#b4bac3", cursor: "pointer" }}
        >
          close
        </Icon>
      </Grid>
      <Grid item xs={12} className={classes.driverInfoGrid}>
        <Grid
          item
          xs={12}
          className={[classes.titleGrid, classes.df].join(" ")}
          style={{ justifyContent: "start", alignItems: "base-line" }}
        >
          <span className={classes.bold}>{name}</span>
          <span className={classes.label2} style={{ marginLeft: "30px" }}>
            차량번호 : {carNumber}
          </span>
          <span className={classes.label2} style={{ marginLeft: "30px" }}>
            휴대폰 : {formatPhoneNumber(phone)}
          </span>
          <span className={classes.label2} style={{ marginLeft: "30px" }}>
            유통사 : {companyName} ({companyAddres})
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginTop: "16px" }}
        >
          <Grid item xs={1} className={classes.dfsc}>
            <span className={classes.label3}>입고 SET</span>
          </Grid>
          <Grid item xs={2} className={classes.dfcc}>
            <span className={classes.ib}>{data.receiptSet}</span>
          </Grid>
          <Grid
            item
            xs={1}
            className={classes.dfcc}
            style={{ marginLeft: "20px" }}
          >
            <span className={classes.label3}>품목 수</span>
          </Grid>
          <Grid item xs={2} className={classes.dfcc}>
            <span
              className={classes.ib}
              style={{ color: "#374553", fontFamily: fonts.malgunGothic }}
            >
              {products.length}
            </span>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ justifyContent: "flex-end" }}
        >
          <select
            value={radioSelectValue}
            onChange={handleRadioBtn}
            className={classes.menu}
          >
            <option value={"normal"}>기본노출</option>
            <option value={"stock-reverse"}>재고 적은순</option>
          </select>
        </Grid>
        {/* <Divider className={classes.divider} /> */}
        <Grid
          item
          xs={12}
          className={classes.popUpTableGrid}
          style={{ textAlign: "center" }}
        >
          <Grid item xs={12}>
            <TableContainer first>
              <Th topleft center xs={2}>
                NO
              </Th>
              <Th center xs={3}>
                상품코드
              </Th>
              <Th center xs={4}>
                상품이미지
              </Th>
              <Th center xs={10}>
                상품명
              </Th>
              <Th center xs={3}>
                공급가
              </Th>
              <Th center xs={2}>
                현재 재고
              </Th>
              {/* <Th topright center xs={3}></Th> */}
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            {products.map((col, idx) => {
              return (
                <>
                  <TableContainer
                    key={idx}
                    last={products.length === idx + 1 ? true : false}
                  >
                    <Td
                      xs={2}
                      center
                      bottomleft={products.length === idx + 1 ? true : false}
                    >
                      {products.length - idx}
                    </Td>
                    <Td xs={3} center>
                      {col.code}
                    </Td>
                    <Td xs={4} center>
                      <div className={classes.dfcc} style={{ width: 100 }}>
                        <CardMedia
                          component="img"
                          image={consts.fileApiUrl + "/" + col.path}
                        />
                      </div>
                    </Td>
                    <Td xs={10} center>
                      {col.name}
                    </Td>
                    <Td xs={3} center>
                      {numFormat(col.price)}원
                    </Td>
                    <Td xs={2} center>
                      {col.stockQuantity}
                    </Td>
                    {/* <Td
                      xs={3}
                      center
                      bottomright={products.length === idx + 1 ? true : false}
                    >
                      <ButtonBase
                        onClick={() => onNumChange(col.stockQuantity)}
                        className={classes.button}
                        variant="outlined"
                      >
                        수량 변경
                      </ButtonBase>
                    </Td> */}
                  </TableContainer>
                </>
              );
            })}
          </Grid>
        </Grid>
        {/* <Divider className={classes.divider} /> */}
      </Grid>
    </Dialog>
  );
}
