import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, TextField } from "@material-ui/core";
import { requestGet, requestPut } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "60px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  addBtn: {
    backgroundColor: "#656565",
    borderRadius: 0,
    width: "120px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  deleteBtn: {
    backgroundColor: "#000",
    marginLeft: "10px",
    borderRadius: 0,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  textfield: {
    "& input": {
      textAlign: "center",
    },
  },
  saveBtn: {
    marginTop: "50px",
    backgroundColor: "#000",
    borderRadius: 0,
    width: "120px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
});

export default function DeliveryCharge() {
  const classes = useStyles();
  const [data, setData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    requestGet({
      url: consts.apiUrl + "/deliveryfees",
      query: { type: "" },
    })
      .then((x) => {
        setData({ ...x });
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  }, []);

  const handleSave = async () => {
    dispatch(loadingStart);
    try {
      for (let key of Object.keys(data)) {
        const d = data[key];
        await requestPut({
          url: consts.apiUrl + "/deliveryfees/" + d.deliveryFeeId,
          body: {
            amount: parseInt(d.amount) || 0,
          },
        });
      }
      dispatch(popupMessage({ title: "수정 완료." }));
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  if (!data) {
    return null;
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "30px" }}
        >
          <Typography variant="h5" className={classes.bold}>
            배송비
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Typography variant="h5" className={classes.bold}>
            주문
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "20px" }}
        >
          <Typography variant="subtutle2">주문 배송비</Typography>
          <TextField
            className={classes.textfield}
            variant="outlined"
            size="small"
            style={{ marginLeft: "20px" }}
            type="number"
            onChange={(e) => {
              data.order.amount = e.target.value;
              setData({ ...data });
            }}
            value={data.order.amount}
          />
          <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
            원
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Typography variant="h5" className={classes.bold}>
            구매자 부담
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "20px" }}
        >
          <Typography variant="subtutle2">교환 배송비</Typography>
          <TextField
            className={classes.textfield}
            variant="outlined"
            size="small"
            style={{ marginLeft: "20px" }}
            type="number"
            onChange={(e) => {
              data["buyer-exchange"].amount = e.target.value;
              setData({ ...data });
            }}
            value={data["buyer-exchange"].amount}
          />
          <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
            원
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "40px" }}
        >
          <Typography variant="subtutle2">반품 배송비</Typography>
          <TextField
            className={classes.textfield}
            variant="outlined"
            size="small"
            type="number"
            onChange={(e) => {
              data["buyer-refund"].amount = e.target.value;
              setData({ ...data });
            }}
            value={data["buyer-refund"].amount}
            style={{ marginLeft: "20px" }}
          />
          <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
            원
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Typography variant="h5" className={classes.bold}>
            판매자 부담
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "20px" }}
        >
          <Typography variant="subtutle2">교환 배송비</Typography>
          <TextField
            className={classes.textfield}
            variant="outlined"
            size="small"
            type="number"
            onChange={(e) => {
              data["seller-exchange"].amount = e.target.value;
              setData({ ...data });
            }}
            value={data["seller-exchange"].amount}
            style={{ marginLeft: "20px" }}
          />
          <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
            원
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "20px" }}
        >
          <Typography variant="subtutle2">반품 배송비</Typography>
          <TextField
            className={classes.textfield}
            variant="outlined"
            size="small"
            type="number"
            onChange={(e) => {
              data["seller-refund"].amount = e.target.value;
              setData({ ...data });
            }}
            value={data["seller-refund"].amount}
            style={{ marginLeft: "20px" }}
          />
          <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
            원
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Typography variant="h5" className={classes.bold}>
            배송비 무료
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "20px" }}
        >
          <Typography variant="subtutle2">기준 배송비</Typography>
          <TextField
            className={classes.textfield}
            variant="outlined"
            size="small"
            style={{ marginLeft: "20px" }}
            type="number"
            onChange={(e) => {
              data["event"].amount = e.target.value;
              setData({ ...data });
            }}
            value={data["event"].amount}
          />
          <Typography variant="subtitle1" style={{ marginLeft: "20px" }}>
            원
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={handleSave}
            variant="outlined"
            className={classes.saveBtn}
          >
            <Typography variant="subtitle1">저장</Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
