/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
} from "../../services/network";
import consts from "../../libs/consts";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import useRouter from "use-react-router";
import fonts from "../../libs/fonts";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  addBtn: {
    backgroundColor: "#fff",
    borderRadius: 2,
    width: "160px",
    "&:hover": {
      backgroundColor: "lightgray",
    },
  },
  deleteBtn: {
    backgroundColor: "#fff",
    marginLeft: "10px",
    borderRadius: 2,
    width: "100px",
    "&:hover": {
      backgroundColor: "lightgray",
    },
  },
  textfield: {
    "& input": {
      textAlign: "center",
      fontSize: 14,
    },
  },
  saveBtn: {
    backgroundColor: "#40cf66",
    borderRadius: 2,
    width: "160px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
});

export default function Car() {
  const classes = useStyles();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selCategoryId, setSelCategoryId] = useState("");
  const [types, setTypes] = useState([]);

  useEffect(() => {
    requestGet({
      url: consts.apiUrl + "/categories",
      query: { type: "car-type" },
    })
      .then((x) => {
        setTypes([...x]);
        if (!x || !x.length) {
          history.replace("/setting/car_type");
          dispatch(popupMessage({ title: "자동자 제조사부터 등록해주세요." }));
        }
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  }, []);

  useEffect(() => {
    selCategoryId && fetch();
  }, [selCategoryId]);

  const fetch = () => {
    requestGet({
      url: consts.apiUrl + "/categories",
      query: { type: "car", parentId: selCategoryId },
    })
      .then((x) => {
        setData([...x]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const insert = async () => {
    dispatch(loadingStart);
    try {
      if (!selCategoryId) {
        throw "제조사를 선택해주세요";
      }
      const { categoryId } = await requestPost({
        url: consts.apiUrl + "/categories",
        body: {
          label: "",
          parentId: selCategoryId || null,
          type: "car",
        },
      });

      setData([
        ...data,
        { label: "", type: "car", value: categoryId, categoryId },
      ]);
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  const save = async () => {
    dispatch(loadingStart);
    try {
      await requestPut({
        url: consts.apiUrl + "/categories",
        body: {
          categories: data.map((x) => {
            return x;
          }),
        },
      });
      fetch();
      dispatch(popupMessage({ title: "저장 완료." }));
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  const handleDeleteCategory = async (category) => {
    dispatch(loadingStart);
    try {
      await requestDelete({
        url: consts.apiUrl + "/categories/" + category.categoryId,
      });
      setData([...data.filter((x) => x.categoryId !== category.categoryId)]);
      dispatch(popupMessage({ title: "삭제 완료." }));
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "20px" }}
        >
          <Typography
            variant="h5"
            style={{
              fontSize: "28px",
              lineHeight: "32px",
              fontFamily: fonts.spoqaHanSansNeoBold,
              color: "#374553",
              paddingTop: "40px",
            }}
          >
            자동차 모델
          </Typography>
        </Grid>
        <Divider
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#c5c8ce",
            marginBottom: "40px",
          }}
        />
        <Grid
          item
          xs={5}
          className={classes.dfsc}
          style={{
            marginBottom: "30px",
            backgroundColor: "#f7f8f9",
            padding: "30px",
            borderRadius: 5,
          }}
        >
          <Typography variant="subtitle2" style={{ width: "100px" }}>
            제조사
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ backgroundColor: "#fff" }}
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              제조사
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selCategoryId}
              onChange={(e) => {
                setData([]);
                setSelCategoryId(e.target.value);
              }}
              label="Age"
            >
              {types.map((x, i) => {
                return (
                  <MenuItem key={i.toString()} value={x.categoryId}>
                    {x.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "30px" }}>
          <Button
            onClick={insert}
            variant="outlined"
            className={classes.addBtn}
          >
            <Typography variant="subtitle2">+ 추가</Typography>
          </Button>

          <Button
            onClick={save}
            className={classes.saveBtn}
            style={{ marginLeft: "10px" }}
          >
            <Typography variant="subtitle2">저장</Typography>
          </Button>
        </Grid>
        {data.length !== 0 && (
          <Grid
            item
            xs={5}
            style={{
              backgroundColor: "#f7f8f9",
              padding: "30px",
              borderRadius: 5,
            }}
          >
            {data.map((x, i) => {
              return (
                <Grid
                  key={i.toString()}
                  item
                  xs={12}
                  className={classes.dfsc}
                  style={{ marginBottom: "20px" }}
                >
                  <Typography variant="subtitle2">모델{i + 1}</Typography>
                  <TextField
                    className={classes.textfield}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: "20px", backgroundColor: "#fff" }}
                    placeholder="제조사"
                    value={x.label}
                    onChange={(e) => {
                      data[i].label = e.target.value;
                      setData([...data]);
                    }}
                  />
                  <Button
                    onClick={() => {
                      handleDeleteCategory(x);
                    }}
                    variant="outlined"
                    className={classes.deleteBtn}
                  >
                    <Typography variant="subtitle2">삭제</Typography>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
