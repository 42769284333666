import React, { useEffect, useRef, useState } from "react";
import useDistribution from "../../../hooks/useDistribution.js";
import { numFormat } from "../../services/utils.js";
import useMember from "../../../hooks/useMember.js";
import TableLayout from "../../components/table-layout/TableLayout.js";
import queryString from "query-string";
import { requestGet } from "../../services/network.js";
import consts from "../../libs/consts.js";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import fonts from "../../libs/fonts.js";
import { RefreshOutlined } from "@material-ui/icons";
import MemoInput from "./MemoInput.js";
import moment from "moment";

const useStyles = makeStyles({
  label3: {
    fontSize: 15,
    color: "#374553",
    fontFamily: fonts.malgunGothicBold,
  },
  ib: {
    borderRadius: 2,
    marginLeft: "1rem",
    minWidth: "20rem",
    display: "flex",
    paddingLeft: 14,
    border: "1px solid #e9ebee",
    height: 40,
    alignItems: "center",
    backgroundColor: "#f7f8f9",
    fontSize: 15,
    color: "#2069e3",
    fontFamily: fonts.malgunGothicBold,
  },
});

export default function Container({ history, location }) {
  const classes = useStyles();
  const [distributions] = useDistribution();
  const [loading, setLoading] = useState(false);
  const { isPartner, distributionId } = useMember();
  const latestUpdatedAt = useRef("");
  const [data, setData] = useState({
    list: [],
    name: "",
  });

  const query = queryString.parse(location.search);

  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    if (!query.distributionId) return;

    requestGet({
      url: consts.apiUrl + "/drivers",
      query: {
        distributionId: query.distributionId,
      },
    })
      .then((res) => {
        setDrivers(
          res.results.map((x) => ({
            label: x.nickname,
            value: x.driverId,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [query.distributionId]);

  const handleSearch = async () => {
    setLoading(true);
    try {
      if (!query.distributionId) {
        throw new Error("유통사를 선택해 주세요.");
      }
      if (
        !query.driverId ||
        !drivers.filter((x) => String(x.value) === query.driverId).length
      ) {
        throw new Error("기사를 선택해 주세요.");
      }

      const datetime = query.date + " " + query.time;
      if (!query.date || !query.time || !moment(datetime).isValid()) {
        throw new Error("올바른 주문 조회 시점을 입력해 주세요.");
      }

      const data = await requestGet({
        url: consts.apiUrl + "/drivers/" + query.driverId + "/stock",
        query: {
          distributionId: query.distributionId,
        },
      });

      const stockData = await requestGet({
        url:
          consts.apiUrl +
          "/drivers/" +
          query.driverId +
          "/works/" +
          data.driverWorkId +
          "/stocks/monitoring",
        query: {
          datetime: datetime,
        },
      });
      latestUpdatedAt.current = moment().format("YYYY-MM-DD HH:mm");
      setData({
        list: stockData,
        name: data.receiptSet,
      });
    } catch (error) {
      setData({
        list: [],
        name: "",
      });
      alert(error.message || error);
    }
    setLoading(false);
  };

  return (
    <>
      <TableLayout
        label="재고 모니터링"
        limitMenu={false}
        onSearch={handleSearch}
        data={data.list}
        dataLoading={loading}
        dataLength={data.list.length}
        renderTableLabel="품목"
        pagination={false}
        defaultQuery={{
          page: 1,
          limit: 10,
          date: "today",
          driverId: "",
          distributionId: isPartner ? distributionId : "",
        }}
        renderHeaderComponent={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <span className={classes.label3}>입고 SET</span>
            <span className={classes.ib}>{data.name}</span>
            <Button
              style={{ marginLeft: "1rem" }}
              onClick={handleSearch}
              color="primary"
              variant="outlined"
            >
              <RefreshOutlined style={{ marginRight: 6 }} fontSize={"small"} />
              새로고침
            </Button>

            <Typography style={{ marginLeft: "1rem" }}>
              업데이트 : {latestUpdatedAt.current}
            </Typography>
          </Box>
        }
        search={[
          [
            isPartner
              ? null
              : {
                  label: "유통사",
                  type: "menu",
                  key: "distributionId",
                  data: distributions,
                },
            {
              label: "배송기사",
              type: "menu",
              key: "driverId",
              data: drivers,
            },
          ].filter((x) => x),
          [
            {
              label: "주문 조회 시점",
              type: "date-time",
              key: "date",
              key2: "time",
            },
          ],
        ]}
        columns={[
          {
            label: "NO",
            render: (col, i) => i + 1,
          },
          {
            label: "카테고리",
            render: (col) => col.category,
          },
          {
            label: "상품명",
            flex: 3,
            render: (col) => col.name,
          },
          {
            label: "판매 가격",
            flex: 2,
            render: (col) => numFormat(col.price) + "원",
          },
          {
            label: "현재고",
            render: (col) => col.stockQuantity,
          },
          {
            label: "주문",
            render: (col) => col.salesQuantity,
          },
          {
            label: "서비스",
            render: (col) => col.serviceQuantity,
          },
          {
            label: "상품메모",
            flex: 3,
            render: (col, i) => (
              <MemoInput
                key={query.driverId + i}
                driverId={query.driverId}
                {...col}
              />
            ),
          },
        ]}
      />
    </>
  );
}
