import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import {
  formatPhoneNumber,
  formatTime,
  numFormat
} from "../../services/utils.js";
import consts from "../../libs/consts.js";
import { formatBirthday, getGenderLabel } from "../../../utils/index.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  // const { data, loading, dataLength, fetchData, fetchExcel } = useListPayload({
  //   endpoint: "/users",
  // });
  const { data, loading, dataLength, fetchData, fetchExcel } = useListPayload({
    excelName: "고객회원",
    endpoint: "/users",
    triggers: ["type"]
  });

  return (
    <TableLayout
      label="고객회원"
      onSearch={fetchData}
      data={[...data]}
      dataLoading={loading}
      onExcel={fetchExcel}
      dataLength={dataLength}
      onClick={col => {
        window.open(
          `/member_management/customer_member_detail?userId=${col.userId}`,
          "_blank"
        );
      }}
      defaultQuery={{
        page: 1,
        limit: 10,
        start: "today",
        end: "today",
        keyword: "",
        method: ""
      }}
      search={[
        [
          {
            label: "가입일",
            type: "date",
            key: "start",
            key2: "end"
          }
        ],
        [
          {
            label: "검색",
            type: "input",
            key: "keyword",
            placeholder: "이름, 이메일(ID), 핸드폰번호"
          },
          {
            label: "가입유형",
            type: "menu",
            key: "method",
            data: [
              { label: "이메일", value: "email" },
              { label: "네이버", value: "naver" },
              { label: "구글", value: "google" },
              { label: "카카오톡", value: "kakaotalk" },
              { label: "애플", value: "apple" }
            ]
          }
        ]
      ]}
      columns={[
        {
          label: "가입일시",
          render: col => formatTime(col.createdAt, "YYYY-MM-DD HH:mm")
        },
        {
          label: "가입유형",
          render: col => (
            <>
              {col.method === "email"
                ? "이메일"
                : col.method === "kakaotalk"
                ? "카카오톡"
                : col.method === "naver"
                ? "네이버"
                : col.method === "google"
                ? "구글"
                : "애플"}
            </>
          )
        },
        {
          label: "이름",
          key: "name"
        },
        {
          label: "성별",
          render: x => <span>{getGenderLabel(x.gender)}</span>
        },
        {
          label: "생년월일 / 나이",
          render: x => (
            <span>
              {formatBirthday(x.birthday)} / {x.age || "-"}
            </span>
          )
        },
        {
          label: "이메일(ID)",
          key: "email",
          clickable: true,
          render: x => x.email || "회원 조회(미등록)",
          flex: 1.5
        },
        {
          label: "휴대폰번호",
          render: col => <>{formatPhoneNumber(col.phone)}</>
        },
        {
          label: "주소지",
          flex: 2.5,
          key: "address"
        },

        {
          label: "마케팅 수신동의",
          render: x => (x.receivePush ? "O" : "X")
        },
        {
          label: "주문건수",
          render: col => <>{numFormat(col.orderCount)}</>
        },
        {
          label: "총 주문금액",
          render: col => <>{numFormat(col.orderAmount) + "원"}</>
        },
        {
          label: "최근 주문일시",
          render: col => <>{formatTime(col.lastOrderAt, "YYYY-MM-DD HH:mm")}</>
        },
        {
          label: "고객등급",
          render: col => (
            <>
              <img
                style={{ width: 20, height: 20, objectFit: "contain" }}
                src={consts.fileApiUrl + "/" + col.grade.split(",")[1]}
              />
              <br />
              {col.grade.split(",")[0]}
            </>
          )
        }
      ]}
    />
  );
}
