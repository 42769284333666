/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Paper,
  Button,
  Collapse,
  TextField,
  Divider,
  FormControlLabel,
  Radio,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  ButtonBase,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  requestDelete,
  requestFile,
  requestGet,
  requestPost,
  requestPut,
} from "../../services/network";
import consts from "../../libs/consts";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { getFileUri } from "../../services/utils";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import TableTitle from "../../components/table/TableTitle";

export default function CategoryChild({ categories, setCategories }) {
  const classes = useStyles();
  const [data, setData] = useState();
  const dispatch = useDispatch();

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fs = new FileReader();
      fs.onloadend = () => {
        file.uri = fs.result;
        setData({ ...data, file });
      };
      fs.readAsDataURL(file);
      e.target.value = "";
    }
  };

  useEffect(() => {
    categories.filter((x) => x.select)[0]
      ? setData({ ...categories.filter((x) => x.select)[0] })
      : setData(null);
  }, [categories]);

  const add = async () => {
    const name = "중분류 " + (data.items.length + 1);
    requestPost({
      url: consts.apiUrl + "/products/categories",
      body: {
        name,
        parentId: data.productCategoryId,
      },
    })
      .then(({ productCategoryId }) => {
        setCategories([
          ...categories.map((x) => {
            if (x.productCategoryId === data.productCategoryId) {
              x.items = [
                ...x.items.map((x) => ({ ...x, select: false })),
                {
                  name,
                  productCategoryId: productCategoryId,
                  select: true,
                  parentId: data.productCategoryId,
                  privacy: 1,
                },
              ];
            }
            return x;
          }),
        ]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const deleteCategory = async () => {
    const d = data.items.filter((x) => x.select)[0];
    if (!d) {
      dispatch(
        popupMessage({
          message: "삭제할 카테고리를 선택해주세요.",
          title: "삭제",
        })
      );
      return;
    }
    requestDelete({
      url: consts.apiUrl + "/products/categories/" + d.productCategoryId,
    })
      .then((x) => {
        setCategories([
          ...categories.map((x) => {
            if (x.productCategoryId === data.productCategoryId) {
              x.items = x.items.filter(
                (y) => y.productCategoryId !== d.productCategoryId
              );
            }
            return x;
          }),
        ]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const save = () => {
    const file = new FormData();

    if (data.file) {
      file.append("file", data.file);
    } else if (data.path) {
      file.append("path", data.path);
    }
    file.append("privacy", data.privacy);
    file.append("name", data.name);
    file.append("negaDesc", data.negaDesc || "");
    file.append("pri1", data.pri1 || "");
    file.append("pri2", data.pri2 || "");
    file.append("pri3", data.pri3 || "");
    file.append("pri4", data.pri4 || "");
    file.append("pri5", data.pri5 || "");
    file.append("pri6", data.pri6 || "");
    file.append("pri7", data.pri7 || "");
    requestFile({
      put: true,
      url: consts.apiUrl + "/products/categories/" + data.productCategoryId,
      form: file,
    })
      .then((x) => {
        setCategories([
          ...categories.map((y) => {
            if (y.productCategoryId === data.productCategoryId) {
              y = {
                ...y,
                ...x,
              };
            }
            return y;
          }),
        ]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const saveChild = () => {
    if (!data.items.length) {
      return;
    } else {
      requestPut({
        url: consts.apiUrl + `/products/categories`,
        body: {
          data: data.items.map((x) => ({
            name: x.name,
            productCategoryId: x.productCategoryId,
          })),
        },
      })
        .then((d) => {
          setCategories([
            ...categories.map((x) => {
              if (x.productCategoryId === data.productCategoryId) {
                x.items = [...data.items];
              }
              return x;
            }),
          ]);
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    }
  };
  if (!data) {
    return null;
  }

  return (
    <>
      <Grid item xs={5}>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <Typography variant="subtitle1">카테고리 정보</Typography>
        </Grid>
        <Grid item xs={12} className={classes.boxHeader}>
          <ButtonBase
            variant="outlined"
            className={classes.button}
            onClick={save}
            style={{ marginLeft: "auto" }}
          >
            저장
          </ButtonBase>
        </Grid>
        <Grid item xs={12} className={classes.boxContent}>
          <Grid item xs={12} className={classes.df}>
            <Grid item xs={2} className={classes.dfcc}>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: fonts.malgunGothicSemiLight,
                  fontSize: 13,
                }}
              >
                분류명
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              className={classes.dfsc}
              style={{ padding: "10px" }}
            >
              <TextField
                inputProps={{ style: { fontSize: 13 } }}
                variant="outlined"
                size="small"
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12} className={classes.df}>
            <Grid item xs={2} className={classes.dfcc}>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: fonts.malgunGothicSemiLight,
                  fontSize: 13,
                }}
              >
                아이콘
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              className={classes.dfsc}
              style={{ padding: "10px" }}
            >
              <div style={{ width: "70px", marginRight: "20px" }}>
                {(data.file || data.path) && (
                  <CardMedia
                    component="img"
                    height={70}
                    style={{ objectFit: "contain" }}
                    image={data.file ? data.file.uri : getFileUri(data.path)}
                  />
                )}
              </div>
              <Button variant="outlined" component="label">
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: fonts.malgunGothicSemiLight,
                    fontSize: 13,
                  }}
                >
                  업로드
                </Typography>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleChangeImage}
                />
              </Button>
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12} className={classes.df}>
            <Grid item xs={2} className={classes.dfcc}>
              <Typography
                variant="subtitle2"
                style={{
                  fontFamily: fonts.malgunGothicSemiLight,
                  fontSize: 13,
                }}
              >
                노출설정
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              className={classes.dfsc}
              style={{ padding: "10px" }}
            >
              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    checked={!data.privacy}
                    className={classes.radioBtn}
                    onChange={(e) => {
                      setData({ ...data, privacy: 0 });
                    }}
                    value="show"
                  />
                }
                label={
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontFamily: fonts.malgunGothicSemiLight,
                      fontSize: 13,
                    }}
                  >
                    노출
                  </Typography>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn2}
                    checked={data.privacy === 1 || data.privacy}
                    onChange={(e) => {
                      setData({ ...data, privacy: 1 });
                    }}
                    value="hide"
                  />
                }
                label={
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontFamily: fonts.malgunGothicSemiLight,
                      fontSize: 13,
                    }}
                  >
                    비노출
                  </Typography>
                }
                labelPlacement="end"
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid
            style={{
              marginTop: 20,
              marginBottom: 10,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <Typography>상품 고시 정보</Typography>
          </Grid>
          <Grid
            style={{
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <TableContainer first>
              <Th topleft xs={4}>
                품명
              </Th>
              <Td topright textfield xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={data.pri1 || ""}
                  onChange={(e) => {
                    setData({ ...data, pri1: e.target.value });
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Td>
            </TableContainer>
            <TableContainer>
              <Th xs={4}>상품 구성</Th>
              <Td textfield xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={data.pri2 || ""}
                  onChange={(e) => {
                    setData({ ...data, pri2: e.target.value });
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Td>
            </TableContainer>
            {/* <TableContainer>
              <Th xs={4}>원산지</Th>
              <Td textfield xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={data.pri3 || ""}
                  onChange={(e) => {
                    setData({ ...data, pri3: e.target.value });
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Td>
            </TableContainer> */}
            <TableContainer>
              <Th xs={4}>생산지</Th>
              <Td textfield xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={data.pri4 || ""}
                  onChange={(e) => {
                    setData({ ...data, pri4: e.target.value });
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Td>
            </TableContainer>
            <TableContainer>
              <Th xs={4}>보관방법</Th>
              <Td textfield xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={data.pri5 || ""}
                  onChange={(e) => {
                    setData({ ...data, pri5: e.target.value });
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Td>
            </TableContainer>
            <TableContainer>
              <Th xs={4}>유통기한</Th>
              <Td textfield xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={data.pri6 || ""}
                  onChange={(e) => {
                    setData({ ...data, pri6: e.target.value });
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Td>
            </TableContainer>
            <TableContainer last>
              <Th bottomleft xs={4}>
                소비자 상담 문의
              </Th>
              <Td bottomright textfield xs={8}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={data.pri7 || ""}
                  onChange={(e) => {
                    setData({ ...data, pri7: e.target.value });
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Td>
            </TableContainer>
          </Grid>

          <Grid
            style={{
              marginTop: 20,
              marginBottom: 10,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <Typography>상품 교환 반품 안내</Typography>
            <TextField
              fullWidth
              multiline
              rows={5}
              size="small"
              variant="outlined"
              value={data.negaDesc || ""}
              onChange={(e) => {
                setData({ ...data, negaDesc: e.target.value });
              }}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} style={{ marginLeft: "16px" }}>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <Typography variant="subtitle1">2차 카테고리 정보</Typography>
        </Grid>
        <Grid item xs={12} className={classes.boxHeader}>
          <ButtonBase
            onClick={add}
            variant="outlined"
            className={classes.button}
          >
            추가
          </ButtonBase>

          <ButtonBase
            onClick={deleteCategory}
            variant="outlined"
            className={classes.button}
            style={{ color: "#ff0808" }}
          >
            삭제
          </ButtonBase>
          <ButtonBase
            variant="outlined"
            className={classes.button}
            onClick={saveChild}
            style={{
              color: "white",
              backgroundColor: "rgb(57, 193, 93)",
              marginLeft: "auto",
            }}
          >
            저장
          </ButtonBase>
        </Grid>
        <Grid item xs={12} className={classes.boxContent}>
          <List component="div" disablePadding>
            {data.items.map((z, r) => {
              return (
                <ListItem
                  key={r.toString()}
                  style={{
                    borderLeft: z.select ? "8px solid #ddd" : "",
                  }}
                  className={classes.secondListItem}
                  onClick={() => {
                    setData({
                      ...data,
                      items: [
                        ...data.items.map((x) => {
                          const s =
                            x.productCategoryId === z.productCategoryId
                              ? !x.select
                              : false;

                          if (s) {
                            document
                              .getElementById("product-category-" + r)
                              .focus();
                          }
                          return {
                            ...x,
                            select: s,
                          };
                        }),
                      ],
                    });
                  }}
                >
                  <ListItemText
                    primary={
                      <input
                        id={"product-category-" + r}
                        value={z.name}
                        onChange={(e) => {
                          data.items[r].name = e.target.value;
                          setData({ ...data, items: [...data.items] });
                        }}
                        type="text"
                        style={{
                          border: "none",
                          alignSelf: "stretch",
                          width: "100%",
                          height: "100%",
                          fontSize: "13px",
                        }}
                      />
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  divider: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#fff",
    height: 40,
    borderRadius: 2,
    border: "1px solid #dddddd",
    marginRight: "10px",
    width: "100px",
    fontSize: 13,
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoMedium,
  },
  boxHeader: {
    border: "1px solid #dddddd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f7f8f9",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  boxContent: {
    height: "600px",
    borderLeft: "1px solid #dddddd",
    overflowY: "auto",
    borderRight: "1px solid #dddddd",
    borderBottom: "1px solid #dddddd",
    padding: "10px",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  addBtn: {
    borderRadius: 2,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  deleteBtn: {
    marginLeft: "10px",
    borderRadius: 2,
    color: "red",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    width: 100,
    borderRadius: 2,
    color: "#fff",
    backgroundColor: "#656565",
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  listItem: {
    display: "flex",
    border: "1px solid #dddddd",
  },
  secondListItem: {
    height: 50,
    marginTop: "10px",
    border: "1px solid #dddddd",
  },
  thirdListItem: {
    marginTop: "10px",
    marginLeft: "40px",
    border: "1px solid #dddddd",
  },
  radioBtn: {
    color: "#40cf66 !important",
  },
  radioBtn2: {
    marginLeft: "30px",
    color: "#40cf66 !important",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  DialogSaveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  resize: { fontSize: "13px" },
});
