import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import {
  formatPhoneNumber,
  formatTime,
  numFormat,
} from "../../services/utils.js";
import consts from "../../libs/consts.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  const { data, loading, dataLength, fetchData } = useListPayload({
    endpoint: "/members/delete",
  });

  return (
    <TableLayout
      label="탈퇴회원"
      onSearch={fetchData}
      data={[...data]}
      dataLoading={loading}
      dataLength={dataLength}
      onClick={(col) => {
        if (col.type === "user") {
          history.push("/member_management/customer_member_detail", {
            userId: col.userId,
          });
        } else {
          history.push("/member_management/driver_member_detail", {
            driverId: col.driverId,
          });
        }
      }}
      defaultQuery={{
        page: 1,
        limit: 10,
        start: "",
        end: "",
        keyword: "",
        method: "",
      }}
      search={[
        [
          {
            label: "가입일",
            type: "date",
            key: "start",
            key2: "end",
          },
        ],
        [
          {
            label: "탈퇴일",
            type: "date",
            key: "start2",
            key2: "end2",
          },
        ],
        [
          {
            label: "검색",
            type: "input",
            key: "keyword",
            placeholder: "이름, 이메일(ID), 핸드폰번호, 차량명",
          },
          {
            label: "회원구분",
            type: "menu",
            key: "method",
            data: [
              { label: "일반회원", value: "user" },
              { label: "기사회원", value: "driver" },
            ],
          },
        ],
      ]}
      columns={[
        {
          label: "회원구분",
          render: (col) => <>{col.type === "user" ? "고객회원" : "기사회원"}</>,
        },
        {
          label: "이름",
          key: "name",
        },
        {
          label: "이메일/아이디",
          key: "username",
          clickable: true,
        },
        {
          label: "휴대폰번호",
          render: (col) => <>{formatPhoneNumber(col.phone)}</>,
        },
        {
          label: "차량명",
          key: "carNumber",
        },
        {
          label: "가입일시",
          render: (col) => formatTime(col.createdAt, "YYYY-MM-DD HH:mm"),
        },
        {
          label: "탈퇴일시",
          render: (col) => formatTime(col.deletedAt, "YYYY-MM-DD HH:mm"),
        },
      ]}
    />
  );
}
