/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Paper,
  Button,
  Divider,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { requestGet, requestPut } from "../../services/network.js";
import consts from "../../libs/consts.js";
import { popupError, popupMessage } from "../../redux/popup/PopupActions.js";
import Editor from "../../components/editor/Editor.js";
import { makeHtmlFile } from "../../services/utils.js";
import {
  loadingStart,
  loadingStop,
} from "../../redux/loading/LoadingActions.js";
import fonts from "../../libs/fonts.js";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  addBtn: {
    backgroundColor: "#656565",
    borderRadius: 0,
    width: "120px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  deleteBtn: {
    backgroundColor: "#000",
    marginLeft: "10px",
    borderRadius: 0,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  textfield: {
    "& input": {
      textAlign: "center",
    },
  },
  saveBtn: {
    backgroundColor: "#40cf66",
    borderRadius: 2,
    width: "120px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  cancleBtn: {
    backgroundColor: "#fff",
    borderRadius: 2,
    width: "120px",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  divider: {
    width: "100%",
    height: "2px",
  },
  tabs: {
    "& .MuiTab-textColorPrimary": {
      color: "#374553",
    },
    // "& .Mui-selected span div h6": {
    //   fontWeight: "bold",
    // },
    "& .MuiTabs-indicator": {
      backgroundColor: "#40cf66",
    },
    "& .Mui-selected": {
      color: "#39c15d",
    },
  },
  ckeditor: {
    marginTop: "30px",
    marginBottom: "30px",
    "& .ck-editor__editable_inline": {
      minHeight: "300px",
    },
  },
});

export default function Terms() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [policy, setPolicys] = useState([]);

  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/policys" })
      .then((x) => {
        setPolicys([...x]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  }, []);

  const savePolicy = async () => {
    const policyData = policy[tabValue];
    if (!policyData) {
      return;
    }

    dispatch(loadingStart);
    try {
      const file = makeHtmlFile(policyData.ref.getHtml());

      const form = new FormData();
      form.append("file", file);

      await requestPut({
        url: consts.apiUrl + "/policys/" + policyData.policyId,
        body: form,
      });
      dispatch(popupMessage({ title: "수정 완료." }));
      requestGet({ url: consts.apiUrl + "/policys" })
        .then((x) => {
          setPolicys([...x]);
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginBottom: "20px" }}
        >
          <Typography
            variant="h5"
            style={{
              fontSize: "28px",
              lineHeight: "32px",
              fontFamily: fonts.spoqaHanSansNeoBold,
              color: "#374553",
              paddingTop: "40px",
            }}
          >
            약관
          </Typography>
        </Grid>
        <Divider
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#c5c8ce",
            marginBottom: "40px",
          }}
        />
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <Tabs
              width="100%"
              value={tabValue}
              // indicatorColor="primary"
              // textColor="primary"
              className={classes.tabs}
              onChange={handleTabValue}
            >
              {policy.map((x, i) => {
                return (
                  <Tab
                    key={i.toString() + x.htmlPath}
                    label={
                      <Grid item xs={12} className={classes.df}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            fontFamily: fonts.spoqaHanSansNeoMedium,
                          }}
                        >
                          {x.name}
                        </Typography>
                      </Grid>
                    }
                  />
                );
              })}
            </Tabs>
            <Grid
              item
              xs={12}
              className={classes.ckeditor}
              style={{ flexDirection: "column", height: "400px" }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                }}
              >
                {policy[tabValue] && (
                  <Editor
                    ref={(ref) => {
                      policy[tabValue].ref = ref;
                    }}
                    key={tabValue.toString() + policy[tabValue].htmlPath}
                    htmlPath={policy[tabValue].htmlPath}
                    html={policy[tabValue].html}
                    onHtmlChange={(h) => {
                      policy[tabValue].html = h;
                      setPolicys([...policy]);
                    }}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button onClick={savePolicy} className={classes.saveBtn}>
                <Typography variant="subtitle1">저장</Typography>
              </Button>
              <Button
                variant="outlined"
                className={classes.cancleBtn}
                style={{ marginLeft: "20px" }}
              >
                <Typography variant="subtitle1">취소</Typography>
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}
