import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import {
  formatPhoneNumber,
  formatTime,
  numFormat,
} from "../../services/utils.js";
import DashBoard from "../../components/dashboard/DashBoard.js";
import qs from "query-string";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { requestGet } from "../../services/network.js";
import consts from "../../libs/consts.js";
import { popupError } from "../../redux/popup/PopupActions.js";
import useMember from "../../../hooks/useMember.js";
import src from "../../libs/noti.mp3";

const useStyles = makeStyles({});

const getOrderState = (type) => {
  switch (type) {
    case "order":
      return "배송준비";
    case "delivery-progress":
      return "배송중";
    case "delivery-complete":
      return "배송완료";
    case "cancel":
      return "주문취소";
    case "exchange-progress":
      return "교환 픽업중";
    case "exchange-complete":
      return "교환완료";
    case "exchange-request":
      return "교환접수";
    case "refund-progress":
      return "반품 픽업중";
    case "refund-complete":
      return "반품완료";
    case "refund-request":
      return "반품접수";
  }
};
export default function Container({ history, location }) {
  const classes = useStyles();
  const user = useSelector((s) => s.user, shallowEqual);
  const { isPartner, distributionId } = useMember();
  const dispatch = useDispatch();
  const [distributions] = useDistribution();
  const { query, data, loading, dataLength, fetchData, fetchExcel } =
    useListPayload({
      excelName: "주문조회",
      endpoint: "/admins/orders",
      triggers: ["type"],
    });

  const [o, so] = useState({});
  const [r, sr] = useState({});
  const [e, se] = useState({});

  useEffect(() => {
    fetchStat();
  }, []);

  useEffect(() => {
    if (!query.start) {
      query.start = moment().format("YYYY-MM-DD");
    }
    let prev = 0;
    requestGet({
      url: consts.apiUrl + "/admins/orders/state",
      query: { ...query },
    }).then((x) => {
      prev = x.paymentLength;
    });
    let timerId = setInterval(() => {
      let audio = new Audio(src);
      requestGet({
        url: consts.apiUrl + "/admins/orders/state",
        query: { ...query },
      }).then((x) => {
        if (x.paymentLength > prev) {
          prev = x.paymentLength;
          audio.play();
        }
        so({ ...x });
      });
    }, 60000);
    return () => {
      clearInterval(timerId);
    };
  }, [
    query.distributionId,
    query.end,
    query.keyword,
    query.limit,
    query.page,
    query.start,
    query.type,
  ]);

  const fetchStat = () => {
    if (!query.start) {
      query.start = moment().format("YYYY-MM-DD");
    }
    requestGet({
      url: consts.apiUrl + "/admins/orders/state",
      query: { ...query },
    })
      .then((x) => {
        so({ ...x });
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
    requestGet({
      url: consts.apiUrl + "/admins/orders/state/refund",
      query: { ...query },
    })
      .then((x) => {
        sr({ ...x });
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
    requestGet({
      url: consts.apiUrl + "/admins/orders/state/exchange",
      query: { ...query },
    })
      .then((x) => {
        se({ ...x });
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  return (
    <TableLayout
      label="주문조회"
      onSearch={() => {
        fetchData();
        fetchStat();
      }}
      data={[...data]}
      dataLoading={loading}
      onExcel={fetchExcel}
      dataLength={dataLength}
      renderTableLabel={getOrderState(query.type)}
      onClick={(col, index) => {
        window.open(
          `/delivery_management/payment?orderId=${
            col.orderId || ""
          }&orderActionId=${col.orderActionId || ""}&sName=${
            col.sName
          }&sPhone=${col.sPhone}`,
          "_blank"
        );
      }}
      renderHeaderComponent={
        <DashBoard
          onClick={(data) => {
            history.replace({
              pathname: location.pathname,
              search: qs.stringify({ ...query, page: 1, type: data }),
            });
          }}
          data={[
            {
              label: "주문/배송/완료",
              icon: "order",
              items: [
                {
                  data: "order",
                  label: "배송준비",
                  value: o.paymentLength,
                  color: "orange",
                },
                {
                  data: "delivery-progress",
                  label: "배송중",
                  value: o.deliveryStartLength,
                },
                {
                  data: "delivery-complete",
                  label: "배송완료",
                  value: o.deliveryCompleteLength,
                },
                {
                  data: "cancel",
                  label: "주문취소",
                  color: "red",
                  value: o.cancelLength,
                },
              ],
            },

            {
              label: "교환",
              icon: "exchange",
              items: [
                {
                  data: "exchange-request",
                  label: "교환접수",
                  value: e.exchangeRequestLength,
                  color: "orange",
                },
                {
                  data: "exchange-progress",
                  label: "픽업중",
                  value: e.exchangePickupLength,
                },
                {
                  data: "exchange-complete",
                  label: "교환완료",
                  value: e.exchangeCompleteLength,
                },
              ],
            },
            {
              label: "반품",
              icon: "refund",
              items: [
                {
                  data: "refund-request",
                  label: "반품접수",
                  value: r.refundRequestLength,
                  color: "orange",
                },
                {
                  data: "refund-progress",
                  label: "픽업중",
                  value: r.refundPickupLength,
                },
                {
                  data: "refund-complete",
                  label: "반품완료",
                  value: r.refundCompleteLength,
                },
              ],
            },
          ]}
        />
      }
      defaultQuery={{
        page: 1,
        limit: 10,
        start: "today",
        end: "today",
        keyword: "",
        type: "order",
        // distributionId: isPartner ? distributionId : 35,
      }}
      search={[
        [
          {
            label: "조회기간",
            type: "date",
            key: "start",
            key2: "end",
          },
        ],
        [
          {
            label: "검색",
            type: "input",
            key: "keyword",
            placeholder: "주문번호, 이름, 휴대폰번호, 상품명",
          },
          !isPartner && {
            label: "유통사",
            type: "menu",
            key: "distributionId",
            data: distributions,
          },
        ],
      ]}
      columns={[
        {
          label: `주문일\n배송완료일시`,
          key: "date",
          clickable: true,
          render: (col) => (
            <span>
              {formatTime(col.createdAt, "YYYY-MM-DD HH:mm")}
              <br />
              {formatTime(col.deliverdAt, "YYYY-MM-DD HH:mm") || "-"}
            </span>
          ),
        },
        {
          label: "주문번호",
          key: "orderNumber",
          clickable: true,
        },
        {
          clickable: true,
          label: "고객명\n전화번호\n아이디",
          render: (col) => (
            <span>
              {col.userName}
              <br />
              {formatPhoneNumber(col.phone)}
              <br />
              {col.email}
            </span>
          ),
        },
        {
          label: "유통사",
          key: "companyName",
        },
        {
          label: "주문상품",
          key: "totalOrderLength",
          render: (col) => (
            <span>
              {col.products
                ? col.products?.split("|")[0] +
                  `${
                    col.products?.split("|").length > 1
                      ? ` 외 ${col.products?.split("|").length - 1}건`
                      : ""
                  }`
                : ""}
            </span>
          ),
        },
        {
          label: "주문총액",
          render: (col) => numFormat(col.paymentAmount),
        },
        {
          label: "주문횟수",
          key: "totalOrderLength",
        },
        {
          label: "배송상태",
          key: "exchangeCompleteLength",
          render: (x) => getOrderState(query.type),
        },
        {
          label: "배송기사\nID",
          render: (col) => (
            <span>
              {col.driverName}
              <br />
              {col.username}
            </span>
          ),
        },
        {
          label: "배송소요시간",
          render: (col) => (
            <span>
              {col.createdAt && col.deliverdAt
                ? moment(col.deliverdAt).diff(
                    moment(col.createdAt),
                    "minutes"
                  ) + "분"
                : "-"}
            </span>
          ),
        },
        {
          label: "주문유형",
          render: (col) => (
            <span>
              {col.type === "store" ? "이웃배송" : "라이브배송"}
              {col.otherOrder ? (
                <>
                  <br />
                  (수기주문)
                </>
              ) : (
                ""
              )}
            </span>
          ),
        },
      ]}
    />
  );
}
