export default {
  medium: "Lato-Regular",
  bold: "Lato-Bold",

  notoSansCJKkrBold: "NotoSansCJKkr-Bold",
  nanumSquareRoundEB: "nanumSquareRoundEB",

  //
  spoqaHanSansNeoMedium: "spoqaHanSansNeoMedium",
  spoqaHanSansNeoRegular: "spoqaHanSansNeoRegular",
  spoqaHanSansNeoBold: "spoqaHanSansNeoBold",

  malgunGothic: "malgunGothic", //
  malgunGothicBold: "malgunGothicBold",
  malgunGothicSemiLight: "malgunGothicSemiLight",
};
