/* eslint-disable no-unreachable-loop */
import React, { useEffect, useState, useRef } from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Grid,
  Dialog,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Button,
  TableCell,
  Checkbox,
  CardMedia,
  TextField,
  Paper,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { requestGet } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import { getFileUri, numFormat } from "../../services/utils";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "50%",
    marginBottom: "100px",
  },
  bold: {
    fontWeight: "bold",
  },
  titleGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid #B7ACAC",
    height: "50px",
    padding: "10px",
  },
  tableGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid #B7ACAC",
    padding: "10px",
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC",
  },
  headerBtn: {
    backgroundColor: "#1C1B1B",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  initializationBtn: {
    width: "120px",
    backgroundColor: "#fff",
    border: "1px solid #e9e9e9",
    borderRadius: 2,
  },
  searchBtn: {
    width: "120px",
    backgroundColor: "#40cf66",
    color: "#fff",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  searchBox: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  divider: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  btn: {
    width: "100px",
    border: "1px solid #B7ACAC",
    borderRadius: 0,
    marginRight: "10px",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    backgroundColor: "#40cf66",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#40cf66",
    color: "#fff",
    padding: " 10px",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  select: {
    width: "300px",
    "& div": {
      backgroundColor: "#fff",
    },
    fontSize: 13,
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
    fontSize: 13,
  },
  resize: { fontSize: "13px" },
  cb: { color: "#40cf66 !important" },
});

const initQuery = {
  keyword: "",
  categoryId: "",
  childCategoryId: "",
  limit: 5,
  page: 1,
};
export default function SetEditorProduct({
  distributionId,
  driverId,
  receiptSetId,
  products,
  setProduts,
}) {
  const classes = useStyles();
  const [query, setQuery] = useState({
    ...initQuery,
  });
  const dispatch = useDispatch();
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const sels = data
    .filter((x) => x.checked)
    .filter((x) => {
      if (products.filter((y) => y.productId === x.productId).length) {
        return false;
      }
      return true;
    });

  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/products/categories" })
      .then((x) => {
        setCategories([
          ...x.categories.map((y) => {
            y.categories = x.childCategories.filter(
              (z) => y.productCategoryId === z.parentId
            );
            return y;
          }),
        ]);
      })
      .catch((x) => {
        dispatch(popupError(x));
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [query.page, receiptSetId, distributionId, driverId]);

  const fetchData = () => {
    requestGet({
      url: consts.apiUrl + "/products/receiptsets/" + receiptSetId,
      query: { ...query, driverId },
    })
      .then((x) => {
        setData([...x.results]);
        setCount(x.totalCount);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const onClickRegisterBtn = () => {
    if (sels.length) {
      setRegisterDialogOpen(true);
    }
  };

  const handleRegisterDialogClose = () => {
    setRegisterDialogOpen(false);
  };

  const onClickRegisterDialogSaveBtn = () => {
    setRegisterDialogOpen(false);
    if (!sels.length) {
      return;
    }
    setProduts([
      ...products,
      ...sels.map((x) => ({ ...x, quantity: "", checked: false })),
    ]);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid item xs={12} className={classes.df}>
          <TableContainer first>
            <Th topleft>검색</Th>
            <Td xs={10} textfield topright>
              <TextField
                inputProps={{ style: { fontSize: 14 } }}
                className={classes.searchBox}
                variant="outlined"
                fullWidth
                size="small"
                placeholder="상품명, 상품코드"
                value={query.keyword}
                onChange={(e) => {
                  setQuery({ ...query, keyword: e.target.value });
                }}
              />
            </Td>
          </TableContainer>
        </Grid>
        <Grid item xs={12} className={classes.dfsc}>
          <TableContainer last>
            <Th bottomleft>카테고리</Th>
            <Td xs={10} textfield bottomright>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                select
                variant="outlined"
                size="small"
                defaultValue={query.categoryId}
                value={query.categoryId}
                onChange={(e) => {
                  setQuery({
                    ...query,
                    categoryId: e.target.value,
                    childCategoryId: "",
                  });
                }}
                className={classes.select}
              >
                <option value={""} className={classes.selectForm}>
                  전체
                </option>
                {categories &&
                  categories.map((x) => {
                    return (
                      <option
                        value={x.productCategoryId}
                        className={classes.selectForm}
                      >
                        {x.name}
                      </option>
                    );
                  })}
              </TextField>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                select
                variant="outlined"
                size="small"
                className={classes.select}
                style={{ marginLeft: "30px", marginRight: "30px" }}
                defaultValue={query.childCategoryId}
                value={query.childCategoryId}
                onChange={(e) => {
                  setQuery({ ...query, childCategoryId: e.target.value });
                }}
              >
                <option value={""} className={classes.selectForm}>
                  전체
                </option>
                {categories &&
                  categories.filter(
                    (x) => x.productCategoryId === query.categoryId
                  )[0] &&
                  categories
                    .filter((x) => x.productCategoryId === query.categoryId)[0]
                    .categories.map((x) => {
                      return (
                        <option
                          value={x.productCategoryId}
                          className={classes.selectForm}
                        >
                          {x.name}
                        </option>
                      );
                    })}
              </TextField>
            </Td>
          </TableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfcc}
          style={{ paddingBottom: "20px", paddingTop: "10px" }}
        >
          <div
            style={{
              width: "300px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button className={classes.searchBtn} onClick={fetchData}>
              <Typography variant="subtitle2">검색</Typography>
            </Button>
            <Button
              variant="contained"
              className={classes.initializationBtn}
              onClick={() => {
                setQuery({ ...initQuery });
              }}
              style={{ marginLeft: "20px" }}
            >
              <Typography variant="subtitle2">초기화</Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.dfsc}
        style={{ margin: "10px 0px" }}
      >
        <Button className={classes.searchBtn} onClick={onClickRegisterBtn}>
          <Typography variant="subtitle2">등록</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.dfsc}>
        <TableContainer first>
          <Th xs={1} cb center topleft>
            <Checkbox
              className={classes.cb}
              color="default"
              checked={
                data.length &&
                data.filter((x) => x.checked).length === data.length
              }
              onChange={(e) => {
                const checked = e.target.checked;
                setData([...data.map((x) => ({ ...x, checked }))]);
              }}
            />
          </Th>
          {data.sort === "sort" && (
            <Th xs={3} center>
              노출순서
            </Th>
          )}
          <Th xs={3} center>
            상품코드
          </Th>
          <Th xs={3} center>
            이미지
          </Th>
          <Th xs={3} center>
            카테고리
          </Th>
          <Th xs={3} center>
            상품명
          </Th>
          <Th xs={3} center>
            판매가격
          </Th>
          <Th xs={3} center topright>
            재고
          </Th>
        </TableContainer>
      </Grid>
      {data.map((col, idx) => {
        return (
          <Grid item xs={12} className={classes.dfsc}>
            <TableContainer
              key={idx}
              last={data.length === idx + 1 ? true : false}
            >
              <Td
                center
                xs={1}
                bottomleft={data.length === idx + 1 ? true : false}
              >
                {Boolean(
                  products.filter((x) => x.productId === col.productId)[0]
                ) ? (
                  <div>등록됨</div>
                ) : (
                  <Checkbox
                    className={classes.cb}
                    color="default"
                    checked={col.checked || false}
                    onChange={(e) => {
                      if (
                        Boolean(
                          products.filter(
                            (x) => x.productId === col.productId
                          )[0]
                        )
                      ) {
                        return;
                      }
                      const checked = e.target.checked;
                      setData([
                        ...data.map((x, i) => ({
                          ...x,
                          checked: idx === i ? checked : x.checked,
                        })),
                      ]);
                    }}
                  />
                )}
              </Td>
              {/*
                    {data.sort === "sort" && (
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: "70px" }}
                  >
                    <IconButton onClick={handleSort(col, "up")} size="small">
                      <Icon>arrow_drop_up</Icon>
                    </IconButton>
                    <IconButton onClick={handleSort(col, "down")} size="small">
                      <Icon>arrow_drop_down</Icon>
                    </IconButton>
                  </TableCell>
                )}
                  */}

              <Td xs={3} center>
                {col.code}
              </Td>
              <Td xs={3} center>
                <CardMedia
                  component="img"
                  height="70"
                  image={getFileUri(col.path)}
                />
              </Td>

              <Td xs={3} center>
                {col.category}
              </Td>
              <Td xs={3} center>
                {col.name}
              </Td>
              <Td xs={3} center>
                {numFormat(col.price)}원
              </Td>
              <Td
                xs={3}
                center
                bottomright={data.length === idx + 1 ? true : false}
              >
                {numFormat(col.stockQuantity)}
              </Td>
            </TableContainer>
          </Grid>
        );
      })}

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Pagination
          variant="outlined"
          shape="rounded"
          count={Math.ceil(count / query.limit)}
          onChange={(e, p) => {
            setQuery({ ...query, page: p });
          }}
        />
      </Grid>

      <Dialog open={registerDialogOpen} onClose={handleRegisterDialogClose}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">등록 처리</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                {sels.length}개 상품이 선택되었습니다.
              </Typography>
              <Typography variant="subtitle1">
                추가 / 등록처리 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setRegisterDialogOpen(false)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                onClick={onClickRegisterDialogSaveBtn}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
}
