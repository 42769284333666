import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Divider,
  Button,
} from "@material-ui/core";
import SetEditTable from "./SetEditTable";
import { useDispatch } from "react-redux";
import {
  popupAction,
  popupError,
  popupMessage,
} from "../../redux/popup/PopupActions";
import {
  requestDelete,
  requestFile,
  requestGet,
  requestPost,
  requestPut,
} from "../../services/network";
import consts from "../../libs/consts";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import DaumPostCode from "./DaumPostcode";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Td from "../../components/table/Td";
import Th from "../../components/table/Th";
import useMember from "../../../hooks/useMember";

const useStyles = makeStyles({
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "20px",
  },
  container2: {
    marginTop: "30px",
    width: "100%",
    minHeight: "100vh",
    height: "50%",
    marginBottom: "100px",
  },
  divider: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  titleCell: {
    backgroundColor: "#dfdfdf",
    borderRight: "1px solid #000",
  },
  cell: {
    borderTop: "1px solid #000",
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    paddingRight: 16,
  },
  bottomCell: {
    borderBottom: "1px solid #000",
  },
  numberInput: {
    "&:-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
  },
  resize: { fontSize: "13px" },
});

export default function OthersOrderAdd({}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [orderId, setReceiptSetId] = useState(false);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  //
  const [ds, setDs] = useState([]);
  const [drivers, setDrivers] = useState([]);
  //
  const [distributionId, setDistributionId] = useState(null);
  const [driverId, setDriverId] = useState(null);
  //
  const [name, setname] = useState("");
  const [address, setaddress] = useState("");
  const [postCode, setpostCode] = useState("");
  const [addressDetail, setaddressDetail] = useState("");
  const [phone, setphone] = useState("");
  const [sName, setsName] = useState("");
  const [sPhone, setsPhone] = useState("");

  //
  const [orderNumber, setOrderNumber] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [memo, setMemo] = useState("");
  const [otherPlace, setOtherPlace] = useState("");

  const [daum, setDaum] = useState(false);

  const member = useMember();

  useEffect(() => {
    if (member.distributionId) {
      setDistributionId(member.distributionId);
    }
  }, [member]);

  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/distributions" }).then((res) => {
      setDs([...res.results]);
    });
  }, []);

  useEffect(() => {
    setDrivers([]);
    setDriverId();
    distributionId &&
      requestGet({
        url: consts.apiUrl + "/drivers",
        query: { distributionId, fw: "1" },
      }).then((res) => {
        setDrivers([...res.results]);
      });
  }, [distributionId]);

  const handleSave = async () => {
    if (!drivers.filter((x) => String(x.driverId) === driverId)[0]) {
      return alert("기사를 선택해 주세요");
    } //else if (products.length === 0) {
    //return alert("상품을 선택해 주세요");
    //}
    else if (
      products.length > 0 &&
      products.filter((x) => !x.quantity).length > 0
    ) {
      return alert("모든 상품의 수량을 입력해 주세요");
    } else if (!name || !address || !phone || !postCode || !sName || !sPhone) {
      return alert("모든 항목을 입력해 주세요.");
    }
    requestPost({
      url: consts.apiUrl + "/otherorders",
      body: {
        name,
        address: address + "\n" + addressDetail,
        phone,
        postCode,
        //
        //
        customOrderNumber: orderNumber,
        memo: memo,
        driverId,
        receiptSetId: drivers.filter((x) => String(x.driverId) === driverId)[0]
          .receiptSetId,
        //
        sName,
        sPhone,
        //
        products: products.map((x) => {
          return {
            productId: x.productId,
            quantity: x.quantity,
            price: x.price,
          };
        }),
      },
    })
      .then((res) => {
        dispatch(
          popupMessage({
            title: "등록 완료",
            message: "수기 주문이 등록 되었습니다.",
            onClick: () => {
              history.replace("/others_order/orders");
            },
          })
        );
      })
      .catch((e) => {
        alert(e.message || e);
      });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.dfsc}>
        <Typography
          variant="h5"
          style={{
            fontSize: "28px",
            lineHeight: "32px",
            fontFamily: fonts.spoqaHanSansNeoBold,
            color: "#374553",
            paddingTop: "40px",
          }}
        >
          수기주문 {orderId ? "상세" : "등록"}
        </Typography>
      </Grid>
      {daum && (
        <DaumPostCode
          onClose={() => {
            setDaum(false);
          }}
          onPress={({ address, postCode }) => {
            setaddress(address);
            setaddressDetail("");
            setpostCode(postCode);
          }}
        />
      )}

      <Divider
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#c5c8ce",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      />
      <Grid item xs={12}></Grid>
      <Grid item xs={12} style={{ marginTop: "30px" }}>
        {!orderId ? (
          <>
            <Grid item xs={10} className={classes.df}>
              <TableContainer first>
                {!member.isPartner && (
                  <>
                    <Th topleft>유통사</Th>
                    <Td textfield>
                      <TextField
                        InputProps={{
                          classes: {
                            input: classes.resize,
                          },
                        }}
                        fullWidth
                        select
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={distributionId}
                        onChange={(e) => {
                          setProducts([]);
                          setDistributionId(e.target.value);
                          setsName(
                            ds.filter(
                              (x) => String(x.distributionId) === e.target.value
                            )[0].companyName
                          );
                        }}
                      >
                        <option
                          value={""}
                          selected={!distributionId}
                          disabled
                          className={classes.selectForm}
                        >
                          선택하기
                        </option>
                        {ds.map((item, idx) => {
                          return (
                            <option
                              key={idx}
                              value={item.distributionId.toString()}
                              selected={distributionId === item.distributionId}
                              className={classes.selectForm}
                            >
                              {item.companyName}
                            </option>
                          );
                        })}
                      </TextField>
                    </Td>
                  </>
                )}
                <Th>배송기사</Th>
                <Td topright textfield>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    fullWidth
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={driverId}
                    onChange={(e) => {
                      setProducts([]);
                      setsPhone(
                        drivers.filter(
                          (x) => String(x.driverId) === e.target.value
                        )[0].phone
                      );
                      setDriverId(e.target.value);
                    }}
                  >
                    <option
                      value={""}
                      selected={!distributionId}
                      disabled
                      className={classes.selectForm}
                    >
                      선택하기
                    </option>
                    {drivers.map((item, idx) => {
                      return (
                        <option
                          key={idx}
                          value={item.driverId.toString()}
                          selected={String(driverId) === String(item.driverId)}
                          className={classes.selectForm}
                        >
                          {item.name}({item.working ? "근무중" : "퇴근"})
                        </option>
                      );
                    })}
                  </TextField>
                </Td>
              </TableContainer>
            </Grid>

            <Grid item xs={10} className={classes.df}>
              <TableContainer>
                <Th>받는분 이름</Th>
                <Td textfield>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={(e) =>
                      e.target.value.length <= 15 && setname(e.target.value)
                    }
                  />
                </Td>
                <Th>받는분 전화번호</Th>
                <Td textfield>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    className={classes.numberInput}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={phone}
                    type="number"
                    onChange={(e) => setphone(e.target.value)}
                  />
                </Td>
              </TableContainer>
            </Grid>

            <Grid item xs={10} className={classes.df}>
              <TableContainer>
                <Th>받는분 주소</Th>
                <Td xs={10} textfield>
                  <Button
                    onClick={() => {
                      setDaum(true);
                    }}
                    style={{ width: "100px" }}
                    variant="outlined"
                    // color="primary"
                  >
                    주소검색
                  </Button>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    inputProps={{ style: { color: "#000" } }}
                    fullWidth
                    style={{ color: "black !important", marginLeft: 16 }}
                    size="small"
                    value={address ? `${address} (${postCode})` : ""}
                    disabled
                  />
                </Td>
              </TableContainer>
            </Grid>

            <Grid item xs={10} className={[classes.df]}>
              <TableContainer>
                <Th>받는분 상세 주소</Th>
                <Td xs={10} textfield>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={addressDetail}
                    onChange={(e) => setaddressDetail(e.target.value)}
                  />
                </Td>
              </TableContainer>
            </Grid>

            <Grid item xs={10} className={[classes.df]}>
              <TableContainer>
                <Th>보내는분 이름</Th>
                <Td textfield>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={sName}
                    onChange={(e) =>
                      e.target.value.length <= 15 && setsName(e.target.value)
                    }
                  />
                </Td>
                <Th>보내는분 전화번호</Th>
                <Td textfield>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    className={classes.numberInput}
                    size="small"
                    type="number"
                    value={sPhone}
                    onChange={(e) => setsPhone(e.target.value)}
                  />
                </Td>
              </TableContainer>
            </Grid>

            <Grid item xs={10} className={[classes.df]}>
              <TableContainer>
                <Th>주문번호</Th>
                <Td textfield>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={orderNumber}
                    onChange={(e) =>
                      e.target.value.length <= 30 &&
                      setOrderNumber(e.target.value)
                    }
                  />
                </Td>
                <Th></Th>
                <Td></Td>
              </TableContainer>
            </Grid>

            <Grid item xs={10} className={[classes.df]}>
              <TableContainer last>
                <Th bottomleft>배송메모</Th>
                <Td bottomright textfield xs={10}>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={memo}
                    onChange={(e) => setMemo(e.target.value)}
                  />
                </Td>
              </TableContainer>
            </Grid>
          </>
        ) : null}
        {distributionId && driverId ? (
          <SetEditTable
            products={products}
            driverId={driverId}
            receiptSetId={
              drivers.filter((x) => String(x.driverId) === driverId)[0] &&
              drivers.filter((x) => String(x.driverId) === driverId)[0]
                .receiptSetId
            }
            setProducts={setProducts}
            onSave={handleSave}
            distributionId={distributionId}
          />
        ) : (
          <Grid container className={classes.container2}>
            <div>배송기사를 선택해 주세요.</div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
