import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import {
  formatPhoneNumber,
  formatTime,
  numFormat,
} from "../../services/utils.js";
import consts from "../../libs/consts.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  const { data, loading, dataLength, fetchData } = useListPayload({
    endpoint: "/notices",
  });

  return (
    <TableLayout
      label="공지사항"
      onSearch={fetchData}
      insertUrl="/customer_service/notice_add"
      data={[...data]}
      dataLoading={loading}
      onClick={(col) => {
        history.push("/customer_service/notice_detail", {
          noticeId: col.noticeId,
        });
      }}
      dataLength={dataLength}
      defaultQuery={{
        page: 1,
        limit: 10,
      }}
      columns={[
        {
          label: "등록일시",
          render: (col) => <>{formatTime(col.createdAt, "YYYY-MM-DD HH:mm")}</>,
        },
        {
          label: "제목",
          key: "title",
          clickable: true,
        },
        {
          label: "조회수",
          key: "view",
        },
        {
          label: "작성자",
          render: () => "관리자",
        },
      ]}
    />
  );
}
