import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "../services/utils";
import { signIn, userPatch } from "../redux/user/UserActions";
import fonts from "../libs/fonts";
import TableTitle from "../components/table/TableTitle";

const useStyles = makeStyles({
  container: {
    width: "calc(100% )",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  titleGrid: {
    height: "50px",
  },
  divider: {
    width: "100%",
    height: "3px",
    backgroundColor: "#7C7878",
    marginBottom: "20px",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dfss: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
  },
  titleCell: {
    backgroundColor: "#dfdfdf",
    borderRight: "1px solid #000",
  },
  cell: {
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "1px solid #000",
  },
  saveBtn: {
    color: "#fff",
    backgroundColor: "#1D1D1D",
    width: "120px",
    "&:hover": {
      backgroundColor: "#1D1D1D",
    },
  },
  titledivider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#c5c8ce",
    marginBottom: "40px",
    marginTop: "20px",
  },

  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  subtitle: {
    marginTop: "10px",
    // marginLeft: "20px",
  },
});

export default function PartnerInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((s) => s.user, []);

  const [agent, setAgent] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    setAddress(user.address);
    setPhoneNumber(user.phone);
    setAgent(user.managerName);
  }, [user]);

  const handleAgent = (e) => {
    setAgent(e.target.value);
  };
  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const save = () => {
    dispatch(
      userPatch({
        phone: phoneNumber,
        password,
        address,
        managerName: agent,
        id: user.distributionId,
      })
    );
  };

  return (
    <Grid container className={classes.container}>
      <Typography
        variant="h5"
        style={{
          fontSize: "28px",
          lineHeight: "32px",
          fontFamily: fonts.spoqaHanSansNeoBold,
          color: "#374553",
          paddingTop: "40px",
        }}
      >
        내 정보
      </Typography>
      <Divider className={classes.titledivider} />
      <Grid item xs={12} style={{ flexDirection: "column" }}>
        <Grid item xs={12} className={classes.df}>
          <Grid
            item
            xs={5}
            className={[classes.dfsc, classes.subtitle].join(" ")}
          >
            <TableTitle>기본정보</TableTitle>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.df}>
          <Grid
            item
            xs={2}
            className={[classes.titleCell, classes.dfsc, classes.cell].join(
              " "
            )}
          >
            <Typography variant="subtitle1">가입일시</Typography>
          </Grid>
          <Grid item xs={3} className={classes.cell}>
            <Typography variant="subtitle1">
              {formatTime(user.createdAt, "YYYY.MM.DD HH:mm")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            className={[classes.titleCell, classes.dfsc, classes.cell].join(
              " "
            )}
          >
            <Typography variant="subtitle1">유통사명</Typography>
          </Grid>
          <Grid item xs={5} className={classes.cell}>
            <Typography variant="subtitle1">{user.companyName}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.df}>
          <Grid
            item
            xs={2}
            className={[classes.titleCell, classes.dfsc, classes.cell].join(
              " "
            )}
          >
            <Typography variant="subtitle1">담당자</Typography>
          </Grid>
          <Grid item xs={3} className={classes.cell}>
            <TextField
              variant="outlined"
              size="small"
              value={agent}
              onChange={handleAgent}
            />
          </Grid>
          <Grid
            item
            xs={2}
            className={[classes.titleCell, classes.dfsc, classes.cell].join(
              " "
            )}
          >
            <Typography variant="subtitle1">전화번호</Typography>
          </Grid>
          <Grid item xs={5} className={[classes.cell, classes.dfsc].join(" ")}>
            <TextField
              variant="outlined"
              size="small"
              value={phoneNumber}
              onChange={handlePhoneNumber}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.df}>
          <Grid
            item
            xs={2}
            className={[classes.titleCell, classes.dfsc, classes.cell].join(
              " "
            )}
          >
            <Typography variant="subtitle1">아이디</Typography>
          </Grid>
          <Grid item xs={3} className={[classes.cell, classes.dfsc].join(" ")}>
            <Typography variant="subtitle1">{user.username}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            className={[classes.titleCell, classes.dfsc, classes.cell].join(
              " "
            )}
          >
            <Typography variant="subtitle1">비밀번호</Typography>
          </Grid>
          <Grid item xs={5} className={[classes.cell, classes.dfsc].join(" ")}>
            <TextField
              type="password"
              variant="outlined"
              size="small"
              value={password}
              onChange={handlePassword}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ marginBottom: "30px" }}
        >
          <Grid
            item
            xs={2}
            className={[classes.titleCell, classes.dfsc, classes.cell].join(
              " "
            )}
            style={{ borderBottom: "1px solid #000" }}
          >
            <Typography variant="subtitle1">코드</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            className={[classes.cell, classes.dfsc].join(" ")}
            style={{ borderBottom: "1px solid #000" }}
          >
            <Typography variant="subtitle1">{user.code}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            className={[classes.titleCell, classes.dfsc, classes.cell].join(
              " "
            )}
            style={{ borderBottom: "1px solid #000" }}
          >
            <Typography variant="subtitle1">주소</Typography>
          </Grid>
          <Grid
            item
            xs={5}
            className={[classes.cell, classes.dfsc].join(" ")}
            style={{ borderBottom: "1px solid #000" }}
          >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={address}
              onChange={handleAddress}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.dfcc}>
          <Button onClick={save} variant="outlined" className={classes.saveBtn}>
            <Typography variant="subtitle1">저장</Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
