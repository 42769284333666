import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../components/table-layout/TableLayout";
import useDistribution from "../../hooks/useDistribution.js";
import useListPayload from "../../hooks/useListPayload.js";
import { popupError } from "../redux/popup/PopupActions";
import { formatPhoneNumber } from "../services/utils";
import DashBoard from "../components/dashboard/DashBoard";
import { requestDelete, requestGet } from "../services/network";
import consts from "../libs/consts";
import qs from "query-string";
import useMember from "../../hooks/useMember";
import { useDispatch } from "react-redux";
import { Button, Dialog, Grid, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dialogTitleCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "lightgrey",
  },
  dialogContentCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "2px solid #656565",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none",
    },
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
});

export default function Container({ history, location }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [distributions] = useDistribution();
  const [cancelId, setCancelId] = useState(null);
  const { isPartner, distributionId } = useMember();
  const { data, query, loading, dataLength, fetchData, fetchExcel } =
    useListPayload({
      excelName: "수기주문 조회",
      endpoint: "/otherorders",
      triggers: ["state"],
    });
  const [o, setO] = useState({
    request: 0,
    progress: 0,
    delivery: 0,
    complete: 0,
  });

  useEffect(() => {
    let tm = setTimeout(() => {
      fetchStat();
    }, 500);
    return () => clearTimeout(tm);
  }, [query.start, query.end, query.keyword, query.distributionId]);

  const fetchStat = () => {
    query.page &&
      requestGet({
        url: consts.apiUrl + "/otherorders/stats",
        query: {
          start: query.start,
          end: query.end,
          keyword: query.keyword,
          distributionId: query.distributionId,
        },
      })
        .then((x) => {
          setO({ ...x });
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
  };

  return (
    <>
      <TableLayout
        label="수기주문 조회"
        onSearch={() => {
          fetchData();
          fetchStat();
        }}
        onExcel={fetchExcel}
        data={[...data]}
        dataLoading={loading}
        dataLength={dataLength}
        renderTableLabel={`${(() => {
          switch (query.state) {
            case "delivery":
              return "배송중";
            case "request":
              return "주문접수";
            case "progress":
              return "배송준비";
            case "complete":
              return "배송완료";
            default:
              return "전체";
          }
        })()}`}
        onClick={(col) => {
          if (col.ordered && col.otherOrderComplete) {
            window.open(
              `/others_order/orders/payment?orderId=${
                col.orderId || ""
              }&orderActionId=${col.orderActionId || ""}&sName=${
                col.sName || ""
              }&sPhone=${formatPhoneNumber(col.sPhone) || ""}`,
              "_blank"
            );
            /*
          history.push("/others_order/orders/payment", {
            orderId: col.orderId,
            orderActionId: col.orderActionId,
            sName: col.sName,
            sPhone: formatPhoneNumber(col.sPhone),
          });*/
          } else {
            dispatch(
              popupError({
                message: "배송완료 주문만 조회할 수 있습니다.",
              })
            );
          }
        }}
        renderHeaderComponent={
          <DashBoard
            justify="space-evenly"
            onClick={(data) => {
              history.replace({
                pathname: location.pathname,
                search: qs.stringify({ ...query, page: 1, state: data }),
              });
            }}
            data={[
              {
                label: "주문/배송/완료",
                icon: "order",
                items: [
                  {
                    data: "",
                    label: "전체",
                    value: o.request + o.progress + o.delivery + o.complete,
                    color: "red",
                  },
                  {
                    data: "request",
                    label: "주문접수",
                    value: o.request,
                    color: "orange",
                  },
                  {
                    data: "progress",
                    label: "배송준비",
                    value: o.progress,
                  },
                  {
                    data: "delivery",
                    label: "배송중",
                    value: o.delivery,
                  },
                  {
                    data: "complete",
                    label: "배송완료",
                    value: o.complete,
                  },
                ],
              },
              {
                disabled: true,
                label: "기사/품목/수량",
                icon: "order",
                items: [
                  {
                    data: "order",
                    label: "배송기사",
                    value: o.driver || 0,
                    color: "orange",
                  },
                  {
                    data: "order",
                    label: "배송품목",
                    value: o.product || 0,
                  },
                  {
                    data: "delivery-progress",
                    label: "배송수량",
                    value: o.quantity || 0,
                  },
                ],
              },
            ]}
          />
        }
        defaultQuery={{
          page: 1,
          limit: 10,
          start: "today",
          end: "today",
          keyword: "",
          distributionId: isPartner ? distributionId : "",
        }}
        search={[
          [
            {
              label: "조회기간",
              type: "date",
              key: "start",
              key2: "end",
            },
          ],
          [
            {
              label: "검색",
              type: "input",
              key: "keyword",
              placeholder: "받는분, 보내는분, 배송기사, 상품명",
            },
            !isPartner && {
              label: "유통사",
              type: "menu",
              key: "distributionId",
              data: distributions,
            },
          ],
        ]}
        columns={[
          {
            label: "주문번호",
            clickable: true,
            key: "orderNumber",
          },
          {
            clickable: true,
            label: "배송번호",
            key: "customOrderNumber",
          },
          {
            label: "주문접수시간\n배송완료시간",
            render: (col) => (
              <span>
                {col.date}
                <br />
                {col.deliveryCompleteDate || ""}
              </span>
            ),
          },
          {
            label: "보내는분\n배송기사",
            render: (col) => (
              <span>
                {col.sName} ({formatPhoneNumber(col.sPhone)})
                <br />
                {col.driverNickName} ({col.driverName})
              </span>
            ),
          },
          {
            label: "받는분(연락처)\n주소",
            render: (col) => (
              <span>
                {col.name} ({formatPhoneNumber(col.phone)})
                <br />
                {col.address} ({col.postCode})
              </span>
            ),
          },
          {
            label: "상품명",
            render: (col) => (
              <span>
                {col.products
                  ? col.products?.split(",").map((x, i) => {
                      return (
                        <span key={i.toString()}>
                          {x}
                          {i !== col.length - 1 && <br />}
                        </span>
                      );
                    })
                  : ""}
              </span>
            ),
          },
          {
            label: "접수상태",
            render: (col) => (
              <span>{col.ordered ? "접수완료" : "접수대기"}</span>
            ),
          },
          {
            label: "배송상태",
            render: (col) => (
              <span>
                {col.orderState === "cancel"
                  ? "주문 취소"
                  : col.ordered
                  ? col.orderState === "order" || col.orderState === "payment"
                    ? "배송대기"
                    : col.otherOrderComplete
                    ? "배송완료"
                    : "배송중"
                  : ""}
              </span>
            ),
          },
          {
            label: "주문취소",
            render: (col) =>
              col.otherOrderComplete || col.orderState === "cancel" ? (
                "-"
              ) : (
                <Button
                  onClick={() => {
                    setCancelId(col.otherOrderId);
                  }}
                  variant="text"
                  color="secondary"
                >
                  주문취소
                </Button>
              ),
          },
        ]}
      />
      <Dialog open={Boolean(cancelId)} onClose={() => setCancelId(null)}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">주문취소</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                주문취소 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setCancelId(false)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  requestDelete({
                    url: consts.apiUrl + "/otherorders/" + cancelId,
                  })
                    .then((x) => {
                      fetchData();
                      setCancelId(null);
                    })
                    .catch((e) => {
                      dispatch(popupError(e));
                    });
                }}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
}
