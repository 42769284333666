import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import fonts from "../../libs/fonts";

export default function Th({
  children,
  xs,
  center,
  topleft,
  topright,
  bottomleft,
  bottomright,
  cb,
}) {
  const classes = useStyles();

  return (
    <Grid item xs={xs ? xs : 2}>
      <div
        className={
          classes.th +
          " " +
          (center && classes.center) +
          " " +
          (topleft && classes.topleft) +
          " " +
          (topright && classes.topright) +
          " " +
          (bottomleft && classes.bottomleft) +
          " " +
          (bottomright && classes.bottomright) +
          " " +
          (cb && classes.cb)
        }
      >
        {children}
      </div>
    </Grid>
  );
}
const useStyles = makeStyles({
  th: {
    backgroundColor: "#f7f8f9",
    borderTop: "1px solid #dddddd",
    borderRight: "1px solid #dddddd",
    padding: "20px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    fontSize: "13px",
    fontFamily: fonts.malgunGothicBold,
    color: "#374553",
    height: "100%",
    maxWidth: "100%",
    wordBreak: "break-all",
  },
  center: {
    justifyContent: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  topleft: {
    borderTopLeftRadius: "5px",
  },
  topright: {
    borderTopRightRadius: "5px",
  },
  bottomleft: {
    borderBottomLeftRadius: "5px",
  },
  bottomright: {
    borderBottomRightRadius: "5px",
  },
  cb: {
    padding: "0px",
  },
});
