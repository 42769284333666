import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import fonts from "../../libs/fonts";
import refund from "../../../assets/icrefund.png";
import exchange from "../../../assets/icexchange.png";
import order from "../../../assets/icorder.png";

export default function DashBoard({
  data,
  onClick,
  justify = "space-between",
}) {
  const classes = useStyle();
  return (
    <Grid container justify={justify} className={classes.root}>
      {data.map((x, i) => {
        return (
          <Grid item xs={12 / data.length} className={classes.item}>
            <div className={classes.header}>
              {x.icon && (
                <img
                  alt="ic"
                  src={(() => {
                    switch (x.icon) {
                      case "order":
                        return order;
                      case "exchange":
                        return exchange;
                      case "refund":
                        return refund;
                    }
                  })()}
                />
              )}
              <span className={classes.label}>{x.label}</span>
            </div>
            <Grid justify="space-around" container>
              {x.items.map((z, y) => {
                return (
                  <Grid
                    item
                    onClick={() => !x.disabled && onClick && onClick(z.data)}
                    xs={12 / x.items.length}
                    className={
                      classes.subItem +
                      " " +
                      (x.disabled && classes.subItemClick)
                    }
                  >
                    <span
                      style={{
                        color:
                          z.color === "orange"
                            ? "#ff7e00"
                            : z.color === "red"
                            ? "#ff0808"
                            : "#39c15d",
                      }}
                      className={classes.value}
                    >
                      {z.value}
                    </span>
                    <span className={classes.subLabel}>{z.label}</span>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

const useStyle = makeStyles({
  subLabel: {
    fontSize: 15,
    color: "#646f7c",
    fontFamily: fonts.spoqaHanSansNeoBold,
  },
  value: {
    marginBottom: 20,
    fontSize: 32,
    fontFamily: fonts.spoqaHanSansNeoBold,
  },
  subItem: {
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 35,
    paddingBottom: 35,
    justifyContent: "center",
  },
  subItemClick: {
    cursor: "default !important",
  },
  root: {
    marginBottom: 40,
  },
  item: {
    height: 200,
    borderRadius: 5,
    border: "1px solid #dddddd",
    width: "30% !important",
    minWidth: "30% !important",
    maxWidth: "30% !important",
  },
  header: {
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    display: "flex",
    alignItems: "center",
    paddingRight: 15,
    paddingLeft: 30,
    height: 50,
    backgroundColor: "#f7f8f9",
    " & img": {
      width: 20,
      height: 20,
      marginRight: 10,
      objectFit: "contain",
    },
  },
  label: {
    fontSize: 17,
    color: "#374553",
    fontFamily: fonts.spoqaHanSansNeoBold,
  },
});
