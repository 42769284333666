import {
  ButtonBase,
  Divider,
  List,
  ListItem,
  makeStyles
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, ChevronRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useRouter from "use-react-router";
import fonts from "../admin/libs/fonts";
import { signOut } from "../admin/redux/user/UserActions";
import logo from "../assets/logo_black.png";
import useMember from "../hooks/useMember";

const routes = [
  {
    title: "배송관리",
    value: "delivery_management",
    items: [
      {
        title: "주문조회",
        value: "order_search"
      },
      {
        title: "배송조회",
        value: "delivery_search"
      }
    ]
  },
  {
    title: "상품관리",
    value: "product_management",
    items: [
      {
        title: "상품조회",
        value: "product_list"
      },
      {
        title: "카테고리관리",
        value: "category_management"
      },
      {
        title: "스토어 카테고리관리",
        value: "store_category_management"
      }
    ]
  },
  {
    title: "입고관리",
    value: "receiving_management",
    items: [
      {
        title: "오늘의 입고관리",
        value: "today_receiving_management"
      },
      {
        title: "재고관리",
        value: "inventory_control"
      },
      {
        title: "재고 모니터링",
        value: "inventory_monitoring"
      },
      {
        title: "발주관리",
        value: "order_management"
      },
      {
        title: "발주내역관리",
        value: "order_history"
      },

      {
        title: "스토어 재고관리",
        value: "store_inventory_control"
      },

      {
        title: "입고 SET",
        value: "receiving_set"
      }
    ]
  },
  {
    title: "수기주문",
    value: "others_order",
    items: [
      {
        title: "수기주문 조회",
        value: "orders"
      },
      {
        title: "수기주문 등록",
        value: "list"
      }
    ]
  },

  {
    title: "회원관리",
    value: "member_management",
    items: [
      {
        title: "고객회원",
        value: "customer_member"
      },
      {
        title: "기사회원",
        value: "driver_member"
      },
      {
        title: "기사회원 인증요청",
        value: "driver_member_auth"
      },
      {
        title: "탈퇴회원",
        value: "withdrawal_member"
      }
    ]
  },
  {
    title: "파트너관리",
    value: "partner_management",
    items: [
      {
        title: "유통사관리",
        value: "distributor_management"
      }
    ]
  },
  {
    title: "매출관리",
    value: "sales_details",
    items: [
      {
        title: "매출관리",
        value: "sales_details"
      }
    ]
  },
  {
    title: "고객센터",
    value: "customer_service",
    items: [
      {
        title: "공지사항",
        value: "notice"
      },
      {
        title: "고객문의",
        value: "customer_enquiry"
      },
      {
        title: "알림발송",
        value: "notification"
      },
      {
        title: "쿠폰관리",
        value: "coupon_management"
      },
      {
        title: "팝업관리",
        value: "popup_management"
      },
      {
        title: "로그인 팝업관리",
        value: "popup_management/login"
      },
      {
        title: "친구추천",
        value: "recommend"
      }
    ]
  },
  {
    title: "설정",
    value: "setting",
    items: [
      {
        title: "고객등급관리",
        value: "customer_grade_management"
      },
      {
        title: "문의유형",
        value: "inquiry_type"
      },

      {
        title: "약관",
        value: "terms"
      },
      {
        title: "자동차 제조사",
        value: "car_type"
      },
      {
        title: "자동차 모델",
        value: "car"
      },
      {
        title: "비회원 기본주소 설정",
        value: "defaultaddr"
      }
    ]
  },
  {
    title: "유통사 정보",
    value: "info"
  }
];

export default function SideBar({}) {
  const { isPartner } = useMember();
  const dispatch = useDispatch();
  const classes = useStyle();
  const { location, history } = useRouter();
  const [data, setData] = useState([...routes]);

  useEffect(() => {
    setData([
      ...routes
        .map(x => {
          x.selected =
            location.pathname.split("/")[1] === x.value ||
            x.value === "delivery_management"
              ? true
              : false;
          if (isPartner) {
            if (x.value === "product_management") {
              x.items = x.items.filter(z => z.value !== "category_management");
              x.items = x.items.filter(
                z => z.value !== "store_category_management"
              );
            }
            if (x.value === "member_management") {
              x.items = x.items.filter(z => z.value === "driver_member");
            }
            if (
              x.value === "setting" ||
              x.value === "customer_service" ||
              x.value === "partner_management"
            ) {
              return false;
            }
          } else {
            if (x.value === "info") {
              return false;
            }
          }
          return x;
        })
        .filter(x => x)
    ]);
  }, [isPartner]);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.logo}>
          <img src={logo} />
          <span>후드딜리버리</span>
        </div>
        <span className={classes.admin}>관리자</span>
        <ButtonBase
          onClick={() => {
            dispatch(signOut);
          }}
          className={classes.logout}>
          로그아웃
        </ButtonBase>
        <Divider className={classes.line} />
      </div>
      <List style={{ flex: 1 }}>
        {data.map((x, i) => {
          return (
            <div key={i.toString()}>
              <ListItem
                button
                onClick={() => {
                  if (!x.items) {
                    history.push("/" + x.value);
                  }
                  data[i].selected = !data[i].selected;
                  setData([...data]);
                  //  if (x.selected) return;
                  //  else history.push("/" + x.value + "/" + x.items[0].value);
                }}
                className={classes.menu + " " + (x.selected && classes.menuOn)}>
                {x.title}
                {x.items &&
                  (x.selected ? (
                    <ArrowDropUp style={{ color: "#374553" }} />
                  ) : (
                    <ArrowDropDown style={{ color: "#c7cad0" }} />
                  ))}
              </ListItem>
              {x.selected && (
                <List>
                  {x.items?.map((z, y) => {
                    let childed =
                      location.pathname === "/" + x.value + "/" + z.value; /* ||
                      location.pathname.startsWith(
                        "/" + x.value + "/" + z.value
                      );*/
                    return (
                      <ListItem
                        button
                        className={
                          classes.paths + " " + (childed && classes.childOn)
                        }
                        onClick={() => {
                          if (childed) return;
                          else history.push("/" + x.value + "/" + z.value);
                        }}>
                        {z.title}
                        <ChevronRight
                          style={{ color: childed ? "#374553" : "#c7cad0" }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </div>
          );
        })}
      </List>
      <div className={classes.footer}>
        <sapn className={classes.info}>전화 : 1661-6515</sapn>
        <sapn className={classes.info}>이메일 : help@logist.co.kr</sapn>
        <sapn className={classes.info}>
          주소 : 서울 마포구 백범로31길 21, 서울창업허브 본관 529호
        </sapn>
        <br />
        <sapn className={classes.info}>
          Copyright © 2021 로지스트 All Rights Reserved
        </sapn>
      </div>
    </div>
  );
}

const useStyle = makeStyles({
  info: {
    fontSize: 12,
    lineHeight: "18px",
    color: "#646f7c",
    fontFamily: fonts.spoqaHanSansNeoRegular,
    "&:first-child": {
      fontFamily: fonts.spoqaHanSansNeoBold
    }
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 23px",
    backgroundColor: "#f7f8f9"
  },
  childOn: {
    fontFamily: fonts.spoqaHanSansNeoBold
  },
  paths: {
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "#646f7c",
    fontFamily: fonts.spoqaHanSansNeoRegular,
    paddingLeft: 34,
    paddingRight: 23,
    paddingTop: 10,
    paddingBottom: 10
  },
  menu: {
    paddingTop: 17,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 17,
    paddingLeft: 23,
    paddingRight: 23,
    fontFamily: fonts.spoqaHanSansNeoRegular,
    fontSize: 16,
    color: "#374553"
  },
  menuOn: {
    fontFamily: fonts.spoqaHanSansNeoBold
    // backgroundColor: "#dfe2e5",
  },
  line: {
    backgroundColor: "#c5c8ce",
    color: "#c5c8ce",
    marginTop: 24
  },
  logout: {
    backgroundColor: "#e9ebee",
    color: "#374552",
    border: "1px solid #374552",
    marginTop: 20,
    height: 32,
    fontFamily: fonts.spoqaHanSansNeoMedium,
    borderRadius: "2px",
    alignSelf: "stretch"
  },
  admin: {
    color: "#374553",
    marginTop: 30,
    fontSize: 16,
    fontFamily: fonts.spoqaHanSansNeoRegular
  },
  root: {
    backgroundColor: "#e9ebee",
    width: "240px",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
    paddingTop: 35,
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  top: {
    paddingLeft: 24,
    paddingRight: 24,
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column"
  },
  logo: {
    alignSelf: "stretch",
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "10px",

      width: 23,
      height: 26,
      objectFit: "contain"
    },
    "& span": {
      color: "#2e3c4b",
      fontSize: "1.4em",
      fontFamily: fonts.nanumSquareRoundEB
    }
  }
});
