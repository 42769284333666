import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Typography,
  Button,
  Paper,
  Dialog,
  CardMedia,
  TextField
} from "@material-ui/core";
import { requestDelete, requestGet, requestPost } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { formatPhoneNumber, getReason, numFormat } from "../../services/utils";
import Axios from "axios";
import History from "./History";
import moment from "moment";
import qs from "query-string";
import Th from "../../components/table/Th";
import TableTitle from "../../components/table/TableTitle";
import Td from "../../components/table/Td";
import TableContainer from "../../components/table/TableContainer";
import fonts from "../../libs/fonts";
import ServiceInventory from "./ServiceInventory";
const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px"
  },
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start"
  },
  dffes: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "start"
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#c5c8ce",
    marginBottom: "40px",
    marginTop: "27px"
  },
  bold: {
    fontWeight: "bold"
  },
  btn: {
    backgroundColor: "#656565",
    color: "#fff",
    borderRadius: 2,
    marginLeft: "10px"
  },
  deleteBtn: {
    color: "#555",
    border: "1px solid #ddd",
    marginBottom: "10px",
    backgroundColor: "#fff",
    borderRadius: 2,
    width: "120px"
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC"
  },
  titlecell: {
    backgroundColor: "#e9e9e9"
  },
  infoCell: {
    borderTop: "1px solid #656565",
    borderRight: "1px solid #656565",
    padding: "20px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: "10px"
  },
  deliveryImage: {
    width: "200px",
    cursor: "pointer",
    objectFit: "contain"
  },
  firstCell: {
    borderLeft: "1px solid #656565"
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  saveBtn: {
    backgroundColor: "#39c15d",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#39c15d"
    }
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#39c15d",
    color: "#fff"
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column"
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)"
    }
  }
});

function getLabel(state, type) {
  switch (state) {
    case "request":
      switch (type) {
        case "delivery":
          return "주문접수";
        case "exchange":
          return "교환접수";
        case "refund":
          return "반품접수";
      }
    case "progress":
      switch (type) {
        case "delivery":
          return "배송처리";
        case "exchange":
          return "교환처리";
        case "refund":
          return "반품처리";
      }
    case "complete":
      switch (type) {
        case "delivery":
          return "배송완료";
        case "exchange":
          return "교환완료";
        case "refund":
          return "반품완료";
      }
  }
}

export default function Payment(props) {
  const classes = useStyles();
  const { history, location } = useReactRouter();
  const query = qs.parse(location.search);
  const orderId = location.state?.orderId || query?.orderId || "";
  const orderActionId =
    location.state?.orderActionId || query?.orderActionId || "";
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [changeDialogOpen, setChangeDialogOpen] = useState(false);
  const [data, setData] = useState({});
  const [ca, setCA] = useState("");
  const [driver, setDriver] = useState({});
  const [store, setStore] = useState({});
  const [payments, setPayments] = useState([]);
  const [paymentRefunds, setPaymentRefunds] = useState([]);
  const dispatch = useDispatch();
  const [actions, setActions] = useState([]);
  const [deliverys, setDeliverys] = useState([]);
  const [adminMessage, setAdminMessage] = useState("");
  const [servicePopup, setServicePopup] = useState({ open: false });

  const changeDialogAgree = () => {
    setChangeDialogOpen(false);
    if (data.type === "store") {
      requestPost({
        url:
          consts.apiUrl +
          "/stores/ordersheets/" +
          data.orderId +
          "/delivery/complete"
      }).then(x => history.goBack());
    } else if (data.type === "driver") {
      requestPost({
        url:
          consts.apiUrl +
          "/drivers/" +
          data.driverId +
          "/orders/" +
          data.orderId +
          "/complete"
      }).then(x => history.goBack());
    }
  };

  useEffect(() => {
    orderId &&
      orderActionId &&
      requestGet({
        url:
          consts.apiUrl +
          "/orders/" +
          orderId +
          "/actions/" +
          orderActionId +
          "/states"
      }).then(res => {
        setActions([...res]);
      });
  }, [orderActionId, orderId]);

  useEffect(() => {
    orderId &&
      requestGet({
        url: consts.apiUrl + "/orders/" + orderId + "/deliverys"
      }).then(res => {
        setDeliverys([...res]);
      });
  }, [orderId]);

  useEffect(() => {
    requestGet({
      url:
        consts.apiUrl +
        (orderActionId
          ? `/orders/${orderId}/actions/${orderActionId}`
          : "/orders/" + orderId)
    })
      .then(data => {
        setData({ ...data });
        setAdminMessage(data.adminMessage || "");
        if (data.isCancel) {
          setPaymentRefunds([
            {
              1: data["paymentCancelAt"],
              2: data["paymentMethod"],
              3: data["paymentCancelAmount"]
            }
          ]);
        } else if (data.orderActionId) {
          if (data.actionPaymentCanceled) {
            setPaymentRefunds([
              {
                1: data["stateUpdatedAt"],
                2: data["paymentMethod"],
                3: data["actionCancelAmount"]
              }
            ]);
          }
        }

        setPayments([
          {
            1: data["paymentAt"],
            2: data["paymentMethod"],
            3: data["sDeliveryAmount"],
            4: data["sProductAmount"],
            5: data["sCouponAmount"],
            6: data["paymentAmount"]
          }
        ]);
        if (data.type === "driver") {
          requestGet({
            url: consts.apiUrl + "/drivers/" + data.driverId
          })
            .then(x => {
              setDriver({ ...x });
            })
            .catch(e => {
              dispatch(
                popupError({
                  message: "기사님 정보를 불러오지 못했습니다."
                })
              );
            });
        } else if (data.type === "store") {
          requestGet({
            url: consts.apiUrl + "/stores/" + data.storeId
          })
            .then(x => {
              setStore({ ...x });
            })
            .catch(e => {
              dispatch(
                popupError({
                  message: "스토어 정보를 불러오지 못했습니다."
                })
              );
            });
        }
        requestGet({
          url: consts.apiUrl + "/drivers/" + data.driverId + "/location"
        })
          .then(x => {
            const { lat, lng } = x;
            if (lat && lng) {
              Axios.get(
                `https://dapi.kakao.com/v2/local/geo/coord2address.json?x=${lng}&y=${lat}`,
                {
                  headers: {
                    authorization: `KakaoAK 9f90f99403ae2174c7fb91384de57f2c`
                  }
                }
              )
                .then(res => {
                  const data = res.data;
                  if (data) {
                    const { documents } = data;
                    const document = documents[0];
                    setCA(document.address.address_name);
                  }
                })
                .catch(e => {
                  dispatch(
                    popupError({
                      message: "기사님의 현재위치 검색 실패했습니다."
                    })
                  );
                });
            }
          })
          .catch(e => {
            dispatch(popupError(e));
          });
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  }, []);

  const handleChangeDialogClose = () => {
    setChangeDialogOpen(false);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const deleteDialogAgree = () => {
    setDeleteDialogOpen(false);
    requestPost({
      url:
        consts.apiUrl +
        "/users/" +
        data.userId +
        "/orders/" +
        data.orderId +
        "/cancel",
      body: { reason: "관리자 주문 취소" }
    }).then(x => window.location.reload());
  };
  const onClickListBtn = () => {
    if (query.orderId) {
      return window.close();
    }
    history.goBack();
  };

  const saveAdminMessage = () => {
    requestPost({
      url: consts.apiUrl + "/admins/orders/" + data.orderId + "/message",
      body: { message: adminMessage }
    })
      .then(x => {
        dispatch(
          popupMessage({
            title: "관리자 메시지 저장 완료",
            label: "확인",
            onClick: () => {
              window.location.reload();
            }
          })
        );
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  };

  const handleDeleteServiceProduct = x => () => {
    requestDelete({
      url:
        consts.apiUrl +
        "/admins/orders/" +
        orderId +
        "/service/" +
        x.orderProductId
    })
      .then(() => {
        dispatch(
          popupMessage({
            title: "서비스 삭제 완료",
            label: "확인",
            onClick: () => {
              window.location.reload();
            }
          })
        );
      })
      .catch(e => dispatch(popupError(e)));
  };

  const handleAddService = () => {
    setServicePopup({
      open: true,
      driverId: data.driverId,
      phone: data.driverPhone,
      driverWorkId: data.driverWorkId,
      title: data.nickname
    });
  };

  return (
    <Grid container className={classes.container}>
      {servicePopup.open && (
        <ServiceInventory
          {...servicePopup}
          orderId={data.orderId}
          onFetch={() => {
            window.location.reload();
          }}
          onClose={() => setServicePopup({ open: false })}
        />
      )}
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ justifyContent: "space-between", marginLeft: "20px" }}>
          <Typography
            variant="h5"
            style={{
              fontSize: "28px",
              lineHeight: "32px",
              fontFamily: fonts.spoqaHanSansNeoBold,
              color: "#374553",
              paddingTop: "40px"
            }}>
            주문정보 상세
          </Typography>
          <Grid
            item
            style={{
              paddingTop: "40px"
            }}>
            {data.orderState === "payment" && (
              <Button
                style={{ marginLeft: 20, marginBottom: 0 }}
                className={classes.deleteBtn}
                onClick={() => setDeleteDialogOpen(true)}>
                주문취소
              </Button>
            )}
            {data.orderState === "delivery" && (
              <Button
                style={{ marginLeft: 20, marginBottom: 0 }}
                className={classes.deleteBtn}
                onClick={() => {
                  setChangeDialogOpen(true);
                }}>
                배송완료
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider style={{ marginLeft: "20px" }} className={classes.divider} />

        {
          // <PaymentState orderId={orderId} />
        }
        <Grid item xs={12} style={{ marginLeft: "20px" }}>
          <Typography
            variant="h5"
            className={classes.bold}
            style={{
              color: "#39c15d",
              marginBottom: 20,
              fontSize: 28,
              lineHeight: "48px"
            }}>
            {(() => {
              switch (data.orderState) {
                case "order":
                case "payment":
                  return "주문완료(배송준비)";
                case "cancel":
                  return "주문취소";
                case "delivery":
                  return "배송중";
                case "complete":
                  return "배송완료";
                case "exchange-request":
                  return "교환신청";
                case "exchange-progress":
                  return "교환 픽업중";
                case "exchange-complete":
                  return "교환 완료";
                case "refund-request":
                  return "반품신청";
                case "refund-progress":
                  return "반품 픽업중";
                case "refund-complete":
                  return "반품 완료";
              }
            })()}
          </Typography>
        </Grid>

        {data.otherOrder ? (
          <Grid
            item
            xs={12}
            style={{ marginLeft: "20px", marginBottom: "30px" }}>
            <Grid item xs={12}>
              <TableTitle>보내는분 정보</TableTitle>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} className={classes.df}>
                <TableContainer first last>
                  <Th topleft bottomleft>
                    이름
                  </Th>
                  <Td>{location.state?.sName || query?.sName || ""}</Td>
                  <Th>전화번호</Th>
                  <Td topright bottomright>
                    {location.state?.sPhone || query?.sPhone || ""}
                  </Td>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12} style={{ marginLeft: "20px", marginBottom: "30px" }}>
          <Grid item xs={12}>
            <TableTitle>주문자 정보</TableTitle>
          </Grid>
          <Grid item xs={12}>
            <TableContainer first>
              <Th topleft xs={2}>
                주문번호
              </Th>
              <Td xs={4}>{data.orderNumber}</Td>
              <Th xs={2}>주문일자</Th>
              <Td topright xs={4}>
                {data.createdAt}
              </Td>
            </TableContainer>
            <TableContainer>
              <Th xs={2}>고객명</Th>
              <Td xs={4}>{data.cName}</Td>
              <Th xs={2}>휴대폰</Th>
              <Td xs={4}>{formatPhoneNumber(data.phone)}</Td>
            </TableContainer>
            <TableContainer>
              <Th xs={2}>주소</Th>
              <Td xs={4}>{data.address + " (" + data.postCode + ")"}</Td>
              <Th xs={2}>배송요청시간</Th>
              <Td xs={4}>
                {`${
                  Boolean(data?.storeDelivery?.split(",")[0]) &&
                  data.storeDelivery.split(",")[0].substring(0, 5)
                } ~ ${
                  Boolean(data?.storeDelivery?.split(",")[1]) &&
                  data.storeDelivery.split(",")[1].substring(0, 5)
                }`}
              </Td>
            </TableContainer>
            <TableContainer last>
              <Th bottomleft xs={2}>
                배송메세지
              </Th>
              <Td bottomright xs={10}>
                {data.message}
              </Td>
            </TableContainer>
          </Grid>
        </Grid>

        {
          //드라이브, 수기주문, 배송중 >= 단계만 해당
        }
        {data.driverId &&
          (data.orderState === "payment" || data.orderState === "delivery") && (
            <Grid
              item
              xs={12}
              style={{
                marginLeft: "20px",
                display: "flex",
                justifyContent: "flex-end"
              }}>
              <Button
                onClick={handleAddService}
                variant="outlined"
                color="primary"
                style={{
                  minWidth: "10rem"
                }}>
                서비스 등록
              </Button>
            </Grid>
          )}
        <Grid item xs={12} style={{ marginLeft: "20px", marginBottom: "30px" }}>
          <Grid item xs={12}>
            <TableTitle>주문상품</TableTitle>
          </Grid>
          <TableContainer first>
            <Th topleft center xs={2}>
              상품코드
            </Th>
            <Th center xs={2}>
              상품이미지
            </Th>
            <Th center xs={5}>
              상품명
            </Th>
            <Th center xs={2}>
              상품금액
            </Th>
            <Th center xs={1}>
              수량
            </Th>
            <Th topright center xs={2}>
              총 합계금액
            </Th>
          </TableContainer>
          {data.products &&
            data.products.map((col, idx) => {
              return (
                <TableContainer
                  last={data.products.length === idx + 1 ? true : false}>
                  <Td
                    bottomleft={data.products.length === idx + 1 ? true : false}
                    center
                    xs={2}>
                    {col.code}
                  </Td>
                  <Td center xs={2}>
                    <CardMedia
                      style={{ borderRadius: "5px" }}
                      component="img"
                      height="100"
                      image={consts.fileApiUrl + "/" + col.path}
                    />
                  </Td>
                  <Td center xs={5}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center"
                      }}>
                      <p>
                        {col.service ? (
                          <span
                            style={{
                              color: "#39c15d",
                              fontWeight: "bold",
                              marginRight: "8px"
                            }}>
                            {"서비스 상품 |"}
                          </span>
                        ) : (
                          ""
                        )}
                        {col.name}
                      </p>
                      {data.driverId &&
                      (data.orderState === "payment" ||
                        data.orderState === "delivery") &&
                      col.service ? (
                        <Button
                          onClick={handleDeleteServiceProduct(col)}
                          color="secondary"
                          variant="outlined"
                          style={{
                            fontSize: "0.7rem",
                            padding: "0.3rem",
                            marginTop: "1rem"
                          }}>
                          서비스 삭제
                        </Button>
                      ) : null}
                    </div>
                  </Td>
                  <Td center xs={2}>
                    {numFormat(col.price)}원
                  </Td>
                  <Td center xs={1}>
                    {col.quantity}
                  </Td>
                  <Td
                    bottomright={
                      data.products.length === idx + 1 ? true : false
                    }
                    center
                    xs={2}>
                    {numFormat(col.price * col.quantity)}원
                  </Td>
                </TableContainer>
              );
            })}
          <Grid item xs={12}></Grid>
        </Grid>

        <Grid item xs={12} style={{ marginLeft: "20px", marginBottom: "30px" }}>
          <Grid item xs={12}>
            <TableTitle>관리자 메시지</TableTitle>
          </Grid>
          <Grid item xs={12}>
            <TableContainer first last>
              <Th topleft xs={2}>
                메시지
              </Th>
              <Td xs={10}>
                <TextField
                  InputProps={{
                    style: { fontWeight: "normal" }
                  }}
                  multiline
                  fullWidth
                  variant="outlined"
                  value={adminMessage}
                  onChange={e => {
                    e.target.value.length >= 300
                      ? null
                      : setAdminMessage(e.target.value);
                  }}
                />
                <Button
                  onClick={saveAdminMessage}
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "100%",
                    marginLeft: "2rem",
                    minWidth: "10rem"
                  }}>
                  저장
                </Button>
              </Td>
            </TableContainer>
          </Grid>
        </Grid>

        {(() => {
          if (data.orderActionId) {
            return (
              <Grid
                item
                xs={12}
                style={{ marginLeft: "20px", marginBottom: "30px" }}>
                <Grid item xs={12}>
                  <TableTitle>
                    {data.statusIn === "refund" ? "반품" : "교환"}정보
                  </TableTitle>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer first>
                    <Th topleft xs={2}>
                      취소사유
                    </Th>
                    <Td topright xs={10}>
                      {`${getReason(data.reason)} ${
                        data.reasonDetail && " (" + data.reasonDetail + ")"
                      }`}
                    </Td>
                  </TableContainer>
                  <TableContainer last>
                    <Th bottomleft xs={2}>
                      배송비 결제
                    </Th>
                    <Td bottomright xs={10}>
                      {data.actionDeliveryPaymentId
                        ? `고객 결제 (${
                            data.actionDeliveryPaymentMethod
                          }) ${numFormat(data.actionDeliveryAmount)}원`
                        : "기사 결제"}
                    </Td>
                  </TableContainer>
                </Grid>

                <History
                  style={{ marginTop: "20px", marginLeft: "0px" }}
                  data={actions
                    .map((x, i) => [
                      getLabel(x.state, x.type),
                      x.createdAt,
                      driver.name,
                      i === 0
                        ? moment(actions[0]?.createdAt).diff(
                            moment(actions[1]?.createdAt),
                            "minutes"
                          ) + "분"
                        : i === 1
                        ? moment(actions[1]?.createdAt).diff(
                            moment(actions[2]?.createdAt),
                            "minutes"
                          ) + "분"
                        : "-",
                      x.address
                    ])
                    .reverse()}
                />
              </Grid>
            );
          }
          if (data.isCancel) {
            return (
              <Grid
                item
                xs={12}
                style={{ marginLeft: "20px", marginBottom: "30px" }}>
                <Grid item xs={12}>
                  <TableTitle>취소 정보</TableTitle>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer first last>
                    <Th topleft bottomleft xs={2}>
                      취소사유
                    </Th>
                    <Td topright bottomright xs={10}>
                      {`${getReason(data.reason)} ${
                        data.reasonDetail && " (" + data.reasonDetail + ")"
                      }`}
                    </Td>
                  </TableContainer>
                </Grid>
              </Grid>
            );
          }
        })()}

        {paymentRefunds && paymentRefunds.length ? (
          <Grid
            item
            xs={12}
            style={{ marginLeft: "20px", marginBottom: "30px" }}>
            <Grid item xs={12}>
              <TableTitle>환불정보</TableTitle>
            </Grid>
            <Grid item xs={12}>
              <TableContainer first>
                <Th topleft center xs={3}>
                  환불일시
                </Th>
                <Th center xs={6}>
                  환불카드
                </Th>
                <Th topright center xs={3}>
                  환불금액
                </Th>
              </TableContainer>
              {paymentRefunds.map((col, idx) => {
                return (
                  <TableContainer last>
                    <Td bottomleft center xs={3}>
                      {col["1"]}
                    </Td>
                    <Td center xs={6}>
                      {col["2"]}
                    </Td>
                    <Td bottomright center xs={3}>
                      {numFormat(col["3"])}원
                    </Td>
                  </TableContainer>
                );
              })}
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {data.type === "driver" ? (
          <Grid
            item
            xs={12}
            style={{ marginLeft: "20px", marginBottom: "30px" }}>
            <Grid item xs={12}>
              <TableTitle>라이브 배송 - 배송 정보</TableTitle>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} className={classes.df}>
                <TableContainer first>
                  <Th topleft xs={2}>
                    유통사
                  </Th>
                  <Td xs={4}>{driver.companyName}</Td>
                  <Th xs={2}>기사이름</Th>
                  <Td topright xs={4}>
                    {driver.name}
                  </Td>
                </TableContainer>
              </Grid>
              <Grid item xs={12} className={classes.df}>
                <TableContainer>
                  <Th xs={2}>차량명/별칭</Th>
                  <Td xs={4}>
                    {driver.car}/{driver.nickname}
                  </Td>
                  <Th xs={2}>차량번호</Th>
                  <Td xs={4}>{driver.carNumber}</Td>
                </TableContainer>
              </Grid>
              <Grid item xs={12} className={classes.df}>
                <TableContainer>
                  <Th xs={2}>배송기사ID</Th>
                  <Td xs={4}>{driver.username}</Td>
                  <Th xs={2}></Th>
                  <Td xs={4}></Td>
                </TableContainer>
              </Grid>
              <Grid item xs={12} className={classes.df}>
                <TableContainer last>
                  <Th bottomleft xs={2}>
                    휴대폰
                  </Th>
                  <Td xs={4}>{formatPhoneNumber(data.driverPhone)}</Td>
                  <Th xs={2}>현재위치</Th>
                  <Td bottomright xs={4}>
                    {ca}
                  </Td>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            style={{ marginLeft: "20px", marginBottom: "30px" }}>
            <Grid item xs={12}>
              <TableTitle>이웃 배송 - 배송 정보</TableTitle>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} className={classes.df}>
                <TableContainer first last>
                  <Th topleft bottomleft xs={2}>
                    스토어명
                  </Th>
                  <Td topright bottomright xs={10}>
                    {store.name}
                  </Td>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        )}

        {Boolean(data.dcePath) && (
          <Grid
            item
            xs={12}
            style={{ marginLeft: "20px", marginBottom: "30px" }}>
            <Grid item xs={12}>
              <TableTitle>배송완료 정보</TableTitle>
            </Grid>
            <Grid item xs={6}>
              <TableContainer last>
                <Th xs={4}>배송완료 사진</Th>
                <Td xs={8}>
                  <img
                    onClick={() => {
                      let a = document.createElement("a");
                      document.body.appendChild(a);
                      a.href = consts.fileApiUrl + "/" + data.dcePath;
                      a.click();
                      a.target = "_blank";
                      document.body.removeChild(a);
                      a = null;
                    }}
                    alt="image"
                    className={classes.deliveryImage}
                    src={consts.fileApiUrl + "/" + data.dcePath}
                  />
                </Td>
              </TableContainer>
            </Grid>
          </Grid>
        )}

        <History
          title="배송정보"
          data={deliverys.map((x, i) => {
            if (x.state === "request") {
              x.createdAt = data.createdAt;
            }
            return [
              getLabel(x.state, x.type),
              x.createdAt,
              driver.name,
              i === 1
                ? moment(deliverys[1]?.createdAt).diff(
                    moment(deliverys[0]?.createdAt),
                    "minutes"
                  ) + "분"
                : i === 2
                ? moment(deliverys[2]?.createdAt).diff(
                    moment(deliverys[1]?.createdAt),
                    "minutes"
                  ) + "분"
                : "-",
              x.address
            ];
          })}
        />

        {!data.otherOrder && (
          <Grid
            item
            xs={12}
            style={{ marginLeft: "20px", marginBottom: "30px" }}>
            <Grid item xs={12}>
              <TableTitle>결제정보</TableTitle>
            </Grid>
            <Grid item xs={12}>
              <TableContainer first>
                <Th topleft center xs={3}>
                  결제일시
                </Th>
                <Th center xs={5}>
                  결제카드
                </Th>
                <Th center xs={2}>
                  배송비
                </Th>
                <Th center xs={2}>
                  상품금액
                </Th>
                <Th center xs={2}>
                  쿠폰할인금액
                </Th>
                <Th topright center xs={2}>
                  결제금액
                </Th>
              </TableContainer>
              {payments.map((col, idx) => {
                return (
                  <TableContainer
                    last={payments.length === idx + 1 ? true : false}>
                    <Td
                      bottomleft={payments.length === idx + 1 ? true : false}
                      center
                      xs={3}>
                      {col["1"]}
                    </Td>
                    <Td center xs={5}>
                      {col["2"]}
                    </Td>
                    <Td center xs={2}>
                      {numFormat(col["3"])}원
                    </Td>
                    <Td center xs={2}>
                      {numFormat(col["4"])}원
                    </Td>
                    <Td center xs={2}>
                      {numFormat(col["5"])}원
                    </Td>
                    <Td
                      bottomright={payments.length === idx + 1 ? true : false}
                      center
                      xs={2}>
                      {numFormat(col["6"])}원
                    </Td>
                  </TableContainer>
                );
              })}
            </Grid>
          </Grid>
        )}

        {/*
  
        <PaymentInfo orderId={orderId} />
  */}

        <Grid
          item
          xs={12}
          className={classes.dfcc}
          style={{ marginBottom: "50px" }}>
          <Button className={classes.deleteBtn} onClick={onClickListBtn}>
            목록
          </Button>
        </Grid>
      </Grid>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">삭제</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column"
              }}>
              <Typography variant="subtitle1">
                주문 취소 처리 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setDeleteDialogOpen(false)}
                className={classes.cancelBtn}>
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                onClick={deleteDialogAgree}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}>
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
      <Dialog open={changeDialogOpen} onClose={handleChangeDialogClose}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">배송 현황</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column"
              }}>
              <Typography variant="subtitle1">
                배송 완료 처리하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setChangeDialogOpen(false)}
                className={classes.cancelBtn}>
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                onClick={changeDialogAgree}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}>
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </Grid>
  );
}
