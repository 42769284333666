import React, { useEffect, useState } from "react";
import { requestGet, requestPost } from "../../services/network.js";
import consts from "../../libs/consts.js";
import { Box, Button, InputBase, makeStyles } from "@material-ui/core";
import fonts from "../../libs/fonts.js";

export default function MemoInput({ driverWorkProductId, driverId, memo }) {
  const [text, setText] = useState(memo || "");

  const handleSave = async () => {
    try {
      await requestPost({
        url:
          consts.apiUrl +
          "/drivers/" +
          driverId +
          "/driverworks/products/" +
          driverWorkProductId +
          "/memo",
        body: {
          memo: text
        }
      });
      alert("저장 완료");
    } catch (error) {
      alert(error.message || error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        alignSelf: "stretch"
      }}>
      <InputBase
        value={text || ""}
        onChange={e => {
          setText(e.target.value);
        }}
        style={{
          flex: 1,
          marginLeft: "1rem",
          border: "1px solid #ddd",
          fontSize: 14,
          padding: "0rem 0.5rem",
          fontFamily: "normal"
        }}
      />
      <Button
        style={{ margin: "0px 1rem" }}
        onClick={handleSave}
        color="primary"
        variant="outlined">
        저장
      </Button>
    </Box>
  );
}
