import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Typography,
  Checkbox,
  Radio,
  Button,
  TextField,
  FormControlLabel
} from "@material-ui/core";
import TableSheet from "./TableSheet";
import { useDispatch } from "react-redux";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import { popupAction, popupError, popupMessage } from "../../redux/popup/PopupActions";
import { requestGet, requestPost } from "../../services/network";
import consts from "../../libs/consts";
import useRouter from "use-react-router";
import { formatPhoneNumber, parseSearch } from "../../services/utils";
import MemberPopup from "../../components/editor/member_popup/MemberPopup";
import fonts from "../../libs/fonts";
import TableTitle from "../../components/table/TableTitle";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px"
  },
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  dfss: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start"
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#c5c8ce",
    marginBottom: "40px",
    marginTop: "27px"
  },
  controlBox: {
    border: "2px solid #000"
  },
  titleCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    backgroundColor: "lightgrey"
  },
  contentCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px"
  },
  sendBtn: {
    width: "120px",
    borderRadius: 2,
    color: "#fff",
    backgroundColor: "#40cf66",
    "&:hover": {
      backgroundColor: "#40cf66"
    }
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  saveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B"
    }
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    backgroundColor: "#e9e9e9",
    padding: "20px"
  },
  textfield: {
    "& div": {
      backgroundColor: "#fff"
    }
  },
  dialogTitleCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "lightgrey"
  },
  dialogContentCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "2px solid #656565"
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none"
    }
  },
  cb: { color: "#40cf66 !important" },
  resize: { fontSize: "13px" },

  rows: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  }
});

export default function Notification(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [checkValue, setCheckValue] = useState(["driver", "user"]);
  const [radioValue, setRadioValue] = useState("all");
  const [selMode, setSelMode] = useState(false);
  const [user, setUser] = useState({});
  const [title, setTitle] = useState("");
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const { location } = useRouter();
  const query = parseSearch(location);

  const [memberIds, setMemberIds] = useState([]);

  useEffect(() => {
    setMemberIds([]);
  }, [radioValue, checkValue]);

  const fetchData = () => {
    requestGet({ url: consts.apiUrl + "/notifications", query: { ...query } })
      .then(x => {
        setTotal(x.totalCount);
        setData([...x.results]);
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  };

  useEffect(() => {
    query.page && fetchData();
  }, [query.page, query.limit]);

  const handleCheckValue = e => {
    if (checkValue.includes(e.target.value)) {
      setCheckValue([...checkValue.filter(x => x !== e.target.value)]);
    } else {
      setCheckValue([...checkValue, e.target.value]);
    }
    setUser({});
  };
  const handleRadioValue = e => {
    if (!checkValue.length) {
      setCheckValue(["driver", "user"]);
    }
    setRadioValue(e.target.value);
    setUser({});
  };
  const onClickChoiceBtn = () => {
    setSelMode(true);
  };

  const send = async () => {
    if(!title || !message){
      return dispatch(popupMessage({title:"오류",message:"발송제목과 내용을 모두 입력해 주세요."}))
    }else if (radioValue === 'individual' && !memberIds.length){
      return dispatch(popupMessage({title:'오류',message:"개별회원 선택시 한명 이상의 회원을 선택해야 됩니다."}))
    }
    dispatch(
      popupAction({
        title: "알림 발송",
        message: `${
          memberIds.length >= 1 ? memberIds.length + "명" : "전체회원"
        }에게 알림을 보내시겠습니까?`,
        onClickConfirm: async () => {
          dispatch(loadingStart);
          try {
            await requestPost({
              url: consts.apiUrl + "/notifications",
              body: {
                message: message,
                title,
                memberIds: memberIds.map(x => x.memberId),
                memberId: user.memberId,
                target: checkValue.length === 2 ? "all" : checkValue[0]
              }
            });
            setMessage("");
            setTitle("");
            setCheckValue(["driver", "user"]);
            setRadioValue("all");
            setUser({});

            fetchData();
          } catch (error) {
            dispatch(popupError(error));
          }
          dispatch(loadingStop);
        }
      })
    );
  };

  return (
    <Grid container className={classes.container}>
      {selMode && (
        <MemberPopup
          // hideMemberCheck={false}
          driver={checkValue.includes("driver")}
          member={checkValue.includes("user")}
          value={memberIds}
          key={checkValue.length === 2 ? "" : checkValue[0]}
          type={checkValue.length === 2 ? "" : checkValue[0]}
          onClose={() => {
            setSelMode(false);
          }}
          onChange={setMemberIds}
        />
      )}
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid item xs={10} className={classes.dfsc}>
            <Typography
              variant="h5"
              style={{
                fontSize: "28px",
                lineHeight: "32px",
                fontFamily: fonts.spoqaHanSansNeoBold,
                color: "#374553",
                paddingTop: "40px"
              }}>
              알림발송
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Divider
              className={classes.divider}
              style={{
                height: "1px",
                backgroundColor: "#c5c8ce",
                marginBottom: "40px",
                marginTop: "20px"
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={10} style={{ marginBottom: "10px" }}>
          <Grid item xs={12} className={classes.df}>
            <TableContainer first>
              <Th topleft>회원구분</Th>
              <Td topright xs={10} textfield>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.cb}
                      color="default"
                      value="driver"
                      checked={checkValue.includes("driver")}
                      onChange={handleCheckValue}
                      style={{ fontSize: "13px" }}
                    />
                  }
                  label={<Typography variant="subtitle2">기사회원</Typography>}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      value="user"
                      checked={checkValue.includes("user")}
                      onChange={handleCheckValue}
                      className={classes.cb}
                    />
                  }
                  label={<Typography variant="subtitle2">고객회원</Typography>}
                  labelPlacement="end"
                />
              </Td>
            </TableContainer>
          </Grid>
          <Grid item xs={12} className={classes.df}>
            <TableContainer>
              <Th>발송대상</Th>
              <Td xs={10} textfield>
                <FormControlLabel
                  control={
                    <Radio
                      className={classes.cb}
                      color="default"
                      value="all"
                      checked={radioValue === "all"}
                      onChange={handleRadioValue}
                    />
                  }
                  label={<Typography variant="subtitle2">전체회원</Typography>}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Radio
                      className={classes.cb}
                      color="default"
                      value="individual"
                      checked={radioValue === "individual"}
                      onChange={handleRadioValue}
                    />
                  }
                  label={<Typography variant="subtitle2">개별회원</Typography>}
                  labelPlacement="end"
                />
              </Td>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={12}
            style={
              radioValue === "individual"
                ? { display: "flex" }
                : { display: "none" }
            }>
            <TableContainer>
              <Th>회원선택</Th>
              <Td xs={10} textfield>
                <Button
                  variant="outlined"
                  onClick={onClickChoiceBtn}
                  style={{ marginRight: "20px" }}>
                  <Typography variant="subtitle2">회원선택</Typography>
                </Button>
                {user.memberId &&
                  `(${user.type === "driver" ? "기사" : "고객"}) ${
                    user.type === "driver" ? user.dName : user.uName
                  }`}
              </Td>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={12}
            style={
              radioValue === "individual"
                ? { display: "flex" }
                : { display: "none" }
            }>
            <TableContainer>
              <Th>선택회원</Th>
              <Td xs={10} textfield>
                <div className={classes.rows}>
                  {memberIds
                    ? memberIds.map((x, i) => {
                        return (
                          <div
                            key={i.toString()}
                            style={{ fontSize: 13, margin: "10px" }}>
                            {(x.name ? x.name : "정보 없음") +
                              "(" +
                              (x.phone
                                ? formatPhoneNumber(x.phone)
                                : "정보 없음") +
                              ")" +
                              (x.driverId ? "-기사" : "-고객")}
                          </div>
                        );
                      })
                    : null}
                </div>
              </Td>
            </TableContainer>
          </Grid>

          <Grid item xs={12} className={classes.df}>
            <TableContainer>
              <Th>발송제목</Th>
              <Td xs={10} textfield>
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.resize
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  value={title}
                  onChange={e => {
                    const value = e.target.value;
                    if (value.length < 100) {
                      setTitle(value);
                    }
                  }}
                  rows={5}
                  placeholder="100자 이내로 입력하세요"
                />
              </Td>
            </TableContainer>
          </Grid>
          <Grid item xs={12} className={classes.df}>
            <TableContainer last>
              <Th bottomleft>발송내용</Th>
              <Td bottomright xs={10} textfield>
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.resize
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  multiline
                  value={message}
                  onChange={e => {
                    const value = e.target.value;
                    if (value.length < 100) {
                      setMessage(value);
                    }
                  }}
                  rows={5}
                  placeholder="100자 이내로 입력하세요"
                />
              </Td>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid
          item
          xs={10}
          className={classes.dfcc}
          style={{ marginBottom: "30px" }}>
          <Button onClick={send} className={classes.sendBtn}>
            <Typography
              style={{ fontFamily: fonts.spoqaHanSansNeoBold, fontSize: 14 }}
              variant="subtitle1">
              보내기
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={10}>
          <TableSheet
            setListData={setData}
            listData={data}
            length={total}
            onFetch={fetchData}
            page={Math.ceil(total / parseInt(query.limit))}
            setCouponEditDialogOpen={data => {
              setCouponEditDialogOpen(data);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
