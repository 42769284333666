import React, { useEffect, useRef, useState } from "react";
import TableLayout from "../components/table-layout/TableLayout.js";
import useDistribution from "../../hooks/useDistribution.js";
import useListPayload from "../../hooks/useListPayload.js";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";

import * as XLSX from "xlsx";
import fonts from "../libs/fonts.js";
import { formatPhoneNumber } from "../services/utils.js";
import { requestPost } from "../services/network.js";
import consts from "../libs/consts.js";

const useStyles = makeStyles({
  button: {
    height: 40,
    backgroundColor: "#fff",
    borderRadius: 2,
    border: "1px solid #dddddd",
    marginRight: "10px",
    width: "150px",
    fontSize: 13,
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoBold,
  },
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  container2: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  titleGrid: {
    height: "50px",
  },
  divider: {
    width: "100%",
    height: "3px",
    backgroundColor: "#7C7878",
    marginBottom: "20px",
  },
  bold: {
    fontWeight: "bold",
  },
});
export default function Container({ history, location }) {
  const classes = useStyles();
  const [distributions] = useDistribution();
  // const { isPartner, distributionId } = useMember();
  const isPartner = true;
  const distributionId = 35;
  const [data, setData] = useState([]);
  const ref = useRef();

  const [loading, setLoading] = useState(false);

  const insertBulk = () => {
    if (data.length >= 1) {
      setLoading(true);
      requestPost({
        url: consts.apiUrl + "/otherorders/bulk",
        body: {
          data: data.map((x) => ({
            driverUserId: x[0],
            name: x[1],
            phone: x[2],
            address: x[3] + "\n" + x[5],
            postCode: x[4],
            customOrderNumber: x[8],
            memo: x[10],
            sName: x[6],
            sPhone: x[7],
            products: x[9].split(",").map((y) => ({
              code: y.split(":")[0],
              quantity: y.split(":")[1],
            })),
          })),
        },
      })
        .then((res) => {
          setData([]);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          alert(e.message || e);
        });
    }
  };

  const uploadTemplage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = (evt) => {
        const bstr = reader.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        data.shift();
        if (data.length < 1) {
          return alert("데이터를 입력해 주세요.");
        }
        setData([...data.filter((x) => x.length >= 1)]);
      };
      reader.readAsBinaryString(file);
    }
    setData([]);
    e.target.value = "";
  };

  const downloadTemplate = async () => {
    const a = document.createElement("a");
    a.setAttribute(
      "href",
      "https://s3.ap-northeast-2.amazonaws.com/file.doongdoong.com/otherorderstemplate/%EC%88%98%EA%B8%B0%EC%A3%BC%EB%AC%B8%EC%96%91%EC%8B%9D.xlsx"
    );
    a.setAttribute("target", "_blank");
    document.body.appendChild(a);
    a.click();
    return document.body.removeChild(a);
  };

  return (
    <>
      <TableLayout
        pagination={false}
        defaultQuery={{}}
        label="수기주문 등록"
        data={[...data]}
        button={{ label: "주문등록", onClick: insertBulk }}
        dataLength={data.length}
        renderTableLabel="등록 대기 현황"
        limitMenu={false}
        renderHeaderComponent={
          <Grid
            container
            xs={12}
            style={{
              backgroundColor: "#f7f8f9",
              borderRadius: 5,
              padding: "30px",
              flexBasis: 0,
              margin: "40px 0px",
            }}
          >
            <ButtonBase
              onClick={() => {
                history.push("/others_order/orders/detail");
              }}
              style={{
                color: "#fff",
                border: "1px solid #39c15d",
                backgroundColor: "#40cf66",
              }}
              variant="outlined"
              color="default"
              className={classes.button}
            >
              개별등록
            </ButtonBase>

            <ButtonBase
              onClick={downloadTemplate}
              variant="outlined"
              color="default"
              className={classes.button}
            >
              템플릿 다운로드
            </ButtonBase>

            <input
              ref={ref}
              style={{ display: "none" }}
              type="file"
              onChange={uploadTemplage}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <ButtonBase
              onClick={() => {
                ref.current?.click();
              }}
              variant="outlined"
              color="default"
              className={classes.button}
            >
              템플릿 업로드
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                setData([]);
              }}
              variant="outlined"
              color="default"
              className={classes.button}
            >
              초기화
            </ButtonBase>
          </Grid>
        }
        columns={[
          {
            label: "기사회원 ID",
            render: (col) => col[0],
          },
          {
            label: "받는분 이름",
            render: (col) => col[1],
          },
          {
            label: "받는분 전화번호",
            render: (col) => formatPhoneNumber(col[2]),
          },
          {
            label: "받는분 주소",
            render: (col) => (
              <>
                {col[3]} ({col[4]})
              </>
            ),
          },
          {
            label: "받는분 상세주소",
            render: (col) => col[5],
          },
          {
            label: "보내는분 이름",
            render: (col) => col[6],
          },
          {
            label: "보내는분 전화번호",
            render: (col) => formatPhoneNumber(col[7]),
          },
          {
            label: "주문번호",
            render: (col) => col[8],
          },
          {
            label: "주문상품",
            render: (col) =>
              col[9].split(",").map((x, i) => {
                const [name, quantity] = x.split(":");
                return (
                  <li key={i.toString()} style={{ marginBottom: "10px" }}>
                    상품코드 : {name}
                    <br />
                    주문수량 : {quantity}
                  </li>
                );
              }),
          },
          {
            label: "배송메모",
            render: (col) => col[10],
          },
        ]}
      />

      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          수기주문 데이터를 등록하고 있습니다.
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
          >
            등록하는데 최대 몇분까지 시간이 소요될 수 있습니다.
            <CircularProgress
              color="inherit"
              style={{ marginLeft: 10, color: "#40cf66" }}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
