import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Divider, Grid, Typography, Button, TextField, Paper, Avatar, Icon, Dialog, CardMedia } from '@material-ui/core';
const useStyles = makeStyles({
	container: {
		width: 'calc(100% - 200px)',
		height: '100%',
		overflow: 'auto'
	},
	df: {
		display: 'flex'
	},
	dfcc: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	dfsc: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center'
	},
	dfss: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'start'
	},
	divider: {
		width: '100%',
		height: '3px',
		backgroundColor: '#7C7878',
		marginBottom: '20px'
	},
	dashboardItem: {
		marginBottom: '30px'
	},
	bold: {
		fontWeight: 'bold'
	},
	detailInfoPaper: {
		width: 'calc(100% - 200px)',
		height: '100%',
		overflow: 'auto',
		paddingTop: '30px',
		paddingLeft: '30px',
		paddingRight: '30px',
		paddingBottom: '50px'
	},
	cell: {
		height: '50px',
		paddingLeft: '10px',
		borderBottom: '1px solid #7C7878'
	},
	cellTop: {
		borderTop: '1px solid #7C7878'
	},
	lastCell: {
		height: '100px',
		paddingLeft: '10px',
		borderBottom: '1px solid #7C7878'
	},
	firstCell: {
		borderRight: '1px solid #7C7878'
	},
	attributeCell: {
		backgroundColor: '#e9e9e9'
	},
	postalCodeField: {
		width: '200px',
		'& input': {
			textAlign: 'center'
		}
	},
	detailInfoBtnGrid: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},

	detailInfoSaveBtn: {
		width: '120px',
		borderRadius: 0,
		border: '1px solid #7C7878',
		backgroundColor: '#747474',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#747474'
		}
	},
	detailInfoListBtn: {
		width: '120px',
		marginLeft: '10px',
		borderRadius: 0,
		backgroundColor: '#e9e9e9',
		border: '1px solid #7C7878'
	},
	withDrawalBtn: {
		backgroundColor: '#e9e9e9',
		marginBottom: '10px',
		'&:hover': {
			backgroundColor: '#e9e9e9'
		}
	},
	cancelBtn: {
		backgroundColor: '#fff',
		color: '#000',
		'&:hover': {
			backgroundColor: '#fff'
		}
	},
	saveBtn: {
		backgroundColor: '#1C1B1B',
		marginLeft: '10px',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#1C1B1B'
		}
	},
	dialogHeader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#1C1B1B',
		color: '#fff'
	},
	dialogContent: {
		display: 'flex',
		width: '400px',
		height: '200px',
		flexDirection: 'column'
	},
	selectForm: {
		cursor: 'pointer',
		paddingLeft: '5px',
		paddingTop: '5px',
		paddingBottom: '5px',
		'&:hover': {
			backgroundColor: 'rgba(0,0,0, 0.1)'
		}
	}
});

export default function DrawalDetailInfo (props) {

	const classes = useStyles();

	const [productSet, setProductSet] = useState('default');
	const [distributor, setDistributor] = useState('유통사 명');
	const [receivingClock, setReceivingClock] = useState('9시');
	const [receivingMinute, setReceivingMinute] = useState('30분');
	const [withDrawalDialogOpen, setWithDrawalDialogOpen] = useState(false);
	const [recoveryDialogOpen, setRecoveryDialogOpen] = useState(false);

	const [password, setPassword] = useState('');
	const [grade, setGrade] = useState('등급');

	const fakeDataArr = (length) => {
		const itemArr = [];
		let item;
		for (let i = 0; i < length; i++) {
			item = {
				name: 'item' + (i + 1),
				value: parseInt(Math.random() * 100 + 1)
			};
			itemArr.push(item);
		}
		return itemArr;
	};

	const handleDetailInfoClose = () => {
		setDetailInfoOpen(false);
	};

	const handleDetailInfoSave = () => {
		// 변경된 정보 저장하는 로직구현필요
		setDetailInfoOpen(false);
	};

	const handleProductSet = (e) => {
		setProductSet(e.target.value);
	};
	const handleDistributor = (e) => {
		setReceivingMinute(e.target.value);
	};
	const handleReceivingClock = (e) => {
		setReceivingClock(e.target.value);
	};
	const handleReceivingMinute = (e) => {
		setReceivingMinute(e.target.value);
	};

	const onClickWithDrawalBtn = () => {
		setWithDrawalDialogOpen(true);
	};

	const handleWithDrawalDialog = () => {
		setWithDrawalDialogOpen(false);
	};
	const handleRecoveryDialog = () => {
		setRecoveryDialogOpen(false);
	};

	const onClickWithDrawalDialogSaveBtn = () => {
		setWithDrawalDialogOpen(false);
	};

	const onClickRecoveryDialogSaveBtn = (e) => {
		setRecoveryDialogOpen(false);
	};

	const onClickRecovery = (e) => {
		setRecoveryDialogOpen(true);
	};

	const handlePassword = (e) => {
		setPassword(e.target.value);
	};

	const handleGrade = (e) => {
		setGrade(e.target.value);
	};

	return (
		<Grid container className={classes.container}>
			<Grid item xs={12}>
				<Paper
					className={classes.detailInfoPaper}
					elevation={0}
					square
				>
					<Grid container>
						<Grid item xs={12}>
							<Grid item xs={10} className={classes.df}>
								<Grid item xs={5} className={classes.dfsc}>
									<Typography variant='h5' style={{ marginBottom: '10px' }} className={classes.bold}>탈퇴회원 상세보기</Typography>
								</Grid>
								<Grid item xs={7} className={classes.df} style={{ justifyContent: 'flex-end' }}>
									<Button variant='outlined' size='small' className={classes.withDrawalBtn} onClick={onClickRecovery}>
										<Typography variant='subtitle1'>복구처리</Typography>
									</Button>
									<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} className={classes.withDrawalBtn} onClick={onClickWithDrawalBtn}>
										<Typography variant='subtitle1'>탈퇴 처리</Typography>
									</Button>
								</Grid>
							</Grid>
							<Grid item xs={10}>
								<Divider className={classes.divider} />
							</Grid>
						</Grid>
						<Grid item xs={12} className={classes.dashboardItem}>
							<Grid item xs={12}>
								<Typography variant='h6' className={classes.bold}>기본정보</Typography>
							</Grid>
							<Grid container>
								<Grid item xs={10} className={classes.df}>
									<Grid item xs={6}>
										<Grid item xs={12} className={classes.df}>
											<Grid item xs={4} className={[classes.dfsc, classes.cell, classes.firstCell, classes.cellTop, classes.attributeCell].join(' ')}>
												<Typography variant='subtitle1'>가입일시</Typography>
											</Grid>
											<Grid item xs={8} className={[classes.dfsc, classes.cell, classes.cellTop].join(' ')}>
												<Typography variant='subtitle1'>2020.03.24 12:00</Typography>
											</Grid>
										</Grid>
										<Grid item xs={12} className={classes.df}>
											<Grid item xs={4} className={[classes.dfsc, classes.cell, classes.firstCell, classes.attributeCell].join(' ')}>
												<Typography variant='subtitle1'>가입유형</Typography>
											</Grid>
											<Grid item xs={8} className={[classes.dfsc, classes.cell].join(' ')}>
												<Typography variant='subtitle1'>페이스북</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6} className={classes.df}>
										<Grid item xs={4} className={[classes.dfsc, classes.cell, classes.cellTop, classes.attributeCell].join(' ')} style={{ height: '100px' }}>
											<Typography variant='subtitle1'>프로필</Typography>
										</Grid>
										<Grid item xs={8} className={[classes.dfsc, classes.cell, classes.cellTop].join(' ')} style={{ height: '100px' }}>
											<span style={{ width: '70px' }}>
												<CardMedia
													component='img'
													height='70'
													image='/images/sample.png'
												/>
											</span>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={10} className={classes.df}>
									<Grid item xs={6} className={classes.df}>
										<Grid item xs={4} className={[classes.dfsc, classes.cell, classes.firstCell, classes.attributeCell].join(' ')}>
											<Typography variant='subtitle1'>이름/성별/나이</Typography>
										</Grid>
										<Grid item xs={8} className={[classes.dfsc, classes.cell].join(' ')}>
											<Typography variant='subtitle1'>홍길동 / 남 / 21</Typography>
										</Grid>
									</Grid>
									<Grid item xs={6} className={classes.df}>
										<Grid item xs={4} className={[classes.dfsc, classes.cell, classes.attributeCell].join(' ')}>
											<Typography variant='subtitle1'>이메일(ID)</Typography>
										</Grid>
										<Grid item xs={8} className={[classes.dfsc, classes.cell].join(' ')}>
											<a href='#'><Typography variant='subtitle1'>abc123@naver.com</Typography></a>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={10} className={classes.df}>
									<Grid item xs={6} className={classes.df}>
										<Grid item xs={4} className={[classes.dfsc, classes.cell, classes.firstCell, classes.attributeCell].join(' ')}>
											<Typography variant='subtitle1'>비밀번호</Typography>
										</Grid>
										<Grid item xs={8} className={[classes.dfsc, classes.cell].join(' ')}>
											<TextField
												type='password'
												variant='outlined'
												size='small'
												value={password}
												onChange={handlePassword}
											/>
										</Grid>
									</Grid>
									<Grid item xs={6} className={classes.df}>
										<Grid item xs={4} className={[classes.dfsc, classes.cell, classes.attributeCell].join(' ')}>
											<Typography variant='subtitle1'>등급</Typography>
										</Grid>
										<Grid item xs={8} className={[classes.dfsc, classes.cell].join(' ')}>
											<TextField
												select
												variant='outlined'
												size='small'
												value={grade}
												onChange={handleGrade}
											>
												<option disabled value='등급' className={classes.selectForm}>등급</option>
												{
													fakeDataArr(3).map((item, idx) => {
														return (
															<option key={idx} value={item.name} className={classes.selectForm}>{item.name}</option>
														);
													})
												}
											</TextField>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={10} className={classes.df}>
									<Grid item xs={6} className={classes.df}>
										<Grid item xs={4} className={[classes.dfsc, classes.cell, classes.firstCell, classes.attributeCell].join(' ')}>
											<Typography variant='subtitle1'>전화번호</Typography>
										</Grid>
										<Grid item xs={8} className={[classes.dfsc, classes.cell].join(' ')}>
											<Typography variant='subtitle1'>010-1234-5687</Typography>
										</Grid>
									</Grid>
									<Grid item xs={6} className={classes.df}>
										<Grid item xs={4} className={[classes.dfsc, classes.cell, classes.attributeCell].join(' ')}>
											<Typography variant='subtitle1'>카드</Typography>
										</Grid>
										<Grid item xs={8} className={[classes.dfcs, classes.cell].join(' ')} style={{ flexDirection: 'column' }}>
											<Typography variant='subtitle1'>국민카드 2123525456</Typography>
											<Typography variant='subtitle1'>신한카드 1235485465</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={10} className={classes.df}>
									<Grid item xs={2} className={[classes.dfsc, classes.cell, classes.firstCell, classes.attributeCell].join(' ')}>
										<Typography variant='subtitle1'>기본배송지</Typography>
									</Grid>
									<Grid item xs={10} className={[classes.dfsc, classes.cell].join(' ')}>
										<Typography variant='subtitle1'>(123-45) 경기 하남시 미사대로165 자이아파트 105-1306</Typography>
									</Grid>
								</Grid>
								<Grid item xs={10} className={classes.df}>
									<Grid item xs={2} className={[classes.dfsc, classes.cell, classes.firstCell, classes.attributeCell].join(' ')}>
										<Typography variant='subtitle1'>주문건수</Typography>
									</Grid>
									<Grid item xs={10} className={[classes.dfsc, classes.cell].join(' ')}>
										<Typography variant='subtitle1'>12</Typography>
									</Grid>
								</Grid>
								<Grid item xs={10} className={classes.df}>
									<Grid item xs={2} className={[classes.dfsc, classes.cell, classes.firstCell, classes.attributeCell].join(' ')}>
										<Typography variant='subtitle1'>총 주문금액</Typography>
									</Grid>
									<Grid item xs={10} className={[classes.dfsc, classes.cell].join(' ')}>
										<Typography variant='subtitle1'>78,000</Typography>
									</Grid>
								</Grid>
								<Grid item xs={10} className={classes.df}>
									<Grid item xs={2} className={[classes.dfsc, classes.cell, classes.firstCell, classes.attributeCell].join(' ')}>
										<Typography variant='subtitle1'>최근 주문일시</Typography>
									</Grid>
									<Grid item xs={10} className={[classes.dfsc, classes.cell].join(' ')}>
										<Typography variant='subtitle1'>2020.03.24 12:00</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						{/* <Grid item xs={10} className={[classes.detailInfoBtnGrid, classes.dashboardItem].join(' ')}>
							<Button variant='contained' onClick={handleDetailInfoSave} className={classes.detailInfoSaveBtn}>저장</Button>
							<Button variant='contained' onClick={handleDetailInfoClose} className={classes.detailInfoListBtn}>목록</Button>
						</Grid> */}
					</Grid>

				</Paper>
			</Grid>
			<Dialog open={withDrawalDialogOpen} onClose={handleWithDrawalDialog}>
				<Paper
					elevation={0}>
					<Grid item xs={12} className={classes.dialogHeader}>
						<Typography variant='subtitle1'>탈퇴처리</Typography>
					</Grid>
					<Grid item xs={12} className={classes.dialogContent}>
						<Grid item xs={12} className={classes.df} style={{ justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column' }}>
							<Typography variant='subtitle1'>탈퇴처리 하시겠습니까?</Typography>
						</Grid>
						<Grid item xs={12} className={classes.dfcc}>
							<Button variant='outlined' onClick={() => setWithDrawalDialogOpen(false)} className={classes.cancelBtn}><Typography varinat='subtitle2'>취소</Typography></Button>
							<Button variant='outlined' onClick={onClickWithDrawalDialogSaveBtn} className={classes.saveBtn} style={{ marginLeft: '10px' }}><Typography varinat='subtitle2'>확인</Typography></Button>
						</Grid>
					</Grid>
				</Paper>
			</Dialog>
			<Dialog open={recoveryDialogOpen} onClose={handleRecoveryDialog}>
				<Paper
					elevation={0}>
					<Grid item xs={12} className={classes.dialogHeader}>
						<Typography variant='subtitle1'>복구처리</Typography>
					</Grid>
					<Grid item xs={12} className={classes.dialogContent}>
						<Grid item xs={12} className={classes.df} style={{ justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column' }}>
							<Typography variant='subtitle1'>복구처리 하시겠습니까?</Typography>
						</Grid>
						<Grid item xs={12} className={classes.dfcc}>
							<Button variant='outlined' onClick={() => setRecoveryDialogOpen(false)} className={classes.cancelBtn}><Typography varinat='subtitle2'>취소</Typography></Button>
							<Button variant='outlined' onClick={onClickRecoveryDialogSaveBtn} className={classes.saveBtn} style={{ marginLeft: '10px' }}><Typography varinat='subtitle2'>확인</Typography></Button>
						</Grid>
					</Grid>
				</Paper>
			</Dialog>
		</Grid>
	);
}
