/* eslint-disable multiline-ternary */
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import PopupAdd from "./PopupAdd.js";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
  },
});

export default function Container() {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <PopupAdd />
    </Grid>
  );
}
