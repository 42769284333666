import React, { useEffect, useState } from "react";
import useDistribution from "../../../hooks/useDistribution.js";
import { numFormat } from "../../services/utils.js";
import { useDispatch } from "react-redux";
import useMember from "../../../hooks/useMember.js";
import TableLayout from "../../components/table-layout/TableLayout.js";
import queryString from "query-string";
import { requestGet, requestPost } from "../../services/network.js";
import consts from "../../libs/consts.js";
import { Box, Button, InputBase, makeStyles } from "@material-ui/core";
import fonts from "../../libs/fonts.js";
import { RefreshOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  label3: {
    fontSize: 15,
    color: "#374553",
    fontFamily: fonts.malgunGothicBold,
  },
  ib: {
    borderRadius: 2,
    marginLeft: "1rem",
    minWidth: "20rem",
    display: "flex",
    paddingLeft: 14,
    border: "1px solid #e9ebee",
    height: 40,
    alignItems: "center",
    backgroundColor: "#f7f8f9",
    fontSize: 15,
    color: "#2069e3",
    fontFamily: fonts.malgunGothicBold,
  },
});

export default function Container({ history, location }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [distributions] = useDistribution();
  const { isPartner, distributionId } = useMember();

  const [data, setData] = useState({
    list: [],
    name: "",
  });

  const query = queryString.parse(location.search);

  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    if (!query.distributionId) return;

    requestGet({
      url: consts.apiUrl + "/drivers",
      query: {
        distributionId: query.distributionId,
      },
    })
      .then((res) => {
        setDrivers(
          res.results.map((x) => ({
            label: x.nickname,
            value: x.driverId,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [query.distributionId]);

  const handleSearch = async () => {
    try {
      if (!query.distributionId) {
        throw new Error("유통사를 선택해 주세요.");
      }
      if (
        !query.driverId ||
        !drivers.filter((x) => String(x.value) === query.driverId).length
      ) {
        throw new Error("기사를 선택해 주세요.");
      }

      const data = await requestGet({
        url: consts.apiUrl + "/drivers/" + query.driverId + "/stock",
        query: {
          distributionId: query.distributionId,
        },
      });

      const stockData = await requestGet({
        url:
          consts.apiUrl +
          "/drivers/" +
          query.driverId +
          "/works/" +
          data.driverWorkId +
          "/stocks",
      });
      setData({
        list: stockData.map((x) => {
          x.orderQuantity = x.originQuantity - x.stockQuantity;
          if (x.orderQuantity < 0) {
            x.orderQuantity = 0;
          }
          return x;
        }),
        name: data.receiptSet,
      });
    } catch (error) {
      setData({
        list: [],
        name: "",
      });
      alert(error.message || error);
    }
  };

  const handleOrder = async (driverWorkProductId, quantity) => {
    try {
      await requestPost({
        url:
          consts.apiUrl +
          "/drivers/" +
          query.driverId +
          "/driverworks/products/" +
          driverWorkProductId +
          "/order",
        body: {
          quantity,
        },
      });
      alert("발주 완료");
      handleSearch();
    } catch (error) {
      alert(error.message || error);
    }
  };

  return (
    <>
      <TableLayout
        label="발주관리"
        limitMenu={false}
        onSearch={handleSearch}
        data={data.list}
        dataLength={data.list.length}
        renderTableLabel="품목"
        pagination={false}
        defaultQuery={{
          page: 1,
          limit: 10,
          driverId: "",
          distributionId: isPartner ? distributionId : "",
        }}
        renderHeaderComponent={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <span className={classes.label3}>입고 SET</span>
            <span className={classes.ib}>{data.name}</span>

            <Button
              style={{ marginLeft: "1rem" }}
              onClick={handleSearch}
              color="primary"
              variant="outlined"
            >
              <RefreshOutlined style={{ marginRight: 6 }} fontSize={"small"} />
              새로고침
            </Button>
          </Box>
        }
        search={[
          [
            isPartner
              ? null
              : {
                  label: "유통사",
                  type: "menu",
                  key: "distributionId",
                  data: distributions,
                },
            {
              label: "배송기사",
              type: "menu",
              key: "driverId",
              data: drivers,
            },
          ].filter((x) => x),
        ]}
        columns={[
          {
            label: "NO",
            render: (col, i) => i + 1,
          },
          {
            label: "카테고리",
            render: (col) => col.category,
          },
          {
            label: "상품명",
            render: (col) => col.name,
          },
          {
            label: "판매 가격",
            render: (col) => numFormat(col.price) + "원",
          },
          {
            label: "입고 세트 설정값",
            render: (col) => col.originQuantity,
          },
          {
            label: "현재고",
            render: (col) => col.stockQuantity,
          },
          {
            label: "발주수량",
            flex: 1.5,
            render: (col, i) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InputBase
                  value={String(col.orderQuantity)}
                  onChange={(e) => {
                    data.list[i].orderQuantity = e.target.value;
                    setData((prev) => ({ ...prev, list: data.list }));
                  }}
                  style={{
                    border: "1px solid #ddd",
                    fontSize: 14,
                    padding: "0.5rem",
                    fontFamily: "normal",
                  }}
                />
                <Button
                  disabled={
                    isNaN(col.orderQuantity) ||
                    String(col.orderQuantity) === "0"
                  }
                  style={{ marginLeft: "1rem" }}
                  onClick={() =>
                    handleOrder(col.driverWorkProductId, col.orderQuantity)
                  }
                  color="primary"
                  variant="contained"
                >
                  발주하기
                </Button>
              </Box>
            ),
          },
        ]}
      />
    </>
  );
}
