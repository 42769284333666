import React, { useState, useEffect } from "react";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { requestGet } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingBottom: "50px",
  },
  titleGrid: {
    height: "50px",
  },
  divider: {
    width: "100%",
    height: "3px",
    backgroundColor: "#7C7878",
    marginBottom: "20px",
  },
  dashboardItem: {
    marginBottom: "30px",
  },
  bold: {
    fontWeight: "bold",
  },
  text: {
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  dateRowGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dateGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "30px",
  },
  tableGrid: {
    marginTop: "10px",
    textAlign: "center",
  },
  tableCell: {
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "2px solid #000",
    borderCollapse: "separate",
  },
  salesSelectGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  companySelect: {
    width: "150px",
    textAlign: "center",
  },
  radioForm: {
    display: "flex",
    justifyContent: "flex-end",
  },
  radioBtn: {
    marginLeft: "30px",
  },
  carInfoGrid: {
    display: "flex",
  },
  carInfoSubGrid: {
    display: "flex",
    height: "100px",
    justifyContent: "flex-start",
    alignItems: "space-around",
    textAlign: "center",
  },
  carInfoSubCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
  },
  df: {
    display: "flex",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dffec: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  box: {
    border: "2px solid #656565",
    display: "flex",
    flexDirection: "column",
    width: "200px",
    height: "200px",
    "&+&": {
      marginLeft: "20px",
    },
  },
  boxHeader: {
    height: "50px",
    borderBottom: "2px solid #656565",
  },
  boxContent: {
    backgroundColor: "#BABABA",
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
});

export default function Delivery({ ds }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [start, setStart] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(moment().format("YYYY-MM-DD"));
  const [distributionId, setDistributionId] = useState("");
  const [sort, setSort] = useState("order");
  const [query, setQuery] = useState({ page: 1, limit: 5 });

  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const handleTableDeliveryPrevDate = (e) => {
    setStart(e.target.value);
  };

  const handleTableDeliveryNextDate = (e) => {
    setEnd(e.target.value);
  };

  const handleSalesSelectValue = (e) => {
    setDistributionId(e.target.value);
  };

  const handleRadioBtn = (e) => {
    setSort(e.target.value);
  };

  const fetch = () => {
    requestGet({
      query: {
        start,
        end,
        sort,
        distributionId,
        page: query.page,
        limit: query.limit,
      },
      url: consts.apiUrl + `/admins/dashboard/drivers/orders`,
    })
      .then((x) => {
        setList([...x.results]);
        setTotal(x.totalCount);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  useEffect(() => {
    if (query.page.toString() === "1") {
      fetch();
    } else {
      setQuery({ ...query, page: 1 });
    }
  }, [start, end, sort, distributionId, query.page, query.limit]);

  return (
    <Grid item xs={12} className={classes.dashboardItem}>
      <Grid item xs={12} className={classes.dateRowGrid}>
        <Grid item xs={2}>
          <Typography variant="h5" className={classes.bold}>
            배송 현황
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.salesSelectGrid}>
          <Grid item xs={6} className={classes.salesSelectGrid}>
            <Typography
              variant="h5"
              className={classes.bold}
              style={{ marginRight: "30px" }}
            >
              {" "}
              유통사 :{" "}
            </Typography>
            <TextField
              className={classes.companySelect}
              select
              variant="standard"
              value={distributionId}
              onChange={(e) => handleSalesSelectValue(e)}
            >
              <option value="" className={classes.selectForm}>
                전체
              </option>
              {ds.map((x, i) => {
                return (
                  <option
                    value={x.distributionId.toString()}
                    className={classes.selectForm}
                  >
                    {x.companyName}
                  </option>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={6} className={classes.radioForm}>
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  className={classes.radioBtn}
                  checked={sort === "order"}
                  onChange={handleRadioBtn}
                  value="order"
                />
              }
              label="총 배송건수"
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  className={classes.radioBtn}
                  checked={sort === "amount"}
                  onChange={handleRadioBtn}
                  value="amount"
                />
              }
              label="판매금액"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.dateGrid}>
          <input
            type="date"
            value={start}
            onChange={handleTableDeliveryPrevDate}
          />
          <span> ~ </span>
          <input
            type="date"
            value={end}
            onChange={handleTableDeliveryNextDate}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.tableGrid}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                순위
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                기사명
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                배송기사ID
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                차량번호
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                총 배송건수
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                일평균 배송건수
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                평균 배송 소요시간
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                판매금액
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                근무일수
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                유통사
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                총근무시간
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                현재 상태
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ backgroundColor: "#e9e9e9" }}
              >
                차량위치
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((col, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell className={classes.tableCell}>
                    {(total || 0) -
                      (idx + 1) +
                      1 -
                      (parseInt(query.page) - 1) * parseInt(query.limit)}
                  </TableCell>{" "}
                  <TableCell className={classes.tableCell}>
                    {col.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {col.username}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {col.carNumber}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {col.deliveryCompleteLength}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {Math.round(col.deliveryDailyLength)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {Math.round(col.deliveryAvgMinute)}분
                  </TableCell>
                  <TableCell className={classes.tableCell}></TableCell>
                  <TableCell className={classes.tableCell}>
                    {col.amount}원
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {col.companyName}
                  </TableCell>
                  <TableCell className={classes.tableCell}></TableCell>
                  <TableCell className={classes.tableCell}></TableCell>
                  <TableCell className={classes.tableCell}>
                    {col.lat + " " + col.lng}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
