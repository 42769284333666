import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import fonts from "../../libs/fonts.js";
import ReceiptSetDetail from "./ReceiptSetDetail";
import useMember from "../../../hooks/useMember.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  const [distributions] = useDistribution();
  const { isPartner, distributionId } = useMember();
  const [sel, setSel] = useState(null);
  const { data, loading, dataLength, fetchData } = useListPayload({
    endpoint: "/drivers/receiptsets",
  });

  return (
    <TableLayout
      label="오늘의 입고관리"
      onSearch={fetchData}
      data={[...data]}
      dataLoading={loading}
      dataLength={dataLength}
      tableComponent={<ReceiptSetDetail key={sel?.driverId} {...sel} />}
      defaultQuery={{
        page: 1,
        limit: 10,

        keyword: "",
        distributionId: isPartner ? distributionId : "",
      }}
      onClick={(col) => {
        setSel({ ...col });
      }}
      search={[
        [
          {
            label: "검색",
            type: "input",
            key: "keyword",
            placeholder: "이름, 배송기사 ID, 차량번호, 핸드폰번호",
          },
        ],
        [
          !isPartner && {
            label: "유통사",
            type: "menu",
            key: "distributionId",
            data: distributions,
          },
          {
            label: "입고상태",
            type: "menu",
            key: "receiptState",
            data: [
              {
                label: "입고완료",
                value: "1",
              },
              {
                label: "입고대기",
                value: "0",
              },
            ],
          },
        ],
      ]}
      columns={[
        {
          label: "배송기사",
          key: "name",
        },
        {
          clickable: true,
          label: "입고 SET",
          key: "receiptSet",
        },
        {
          label: "입고상태",
          render: (col) => (
            <span
              style={{
                color: col.driverWorkId ? "#39c15d" : "#ff7e00",
                fontFamily: col.driverWorkId
                  ? fonts.malgunGothicBold
                  : fonts.malgunGothicSemiLight,
              }}
            >
              {Boolean(col.driverWorkId) ? "입고완료" : "입고대기"}
            </span>
          ),
        },
      ]}
    />
  );
}
