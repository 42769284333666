import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  Avatar,
  Icon,
  Dialog,
  CardMedia,
  Checkbox
} from "@material-ui/core";
import { withRouter } from "react-router";
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut
} from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { formatTime } from "../../services/utils";
import DaumPostCode from "./DaumPostcode";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import { formatPhoneNumber } from "../../services/utils";
import fonts from "../../libs/fonts";
import TableTitle from "../../components/table/TableTitle";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import useMember from "../../../hooks/useMember";

const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto"
  },
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",

    alignItems: "center",
    alignSelf: "stretch"
  },
  dfss: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start"
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#c5c8ce",
    marginBottom: "40px",
    marginTop: "27px"
  },
  dashboardItem: {
    marginBottom: "30px"
  },
  bold: {
    fontWeight: "bold"
  },
  detailInfoPaper: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px"
  },
  cell: {
    minHeight: "50px",
    boxSizing: "border-box",
    padding: "10px",
    height: "auto",
    borderBottom: "1px solid #7C7878"
  },
  cellTop: {
    borderTop: "1px solid #7C7878"
  },
  lastCell: {
    height: "auto",
    minHeight: "100px",
    paddingLeft: "10px",
    borderBottom: "1px solid #7C7878"
  },
  firstCell: {
    borderRight: "1px solid #7C7878"
  },
  attributeCell: {
    backgroundColor: "#e9e9e9"
  },
  postalCodeField: {
    width: "200px",
    "& input": {
      textAlign: "center"
    }
  },
  detailInfoBtnGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  detailInfoSaveBtn: {
    width: "120px",
    borderRadius: 2,
    // border: "1px solid #7C7878",
    backgroundColor: "#40cf66",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66"
    }
  },
  detailInfoListBtn: {
    width: "120px",
    marginLeft: "10px",
    borderRadius: 0,
    backgroundColor: "#fff",
    border: "1px solid #ddd"
  },
  withDrawalBtn: {
    // backgroundColor: "#e9e9e9",
    width: "100px",
    height: "36px",
    // marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#e9e9e9"
    },
    borderRadius: "2px",
    border: "1px solid #ddd"
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  saveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B"
    }
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff"
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column"
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)"
    }
  },
  cb: { color: "#40cf66 !important" },
  resize: { fontSize: "13px" }
});

export default withRouter(function DriverDetailInfo({ location, history }) {
  const state = location.state;
  const classes = useStyles();
  const [productSet, setProductSet] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [distributor, setDistributor] = useState("");
  const [driverNickname, setDriverNickname] = useState("");
  const [selectDistributor, setSelectDistributor] = useState("");
  const [rest, setRest] = useState(false);
  const [phone, setPhone] = useState("");
  const [setReceiptName, setSetReceiptName] = useState(false);
  const [setReceiptPrice, setSetReceiptPrice] = useState(false);
  const [setReceiptQuantity, setSetReceiptQuantity] = useState(false);
  const [restMessage, setRestMessage] = useState("");
  const [repeatNotifi, setRepeatNotifi] = useState(false);
  const [repeatNotifiMinute, setRepeatNotifiMinute] = useState(false);

  const [withDrawalDialogOpen, setWithDrawalDialogOpen] = useState(false);
  const [data, setData] = useState();
  const [sets, setSets] = useState([]);

  const [postCodes, setPostCodes] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [distributorChangeDialogOpen, setDistributorChangeDialogOpen] =
    useState(false);
  const [keywords, setKeywords] = useState({});
  const [dialog, setDialog] = useState(false);
  const [introduce, setIntroduce] = useState("");
  const dispatch = useDispatch();

  const [working, setWorking] = useState(false);
  const { isPartner } = useMember();
  useEffect(() => {
    if (distributor) {
      requestGet({
        url: consts.apiUrl + "/receiptsets",
        query: {
          distributionId: distributor
        }
      })
        .then(x => {
          setSets([...x.results]);
        })
        .catch(e => {
          dispatch(popupError(e));
        });
    }
  }, [distributor]);

  useEffect(() => {
    if (state?.driverId) {
      requestGet({
        url: consts.apiUrl + "/drivers/" + state.driverId + "/workState"
      })
        .then(data => {
          if (data.working) {
            setWorking(true);
          }
          if (data.resting) {
            setRest(true);
          }
        })
        .catch(e => {
          alert("기사님의 출근 상태를 조회하는중 오류가 발생했습니다.");
        });
      requestGet({
        url: consts.apiUrl + "/drivers/" + state.driverId,
        query: { from: "admin" }
      })
        .then(d => {
          setIntroduce(d.introduce);
          setData({ ...d });
          setDeleted(d.isDeleted === 1);
          setPhone(d.phone);
        })
        .catch(e => {
          dispatch(popupError(e));
        });

      requestGet({ url: consts.apiUrl + "/distributions" })
        .then(x => {
          setDistributions([...x.results]);
        })
        .catch(x => {
          dispatch(popupError(x));
        });
    } else {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if (!repeatNotifi) {
      setRepeatNotifiMinute("");
    }
  }, [repeatNotifi]);
  useEffect(() => {
    if (data) {
      setSetReceiptName(Boolean(data.setReceiptName));
      setSetReceiptPrice(Boolean(data.setReceiptPrice));
      setSetReceiptQuantity(Boolean(data.setReceiptQuantity));
      setProductSet(data.receiptSetId);
      setDistributor(data.distributionId);
      setPostCodes(
        (data.postCodes || "")
          .split(",")
          .sort((a, b) => parseInt(a) - parseInt(b))
      );
      setDriverNickname(data.nickname);
      setRestMessage(data.restMessage || "");
      setRepeatNotifi(Boolean(data.repeatNotifi));
      setRepeatNotifiMinute(data.repeatNotifiMinute || "");
    }
  }, [data]);

  const handleDetailInfoClose = () => {
    history.goBack();
  };

  const handleDetailInfoSave = async () => {
    dispatch(loadingStart);
    try {
      if (!distributor) {
        throw "유통사를 선택해주세요.";
      } else if (!productSet) {
        throw "입고SET을 지정해주세요.";
      } else if (postCodes.length < 1) {
        throw "서비스 지역을 추가해주세요.";
      } else if (driverNickname === "") {
        throw "차량 별명을 입력해주세요.";
      } else if (phone === "") {
        throw "휴대폰번호를 입력해주세요.";
      }
      const c = [];
      const v = [];
      if (data.distributionId.toString() !== distributor.toString()) {
        c.push("distributionId");
        v.push(distributor);
      }
      c.push("introduce");
      v.push(introduce);

      c.push("phone");
      v.push(phone);

      c.push("receiptSetId");
      v.push(productSet);

      c.push("nickname");
      v.push(driverNickname);

      c.push("restMessage");
      v.push(restMessage);

      c.push("repeatNotifi");
      v.push(repeatNotifi ? "1" : "0");
      if (repeatNotifi) {
        if (isNaN(repeatNotifiMinute) || repeatNotifiMinute > 60) {
          return dispatch(
            popupError({ message: "올바른 반복알림 주기를 입력해 주세요." })
          );
        }
      }
      c.push("repeatNotifiMinute");
      v.push(repeatNotifi ? repeatNotifiMinute : "0");

      c.push("setReceiptName");
      v.push(setReceiptName ? "1" : "0");

      c.push("setReceiptPrice");
      v.push(setReceiptPrice ? "1" : "0");

      c.push("setReceiptQuantity");
      v.push(setReceiptQuantity ? "1" : "0");

      await requestPut({
        url: consts.apiUrl + "/drivers/" + data.driverId,
        body: {
          columns: c,
          values: v
        }
      });

      await requestPost({
        url: consts.apiUrl + "/admins/drivers/" + data.driverId + "/area",
        body: {
          data: postCodes
            .filter(x => x)
            .map(x => ({
              postCode: x,
              keyword: keywords[x]
            }))
        }
      });

      history.goBack();
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  const handleProductSet = e => {
    setProductSet(e.target.value);
  };

  const onClickWithDrawalBtn = () => {
    setWithDrawalDialogOpen(true);
  };

  const handleWithDrawalDialog = () => {
    setWithDrawalDialogOpen(false);
  };
  const handleDistributorChangeDialog = () => {
    setDistributorChangeDialogOpen(false);
  };

  const handleDriverNickname = e => {
    setDriverNickname(e.target.value);
  };

  const onClickWithDrawalDialogSaveBtn = () => {
    setWithDrawalDialogOpen(false);
    if (deleted) {
      requestPost({
        url: consts.apiUrl + "/drivers/" + data.driverId + "/restore"
      })
        .then(x => {
          dispatch(popupMessage({ title: "복구 완료." }));
          history.goBack();
        })
        .catch(e => {
          dispatch(popupError(e));
        });
    } else {
      requestDelete({ url: consts.apiUrl + "/drivers/" + data.driverId })
        .then(x => {
          dispatch(popupMessage({ title: "탈퇴 완료." }));
          history.goBack();
        })
        .catch(e => {
          dispatch(popupError(e));
        });
    }
  };

  const onClickDistributorDialogSaveBtn = e => {
    setDistributor(selectDistributor);
    setDistributorChangeDialogOpen(false);
    setSelectDistributor(null);
  };

  const onClickDistributorChange = id => e => {
    setDistributorChangeDialogOpen(true);
    setSelectDistributor(id);
  };

  const handleRest = () => {
    requestPost({
      url:
        consts.apiUrl +
        `/drivers/${data.driverId}/${rest ? "workunrest" : "workrest"}`
    })
      .then(() => {
        alert(rest ? "휴식 취소 되었습니다." : "휴식중 처리가 완료되었습니다.");
        setRest(rest ? false : true);
      })
      .catch(e => {
        alert(e.message || e);
      });
  };

  if (!data) {
    return null;
  }
  return (
    <Grid container className={classes.container}>
      {dialog && (
        <DaumPostCode
          onClose={() => {
            setDialog(false);
          }}
          onPress={({ address, postCode }) => {
            if (postCodes.includes(postCode)) {
              return;
            }
            setPostCodes([...postCodes, postCode]);

            keywords[postCode] = address;
            setKeywords({ ...keywords });
          }}
        />
      )}
      <Grid item xs={12}>
        <Paper className={classes.detailInfoPaper} elevation={0} square>
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={10} className={classes.df}>
                <Grid item xs={5} className={classes.dfsc}>
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: "28px",
                      lineHeight: "32px",
                      fontFamily: fonts.spoqaHanSansNeoBold,
                      color: "#374553",
                      paddingTop: "40px"
                    }}>
                    기사회원 상세보기
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={classes.df}
                  style={{ justifyContent: "flex-end", alignSelf: "flex-end" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.withDrawalBtn}
                    onClick={onClickWithDrawalBtn}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: fonts.spoqaHanSansNeoMedium,
                        fontSize: "13px",
                        color: "#555"
                      }}>
                      {data.isDeleted ? "복구처리" : "탈퇴처리"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={[classes.dfsc, classes.dashboardItem].join(" ")}>
              <Avatar>
                {data.profilePath ? (
                  <CardMedia
                    style={{ width: "60px", height: "60px" }}
                    image={consts.fileApiUrl + "/" + data.profilePath}
                  />
                ) : (
                  <Icon>person_outline</Icon>
                )}
              </Avatar>
              <Typography
                variant="h5"
                className={classes.bold}
                style={{ marginLeft: "20px" }}>
                {data.name}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dashboardItem}>
              <Grid item xs={12}>
                <TableTitle>기본정보</TableTitle>
              </Grid>
              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer first>
                  <Th topleft>가입일시</Th>
                  <Td>{formatTime(data.createdAt, "YYYY-MM-DD HH:mm")}</Td>
                  <Th>이름</Th>
                  <Td topright>{data.name}</Td>
                </TableContainer>
              </Grid>
              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer>
                  <Th>드라이버 ID</Th>
                  <Td>{data.username}</Td>
                  <Th>차량 별칭</Th>
                  <Td textfield>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.resize
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={driverNickname}
                      onChange={handleDriverNickname}
                    />
                  </Td>
                </TableContainer>
              </Grid>
              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer>
                  <Th>차종</Th>
                  <Td>
                    {data.carType} / {data.car}
                  </Td>
                  <Th>차량 번호</Th>
                  <Td>{data.carNumber}</Td>
                </TableContainer>
              </Grid>
              {/**repeatNotifi */}
              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer>
                  {!isPartner && <Th>유통사</Th>}
                  {!isPartner && (
                    <Td textfield>
                      <TextField
                        InputProps={{
                          classes: {
                            input: classes.resize
                          }
                        }}
                        select
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={distributor}>
                        {distributions.map((item, idx) => {
                          return (
                            <option
                              key={idx}
                              className={classes.selectForm}
                              value={item.distributionId}
                              onClick={onClickDistributorChange(
                                item.distributionId
                              )}>
                              {item.companyName}
                            </option>
                          );
                        })}
                      </TextField>
                    </Td>
                  )}
                  <Th>입고 SET</Th>
                  <Td textfield>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.resize
                        }
                      }}
                      select
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={productSet}
                      onChange={handleProductSet}>
                      {sets.map((item, idx) => {
                        return (
                          <option
                            key={idx}
                            value={item.receiptSetId}
                            className={classes.selectForm}>
                            {item.name}
                          </option>
                        );
                      })}
                    </TextField>
                  </Td>
                </TableContainer>
              </Grid>
              {/* <Grid item xs={10} className={classes.dfsc}>
                <Grid
                  item
                  xs={10}
                  className={[classes.dfsc, classes.cell].join(" ")}
                />
              </Grid> */}
              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer>
                  <Th>서비스 지역</Th>
                  <Td textfield xs={10} column>
                    <div
                      style={{
                        width: "49%",
                        paddingLeft: "1%",
                        marginBottom: "20px"
                      }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setDialog(true);
                        }}
                        className={classes.detailInfoSaveBtn}
                        style={{ marginTop: 10 }}>
                        추가
                      </Button>
                    </div>

                    <div
                      style={{
                        width: "50%",
                        marginBottom: "20px"
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        maxHeight: 300,
                        overflowY: "auto"
                      }}>
                      {postCodes.map((code, index) => {
                        return (
                          <div
                            key={index.toString()}
                            style={{
                              padding: "0px 1%",
                              justifyContent: "center",
                              boxSizing: "border-box",
                              marginBottom: "10px"
                            }}>
                            <TextField
                              InputProps={{
                                classes: {
                                  input: classes.resize
                                }
                              }}
                              style={{ alignSelf: "stretch" }}
                              inputProps={{
                                style: {
                                  padding: "10px",
                                  color: "#000",
                                  alignSelf: "stretch"
                                }
                              }}
                              value={code}
                              disabled
                              variant="outlined"
                            />
                            <Button
                              onClick={() => {
                                setPostCodes([
                                  ...postCodes.filter(x => x !== code)
                                ]);
                              }}
                              variant="contained"
                              color="default"
                              style={{
                                backgroundColor: "#fff",
                                color: "#555",
                                border: "1px solid #ddd",
                                marginLeft: "20px"
                              }}>
                              삭제
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </Td>
                </TableContainer>
              </Grid>
              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer>
                  <Th>휴대폰번호</Th>
                  <Td textfield>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.resize
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      value={phone}
                      onChange={e => {
                        setPhone(e.target.value);
                      }}
                      size="small"
                    />
                  </Td>
                  <Th>리뷰/평점</Th>
                  <Td>
                    {/* <Typography variant="h5"> */}
                    {parseFloat(data.rating).toFixed(2)}점{/* </Typography> */}
                    {/* <Typography variant="subtitle1"> */}({data.reviewLength}
                    건)
                    {/* </Typography> */}
                  </Td>
                </TableContainer>
              </Grid>

              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer>
                  <Th>반복알림 설정 여부</Th>
                  <Td xs={10} textfield>
                    <Checkbox
                      className={classes.cb}
                      onChange={e => {
                        let checked = e.target.checked;
                        setRepeatNotifi(checked);
                      }}
                      color="default"
                      style={{ color: "black" }}
                      checked={repeatNotifi}
                    />

                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.resize
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="반복알림 주기(분)"
                      type="number"
                      value={repeatNotifiMinute}
                      onChange={e => setRepeatNotifiMinute(e.target.value)}
                    />
                  </Td>
                </TableContainer>
              </Grid>
              {/**repeatNotifi */}

              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer>
                  <Th>입고 상품명 수정</Th>
                  <Td center xs={2} textfield>
                    <Checkbox
                      className={classes.cb}
                      onChange={e => {
                        let checked = e.target.checked;
                        setSetReceiptName(checked);
                      }}
                      color="default"
                      style={{ color: "black" }}
                      checked={setReceiptName}
                    />
                  </Td>
                  <Th>입고 상품가격 수정</Th>
                  <Td center xs={2} textfield>
                    <Checkbox
                      className={classes.cb}
                      onChange={e => {
                        let checked = e.target.checked;
                        setSetReceiptPrice(checked);
                      }}
                      color="default"
                      style={{ color: "black" }}
                      checked={setReceiptPrice}
                    />
                  </Td>
                  <Th>입고 입고수량 수정</Th>
                  <Td center xs={2} textfield>
                    <Checkbox
                      className={classes.cb}
                      onChange={e => {
                        let checked = e.target.checked;
                        setSetReceiptQuantity(checked);
                      }}
                      color="default"
                      style={{ color: "black" }}
                      checked={setReceiptQuantity}
                    />
                  </Td>
                </TableContainer>
              </Grid>

              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer>
                  <Th>주문마감 / 주문중단 / 휴식</Th>
                  <Td xs={10}>
                    <Button
                      onClick={handleRest}
                      color="default"
                      disabled={!working}
                      variant="outlined"
                      style={{
                        borderColor: "#ddd",
                        marginRight: 20
                      }}>
                      {rest ? "휴식 취소" : "휴식 처리"}
                    </Button>
                    기사님 출근시에만 변경 가능합니다.
                  </Td>
                </TableContainer>
              </Grid>
              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer>
                  <Th>간략소개</Th>
                  <Td textfield xs={10}>
                    <TextField
                      multiline
                      InputProps={{
                        classes: {
                          input: classes.resize
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={introduce}
                      onChange={e => setIntroduce(e.target.value)}
                    />
                  </Td>
                </TableContainer>
              </Grid>

              <Grid item xs={10} className={classes.dfsc}>
                <TableContainer last>
                  <Th bottomleft>휴식중 노출 메시지</Th>
                  <Td bottomright xs={10} textfield>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.resize
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={restMessage}
                      onChange={e => setRestMessage(e.target.value)}
                    />
                  </Td>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid
              item
              xs={8}
              className={[
                classes.detailInfoBtnGrid,
                classes.dashboardItem
              ].join(" ")}>
              <Button
                variant="contained"
                onClick={handleDetailInfoSave}
                className={classes.detailInfoSaveBtn}>
                저장
              </Button>
              <Button
                variant="contained"
                onClick={handleDetailInfoClose}
                className={classes.detailInfoListBtn}>
                목록
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Dialog open={withDrawalDialogOpen} onClose={handleWithDrawalDialog}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">
              {deleted ? "복구처리" : "탈퇴처리"}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column"
              }}>
              <Typography variant="subtitle1">
                {deleted ? "복구처리" : "탈퇴처리"} 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setWithDrawalDialogOpen(false)}
                className={classes.cancelBtn}>
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={onClickWithDrawalDialogSaveBtn}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}>
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
      <Dialog
        open={distributorChangeDialogOpen}
        onClose={handleDistributorChangeDialog}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">유통사 변경</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column"
              }}>
              <Typography variant="subtitle1" style={{ color: "red" }}>
                유통사를 변경하시면
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ marginBottom: "20px", color: "red" }}>
                상품카테고리와 상품이 초기화 됩니다.
              </Typography>
              <Typography variant="subtitle1" style={{ color: "red" }}>
                그래도 진행하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => {
                  setDistributorChangeDialogOpen(false);
                  setSelectDistributor(null);
                }}
                className={classes.cancelBtn}>
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={onClickDistributorDialogSaveBtn}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}>
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </Grid>
  );
});
