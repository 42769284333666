import {
  ButtonBase,
  InputBase,
  makeStyles,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import React from "react";
import fonts from "../../libs/fonts";
import qs from "query-string";
import { useHistory, useLocation } from "react-router";
import moment from "moment";
import { SearchOutlined } from "@material-ui/icons";
import useMember from "../../../hooks/useMember";

export default function Search({ data, onSearch, onInit }) {
  const classes = useStyle();
  const { isPartner, distributionId } = useMember();
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);

  const handleChange = (key, value, type, more) => {
    if (type === "date") {
      if (!moment(value).isValid()) {
        return;
      }
    }
    history.replace({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [key]: value,
        ...more,
      }),
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        {data.map((x, i) => {
          return (
            <div className={classes.row} key={i.toString()}>
              {x.map((z, y) => {
                if (isPartner && z.key === "distributionId") {
                  return null;
                }

                return (
                  <div
                    key={z.toString()}
                    className={classes.item}
                    style={{
                      flex:
                        x[y + 1] &&
                        x[y + 1].type === "menu" &&
                        z.type !== "menu"
                          ? 3
                          : 2,
                    }}
                  >
                    <span className={classes.itemLabel}>{z.label}</span>
                    <div className={classes.value}>
                      {z.type === "input" && (
                        <InputBase
                          value={query[z.key] || ""}
                          fullWidth
                          onChange={(e) => handleChange(z.key, e.target.value)}
                          placeholder={z.placeholder}
                          className={classes.input}
                        />
                      )}
                      {z.type === "menu" &&
                        (() => {
                          return (
                            <select
                              value={query[z.key] || ""}
                              onChange={(e) =>
                                handleChange(z.key, e.target.value)
                              }
                              className={classes.menu}
                            >
                              <option value="">전체</option>
                              {z.data?.map((item, index) => {
                                return (
                                  <option value={item.value.toString()}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          );
                        })()}
                      {z.type === "menu2" && (
                        <>
                          <select
                            value={query[z.key] || ""}
                            onChange={(e) =>
                              handleChange(z.key, e.target.value)
                            }
                            className={classes.menu}
                          >
                            <option value="">전체</option>
                            {z.data?.map((item, index) => {
                              return (
                                <option value={item.value.toString()}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>

                          <select
                            value={query[z.key2] || ""}
                            style={{ marginLeft: 10 }}
                            onChange={(e) =>
                              handleChange(z.key2, e.target.value)
                            }
                            className={classes.menu}
                          >
                            <option value="">전체</option>
                            {z.data2?.map((item, index) => {
                              return (
                                <option value={item.value.toString()}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}

                      {z.type === "date" && (
                        <>
                          <input
                            max="2999-12-31"
                            className={classes.date}
                            type="date"
                            value={query[z.key] || ""}
                            onChange={(e) =>
                              handleChange(z.key, e.target.value, z.type)
                            }
                          />
                          <span className={classes.dd} />
                          <input
                            max="2999-12-31"
                            className={classes.date}
                            type="date"
                            value={query[z.key2] || ""}
                            onChange={(e) =>
                              handleChange(z.key2, e.target.value, z.type)
                            }
                          />
                          <div className={classes.buttons}>
                            {[
                              [
                                "오늘",
                                moment().format("YYYY-MM-DD"),
                                moment().format("YYYY-MM-DD"),
                              ],
                              [
                                "1주일",
                                moment()
                                  .subtract(7, "days")
                                  .format("YYYY-MM-DD"),
                                moment().format("YYYY-MM-DD"),
                              ],
                              [
                                "1개월",
                                moment()
                                  .subtract(30, "days")
                                  .format("YYYY-MM-DD"),
                                moment().format("YYYY-MM-DD"),
                              ],
                              [
                                "3개월",
                                moment()
                                  .subtract(90, "days")
                                  .format("YYYY-MM-DD"),
                                moment().format("YYYY-MM-DD"),
                              ],
                            ].map((x, i) => {
                              return (
                                <ButtonBase
                                  onClick={() => {
                                    handleChange(z.key, x[1], z.type, {
                                      [z.key2]: x[2],
                                    });
                                  }}
                                  className={classes.button}
                                  key={i.toString()}
                                >
                                  {x[0]}
                                </ButtonBase>
                              );
                            })}
                          </div>
                        </>
                      )}

                      {z.type === "date-time" && (
                        <>
                          <input
                            max="2999-12-31"
                            className={classes.date}
                            type="date"
                            value={query[z.key] || ""}
                            onChange={(e) =>
                              handleChange(z.key, e.target.value, "date")
                            }
                          />
                          <input
                            autoComplete
                            placeholder="HH:MM"
                            autoSave
                            className={classes.date}
                            style={{ marginLeft: "1rem" }}
                            type="time"
                            value={query[z.key2] || ""}
                            onChange={(e) =>
                              handleChange(z.key2, e.target.value)
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className={classes.sub}>
        <Button onClick={onInit} className={classes.initButton}>
          <Typography className={classes.buttonText}>초기화</Typography>
        </Button>
        <Button onClick={onSearch} className={classes.searchButton}>
          <SearchOutlined style={{ marginRight: 6 }} fontSize={"small"} />
          <Typography className={classes.buttonText}>검색</Typography>
        </Button>
      </div>
    </div>
  );
}

const useStyle = makeStyles({
  searchButton: {
    marginBottom: 14,
    marginLeft: 10,
    borderRadius: 2,
    border: "1px solid #39c15d",
    minWidth: 150,
    fontFamily: fonts.spoqaHanSansNeoBold,
    backgroundColor: "#40cf66",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
    color: "#fff",

    height: 40,
  },
  initButton: {
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoBold,
    marginBottom: 14,
    borderRadius: 2,
    border: "1px solid #dddddd",
    minWidth: 150,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
    height: 40,
  },
  buttonText: {
    fontFamily: fonts.spoqaHanSansNeoBold,
    fontSize: 13,
  },
  date: {
    cursor: "pointer",
    borderRadius: 2,
    border: "1px solid #e9ebee",
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    width: "160px",
  },
  dd: {
    margin: 6,
    backgroundColor: "#a7aeb9",
    height: 2,
    width: 8,
  },
  menu: {
    borderRadius: 2,
    border: "1px solid #e9ebee",
    height: 40,
    flex: 1,
    padding: "10px",
    backgroundColor: "#fff",
  },
  //
  input: {
    border: "1px solid #e9ebee",
    padding: "0px 15px",
    height: 40,
    fontSize: 13,
    backgroundColor: "#fff",
    borderRadius: 4,
  },
  //
  value: {
    marginRight: "70px",
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  sub: {
    minWidth: "40%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "start",
  },
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "16px 30px",
    margin: "40px 0px",
    backgroundColor: "#f7f8f9",
    borderRadius: 5,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  row: {
    alignSelf: "stretch",
    flexDirection: "row",
    display: "flex",
    alignItems: "stretch",
    padding: "14px 0px",
  },
  item: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  itemLabel: {
    fontSize: 15,
    color: "#374553",
    minWidth: "100px",
    fontFamily: fonts.spoqaHanSansNeoBold,
  },
  button: {
    flex: 1,
    color: "#a7aeb9",
    height: 40,
    borderRight: "1px solid #e9ebee",
    fontFamily: fonts.spoqaHanSansNeoRegular,
    backgroundColor: "#fff",
  },
  buttons: {
    border: "1px solid #e9ebee",
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginLeft: 10,
    borderRight: "0px solid #e9ebee",
  },
});
