import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import {
  formatPhoneNumber,
  formatTime,
  numFormat,
} from "../../services/utils.js";
import consts from "../../libs/consts.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  const [distributions] = useDistribution();
  const { data, loading, dataLength, fetchData } = useListPayload({
    endpoint: "/admins/drivers",
  });

  return (
    <TableLayout
      label="기사회원"
      onSearch={fetchData}
      data={[...data]}
      dataLoading={loading}
      dataLength={dataLength}
      onClick={(col) => {
        history.push("/member_management/driver_member_detail", {
          driverId: col.driverId,
        });
      }}
      defaultQuery={{
        page: 1,
        limit: 10,
        start: "",
        end: "",
        keyword: "",
        method: "",
      }}
      search={[
        [
          {
            label: "가입일",
            type: "date",
            key: "start",
            key2: "end",
          },
        ],
        [
          {
            label: "유통사",
            type: "menu",
            key: "distributionId",
            data: distributions,
          },
          {
            label: "근무상태",
            type: "menu",
            key: "workingState",
            data: [
              { label: "근무중", value: "on" },
              { label: "퇴근", value: "off" },
            ],
          },
        ],
        [
          {
            label: "검색",
            type: "input",
            key: "keyword",
            placeholder: "이름, 휴대폰번호, 차량명, 차량번호,서비스지역",
          },
        ],
      ]}
      columns={[
        {
          label: "가입일시",
          render: (col) => <>{formatTime(col.createdAt, "YYYY-MM-DD HH:mm")}</>,
        },
        {
          label: "닉네임",
          key: "nickname",
        },
        {
          label: "배송기사 ID",
          key: "username",
          clickable: true,
        },
        {
          label: "이름",
          key: "name",
        },
        {
          label: "휴대폰번호",
          render: (col) => <>{formatPhoneNumber(col.phone)}</>,
        },
        {
          label: "차량번호",
          key: "carNumber",
        },
        {
          label: "상품 SET",
          key: "receiptSet",
        },
        {
          label: "유통사",
          key: "companyName",
        },
        {
          label: "서비스지역",
          key: "workingArea",
          render: (x) => x.workingArea?.split(",").length || 0,
        },
        {
          label: "근무상태",
          render: (col) => (
            <>
              {col.workState === "on"
                ? "근무중"
                : col.workState === "off"
                ? "퇴근"
                : "출근안함"}
            </>
          ),
        },
        {
          label: "휴식상태",
          render: (col) => <>{col.rest ? "휴식중" : "-"}</>,
        },
      ]}
    />
  );
}
