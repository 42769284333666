/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  CardMedia,
  Icon,
  Dialog,
  TextField,
  ButtonBase,
  Button
} from "@material-ui/core";
import { requestGet, requestPost } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import { numFormat } from "../../services/utils";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px"
  },
  titleGrid: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px"
  },
  bold: {
    fontSize: 32,
    color: "#374553",
    fontFamily: fonts.spoqaHanSansNeoBold,
    fontWeight: "bold"
  },
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  dfcb: {
    display: "flex",
    justifyContent: "center",
    alignItems: "base-line"
  },
  infoGrid: {
    border: "1px solid #DFDFDF",
    backgroundColor: "#efefef",
    marginBottom: "20px"
  },
  infoContent: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px"
  },
  infocell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px"
  },
  select: {
    "& div": {
      backgroundColor: "#fff"
    }
  },
  searchBox: {
    "& input": {
      backgroundColor: "#fff"
    }
  },
  initializationBtn: {
    width: "120px",
    backgroundColor: "#fff",
    border: "1px solid #e9e9e9",
    borderRadius: 0
  },
  divider: {
    width: "100%"
  },
  searchBtn: {
    width: "120px",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#000"
    }
  },
  driverInfoGrid: {
    maxHeight: "800px",
    height: "800px",
    overflowY: "scroll"
  },
  receivingTimeTextField: {
    "& input": {
      textAlign: "center"
    }
  },
  receivingStatusTextField: {
    textAlign: "center",
    "& input": {
      color: "blue",
      textAlign: "center"
    }
  },
  popUpTableGrid: {
    height: "800px",
    marginBottom: "100pxs"
  },
  popupTableCell: {
    textAlign: "center",
    border: "1px solid #dddddd",
    color: "#374553",
    fontSize: 13,
    fontFamily: fonts.malgunGothicBold,
    "& span": {
      color: "#374553",
      fontSize: 13,
      fontFamily: fonts.malgunGothicBold
    }
  },
  radioBtn: {
    marginLeft: "20px"
  },
  label2: {
    fontFamily: fonts.malgunGothicSemiLight,
    fontSize: 13,
    color: "#374553"
  },
  th: {
    "& th": { padding: 0, height: 50, borderTop: "0px" }
  },
  menu: {
    borderRadius: 2,
    border: "1px solid #e9ebee",
    height: 40,
    marginBottom: 10,
    padding: "10px",
    backgroundColor: "#fff"
  },
  label3: {
    fontSize: 13,
    color: "#374553",
    fontFamily: fonts.malgunGothicBold
  },
  ib: {
    flex: 1,
    borderRadius: 2,
    display: "flex",
    paddingLeft: 14,
    border: "1px solid #e9ebee",
    height: 36,
    alignItems: "center",
    backgroundColor: "#f7f8f9",
    fontSize: 13,
    color: "#2069e3",
    fontFamily: fonts.malgunGothicBold
  },
  button: {
    backgroundColor: "#fff",
    // height: 40,
    borderRadius: 2,
    border: "1px solid #dddddd",
    // marginRight: "10px",
    // width: "100px",
    fontSize: 13,
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoMedium,
    padding: "5px",
    marginTop: 5
  }
});

export default function ServiceInventory({
  onClose,
  driverId,
  driverWorkId,
  title,
  phone,
  onFetch,
  orderId
}) {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const classes = useStyles();
  const [data, setData] = useState({});
  const [radioSelectValue, setRadioSelectValue] = useState("normal");
  const [stock, setStock] = useState([]);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    requestGet({
      url: consts.apiUrl + "/drivers/" + driverId + "/works/" + driverWorkId
    })
      .then(x => {
        setData({ ...x });
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  }, [reload]);

  useEffect(() => {
    requestGet({
      url: consts.apiUrl + `/drivers/${driverId}/works/${driverWorkId}/stocks`,
      query: {
        sort:
          radioSelectValue === "stock"
            ? "stock"
            : radioSelectValue === "normal"
            ? ""
            : "stock-reverse"
      }
    })
      .then(d => {
        setProducts([...d]);
        setStock(
          d.map(x => {
            return { driverWorkProductId: x.driverWorkProductId, quantity: "" };
          })
        );
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  }, [radioSelectValue, reload]);

  const onClickClose = () => {
    onClose();
  };
  const handleRadioBtn = e => {
    setRadioSelectValue(e.target.value);
  };

  const handleService = () => {
    const serviceProducts = products.filter(x => x.service);
    if (!serviceProducts.length) {
      return dispatch(popupError({ message: "서비스 상품을 선택해 주세요." }));
    } else if (serviceProducts.filter(x => !x.serviceQuantity).length) {
      return dispatch(
        popupError({ message: "서비스 수량을 올바르게 입력해 주세요." })
      );
    }

    requestPost({
      url: consts.apiUrl + "/admins/orders/" + orderId + "/service",
      body: {
        data: serviceProducts.map(x => ({
          productId: x.productId,
          quantity: x.serviceQuantity
        }))
      }
    })
      .then(() => {
        dispatch(
          popupMessage({
            title: "서비스 등록 완료",
            label: "확인",
            onClick: onFetch
          })
        );
      })
      .catch(e => dispatch(popupError(e)));
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          border: "3px solid #ccc",
          padding: "20px",
          borderRadius: "15px",
          position: "relative"
        }
      }}
      open
      fullWidth
      maxWidth="md"
      onClose={onClickClose}>
      <Grid
        style={{ marginBottom: "1rem" }}
        container
        xs={12}
        justify="space-between">
        <span style={{ fontSize: 18 }}>서비스 등록</span>
        <Icon
          onClick={onClickClose}
          style={{ color: "#b4bac3", cursor: "pointer" }}>
          close
        </Icon>
      </Grid>

      <Grid item xs={12} className={classes.driverInfoGrid}>
        <Grid
          item
          xs={12}
          className={[classes.titleGrid, classes.df].join(" ")}
          style={{ justifyContent: "start", alignItems: "base-line" }}>
          <span className={classes.bold}>{title}</span>
          <span className={classes.label2} style={{ marginLeft: "30px" }}>
            휴대폰: {phone}
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfsc}
          style={{ marginTop: "16px" }}>
          <Grid item xs={1} className={classes.dfsc}>
            <span className={classes.label3}>입고 SET</span>
          </Grid>
          <Grid item xs={2} className={classes.dfcc}>
            <span className={classes.ib}>{data.receiptSet}</span>
          </Grid>
          <Grid
            item
            xs={1}
            className={classes.dfcc}
            style={{ marginLeft: "20px" }}>
            <span className={classes.label3}>품목 수</span>
          </Grid>
          <Grid item xs={2} className={classes.dfcc}>
            <span
              className={classes.ib}
              style={{ color: "#374553", fontFamily: fonts.malgunGothic }}>
              {products.length}
            </span>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ justifyContent: "flex-end" }}>
          <select
            value={radioSelectValue}
            onChange={handleRadioBtn}
            className={classes.menu}>
            <option value={"normal"}>기본노출</option>
            <option value={"stock"}>재고 많은순</option>
            <option value={"stock-reverse"}>재고 적은순</option>
          </select>
        </Grid>
        <Grid
          style={{ textAlign: "center" }}
          item
          xs={12}
          className={classes.popUpTableGrid}>
          <Grid item xs={12}>
            <TableContainer first>
              <Th topleft center xs={2}>
                NO
              </Th>
              <Th center xs={3}>
                상품코드
              </Th>
              <Th center xs={4}>
                상품이미지
              </Th>
              <Th center xs={10}>
                상품명
              </Th>
              <Th center xs={3}>
                공급가
              </Th>
              <Th center xs={2}>
                현재 재고
              </Th>
              <Th topright center xs={3}>
                서비스 수량
              </Th>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              // maxHeight: "500px",
              // overflowY: "auto",
              borderBottom: "1px solid #ccc"
            }}
            className="hidescroll">
            {products.map((col, idx) => {
              return (
                <>
                  <TableContainer
                    style={{
                      backgroundColor: products[idx].service ? "#eee" : "#fff"
                    }}
                    key={idx}
                    last={products.length === idx + 1 ? true : false}>
                    <Td
                      xs={2}
                      center
                      bottomleft={products.length === idx + 1 ? true : false}>
                      {products.length - idx}
                    </Td>
                    <Td xs={3} center>
                      {col.code}
                    </Td>
                    <Td xs={4} center>
                      <div className={classes.dfcc} style={{ width: 100 }}>
                        <CardMedia
                          component="img"
                          image={consts.fileApiUrl + "/" + col.path}
                        />
                      </div>
                    </Td>
                    <Td xs={10} center>
                      {col.name}
                    </Td>
                    <Td xs={3} center>
                      {numFormat(col.price)}원
                    </Td>
                    <Td xs={2} center>
                      {col.stockQuantity}
                    </Td>
                    <Td
                      xs={3}
                      center
                      bottomright={products.length === idx + 1 ? true : false}>
                      <Grid>
                        <TextField
                          value={products[idx].serviceQuantity}
                          onChange={e => {
                            products[idx].serviceQuantity = e.target.value;
                            if (products[idx].serviceQuantity === "0") {
                              return;
                            }
                            if (
                              products[idx].serviceQuantity &&
                              parseInt(products[idx].serviceQuantity) >
                                parseInt(col.stockQuantity)
                            ) {
                              return;
                            }

                            setProducts([...products]);
                          }}
                          style={{ backgroundColor: "#fff" }}
                          fullWidth
                          type={"number"}
                          disabled={!products[idx].service}
                          variant="outlined"
                          size="small"
                        />
                        <ButtonBase
                          onClick={() => {
                            if (String(col.stockQuantity) === "0") return;
                            products[idx].service = !products[idx].service;
                            if (!products[idx].service) {
                              products[idx].serviceQuantity = "";
                            }
                            setProducts([...products]);
                          }}
                          className={classes.button}
                          variant="outlined">
                          {products[idx].service ? "취소" : "서비스 등록"}
                        </ButtonBase>
                      </Grid>
                    </Td>
                  </TableContainer>
                </>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        style={{
          borderTop: "1px solid #ccc",
          backgroundColor: "#fff",
          padding: "1rem"
        }}
        justifyContent="flex-end">
        <Button color="primary" variant="contained" onClick={handleService}>
          서비스 등록하기
        </Button>
      </Grid>
    </Dialog>
  );
}
