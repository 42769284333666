import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Payment from "./Payment.js";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
  },
});

export default function Container() {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Payment />
    </Grid>
  );
}
