/* eslint-disable multiline-ternary */
import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  Paper,
} from "@material-ui/core";
import Editor from "../../components/editor/Editor";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import { requestPost } from "../../services/network";
import consts from "../../libs/consts";
import useRouter from "use-react-router";
import { makeHtmlFile } from "../../services/utils";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  deleteBtn: {
    marginLeft: "auto",
    backgroundColor: "lightgrey",
    color: "#000",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "lightgrey",
    },
  },
  agreeBtn: {
    width: "120px",
    backgroundColor: "#40cf66",
    color: "#fff",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  cancelBtn: {
    width: "120px",
    marginLeft: "10px",
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#c5c8ce",
    marginBottom: "40px",
    marginTop: "27px",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  ckeditor: {
    marginTop: "30px",
    marginBottom: "30px",
    "& .ck-editor__editable_inline": {
      minHeight: "300px",
    },
  },
  tableTitleCell: {
    backgroundColor: "lightgrey",
    borderTop: "2px solid #656565",
    borderRight: "2px solid #656565",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  tableCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    borderTop: "2px solid #656565",
  },
  dialogCancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  dialogSaveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  cb: { color: "#40cf66 !important" },
  resize: { fontSize: "13px" },
});

export default function NoticeAdd() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState("");
  const [title, setTitle] = useState("");
  const { history } = useRouter();
  const editorRef = useRef();
  const [checkValue, setCheckValue] = useState(["driver", "user"]);
  const handleCheckValue = (e) => {
    if (checkValue.includes(e.target.value)) {
      setCheckValue([...checkValue.filter((x) => x !== e.target.value)]);
    } else {
      setCheckValue([...checkValue, e.target.value]);
    }
  };

  const save = async () => {
    dispatch(loadingStart);
    try {
      if (!title) {
        throw "제목을 입력해주세요.";
      } else if (!checkValue.length) {
        throw "구분을 체크해주세요.";
      }
      const form = new FormData();
      form.append("html", makeHtmlFile(editorRef.current.getHtml() || ""));
      form.append("title", title);
      form.append("type", checkValue.length === 2 ? "all" : checkValue[0]);
      await requestPost({ url: consts.apiUrl + "/notices", body: form });
      history.goBack();
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid item xs={12} style={{ marginBottom: "30px" }}>
          <Grid
            item
            xs={12}
            className={classes.dfsc}
            style={{ marginBottom: "10px" }}
          >
            <Typography
              variant="h5"
              style={{
                fontSize: "28px",
                lineHeight: "32px",
                fontFamily: fonts.spoqaHanSansNeoBold,
                color: "#374553",
                paddingTop: "40px",
              }}
            >
              공지사항 등록하기
            </Typography>
          </Grid>
          <Divider
            className={classes.divider}
            style={{
              height: "1px",
              backgroundColor: "#c5c8ce",
              marginBottom: "40px",
              marginTop: "20px",
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "30px" }}>
          <Grid item xs={12} className={classes.df}>
            <TableContainer first>
              <Th topleft>제목</Th>
              <Td topright xs={10} textfield>
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="제목을 입력하세요"
                />
              </Td>
            </TableContainer>
          </Grid>
          <Grid item xs={12} className={classes.df}>
            <TableContainer last>
              <Th bottomleft>구분</Th>
              <Td bottomright xs={10} textfield>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.cb}
                      color="default"
                      checked={checkValue.includes("driver")}
                      value="driver"
                      onChange={handleCheckValue}
                    />
                  }
                  label="기사회원"
                  labelPlacement="end"
                />
                <FormControlLabel
                  style={{ marginLeft: "20px" }}
                  control={
                    <Checkbox
                      className={classes.cb}
                      color="default"
                      checked={checkValue.includes("user")}
                      value="user"
                      onChange={handleCheckValue}
                    />
                  }
                  label="고객회원"
                  labelPlacement="end"
                />
              </Td>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.ckeditor}
          style={{ flexDirection: "column", height: "500px" }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              height: "100%",
            }}
          >
            <Editor ref={editorRef} html={detail} onHtmlChange={setDetail} />
          </div>
          <Grid
            item
            xs={12}
            className={classes.dfcc}
            style={{ marginTop: "30px" }}
          >
            <Button onClick={save} className={classes.agreeBtn}>
              <Typography variant="subtitle1">등록</Typography>
            </Button>
            <Button variant="outlined" className={classes.cancelBtn}>
              <Typography variant="subtitle1">취소</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
