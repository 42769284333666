import { ButtonBase, CircularProgress, makeStyles } from "@material-ui/core";
import {
    Divider,
    Grid,
    Typography,
    Button,
    Paper,
    Dialog,
    CardMedia,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
  } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Search from "../search/Search";
import TitleBar from "../titlebar/TitleBar";
import moment from "moment";
import qs from "query-string";
import { Pagination } from "@material-ui/lab";
import fonts from "../../libs/fonts";
import download from "../../../assets/download.png";

export default function TableTitle({children, xs}) {
    const classes = useStyles();
    
    return(
        <Grid item xs={xs? xs : 2} className={classes.title}>{children}</Grid>
    )
}
const useStyles= makeStyles({
    title:{
        padding: "16px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        paddingLeft: "0px",
        fontSize:"16px",
        color:"#374553",
        fontFamily:fonts.spoqaHanSansNeoBold
    }
});
