import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#000",
  },
  title: {
    color: "#fff",
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DaumPostCode({ onPress, onClose }) {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  const handleComplete = (data) => {
    const { zonecode, address, jibunAddress } = data;

    const addr = address || jibunAddress;

    if (!zonecode || !addr) {
      alert("검색되지 않는 주소입니다.");
    }

    onPress({ address: addr, postCode: zonecode });
    handleClose();
  };

  return (
    <Dialog
      fullScreen
      open
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" className={classes.title}>
            주소검색
          </Typography>
        </Toolbar>
      </AppBar>
      <DaumPostcode height="100%" onComplete={handleComplete} width="100%" />
    </Dialog>
  );
}
