import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import { formatPhoneNumber } from "../../services/utils.js";
import InventoryDetail from "./InventoryDetail.js";
import useMember from "../../../hooks/useMember.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  const [distributions] = useDistribution();
  const { isPartner, distributionId } = useMember();
  const { data, loading, dataLength, fetchData, fetchExcel } = useListPayload({
    excelName: "재고관리",
    endpoint: "/drivers/stocks",
  });
  const [sel, setSel] = useState(null);

  return (
    <>
      {sel && (
        <InventoryDetail
          {...sel}
          onClose={() => {
            setSel(null);
          }}
        />
      )}
      <TableLayout
        label="재고관리"
        onSearch={fetchData}
        data={[...data]}
        dataLoading={loading}
        onExcel={fetchExcel}
        dataLength={dataLength}
        onClick={(item) => {
          setSel({ ...item });
        }}
        defaultQuery={{
          page: 1,
          limit: 10,
          start: "today",
          end: "today",
          keyword: "",
          distributionId: isPartner ? distributionId : "",
          workState: "",
        }}
        search={[
          [
            {
              label: "입고처리일",
              type: "date",
              key: "start",
              key2: "end",
            },
          ],

          [
            !isPartner && {
              label: "유통사",
              type: "menu",
              key: "distributionId",
              data: distributions,
            },
            {
              label: "근무상태",
              type: "menu",
              key: "workState",
              data: [
                { label: "근무중", value: "on" },
                { label: "퇴근", value: "off" },
                { label: "", value: "" },
              ],
            },
          ],
          [
            {
              label: "검색",
              type: "input",
              key: "keyword",
              placeholder: "이름, 핸드폰번호, 아이디, 차량번호, 서비스지역",
            },
            {},
          ],
        ]}
        columns={[
          {
            label: "아이디\n이름",
            render: (col) => (
              <>
                {col.username}
                <br />
                {col.name}
              </>
            ),
          },
          {
            label: "휴대폰번호",
            render: (col) => formatPhoneNumber(col.phone),
          },
          {
            label: "차량번호",
            key: "carNumber",
          },
          {
            label: "상품 SET",
            key: "receiptSet",
            clickable: true,
            flex: 2,
          },
          {
            label: "서비스 지역",
            key: "workingArea",
            render: (x) => x.workingArea?.split(",").length || "0",
          },
          {
            label: "근무상태/재고여부",
            render: (col) => (
              <span
                style={{
                  color: col.workState ? "#39c15d" : "#999",
                }}
              >
                {col.workState ? "근무중" : "퇴근"} /{" "}
                {col.workState ? "보유" : "반납"}
              </span>
            ),
          },
          {
            label: "입고처리",
            key: "startedAt",
          },
          {
            label: "반납처리",
            key: "endedAt",
          },
        ]}
      />
    </>
  );
}
