import React, { useState } from "react";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import useMember from "../../../hooks/useMember.js";
import {
  Button,
  Dialog,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { requestDelete } from "../../services/network.js";
import consts from "../../libs/consts.js";

const useStyles = makeStyles({
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
});

export default function Container() {
  const classes = useStyles();
  const [distributions] = useDistribution();
  const { isPartner, distributionId } = useMember();
  const [sel, setSel] = useState(null);
  const { data, loading, dataLength, fetchData, fetchExcel } = useListPayload({
    excelName: "발주내역관리",
    endpoint: "/distributions/productorders",
  });

  const handleDeleteHistory = (id) => (e) => {
    setSel(null);
    requestDelete({
      url: consts.apiUrl + "/distributions/productorders/" + id,
    })
      .then(fetchData)
      .catch((e) => alert(e.message || e));
  };

  return (
    <>
      <TableLayout
        label="발주내역관리"
        onSearch={fetchData}
        data={[...data]}
        dataLoading={loading}
        onExcel={fetchExcel}
        dataLength={dataLength}
        defaultQuery={{
          page: 1,
          limit: 10,
          start: "today",
          end: "today",
          keyword: "",
          distributionId: isPartner ? distributionId : "",
        }}
        search={[
          [
            {
              label: "입고처리일",
              type: "date",
              key: "start",
              key2: "end",
            },
          ],
          [
            !isPartner && {
              label: "유통사",
              type: "menu",
              key: "distributionId",
              data: distributions,
            },
            {},
          ],
          [
            {
              label: "검색",
              type: "input",
              key: "keyword",
              placeholder: "유통사, 기사명, 기사 ID, 상품코드, 상품이름",
            },
            {},
          ],
        ]}
        columns={[
          {
            label: "유통사",
            key: "companyName",
          },
          {
            label: "발주일자",
            key: "createdAt",
            type: "date",
          },
          {
            label: "배송기사 ID",
            key: "username",
          },
          {
            label: "상품코드",
            key: "code",
          },
          {
            label: "카테고리",
            key: "category",
          },
          {
            label: "발주상품",
            key: "name",
          },
          {
            label: "공급가",
            key: "price",
          },
          {
            label: "발주수량",
            key: "quantity",
          },
          {
            label: "발주금액",
            key: "category",
            render: (x) => parseInt(x.quantity) * parseInt(x.price),
          },
          {
            label: "삭제",
            render: (x) => (
              <Button
                onClick={() => setSel(x.driverWorkProductOLId)}
                variant="outlined"
                color="primary"
                size="small"
              >
                삭제
              </Button>
            ),
          },
        ]}
      />

      <Dialog open={Boolean(sel)} onClose={() => setSel(null)}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">삭제처리</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                삭제처리 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setSel(null)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={handleDeleteHistory(sel)}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
}
