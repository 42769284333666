import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import fonts from "../../libs/fonts";

export default function TitleBar({ label, buttons }) {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <span>{label}</span>
      {buttons &&
        buttons.map((x, i) => {
          return (
            <ButtonBase
              className={classes.button}
              onClick={x.onClick}
              key={i.toString()}
            >
              {x.label}
            </ButtonBase>
          );
        })}
    </div>
  );
}

const useStyle = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& span": {
      fontSize: "28px",
      lineHeight: "32px",
      fontFamily: fonts.spoqaHanSansNeoBold,
      color: "#374553",
    },
    padding: "20px 0px",
    borderBottom: "1px solid #c5c8ce",
  },
  button: {
    height: 40,
    borderRadius: 2,
    marginRight: "10px",
    width: "100px",
    backgroundColor: "#39c15d",
    color: "#fff",
    fontFamily: fonts.spoqaHanSansNeoMedium,
    marginBottom:"-8px"
  },
});
