import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import { formatTime } from "../../services/utils.js";
import { Grid, Typography, Paper, Divider, Tabs, Tab } from "@material-ui/core";
import { requestGet } from "../../services/network.js";
import consts from "../../libs/consts.js";
import { popupError } from "../../redux/popup/PopupActions.js";
import qs from "query-string";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  addBtn: {
    marginLeft: "auto",
    backgroundColor: "#656565",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  df: {
    display: "flex",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignContent: "center",
  },
  divider: {
    width: "100%",
    height: "1px",
    color: "#ddd",
    backgroundColor: "#ddd",
  },
});

export default function Container({ history, location }) {
  const classes = useStyles();
  const {
    data,
    query,
    loading,
    dataLength,
    fetchData,
    clearData,
  } = useListPayload({
    endpoint: "/inquiries",
    triggers: ["type"],
  });

  const [types, setTypes] = useState([]);
  const [ut, setUt] = useState(0);
  const [dt, setDt] = useState(0);
  useEffect(() => {
    requestGet({
      url: consts.apiUrl + "/categories",
      query: { type: "inquiry" },
    })
      .then((x) => {
        setTypes([...x]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });

    requestGet({
      url: consts.apiUrl + "/inquiries",
      query: { ...query, type: "user" },
    })
      .then((x) => {
        setUt(x.totalCount);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
    requestGet({
      url: consts.apiUrl + "/inquiries",
      query: { ...query, type: "driver" },
    })
      .then((x) => {
        setDt(x.totalCount);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  }, []);

  return (
    <TableLayout
      label="고객문의"
      onSearch={fetchData}
      data={[...data]}
      dataLength={dataLength}
      dataLoading={loading}
      onClick={(col) => {
        history.push("/customer_service/detail", {
          inquiryId: col.inquiryId,
        });
      }}
      defaultQuery={{
        page: 1,
        limit: 10,
        type: "user",
        keyword: "",
        categoryId: "",
        reply: "",
      }}
      renderTab={
        <Grid item xs={12} style={{ flexBasis: 0, marginTop: 20 }}>
          <Tabs
            value={query.type}
            TabIndicatorProps={{
              style: {
                color: "black",
                backgroundColor: "black",
                borderColor: "black",
              },
            }}
            indicatorColor="primary"
            textColor="inherit"
            onChange={(e, v) => {
              clearData();
              history.replace({
                pathname: location.pathname,
                search: qs.stringify({
                  page: 1,
                  limit: 10,
                  keyword: "",
                  categoryId: "",
                  reply: "",
                  type: v,
                }),
              });
            }}
          >
            {[
              ["user", "일반회원", ut],
              ["driver", "기사회원", dt],
            ].map((x, i) => {
              return (
                <Tab
                  key={i.toString()}
                  value={x[0]}
                  label={
                    <Grid item xs={12} className={classes.df}>
                      <Typography variant="subtitle1">{x[1]}</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold", marginLeft: "5px" }}
                      >
                        {x[2]}
                      </Typography>
                    </Grid>
                  }
                />
              );
            })}
          </Tabs>
          <Divider className={classes.divider} />
        </Grid>
      }
      search={[
        [
          {
            label: "검색",
            placeholder: "이름, 아이디",
            type: "input",
            key: "keyword",
          },
        ],
        query.type === "user"
          ? [
              {
                label: "문의유형",
                type: "menu",
                key: "categoryId",
                data: types.map((x) => ({
                  label: x.label,
                  value: x.categoryId.toString(),
                })),
              },
              {
                label: "답변여부",
                type: "menu",
                key: "reply",
                data: [
                  { label: "완료", value: "1" },
                  { label: "미완료", value: "0" },
                ],
              },
            ]
          : [
              {
                label: "답변여부",
                type: "menu",
                key: "reply",
                data: [
                  { label: "완료", value: "1" },
                  { label: "미완료", value: "0" },
                ],
              },
              {},
            ],
      ]}
      columns={[
        {
          label: "등록일시",
          render: (col) => <>{formatTime(col.createdAt, "YYYY-MM-DD HH:mm")}</>,
        },
        {
          label: "문의유형",
          key: "label",
        },
        {
          label: "문의내용",
          key: "title",
          clickable: true,
        },
        {
          label: "이름",
          key: "name",
        },
        {
          label: "이메일(아이디)",
          key: "username",
        },
        {
          label: "답변상태",
          render: (col) => <>{col.replyAt ? "완료" : "미완료"}</>,
        },
      ]}
    />
  );
}
