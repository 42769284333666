import queryString from "query-string";
import consts from "../libs/consts";
const axios = require("axios").default;

export const requestPost = async ({ url, body = {}, headers }) => {
  try {
    const response = await axios.post(url, body, {
      headers: {
        ...headers,
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    throw handleNetworkError(error);
  }
};

export const requestFile = async ({ put, patch, url, form, headers }) => {
  try {
    const func = patch ? axios.patch : put ? axios.put : axios.post;
    const response = await func(url, form, {
      headers: {
        ...headers,
        "content-type": "multipart/form-data",
        "Content-Type": "multipart/form-data",
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    throw handleNetworkError(error);
  }
};

export const requestDelete = async ({ url, headers, query = {} }) => {
  try {
    const qs = queryString.stringify(query);
    const response = await axios.delete(`${url}?${qs}`, {
      headers: {
        ...headers,
        authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    throw handleNetworkError(error);
  }
};

export const requestPut = async ({ url, body = {}, headers }) => {
  try {
    const response = await axios.put(url, body, {
      headers: {
        ...headers,
        authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    throw handleNetworkError(error);
  }
};

export const requestGet = async ({ url, headers, query = {} }) => {
  try {
    if (url.startsWith(consts.apiUrl)) {
      if (!headers) {
        headers = {};
      }
      headers.authorization = `Bearer ${localStorage.getItem("token")}`;
    }
    const qs = queryString.stringify(query);
    const response = await axios.get(`${url}?${qs}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        ...headers
      }
    });
    return response.data;
  } catch (error) {
    throw handleNetworkError(error);
  }
};

export const requestPatch = async ({ url, body = {}, headers }) => {
  try {
    const response = await axios.patch(url, body, {
      headers: {
        ...headers,
        authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    throw handleNetworkError(error);
  }
};

function handleNetworkError(error) {
  console.log(error);
  let errorObj;
  if (error.response) {
    const { data, status } = error.response;
    if (status === 401) {
      localStorage.clear("token");
      alert("접근 권한이 없습니다. 다시 로그인해 주세요.");
      window.location.href = "/signin";
    }
    if (typeof data === "string") {
      errorObj = { message: data };
    } else {
      errorObj = data;
    }
  } else {
    errorObj = error;
  }
  return errorObj;
}
