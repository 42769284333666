import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import DaumPostcode from "react-daum-postcode";
import TableContainer from "../../table/TableContainer";
import Th from "../../table/Th";
import Td from "../../table/Td";
import fonts from "../../../libs/fonts";
const useStyles = makeStyles({
  container: {
    marginBottom: "70px",
    marginTop: "50px",

    alignSelf: "center",
    width: "90%",
  },
  divider: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  bold: {
    fontWeight: "bold",
  },
  text: {
    marginLeft: "30px",
    width: 100,
  },
  controlGrid: {
    border: "2px solid #e9e9e9",
  },
  firstGrid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // height: "50px",
  },
  secondGrid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // height: "50px",
  },
  thirdGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
  radioBtn: {
    marginLeft: "30px",
    color: "#40cf66 !important",
  },
  initializationBtn: {
    width: "120px",
    backgroundColor: "#fff",
    border: "1px solid #e9e9e9",
    borderRadius: 2,
  },
  searchBtn: {
    width: "120px",
    backgroundColor: "#40cf66",
    color: "#fff",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  dateGrid: {
    display: "flex",
    justifyContent: "flex-start",
  },
  searchGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  searchBox: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  textField: {
    marginLeft: "40px",
    "& div": {
      backgroundColor: "#fff",
    },
  },
  select: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cb: { color: "#40cf66 !important" },
});

export default function ControlBox({
  driver,
  member,
  onSearch,
  onChange,
  data,
  hideMemberCheck,
}) {
  const classes = useStyles();
  const handlePrevDate = (e) => {
    onChange({
      start: e.target.value,
    });
  };
  const handleDaumpostcode = (d) => {
    const { zonecode, address, jibunAddress } = d;

    const addr = address || jibunAddress;

    if (!zonecode || !addr) {
      alert("검색되지 않는 주소입니다.");
    }
    onChange({
      postCodes: data.postCodes.includes(zonecode)
        ? data.postCodes
        : [...data.postCodes, zonecode],
    });
  };
  const handleNextDate = (e) => {
    onChange({
      end: e.target.value,
    });
  };
  const handleSearchQuery = (e) => {
    onChange({
      keyword: e.target.value,
    });
  };
  const handleSearchAddress = (e) => {
    onChange({
      address: e.target.value,
    });
  };
  const handleRadioBtn = (e) => {
    onChange({
      method: e.target.value,
    });
  };
  const handleRadioBtn2 = (e) => {
    onChange({
      memberType: e.target.value,
    });
  };
  const onClickInitialBtn = () => {
    if (data.memberType === "driver") {
      onChange({
        start: "",
        end: "",
        keyword: "",
        page: 1,
        limit: 10,
        postCodes: [],
        method: "",
      });
    } else {
      onChange({
        start: "",
        end: "",
        keyword: "",
        address: "",
        page: 1,
        limit: 10,
        method: "",
      });
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.firstGrid}>
        <TableContainer first>
          <Th topleft>가입일</Th>
          <Td textfield>
            <input
              type="date"
              value={data.start}
              onChange={handlePrevDate}
              style={{ marginRight: "10px" }}
            />
            <span> ~ </span>
            <input
              type="date"
              value={data.end}
              onChange={handleNextDate}
              style={{ marginLeft: "10px" }}
            />
          </Td>
          <Th>검색</Th>
          <Td topright textfield>
            <TextField
              className={classes.searchBox}
              inputProps={{ style: { fontSize: 13 } }}
              variant="outlined"
              size="small"
              placeholder={
                data.memberType === "driver"
                  ? "이름, 휴대폰번호, 차량명, 차량번호, 서비스지역"
                  : "이름, 이메일(ID), 휴대폰번호"
              }
              fullWidth
              value={data.keyword}
              onChange={handleSearchQuery}
            />
          </Td>
        </TableContainer>
      </Grid>

      {!hideMemberCheck && (
        <Grid item xs={12} className={classes.secondGrid}>
          <TableContainer>
            <Th>회원구분</Th>
            <Td textfield xs={10}>
              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={data.memberType === "member"}
                    onChange={handleRadioBtn2}
                    value="member"
                  />
                }
                disabled={!member}
                label={<Typography variant="subtitle2">고객</Typography>}
                labelPlacement="end"
              />

              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={data.memberType === "driver"}
                    onChange={handleRadioBtn2}
                    value="driver"
                  />
                }
                disabled={!driver}
                label={
                  <Typography variant="subtitle2" style={{ color: "#374553" }}>
                    기사
                  </Typography>
                }
                labelPlacement="end"
              />
            </Td>
          </TableContainer>
        </Grid>
      )}
      {data.memberType !== "driver" && (
        <Grid item xs={12} className={classes.secondGrid}>
          <TableContainer>
            <Th>가입유형</Th>
            <Td textfield xs={10}>
              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={data.method === "" || !data.method}
                    onChange={handleRadioBtn}
                    value=""
                  />
                }
                label={<Typography variant="subtitle2">전체</Typography>}
                labelPlacement="end"
              />

              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={data.method === "email"}
                    onChange={handleRadioBtn}
                    value="email"
                  />
                }
                label={<Typography variant="subtitle2">이메일</Typography>}
                labelPlacement="end"
              />

              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={data.method === "google"}
                    onChange={handleRadioBtn}
                    value="google"
                  />
                }
                label={<Typography variant="subtitle2">구글</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={data.method === "kakaotalk"}
                    onChange={handleRadioBtn}
                    value="kakaotalk"
                  />
                }
                label={<Typography variant="subtitle2">카카오톡</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={data.method === "naver"}
                    onChange={handleRadioBtn}
                    value="naver"
                  />
                }
                label={<Typography variant="subtitle2">네이버</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="default"
                    className={classes.radioBtn}
                    checked={data.method === "apple"}
                    onChange={handleRadioBtn}
                    value="apple"
                  />
                }
                label={<Typography variant="subtitle2">애플</Typography>}
                labelPlacement="end"
              />
            </Td>
          </TableContainer>
        </Grid>
      )}
      {data.memberType !== "driver" && (
        <Grid item xs={12} className={classes.secondGrid}>
          <TableContainer last>
            <Th bottomleft>주소지</Th>
            <Td textfield xs={10} bottomright>
              <TextField
                className={classes.searchBox}
                inputProps={{ style: { fontSize: 13 } }}
                style={{ width: 400 }}
                variant="outlined"
                size="small"
                placeholder="',' OR 검색  ex) 고양,잠실,해운대"
                fullWidth
                value={data.address}
                onChange={handleSearchAddress}
              />
            </Td>
          </TableContainer>
        </Grid>
      )}

      {data.memberType === "driver" && (
        <Grid
          item
          xs={12}
          className={classes.secondGrid}
          style={{ height: "auto" }}
        >
          <TableContainer last>
            <Th bottomleft>우편번호</Th>
            <Td xs={8} textfield>
              <DaumPostcode
                animation
                height="400px"
                style={{ display: "flex" }}
                onComplete={handleDaumpostcode}
                width="100%"
              />
            </Td>
            <Td xs={2} textfield bottomright>
              {data.postCodes.map((code, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      style={{ alignSelf: "stretch", marginRight: 20 }}
                      inputProps={{
                        style: {
                          padding: "10px",
                          color: "#000",
                          backgroundColor: "#fff",
                          alignSelf: "stretch",
                        },
                      }}
                      value={code}
                      disabled
                      variant="outlined"
                      key={index.toString()}
                    />
                    <Button
                      onClick={() => {
                        onChange({
                          postCodes: [
                            ...data.postCodes.filter((x) => x !== code),
                          ],
                        });
                      }}
                      variant="contained"
                      color="default"
                      style={{ backgroundColor: "#000", color: "#fff" }}
                    >
                      삭제
                    </Button>
                  </div>
                );
              })}
            </Td>
          </TableContainer>
        </Grid>
      )}
      <Grid item xs={12} className={classes.thirdGrid}>
        <div
          style={{ width: "300px", display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="outlined"
            className={classes.initializationBtn}
            onClick={onClickInitialBtn}
          >
            <Typography
              style={{ fontFamily: fonts.spoqaHanSansNeoBold, fontSize: 14 }}
              variant="subtitle1"
            >
              초기화
            </Typography>
          </Button>
          <Button
            className={classes.searchBtn}
            onClick={onSearch}
            style={{ marginLeft: "20px" }}
          >
            <Typography
              style={{ fontFamily: fonts.spoqaHanSansNeoBold, fontSize: 14 }}
              variant="subtitle1"
            >
              검색
            </Typography>
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
