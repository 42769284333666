/* eslint-disable multiline-ternary */
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import NavPanel from "../../nav/NavPanel.js";
import SidePanel from "../../nav/sub/SidePanel.js";
import CategoryManagement from "./CategoryManagement.js";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
  },
});

export default function Container() {
  const classes = useStyles();

  const [selectNavItem, setSelectNavItem] = useState("product_management");
  const [selectMenu, setSelectMenu] = useState("category_management");

  return (
    <Grid container className={classes.container}>
      <CategoryManagement />
    </Grid>
  );
}
