import { ButtonBase, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Search from "../search/Search";
import TitleBar from "../titlebar/TitleBar";
import moment from "moment";
import qs from "query-string";
import { Pagination } from "@material-ui/lab";
import fonts from "../../libs/fonts";
import download from "../../../assets/download.png";

export default function TableContainer({children, last,style, onClick, first, bottomleft, bottomright, checked}) {
    const classes = useStyles();
    
    return(
        <Grid onClick={onClick} item xs={12}
        className={
            classes.root
            + " " + 
            (last && classes.last)
            + " " + 
            (first && classes.first)
            + " " + 
            (bottomleft && classes.bottomleft)
            + " " + 
            (bottomright && classes.bottomright)
            + " " + 
            (checked && classes.checked)
        }
            style={style}>
            {children}
        </Grid>
    )
}
const useStyles= makeStyles({
    root:{
        borderLeft: "1px solid #dddddd",
        display:"flex",
        
    },
    last:{
        borderBottom: "1px solid #dddddd",
        borderBottomLeftRadius:"5px",
        borderBottomRightRadius:"5px"
    },
    first:{
        borderTopLeftRadius:"5px"
    },
    bottomleft:{
        borderBottomLeftRadius:"5px"
    },
    bottomright:{
        borderBottomRightRadius:"5px"
    },
    checked:{
        backgroundColor:"#f5fcf5"
    }
});