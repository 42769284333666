let isLocal = false;
let isDev = false;
export default {
  apiUrl: isLocal
    ? "http://localhost:8000/v2/adm"
    : isDev
    ? "https://dev-api.hoodtruck.co.kr/v2/adm"
    : "https://api.hoodtruck.co.kr/v2/adm",
  fileApiUrl: "https://s3.ap-northeast-2.amazonaws.com/file.doongdoong.com",
  defaultQuery: {
    page: 1,
    limit: 10,
  },
};
