import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";
import fonts from "../../libs/fonts";
import {
  Divider,
  Grid,
  Typography,
  Button,
  Paper,
  Dialog,
  CardMedia,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
const useStyles = makeStyles({
  bold: {
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "center",
    border: "1px solid #000",
    fontSize: "13px",
    fontFamily: fonts.malgunGothicBold,
    color: "#374553",
    // fontWeight:"bold",
  },
  tableCell2: {
    textAlign: "center",
    border: "1px solid #dddddd",
    fontSize: "13px",
    fontFamily: fonts.malgunGothic,
    color: "#222222",
  },
  tableRow: {
    backgroundColor: "#f7f8f9",
    borderRadius: "5px",
  },
  title: {
    padding: "16px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: "0px",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#374553",
    fontFamily: fonts.spoqaHanSansNeoBold,
  },
  topleft: {
    borderTopLeftRadius: "5px",
  },
  topright: {
    borderTopRightRadius: "5px",
  },
  bottomleft: {
    borderBottomLeftRadius: "5px",
  },
  bottomright: {
    borderBottomRightRadius: "5px",
  },
});

export default function History({ style, title, data }) {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      style={{ marginLeft: "20px", marginBottom: "30px", ...style }}
    >
      <Grid item xs={12}>
        {Boolean(title) ? (
          <Typography variant="subtitle1" className={classes.title}>
            {title}
          </Typography>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        <TableContainer first last={data?.length === 0 ? true : false}>
          <Th center topleft bottomleft={data?.length === 0 ? true : false}>
            구분
          </Th>
          <Th xs={4} center>
            일시
          </Th>
          <Th center>작업자</Th>
          <Th center>소요시간</Th>
          <Th
            xs={6}
            center
            topright
            bottomright={data?.length === 0 ? true : false}
          >
            위치
          </Th>
        </TableContainer>
        <Grid item xs={12}>
          {data?.map((x, i) => (
            <TableContainer
              last={data?.length === i + 1 ? true : false}
              key={i.toString()}
            >
              {x?.map((y, z) => {
                return (
                  <Td
                    center
                    xs={
                      z === 0
                        ? 2
                        : z === 1
                        ? 4
                        : z === 2
                        ? 2
                        : z === 3
                        ? 2
                        : z === 4
                        ? 6
                        : null
                    }
                    bottomleft={data?.length === i + 1 ? true : false}
                    bottomright={data?.length === i + 1 ? true : false}
                    key={z.toString()}
                  >
                    {y}
                  </Td>
                );
              })}
            </TableContainer>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
