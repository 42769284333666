import React, { useState, useEffect } from "react";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import ChartPanel from "./ChartPanel";
import Member from "./Member";
import Delivery from "./Delivery";
import { requestGet } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingBottom: "50px",
  },
  titleGrid: {
    height: "50px",
  },
  divider: {
    width: "100%",
    height: "3px",
    backgroundColor: "#7C7878",
    marginBottom: "20px",
  },
  dashboardItem: {
    marginBottom: "30px",
  },
  bold: {
    fontWeight: "bold",
  },
  text: {
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  dateRowGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dateGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "30px",
  },
  tableGrid: {
    marginTop: "10px",
    textAlign: "center",
  },
  tableCell: {
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "2px solid #000",
    borderCollapse: "separate",
  },
  salesSelectGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  companySelect: {
    width: "150px",
    textAlign: "center",
  },
  radioForm: {
    display: "flex",
    justifyContent: "flex-end",
  },
  radioBtn: {
    marginLeft: "30px",
  },
  carInfoGrid: {
    display: "flex",
  },
  carInfoSubGrid: {
    display: "flex",
    height: "100px",
    justifyContent: "flex-start",
    alignItems: "space-around",
    textAlign: "center",
  },
  carInfoSubCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
  },
  df: {
    display: "flex",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dffec: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  box: {
    border: "2px solid #656565",
    display: "flex",
    flexDirection: "column",
    width: "200px",
    height: "200px",
    "&+&": {
      marginLeft: "20px",
    },
  },
  boxHeader: {
    height: "50px",
    borderBottom: "2px solid #656565",
  },
  boxContent: {
    backgroundColor: "#BABABA",
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
});

export default function Container() {
  const classes = useStyles();
  const [ds, setDs] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/distributions" })
      .then((x) => {
        setDs([...x.results]);
      })
      .catch((x) => {
        dispatch(popupError(x));
      });
  }, []);
  const fakeDataArr = (length) => {
    const itemArr = [];
    let item;
    for (let i = 0; i < length; i++) {
      item = {
        name: "item" + (i + 1),
        value: parseInt(Math.random() * 100 + 1),
      };
      itemArr.push(item);
    }
    return itemArr;
  };

  const [deliveryPrevDate, setDeliveryPrevDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [deliveryNextDate, setDeliveryNextDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [orderPrevDate, setOrderPrevDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [orderNextDate, setOrderNextDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [salesPrevDate, setSalesPrevDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [salesNextDate, setSalesNextDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [tableDeliveryPrevDate, setTableDeliveryPrevDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [tableDeliveryNextDate, setTableDeliveryNextDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [salesSelectValue, setSalesSelectValue] = useState("item1");
  const [radioSelectValue, setRadioSelectValue] = useState("delivery_count");

  const [distributor, setDistributor] = useState("전체");
  const [order, setOrder] = useState("일반");

  const handleDliveryPrevDate = (e) => {
    setDeliveryPrevDate(e.target.value);
  };

  const handleDliveryNextDate = (e) => {
    setDeliveryNextDate(e.target.value);
  };

  const handleOrderPrevDate = (e) => {
    setOrderPrevDate(e.target.value);
  };

  const handleOrderNextDate = (e) => {
    setOrderNextDate(e.target.value);
  };

  const handleSalesPrevDate = (e) => {
    setSalesPrevDate(e.target.value);
  };

  const handleSalesNextDate = (e) => {
    setSalesNextDate(e.target.value);
  };

  const handleDistributor = (e) => {
    setDistributor(e.target.value);
  };

  const handleOrder = (e) => {
    setOrder(e.target.value);
  };

  return <span>대쉬보드는 모든 로직 구현이후 최종적으로 구현됩니다.</span>;
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {/* 대분류 */}
        <Grid item xs={12} className={classes.titleGrid}>
          <Typography variant="h5" className={classes.bold}>
            현황
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        {/*      <Grid item xs={12} className={classes.dashboardItem}>
          <Grid item xs={12} className={classes.dateRowGrid}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.bold}>
                배송 현황
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.dateGrid}>
              <Typography variant="subtitle2">유통사:</Typography>
              <TextField
                select
                size="small"
                variant="standard"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  width: "120px",
                }}
                value={distributor}
                onChange={handleDistributor}
              >
                <option value="전체" className={classes.selectForm}>
                  전체
                </option>
              </TextField>
              <input
                type="date"
                value={deliveryPrevDate}
                onChange={handleDliveryPrevDate}
              />
              <span> ~ </span>
              <input
                type="date"
                value={deliveryNextDate}
                onChange={handleDliveryNextDate}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.tableGrid}>
            <Table>
              <TableHead>
                <TableRow>
                  {(() => {
                    return fakeDataArr(10).map((col, idx) => {
                      return (
                        <TableCell
                          className={classes.tableCell}
                          style={{ backgroundColor: "#e9e9e9" }}
                          key={idx}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.bold}
                          >
                            {col.name}
                          </Typography>
                        </TableCell>
                      );
                    });
                  })()}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {(() => {
                    return fakeDataArr(10).map((col, idx) => {
                      return (
                        <TableCell className={classes.tableCell} key={idx}>
                          <Typography
                            variant="subtitle2"
                            className={classes.bold}
                          >
                            {col.value}
                          </Typography>
                        </TableCell>
                      );
                    });
                  })()}
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid> */}

        {/*
	
	
        <Grid
          item
          xs={12}
          className={[classes.dashboardItem, classes.df].join(" ")}
        >
          <div className={classes.box}>
            <div className={[classes.boxHeader, classes.dfcc].join(" ")}>
              <Typography variant="h5" className={classes.text}>
                전체 차량
              </Typography>
            </div>
            <div
              className={[classes.boxContent, classes.dfcc].join(" ")}
              style={{ flex: 1 }}
            >
              <Typography variant="h5" className={classes.text}>
                120
              </Typography>
            </div>
          </div>
          <div className={classes.box}>
            <div className={[classes.boxHeader, classes.dfcc].join(" ")}>
              <Typography variant="h5" className={classes.text}>
                운영 차량
              </Typography>
            </div>
            <div
              className={[classes.boxContent, classes.dfcc].join(" ")}
              style={{ flex: 1 }}
            >
              <Typography variant="h5" className={classes.text}>
                110
              </Typography>
            </div>
          </div>
          <div className={classes.box}>
            <div className={[classes.boxHeader, classes.dfcc].join(" ")}>
              <Typography variant="h5" className={classes.text}>
                휴무
              </Typography>
            </div>
            <div
              className={[classes.boxContent, classes.dfcc].join(" ")}
              style={{ flex: 1 }}
            >
              <Typography variant="h5" className={classes.text}>
                10
              </Typography>
            </div>
          </div>
          <div className={classes.box}>
            <div className={[classes.boxHeader, classes.dfcc].join(" ")}>
              <Typography variant="h5" className={classes.text}>
                평균 배송 소요
              </Typography>
            </div>
            <div
              className={[classes.boxContent, classes.dfcc].join(" ")}
              style={{ flex: 1 }}
            >
              <Typography variant="h5" className={classes.text}>
                15.3분
              </Typography>
            </div>
          </div>
          <div className={classes.box}>
            <div className={[classes.boxHeader, classes.dfcc].join(" ")}>
              <Typography variant="h5" className={classes.text}>
                차량당 평균 주문
              </Typography>
            </div>
            <div
              className={[classes.boxContent, classes.dfcc].join(" ")}
              style={{ flex: 1 }}
            >
              <Typography variant="h5" className={classes.text}>
                15건/대
              </Typography>
            </div>
          </div>
          <div className={classes.box}>
            <div className={[classes.boxHeader, classes.dfcc].join(" ")}>
              <Typography variant="h5" className={classes.text}>
                객단가
              </Typography>
            </div>
            <div
              className={[classes.boxContent, classes.dfcc].join(" ")}
              style={{ flex: 1 }}
            >
              <Typography variant="h5" className={classes.text}>
                12,050
              </Typography>
            </div>
          </div>
          <div className={classes.box}>
            <div
              className={[classes.boxHeader, classes.dfcc].join(" ")}
              style={{ flexDirection: "column" }}
            >
              <Typography variant="h5" className={classes.text}>
                차량당 평균
              </Typography>
              <Typography variant="h5" className={classes.text}>
                운영상품수
              </Typography>
            </div>
            <div
              className={[classes.boxContent, classes.dfcc].join(" ")}
              style={{ flex: 1 }}
            >
              <Typography variant="h5" className={classes.text}>
                121개
              </Typography>
            </div>
          </div>
        </Grid>*/}

        {/* 주문현황 <Grid item xs={12} className={classes.dashboardItem}>
          <Grid item xs={12} className={classes.dateRowGrid}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.bold}>
                주문 현황
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.dateGrid}>
              <Typography variant="subtitle2">주문건:</Typography>
              <TextField
                select
                size="small"
                variant="standard"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  width: "120px",
                }}
                value={order}
                onChange={handleOrder}
              >
                <option value="일반" className={classes.selectForm}>
                  일반
                </option>
              </TextField>
              <input
                type="date"
                value={orderPrevDate}
                onChange={handleOrderPrevDate}
              />
              <span> ~ </span>
              <input
                type="date"
                value={orderNextDate}
                onChange={handleOrderNextDate}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ChartPanel />
          </Grid>
        </Grid> */}

        {/* 매출현황 */}
        {/*
	<Grid item xs={12} className={classes.dashboardItem}>
          <Grid item xs={12} className={classes.dateRowGrid}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.bold}>
                주문 / 판매 현황
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.dateGrid}>
              <input
                type="date"
                value={salesPrevDate}
                onChange={handleSalesPrevDate}
              />
              <span> ~ </span>
              <input
                type="date"
                value={salesNextDate}
                onChange={handleSalesNextDate}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.tableGrid}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    style={{ backgroundColor: "#e9e9e9" }}
                  >
                    유통사
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ backgroundColor: "#e9e9e9" }}
                  >
                    운영차량
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ backgroundColor: "#e9e9e9" }}
                  >
                    주문건수
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ backgroundColor: "#e9e9e9" }}
                  >
                    매출
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ backgroundColor: "#e9e9e9" }}
                  >
                    객단가
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ backgroundColor: "#e9e9e9" }}
                  >
                    일 평균 배송건수
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ backgroundColor: "#e9e9e9" }}
                  >
                    평균 배송소요시간
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(() => {
                  const cell = fakeDataArr(7).map((col, idx) => {
                    return (
                      <TableCell key={idx} className={classes.tableCell}>
                        {col.value}
                      </TableCell>
                    );
                  });

                  return fakeDataArr(5).map((col, idx) => {
                    return <TableRow key={idx}>{cell}</TableRow>;
                  });
                })()}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
{
	*/}
        {/* 
        <Delivery ds={ds} />
		
		*/}
        <Member />
      </Grid>
    </Grid>
  );
}
