import { ButtonBase, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Search from "../search/Search";
import TitleBar from "../titlebar/TitleBar";
import moment from "moment";
import qs from "query-string";
import { Pagination } from "@material-ui/lab";
import fonts from "../../libs/fonts";
import download from "../../../assets/download.png";
export default function TableLayout({
  label,
  search,
  onSearch,
  renderTableLabel,
  defaultQuery,
  onClick,
  insertUrl,
  data,
  renderTab,
  renderHeaderComponent,
  dataLength,
  dataLoading,
  columns,
  buttons,
  tableComponent,
  option,
  onExcel,
  limitMenu = true,
  pagination = true,
  button,
}) {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);

  useEffect(() => {
    if ((defaultQuery && !query?.page) || !query.limit) handleInitQuery();
  }, []);

  const handleInitQuery = () => {
    if (defaultQuery.start === "today") {
      defaultQuery.start = moment().format("YYYY-MM-DD");
    }
    if (defaultQuery.end === "today") {
      defaultQuery.end = moment().format("YYYY-MM-DD");
    }
    if (defaultQuery.date === "today") {
      defaultQuery.date = moment().format("YYYY-MM-DD");
    }
    history.replace({
      pathname: location.pathname,
      search: qs.stringify({
        ...defaultQuery,
      }),
    });
  };

  const renderLimit = () => {
    if (!limitMenu) return null;
    return (
      <select
        value={query.limit || ""}
        onChange={(e) =>
          history.replace({
            pathname: location.pathname,
            search: qs.stringify({
              ...query,
              limit: e.target.value,
            }),
          })
        }
        className={classes.menu}
        style={{ marginRight: onExcel ? 10 : 0 }}
      >
        <option value="">전체</option>
        <option value={"10"}>10개</option>
        <option value={"25"}>25개</option>
        <option value={"50"}>50개</option>
        <option value={"75"}>75개</option>
        <option value={"100"}>100개</option>
        <option value={"200"}>200개</option>
        <option value={"500"}>500개</option>
      </select>
    );
  };

  return (
    <div className={classes.root}>
      <TitleBar
        label={label}
        buttons={
          insertUrl && [
            { label: "등록", onClick: () => history.push(insertUrl) },
          ]
        }
      />
      {renderTab && renderTab}
      {search && (
        <Search
          onInit={handleInitQuery}
          onSearch={() => {
            if (parseInt(query.page) > 1) {
              return history.replace({
                pathname: location.pathname,
                search: qs.stringify({
                  ...query,
                  page: 1,
                }),
              });
            }
            onSearch();
          }}
          data={search}
        />
      )}

      {renderHeaderComponent && renderHeaderComponent}
      {tableComponent ? (
        <div
          className={
            classes.labelbar +
            " " +
            (!search && !renderHeaderComponent && classes.labelBarMargin)
          }
        >
          <div className={classes.listLabe2}>
            <span>검색결과 {dataLength}</span>
            {renderLimit()}
          </div>
        </div>
      ) : (
        <div
          className={
            classes.labelbar +
            " " +
            (!search && !renderHeaderComponent && classes.labelBarMargin)
          }
        >
          <div
            className={classes.listLabel}
            style={{ minWidth: buttons ? "90%" : "50%" }}
          >
            <span>{renderTableLabel || "목록"}</span>
            <div />
            <span>검색결과 {dataLength}</span>
          </div>
          {buttons && (
            <div className={classes.buttons}>
              {buttons.map((x, i) => {
                return (
                  <ButtonBase
                    onClick={x.onClick}
                    key={i.toString()}
                    className={classes.button}
                  >
                    {x.label}
                  </ButtonBase>
                );
              })}
            </div>
          )}
          <div className={classes.options}>
            {option && (
              <select
                value={query[option.key] || ""}
                onChange={(e) =>
                  history.replace({
                    pathname: location.pathname,
                    search: qs.stringify({
                      ...query,
                      [option.key]: e.target.value,
                    }),
                  })
                }
                className={classes.menu}
              >
                {option.data.map((x, i) => {
                  return <option value={x.value}>{x.label}</option>;
                })}
              </select>
            )}
            {renderLimit()}
            {button && (
              <ButtonBase
                onClick={button.onClick}
                className={classes.button}
                style={{
                  marginRight: 0,
                }}
              >
                {button.label}
              </ButtonBase>
            )}
            {onExcel && (
              <ButtonBase onClick={onExcel} className={classes.excel}>
                <img src={download} />
              </ButtonBase>
            )}
          </div>
        </div>
      )}
      <div className={classes.tableWrap}>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            {columns &&
              columns
                .filter((x) => x)
                .map((x, i) => {
                  return (
                    <div
                      style={{ flex: x.flex || 1 }}
                      className={classes.tableHeaderItem}
                      key={i.toString()}
                    >
                      {x.labelRender ||
                        (x.label || "").split("").map((s, i) => {
                          return s === "\n" ? <br key={i.toString()} /> : s;
                        })}
                    </div>
                  );
                })}
          </div>
          {dataLoading ? (
            <CircularProgress
              color="inherit"
              style={{
                color: "#39c15d",
                margin: "20px",
                alignSelf: "center",
              }}
            />
          ) : (
            data &&
            data.map((x, i) => {
              return (
                <div
                  className={classes.tableRow}
                  style={{ backgroundColor: x.checked ? "#f5fcf5" : "white" }}
                  key={i.toString()}
                >
                  {columns
                    .filter((x) => x)
                    .map((item, index) => {
                      return (
                        <div
                          onClick={
                            item.clickable
                              ? () => {
                                  onClick(x, i);
                                }
                              : null
                          }
                          className={
                            classes.tableRowItem +
                            " " +
                            (item.clickable && classes.tableRowItemClick) +
                            " " +
                            (item.label === "판매상태" &&
                              item.render(x, i) === "품절" &&
                              classes.colorRed) +
                            " " +
                            (item.label === "판매상태" &&
                              item.render(x, i) === "판매중" &&
                              classes.colorGreen) +
                            " " +
                            (item.label === "판매상태" &&
                              item.render(x, i) === "판매중지" &&
                              classes.colorOrange) +
                            " " +
                            (item.label === "근무상태/재고여부" &&
                              item.render(x, i).props.children[0] ===
                                "근무중" &&
                              classes.colorGreen)
                          }
                          style={{ flex: item.flex || 1 }}
                          key={index.toString() + x.sort}
                        >
                          {item.render ? item.render(x, i) : x[item.key]}
                        </div>
                      );
                    })}
                </div>
              );
            })
          )}
        </div>
        {tableComponent && tableComponent}
      </div>
      {pagination && (
        <Pagination
          className={classes.pagination}
          variant="outlined"
          shape="rounded"
          count={Math.ceil(dataLength / parseInt(query.limit))}
          page={parseInt(query.page)}
          onChange={(e, p) => {
            history.replace({
              pathname: location.pathname,
              search: qs.stringify({
                ...query,
                page: p,
              }),
            });
          }}
        />
      )}
    </div>
  );
}

const useStyle = makeStyles({
  tableRowItemClick: {
    cursor: "pointer",
    color: "#2069e3 !important",
    fontFamily: fonts.malgunGothicBold + " !important",
    "& span": {
      color: "#2069e3 !important",
      fontFamily: fonts.malgunGothicBold + " !important",
    },
  },
  button: {
    height: 40,
    borderRadius: 2,
    border: "1px solid #dddddd",
    marginRight: "10px",
    width: "100px",
    fontSize: 13,
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoMedium,
  },
  excel: {
    width: 40,
    height: 40,
    borderRadius: 2,
    border: "1px solid #dddddd",
    "& img": {
      width: 12,
      height: 16,
      objectFit: "contain",
    },
  },
  menu: {
    borderRadius: 2,
    border: "1px solid #e9ebee",
    height: 40,
    marginLeft: 10,
    marginRight: 10,
    minWidth: 106,
    padding: "10px",
    backgroundColor: "#fff",
  },
  labelbar: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttons: {
    display: "flex",
    marginBottom: 10,
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  options: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  listLabe2: {
    margin: "10px 0px",
    justifyContent: "space-between",
    flex: 1,
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 14,
      color: "#646f7c",
      fontFamily: fonts.spoqaHanSansNeoBold,
    },
  },
  listLabel: {
    margin: "10px 0px",
    display: "flex",
    alignItems: "center",
    "& :nth-child(1)": {
      fontSize: 25,
      color: "#374553",
      fontFamily: fonts.spoqaHanSansNeoBold,
    },
    "& :nth-child(2)": {
      width: 1,
      height: 13,
      margin: "0px 16px",
      backgroundColor: "#ddd",
    },
    "& :nth-child(3)": {
      fontSize: 14,
      color: "#646f7c",
      fontFamily: fonts.spoqaHanSansNeoBold,
    },
  },
  table: {
    flex: 1,
    borderRadius: 5,
    border: "1px solid #dddddd",
    display: "flex",
    flexDirection: "column",
  },
  tableRow: {
    minHeight: 50,
    display: "flex",

    alignItems: "stretch",
    borderBottom: "1px solid #dddddd",
    "&:last-child": {
      // borderBottom: "0px",
    },
  },
  tableRowItem: {
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center",
    borderRight: "1px solid #dddddd",
    "&:last-child": {
      borderRight: "0px",
    },
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    color: "#374553",
    fontFamily: fonts.malgunGothicSemiLight,
    "& span": {
      textAlign: "center",
      fontSize: 13,
      fontFamily: fonts.malgunGothicSemiLight,
      textAlign: "center",
    },
  },
  tableHeader: {
    position: "sticky",
    top: 0,
    minHeight: 50,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    display: "flex",
    alignItems: "stretch",
    backgroundColor: "#f7f8f9",
    borderBottom: "1px solid #dddddd",
  },
  tableHeaderItem: {
    borderRight: "1px solid #dddddd",
    textAlign: "center",
    "&:last-child": {
      borderRight: "0px",
    },
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    color: "#374553",
    fontFamily: fonts.malgunGothicBold,
  },
  tableWrap: {
    display: "flex",
    flex: 1,
  },
  labelBarMargin: {
    marginTop: 30,
  },
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 50,
    paddingBottom: 50,
    wordBreak: "break-all",
  },
  pagination: {
    marginTop: 60,
    alignSelf: "center",
  },
  colorRed: {
    color: "red",
  },
  colorGreen: {
    color: "#39c15d",
  },
  colorOrange: {
    color: "orange",
  },
});
