import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../components/table-layout/TableLayout";
import useDistribution from "../../hooks/useDistribution.js";
import useListPayload from "../../hooks/useListPayload.js";
import { formatPhoneNumber, formatTime, numFormat } from "../services/utils.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  const [distributions] = useDistribution();
  const { data, loading, fetchExcel, dataLength, fetchData } = useListPayload({
    endpoint: "/sales",
    excelName: "매출관리",
  });

  return (
    <TableLayout
      label="이웃 배송"
      onSearch={fetchData}
      data={[...data]}
      onExcel={fetchExcel}
      dataLoading={loading}
      dataLength={dataLength}
      defaultQuery={{
        page: 1,
        limit: 10,
        start: "today",
        end: "today",
        keyword: "",
        distributionId: "",
      }}
      search={[
        [
          {
            label: "조회기간",
            type: "date",
            key: "start",
            key2: "end",
          },
        ],
        [
          {
            label: "검색",
            type: "input",
            key: "keyword",
            placeholder: "유통사명, 담당자명, 휴대폰번호",
          },
          {
            label: "유통사",
            type: "menu",
            key: "distributionId",
            data: distributions,
          },
        ],
      ]}
      columns={[
        {
          label: "주문일자\n배송완료 일시",
          render: (col) => (
            <>
              {" "}
              {formatTime(col.createdAt, "YYYY-MM-DD HH:mm")}
              <br />
              {formatTime(col.deliverdAt, "YYYY-MM-DD HH:mm")}
            </>
          ),
        },
        {
          label: "유통사",
          key: "companyName",
        },
        {
          label: "주문번호",
          key: "orderNumber",
        },
        {
          label: "고객명\n전화번호",
          render: (col) => (
            <>
              {col.userName}
              <br />
              {formatPhoneNumber(col.phone)}
            </>
          ),
        },
        {
          label: "주문상품",
          flex: 2,
          render: (col) => (
            <>
              {col.products
                ? col.products?.split("|")[0] +
                  `${
                    col.products?.split("|").length > 1
                      ? ` 외 ${col.products?.split("|").length - 1}건`
                      : ""
                  }`
                : ""}
            </>
          ),
        },
        {
          label: "총 결제금액",
          render: (col) => <>{numFormat(col.paymentAmount) + "원"}</>,
        },
        {
          label: "상품금액",
          render: (col) => <>{numFormat(col.productAmount) + "원"}</>,
        },
        {
          label: "배송비",
          render: (col) => <>{numFormat(col.deliveryAmount) + "원"}</>,
        },
        {
          label: "쿠폰 할인금액",
          render: (col) => <>{numFormat(col.couponAmount) + "원"}</>,
        },
        {
          label: "배송기사\n차량번호",
          render: (col) => (
            <>
              {col.driverName}
              <br />
              {col.carNumber}
            </>
          ),
        },
      ]}
    />
  );
}
