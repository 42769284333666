import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Grid,
  Typography,
  Paper,
  Tabs,
  Tab,
  Button,
  Avatar,
  TextField,
  Dialog,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import consts from "../../../libs/consts";
import { requestGet, requestPut } from "../../../services/network";
import { useDispatch } from "react-redux";
import { popupError } from "../../../redux/popup/PopupActions";
import { formatTime } from "../../../services/utils";
import fonts from "../../../libs/fonts";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  titleGrid: {
    height: "50px",
  },
  divider: {
    width: "100%",
    height: "2px",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dffec: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  leftPanel: {
    borderRight: "1px solid #E0E0E0",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  rightPanel: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  driver: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "20px",
  },
  btn: {
    width: "100px",
    borderRadius: 0,
    "& + &": {
      marginLeft: "10px",
    },
    fontSize: 13,
    fontFamily: fonts.spoqaHanSansNeoMedium,
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
    fontSize: 13,
    fontFamily: fonts.spoqaHanSansNeoMedium,
  },
  saveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
    fontSize: 13,
    fontFamily: fonts.spoqaHanSansNeoMedium,
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  dialogContent2: {
    width: "400px",
    height: "400px",
  },
  dialogRow: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "80px",
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  approveBtn: {
    width: "100px",
    backgroundColor: "#40cf66",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
    fontSize: 13,
    fontFamily: fonts.spoqaHanSansNeoMedium,
  },
  rejectBtn: {
    width: "100px",
    backgroundColor: "red",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "red",
    },
    fontSize: 13,
    fontFamily: fonts.spoqaHanSansNeoMedium,
  },
});

export default function AuthUnconfirm({ onCount }) {
  const classes = useStyles();
  const [query, serQuery] = useState({
    ...consts.defaultQuery,
  });
  const [turnBackDialogOpen, setTurnBackDialogOpen] = useState(false);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [dialogDistributor, setDialogDistributor] = useState("");
  const [dialogProductSet, setDialogProductSet] = useState("");

  const dispatch = useDispatch();
  const [driver, setDriver] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [count, setCount] = useState(0);
  const [ds, setDs] = useState([]);
  const [rs, setRs] = useState([]);
  const [nickname, setNickname] = useState("");

  useEffect(() => {
    if (dialogDistributor) {
      requestGet({
        url: consts.apiUrl + "/receiptsets",
        query: {
          distributionId: dialogDistributor,
        },
      })
        .then((x) => {
          setRs([...x.results]);
        })
        .catch((x) => {
          console.log(x);
          dispatch(popupError(x));
        });
    } else {
      setRs([]);
      setDialogProductSet("");
    }
  }, [dialogDistributor]);

  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/distributions" })
      .then((x) => {
        setDs([...x.results]);
      })
      .catch((x) => {
        console.log(x);
        dispatch(popupError(x));
      });
  }, []);

  const handleNickname = (e) => {
    setNickname(e.target.value);
  };
  const fetch = () => {
    requestGet({
      url: consts.apiUrl + "/drivers",
      query: {
        ...query,
        state: "register",
      },
    })
      .then((x) => {
        onCount(x.totalCount);
        setDrivers([...x.results]);
        setCount(x.totalCount);
      })
      .catch((e) => {
        console.log(e);
        dispatch(popupError(e));
      });
  };
  useEffect(() => {
    fetch();
  }, [query.page]);

  useEffect(() => {
    setRs([]);
    setDialogDistributor("");
    setDialogProductSet("");
  }, [driver]);

  const handleTurnBackDialogClose = () => {
    setTurnBackDialogOpen(false);
  };

  const handleApproveDialogClose = () => {
    setApproveDialogOpen(false);
  };

  const turnBackDialogAgreeBtn = async () => {
    //반려
    try {
      if (!driver.driverId) {
        throw "기사를 선택해주세요.";
      }
      setTurnBackDialogOpen(false);
      await requestPut({
        url: consts.apiUrl + "/drivers/" + driver.driverId,
        body: {
          columns: ["state", "distributionId", "receiptSetId"],
          values: ["reject", null, null],
        },
      });
      setDriver({});
      fetch();
    } catch (error) {
      dispatch(popupError(error));
    }
  };

  const approveDialogAgreeBtn = async () => {
    //승인
    try {
      if (!nickname) {
        throw "차량 별칭을 입력해주세요.";
      } else if (!driver.driverId) {
        throw "기사를 선택해주세요.";
      } else if (!dialogDistributor) {
        throw "유통사를 선택해주세요.";
      } else if (!dialogProductSet) {
        throw "입고 SET을 선택해주세요.";
      }
      setApproveDialogOpen(false);
      await requestPut({
        url: consts.apiUrl + "/drivers/" + driver.driverId,
        body: {
          columns: ["state", "distributionId", "receiptSetId", "nickname"],
          values: ["approve", dialogDistributor, dialogProductSet, nickname],
        },
      });
      setDriver({});
      fetch();
    } catch (error) {
      dispatch(popupError(error));
    }
  };

  const handleDialogDistributor = (e) => {
    setDialogDistributor(e.target.value);
  };

  const handleDialogProductSet = (e) => {
    setDialogProductSet(e.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.df}>
        <Paper
          elevation={0}
          square
          variant="outlined"
          style={{ flex: 1, borderRadius: 5 }}
        >
          <Grid item xs={12} className={classes.df} style={{ height: "600px" }}>
            <Grid
              item
              xs={4}
              className={classes.leftPanel}
              style={{ flex: 1, overflow: "auto", overflowY: "auto" }}
            >
              {drivers.map((x, i) => {
                return (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDriver({ ...x });
                    }}
                    key={i.toString()}
                  >
                    <Grid item xs={12} className={classes.driver}>
                      <Typography
                        // variant="subtitle1"
                        style={{
                          fontFamily: fonts.malgunGothicSemiLight,
                          fontSize: 13,
                        }}
                      >
                        {x.name} 기사님
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontFamily: fonts.malgunGothicSemiLight,
                          fontSize: 13,
                        }}
                      >
                        {formatTime(x.createdAt, "YYYY-MM-DD HH.mm")}
                      </Typography>
                    </Grid>
                    <Divider
                      className={classes.divider}
                      style={{ height: "1px" }}
                    />
                  </div>
                );
              })}
              <Grid
                item
                xs={12}
                className={classes.df}
                style={{
                  justifyContent: "center",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={Math.ceil(count / query.limit)}
                  onChange={(e, p) => {
                    serQuery({ ...query, page: p });
                  }}
                />
              </Grid>
            </Grid>
            {driver.driverId && (
              <Grid item xs={8} className={classes.rightPanel}>
                <Grid
                  item
                  xs={12}
                  className={classes.dffec}
                  style={{ marginBottom: "20px" }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => setTurnBackDialogOpen(true)}
                    size="small"
                    className={classes.rejectBtn}
                  >
                    <Typography style={{ color: "#fff" }} variant="subtitle1">
                      반려
                    </Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setApproveDialogOpen(true)}
                    size="small"
                    className={classes.approveBtn}
                  >
                    <Typography style={{ color: "#fff" }} variant="subtitle1">
                      승인
                    </Typography>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.dfsc}
                  style={{ marginBottom: "20px" }}
                >
                  <Avatar style={{ width: "60px", height: "60px" }} />
                  <Typography
                    variant="h5"
                    style={{
                      fontFamily: fonts.spoqaHanSansNeoBold,
                      fontSize: 20,
                      marginLeft: "20px",
                    }}
                  >
                    {driver.name} 기사님
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12} className={classes.df}>
                    <Grid item xs={6} className={classes.df}>
                      <Grid
                        item
                        xs={2}
                        className={classes.dfsc}
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontFamily: fonts.malgunGothicSemiLight,
                            fontSize: 13,
                            marginLeft: "10px",
                          }}
                        >
                          이름
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        className={classes.dfsc}
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontFamily: fonts.malgunGothicSemiLight,
                            fontSize: 13,
                            marginLeft: "30px",
                          }}
                        >
                          {driver.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className={classes.df}>
                      <Grid
                        item
                        xs={2}
                        className={classes.dfsc}
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontFamily: fonts.malgunGothicSemiLight,
                            fontSize: 13,
                            marginLeft: "10px",
                          }}
                        >
                          휴대폰
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        className={classes.dfsc}
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontFamily: fonts.malgunGothicSemiLight,
                            fontSize: 13,
                            marginLeft: "30px",
                          }}
                        >
                          {driver.phone}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className={classes.df}>
                    <Grid item xs={6} className={classes.df}>
                      <Grid
                        item
                        xs={2}
                        className={classes.dfsc}
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontFamily: fonts.malgunGothicSemiLight,
                            fontSize: 13,
                            marginLeft: "10px",
                          }}
                        >
                          차량번호
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        className={classes.dfsc}
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontFamily: fonts.malgunGothicSemiLight,
                            fontSize: 13,
                            marginLeft: "30px",
                          }}
                        >
                          {driver.carNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className={classes.df}>
                      <Grid
                        item
                        xs={2}
                        className={classes.dfsc}
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontFamily: fonts.malgunGothicSemiLight,
                            fontSize: 13,
                            marginLeft: "10px",
                          }}
                        >
                          차종
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        className={classes.dfsc}
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontFamily: fonts.malgunGothicSemiLight,
                            fontSize: 13,
                            marginLeft: "30px",
                          }}
                        >
                          {driver.carTypeName}/{driver.carName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
        <Dialog open={turnBackDialogOpen} onClose={handleTurnBackDialogClose}>
          <Paper elevation={0}>
            <Grid item xs={12} className={classes.dialogHeader}>
              <Typography variant="subtitle1">인증 확인</Typography>
            </Grid>
            <Grid item xs={12} className={classes.dialogContent}>
              <Grid
                item
                xs={12}
                className={classes.df}
                style={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography variant="subtitle1">
                  반려처리하시겠습니까?
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.dfcc}>
                <Button
                  variant="outlined"
                  onClick={() => setTurnBackDialogOpen(false)}
                  className={classes.cancelBtn}
                >
                  <Typography varinat="subtitle2">취소</Typography>
                </Button>
                <Button
                  variant="outlined"
                  onClick={turnBackDialogAgreeBtn}
                  className={classes.saveBtn}
                  style={{ marginLeft: "10px" }}
                >
                  <Typography varinat="subtitle2">확인</Typography>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Dialog>
        <Dialog open={approveDialogOpen} onClose={handleApproveDialogClose}>
          <Paper elevation={0}>
            <Grid item xs={12} className={classes.dialogHeader}>
              <Typography variant="subtitle1">인증 확인</Typography>
            </Grid>
            <Grid item xs={12} className={classes.dialogContent2}>
              <Grid item xs={12} className={classes.dfcc}>
                <Typography variant="subtitle1">
                  승인처리 하시겠습니까?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  className={[classes.dfsc, classes.dialogRow].join(" ")}
                >
                  <Typography variant="subtitle2">차량별칭</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={nickname}
                    onChange={handleNickname}
                    style={{
                      fontFamily: fonts.malgunGothicSemiLight,
                      fontSize: 13,
                      marginLeft: "30px",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={[classes.dfsc, classes.dialogRow].join(" ")}
                >
                  <Typography variant="subtitle2">유통사</Typography>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    value={dialogDistributor}
                    onChange={handleDialogDistributor}
                    style={{
                      fontFamily: fonts.malgunGothicSemiLight,
                      fontSize: 13,
                      marginLeft: "30px",
                    }}
                  >
                    <option className={classes.selectForm} value="">
                      유통사 선택
                    </option>
                    {ds.map((x) => (
                      <option
                        className={classes.selectForm}
                        value={x.distributionId}
                      >
                        {x.companyName}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={[classes.dfsc, classes.dialogRow].join(" ")}
                >
                  <Typography variant="subtitle2">입고set</Typography>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    value={dialogProductSet}
                    onChange={handleDialogProductSet}
                    style={{
                      fontFamily: fonts.malgunGothicSemiLight,
                      fontSize: 13,
                      marginLeft: "30px",
                    }}
                  >
                    <option className={classes.selectForm} value="">
                      입고set 선택
                    </option>
                    {rs.map((x) => (
                      <option
                        className={classes.selectForm}
                        value={x.receiptSetId}
                      >
                        {x.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.dfcc}>
                <Button
                  variant="outlined"
                  onClick={() => setApproveDialogOpen(false)}
                  className={classes.cancelBtn}
                >
                  <Typography varinat="subtitle2">취소</Typography>
                </Button>
                <Button
                  variant="outlined"
                  onClick={approveDialogAgreeBtn}
                  className={classes.saveBtn}
                  style={{ marginLeft: "10px" }}
                >
                  <Typography varinat="subtitle2">확인</Typography>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Dialog>
      </Grid>
    </Grid>
  );
}
