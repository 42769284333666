/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Popup from "./Popup.js";
import { replaceQuery } from "../../services/utils.js";
import consts from "../../libs/consts.js";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
  },
});

export default function Container({ history, location }) {
  const classes = useStyles();
  const [selectNavItem, setSelectNavItem] = useState("customer_service");
  const [selectMenu, setSelectMenu] = useState("popup_management");
  useEffect(() => {
    if (
      (history.action === "PUSH" || history.action === "REPLACE") &&
      !location.search.includes("page=")
    ) {
      replaceQuery(history, location, {
        ...consts.defaultQuery,
      });
    }
  });
  return (
    <Grid container className={classes.container}>
      {<Popup />}
    </Grid>
  );
}
