/* eslint-disable no-unreachable-loop */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  CardMedia,
  Dialog,
  Slide,
  Icon,
  IconButton,
  Button,
  TableCell,
  TextField,
  Radio,
  FormControlLabel,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { requestDelete, requestGet } from "../../services/network";
import consts from "../../libs/consts";
import { formatTime, parseSearch, replaceQuery } from "../../services/utils";
import useRouter from "use-react-router";
import moment from "moment";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import ReceiveUserPopup from "./ReceiveUserPopup";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "50%",
    marginBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  titleGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  searchResultCountText: {
    color: "red",
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC",
  },
  detailCell: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  btn: {
    borderRadius: 0,
    marginLeft: "auto",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
});

export default function TableSheet(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { history, location, match } = useRouter();
  const query = parseSearch(location);
  const [popup, setPopup] = useState(false);
  const {
    setCouponEditDialogOpen,
    listData,
    length,
    page,
    setListData,
    onFetch,
  } = props;

  return (
    <Grid container className={classes.container}>
      {Boolean(popup) && (
        <ReceiveUserPopup
          key={Boolean(popup)}
          {...popup}
          onClose={() => {
            setPopup(false);
          }}
        />
      )}

      <Grid
        item
        xs={12}
        className={classes.dfsc}
        style={{ marginBottom: "10px" }}
      >
        <Typography
          variant="h5"
          style={{
            fontSize: "28px",
            lineHeight: "32px",
            fontFamily: fonts.spoqaHanSansNeoBold,
            color: "#374553",
            paddingTop: "20px",
          }}
        >
          발송내역
        </Typography>
      </Grid>

      <div
        style={{
          marginLeft: "calc(100% - 140px)",
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        보기
        <select
          value={query.limit}
          onChange={(e) => {
            replaceQuery(history, location, {
              limit: e.target.value,
              page: 1,
            });
          }}
          style={{
            border: "1px solid #ddd",
            padding: "10px",
            marginLeft: 8,
            width: 100,
          }}
        >
          <option value="10" label="10개" />
          <option value="25" label="25개" />
          <option value="50" label="50개" />
          <option value="75" label="75개" />
          <option value="100" label="100개" />
          <option value="200" label="200개" />
          <option value="500" label="500개" />
        </select>
      </div>

      <Grid item xs={12}>
        <Grid item xs={12}>
          <TableContainer first>
            <Th topleft center xs={1}>
              NO
            </Th>
            <Th center xs={2}>
              발송일자
            </Th>
            <Th center xs={2}>
              발송대상
            </Th>
            <Th center xs={5}>
              발송제목
            </Th>
            <Th topright center xs={7}>
              발송내용
            </Th>
            {/* <Th center xs={2}>발송고객</Th> */}
          </TableContainer>
        </Grid>
        {listData.map((col, idx) => {
          return (
            <Grid item xs={12}>
              <TableContainer
                last={listData.length === idx + 1 ? true : false}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPopup(col);
                }}
              >
                <Td
                  bottomleft={listData.length === idx + 1 ? true : false}
                  center
                  xs={1}
                >
                  {length -
                    (idx + 1) +
                    1 -
                    (parseInt(query.page) - 1) * parseInt(query.limit)}
                </Td>
                <Td center xs={2}>
                  {col.format}
                </Td>
                <Td center xs={2}>
                  {(() => {
                    if (col.filtered) {
                      switch (col.memberType) {
                        case "all":
                          return "개별회원(전체)";
                        case "user":
                          return "개별회원(고객)";
                        default:
                          return "개별회원(기사)";
                      }
                    } else {
                      switch (col.memberType) {
                        case "all":
                          return "전체회원(전체)";
                        case "user":
                          return "전체회원(고객)";
                        default:
                          return "전체회원(기사)";
                      }
                    }
                  })()}
                </Td>
                <Td center xs={5}>
                  {col.title}
                </Td>
                <Td
                  bottomright={listData.length === idx + 1 ? true : false}
                  center
                  xs={7}
                >
                  {col.message}
                </Td>
                {/* <Td center xs={2}>
                      {col.sended}
                    </Td> */}
              </TableContainer>
            </Grid>
          );
        })}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Pagination
            variant="outlined"
            shape="rounded"
            page={parseInt(query.page)}
            count={page}
            onChange={(e, p) => {
              replaceQuery(history, location, { page: p });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
