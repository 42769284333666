/* eslint-disable no-unreachable-loop */
import React, { useEffect, useState, useRef } from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Grid,
  Dialog,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Icon,
  IconButton,
  Button,
  TableCell,
  Checkbox,
  CardMedia,
  TextField,
  Radio,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import SetEditorProduct from "./SetEditorTableProduct";
import { getFileUri, numFormat } from "../../services/utils";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";

const useStyles = makeStyles({
  container: {
    marginTop: "30px",
    width: "100%",
    height: "50%",
    marginBottom: "100px",
  },
  bold: {
    fontWeight: "bold",
  },
  titleGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid #B7ACAC",
    height: "50px",
    padding: "10px",
  },
  tableGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid #B7ACAC",
    padding: "10px",
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC",
  },
  headerBtn: {
    backgroundColor: "#fff",
    color: "#555",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  initializationBtn: {
    width: "120px",
    backgroundColor: "#fff",
    border: "1px solid #e9e9e9",
    borderRadius: 0,
  },
  searchBtn: {
    width: "120px",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  searchBox: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  divider: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  btn: {
    width: "100px",
    border: "1px solid #B7ACAC",
    borderRadius: 0,
    marginRight: "10px",
  },
  cancelBtn: {
    width: "100px",
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    width: "100px",
    backgroundColor: "#40cf66",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  select: {
    width: "150px",
    "& div": {
      backgroundColor: "#fff",
    },
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  cb: { color: "#40cf66 !important" },
});

export default function SetEditTable({
  products,
  setProducts,
  onSave,
  receiptSetId,
  driverId,
  distributionId,
}) {
  const classes = useStyles();
  const { history } = useReactRouter();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [renderProducts, setRenderProducts] = useState([]);
  const [query, setQuery] = useState({ page: 1, limit: 5 });
  const sels = renderProducts.filter((x) => x.checked);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  useEffect(() => {
    setRenderProducts([
      ...products.filter((x, i) => {
        if (
          (query.page - 1) * query.limit <= i &&
          i < query.page * query.limit
        ) {
          return true;
        } else {
          return false;
        }
      }),
    ]);
  }, [query, products]);

  const handleSaveDialog = () => {
    setSaveDialogOpen(false);
  };

  const onClickSaveDialogSaveBtn = () => {
    setSaveDialogOpen(false);
  };

  const onClickSaveBtn = () => {
    onSave();
  };

  const onClickCancelAdd = () => {
    history.goBack();
  };

  const deleteProducts = () => {
    if (sels) {
      setProducts([
        ...products.filter((x) => {
          if (sels.filter((y) => y.productId === x.productId)[0]) {
            return false;
          } else {
            return true;
          }
        }),
      ]);
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        className={classes.dfsc}
        style={{ marginBottom: "10px" }}
      >
        <Button
          onClick={deleteProducts}
          variant="outlined"
          className={classes.headerBtn}
          style={{ marginLeft: "10px" }}
        >
          <Typography variant="subtitle2">상품 삭제</Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TableContainer first>
              <Th center cb xs={1} topleft>
                <Checkbox
                  className={classes.cb}
                  color="default"
                  checked={
                    renderProducts.length &&
                    renderProducts.filter((x) => x.checked).length ===
                      renderProducts.length
                  }
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setRenderProducts([
                      ...renderProducts.map((x) => ({ ...x, checked })),
                    ]);
                  }}
                />
              </Th>
              <Th xs={3} center>
                상품코드
              </Th>
              <Th xs={3} center>
                이미지
              </Th>
              <Th xs={3} center>
                카테고리
              </Th>
              <Th xs={3} center>
                상품명
              </Th>
              <Th xs={3} center>
                판매가격
              </Th>
              <Th xs={3} center topright>
                주문수량
              </Th>
            </TableContainer>
          </Grid>
          {renderProducts.length === 0 && <Divider />}
          <Grid item xs={12}>
            {renderProducts.map((col, idx) => {
              return (
                <TableContainer
                  key={idx}
                  last={renderProducts.length === idx + 1 ? true : false}
                >
                  <Td
                    xs={1}
                    center
                    bottomleft={
                      renderProducts.length === idx + 1 ? true : false
                    }
                  >
                    <Checkbox
                      className={classes.cb}
                      color="default"
                      checked={col.checked || false}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setRenderProducts([
                          ...renderProducts.map((x, i) => ({
                            ...x,
                            checked: idx === i ? checked : x.checked,
                          })),
                        ]);
                      }}
                    />
                  </Td>

                  <Td xs={3} center>
                    {col.code}
                  </Td>
                  <Td xs={3} center>
                    <CardMedia
                      component="img"
                      height="70"
                      image={getFileUri(col.path)}
                    />
                  </Td>
                  <Td xs={3} center>
                    {col.category}{" "}
                    {col.childCategory ? " / " + col.childCategory : ""}
                  </Td>
                  <Td xs={3} center>
                    {col.name}
                  </Td>
                  <Td xs={3} center>
                    {numFormat(col.price)}원
                  </Td>
                  <Td
                    xs={3}
                    center
                    textfield
                    bottomright={
                      renderProducts.length === idx + 1 ? true : false
                    }
                  >
                    {
                      //col.quantity
                    }
                    <TextField
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      variant="outlined"
                      value={col.quantity}
                      onChange={(e) => {
                        let count = e.target.value;
                        if (count && isNaN(count)) {
                          return;
                        }

                        if (count !== "") {
                          count = parseInt(count);
                        }
                        if (count < 1) {
                          dispatch(
                            popupError({
                              message: "입고수량을 1개이상 설정해주세요.",
                            })
                          );
                          return;
                        } else if (count > 10000000) {
                          dispatch(
                            popupError({
                              message: "올바른 수량을 입력해주세요.",
                            })
                          );
                          return;
                        }
                        products[
                          products.findIndex(
                            (x) => x.productId === col.productId
                          )
                        ].quantity = count;
                        setProducts([...products]);
                      }}
                    />
                  </Td>
                </TableContainer>
              );
            })}
          </Grid>

          <Grid item xs={12} className={classes.df}>
            <Grid
              item
              xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Pagination
                variant="outlined"
                shape="rounded"
                count={Math.ceil(products.length / query.limit)}
                onChange={(e, page) => {
                  setQuery({ ...query, page });
                }}
              />
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Button
                variant="outlined"
                className={classes.cancelBtn}
                onClick={onClickCancelAdd}
              >
                <Typography variant="subtitle2">취소</Typography>
              </Button>
              <Button onClick={onClickSaveBtn} className={classes.saveBtn}>
                <Typography variant="subtitle2">저장</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        style={{
          marginTop: "50px",
          marginBottom: "20px",
          height: "0px",
          width: "100%",
          backgroundColor: "#656565",
        }}
      />
      <SetEditorProduct
        products={products}
        setProduts={setProducts}
        driverId={driverId}
        distributionId={distributionId}
        receiptSetId={receiptSetId}
      />
      <Dialog open={saveDialogOpen} onClose={handleSaveDialog}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">1일 입고 수량 설정</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                1일 입고수량 미등록 상품이 있습니다.
              </Typography>
              <Typography variant="subtitle1">
                상품 입고수량을 설정해주세요.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setSaveDialogOpen(false)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={onClickSaveDialogSaveBtn}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </Grid>
  );
}
