import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import {
  formatPhoneNumber,
  formatTime,
  numFormat,
} from "../../services/utils.js";
import consts from "../../libs/consts.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  // const { data, loading, dataLength, fetchData, fetchExcel } = useListPayload({
  //   endpoint: "/users",
  // });
  const { data, loading, dataLength, fetchData, fetchExcel } = useListPayload({
    excelName: "친구추천 관리",
    endpoint: "/sharefriends",
    triggers: ["type"],
  });
  return (
    <TableLayout
      label="친구추천 관리"
      onSearch={fetchData}
      data={[...data]}
      dataLoading={loading}
      onExcel={fetchExcel}
      dataLength={dataLength}
      //   onClick={(col) => {
      //     window.open(
      //       `/member_management/customer_member_detail?userId=${col.userId}`,
      //       "_blank"
      //     );
      //   }}
      defaultQuery={{
        page: 1,
        limit: 10,
        start: "today",
        end: "today",
        keyword: "",
        method: "",
      }}
      search={[
        [
          {
            label: "조회기간",
            type: "date",
            key: "start",
            key2: "end",
          },
        ],
        [
          {
            label: "검색",
            type: "input",
            key: "keyword",
            placeholder: "이름, 이메일(ID), 핸드폰번호",
          },
        ],
      ]}
      columns={[
        {
          label: "추천일시",
          render: (col) => col.createdAt,
        },
        {
          label: "이름",
          render: (col) => col.name,
        },
        {
          label: "이메일",
          render: (col) => col.email,
        },
        {
          label: "휴대폰번호",
          render: (col) => col.phone,
        },
        {
          label: "피추천인 번호",
          render: (col) => col.receiverPhone,
        },
        {
          label: "피추천인 가입여부",
          render: (col) => (col.joined ? "O" : "X"),
        },
      ]}
    />
  );
}
