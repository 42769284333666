import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Divider,
  Button
} from "@material-ui/core";
import SetEditTable from "./SetEditTable";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import {
  requestDelete,
  requestFile,
  requestGet,
  requestPost,
  requestPut
} from "../../services/network";
import consts from "../../libs/consts";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";
import fonts from "../../libs/fonts";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import TableContainer from "../../components/table/TableContainer";
import useMember from "../../../hooks/useMember";

const useStyles = makeStyles({
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)"
    }
  },
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: "20px"
  },
  container2: {
    marginTop: "30px",
    width: "100%",
    minHeight: "100vh",
    height: "50%",
    marginBottom: "100px"
  },
  divider: {
    width: "100%"
  },
  bold: {
    fontWeight: "bold"
  },
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  titleCell: {
    backgroundColor: "#dfdfdf",
    borderRight: "1px solid #000"
  },
  cell: {
    borderTop: "1px solid #000",
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingBottom: "10px"
  },
  bottomCell: {
    borderBottom: "1px solid #000"
  },
  resize: { fontSize: "13px" }
});

export default function SetEditor({}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [setName, setSetName] = useState("");
  const [receiptSetId, setReceiptSetId] = useState(false);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const [ds, setDs] = useState([]);
  const [distributionId, setDistributionId] = useState(null);
  const [data, setData] = useState({});
  const [image, setImage] = useState(null);
  const [path, setPath] = useState();
  const [loaded, setLoaded] = useState(false);

  const member = useMember();

  useEffect(() => {
    if (member.isPartner) {
      setDistributionId(member.distributionId);
    }
  }, [member]);
  useEffect(() => {
    if (location.state?.receiptSetId) {
      setReceiptSetId(location.state?.receiptSetId);
      requestGet({
        url: consts.apiUrl + "/receiptsets/" + location.state?.receiptSetId
      })
        .then(x => {
          setSetName(x.name);
          setPath(x.path);
          setData({ ...x });
          setDistributionId(x.distributionId);
          setProducts(
            [...x.products.map(x => ({ ...x, checked: false }))]
              .sort((a, b) => {
                if (a.state === "on" && b.state !== "on") return -1;
                return 0;
              })
              .reverse()
          );
          setLoaded(true);
        })
        .catch(x => {
          dispatch(popupError(x));
        });
    } else {
      setLoaded(true);
      requestGet({ url: consts.apiUrl + "/distributions" })
        .then(x => {
          setDs([...x.results]);
        })
        .catch(x => {
          dispatch(popupError(x));
        });
    }
  }, []);
  const handleSave = async () => {
    dispatch(loadingStart);
    try {
      if (!member.distributionId && !distributionId) {
        throw "유통사를 선택해주세요.";
      } else if (!products.length) {
        throw "입고 상품을 등록해주세요.";
      } else if (!setName) {
        throw "입고 Set명을 입력해주세요.";
      } else if (!image && !path) {
        throw "입고 Set이미지를 등록해주세요.";
      }
      const formData = new FormData();

      products.forEach(x => {
        formData.append(
          "products",
          JSON.stringify(
            x.receiptSetProductId
              ? {
                  receiptSetProductId: x.receiptSetProductId,
                  quantity: x.quantity,
                  productId: x.productId,
                  store: x.store
                }
              : {
                  quantity: x.quantity,
                  productId: x.productId,
                  store: x.store
                }
          )
        );
      });

      if (image) {
        formData.append("file", image.file);
      }
      formData.append("path", path);
      formData.append("name", setName);
      formData.append(
        "distributionId",
        member.isPartner ? member.distributionId : distributionId
      );

      let r = "";
      if (receiptSetId) {
        await requestFile({
          url: consts.apiUrl + "/receiptsets/" + receiptSetId,
          form: formData,
          put: true
        });
        r = receiptSetId;
      } else {
        r = (
          await requestFile({
            url: consts.apiUrl + "/receiptsets",
            form: formData
          })
        ).id;
      }

      requestGet({
        url: consts.apiUrl + "/receiptsets/" + r
      })
        .then(x => {
          setReceiptSetId(r);
          setSetName(x.name);
          setPath(x.path);
          setData({ ...x });
          setDistributionId(x.distributionId);
          setProducts(
            [...x.products.map(x => ({ ...x, checked: false }))]
              .sort((a, b) => {
                if (a.state === "on" && b.state !== "on") return -1;
                return 0;
              })
              .reverse()
          );
        })
        .catch(x => {
          dispatch(popupError(x));
        });

      //history.goBack();
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  const handleSetName = e => {
    setSetName(e.target.value);
  };

  const handleImageChagne = e => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const uri = reader.result;
        setImage({ uri, file });
      };
      reader.readAsDataURL(file);
      e.target.value = "";
    }
  };
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.dfsc}>
        <Typography
          variant="h5"
          style={{
            fontSize: "28px",
            lineHeight: "32px",
            fontFamily: fonts.spoqaHanSansNeoBold,
            color: "#374553",
            paddingTop: "40px"
          }}>
          입고SET{receiptSetId ? "수정" : "등록"}
        </Typography>
        {receiptSetId && (
          <Button
            onClick={() => {
              if (parseInt(data.driverLength) >= 1) {
                alert(
                  "해당 입고 SET에 연결된 차량이 있습니다. 연결 해재후 삭제해주세요."
                );
                return;
              }
              requestDelete({
                url: consts.apiUrl + "/receiptsets/" + receiptSetId
              })
                .then(() => {
                  history.goBack();
                })
                .catch(e => {
                  alert(e.message || e);
                });
            }}
            variant="contained"
            color="secondary"
            style={{
              padding: "5px 50px",
              position: "absolute",
              right: 70,
              marginBottom: "16px",
              backgroundColor: "#ff4747",
              fontFamily: fonts.spoqaHanSansNeoBold
            }}>
            입고 SET 삭제
          </Button>
        )}
      </Grid>
      <Divider
        className={classes.divider}
        style={{
          height: "1px",
          backgroundColor: "#c5c8ce",
          marginBottom: "40px",
          marginTop: "20px"
        }}
      />
      <Grid item xs={12} style={{ marginTop: "10px" }}>
        {!receiptSetId ? (
          <>
            <Grid item xs={12} className={classes.df}>
              <TableContainer>
                <Th>Set명</Th>
                <Td textfield>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={setName}
                    onChange={handleSetName}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
                {!member.isPartner && (
                  <>
                    <Th>유통사</Th>
                    <Td textfield>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={distributionId}
                        onChange={e => {
                          setProducts([]);
                          setDistributionId(e.target.value);
                        }}
                        style={{ marginLeft: "20px" }}
                        InputProps={{
                          classes: {
                            input: classes.resize
                          }
                        }}>
                        <option
                          value={""}
                          selected={!distributionId}
                          disabled
                          className={classes.selectForm}>
                          선택하기
                        </option>
                        {ds.map((item, idx) => {
                          return (
                            <option
                              key={idx}
                              value={item.distributionId.toString()}
                              selected={distributionId === item.distributionId}
                              className={classes.selectForm}>
                              {item.companyName}
                            </option>
                          );
                        })}
                      </TextField>
                    </Td>
                  </>
                )}
              </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.df}>
              <TableContainer last>
                <Th>Set이미지</Th>
                <Td xs={10} textfield>
                  {Boolean(image) && (
                    <img
                      alt="icon"
                      src={image.uri}
                      style={{
                        width: "50px",
                        marginRight: "16px",
                        height: "50px",
                        objectFit: "contain"
                      }}
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChagne}
                  />
                </Td>
              </TableContainer>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} className={classes.df}>
              <TableContainer first>
                <Th topleft>Set명</Th>
                <Td textfield>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={setName}
                    onChange={handleSetName}
                    InputProps={{
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </Td>
                <Th>상품수</Th>
                <Td topright>{data.products?.length}</Td>
              </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.df}>
              <TableContainer>
                <Th>Set이미지</Th>
                <Td xs={10}>
                  {Boolean(image || path) && (
                    <img
                      alt="icon"
                      src={image ? image.uri : consts.fileApiUrl + "/" + path}
                      style={{
                        width: "50px",
                        marginRight: "16px",
                        height: "50px",
                        objectFit: "contain"
                      }}
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChagne}
                  />
                </Td>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{ marginBottom: "30px" }}>
              <TableContainer last>
                <Th bottomleft>코드</Th>
                <Td>{data.dCode}</Td>
                <Th>연결 등록차량</Th>
                <Td bottomright>{data.driverLength}대</Td>
              </TableContainer>
            </Grid>
          </>
        )}
        {distributionId && loaded ? (
          <SetEditTable
            receiptSetId={receiptSetId}
            products={products}
            setProducts={setProducts}
            onSave={handleSave}
            distributionId={distributionId}
          />
        ) : (
          <Grid container className={classes.container2}>
            <div>유통사를 먼저 선택해주세요</div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
