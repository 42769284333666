import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ControlBox from "./ControlBox";
import TableSheet from "./TableSheet";
import { requestGet } from "../../../services/network";
import consts from "../../../libs/consts";
import fonts from "../../../libs/fonts";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    backgroundColor: "#fff"
  },
  title: {
    color: "#374553",
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MemberPopup({
  onChange,
  value,
  onClose,
  driver = false,
  member = true,
  hideMemberCheck
}) {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [length, setLength] = useState(0);
  const [query, setQuery] = useState({
    // start: momnet().format("YYYY-MM-DD"),
    // end: momnet().format("YYYY-MM-DD"),
    start: "",
    end: "",
    method: "",
    keyword: "",
    page: 1,
    address: "",
    limit: 10,
    postCodes: [],
    memberType: "member"
  });

  useEffect(() => {
    if (driver && !member) {
      setQuery({ ...query, memberType: "driver" });
    } else {
      setQuery({ ...query, memberType: "member" });
    }
  }, [driver, member]);

  useEffect(() => {
    let p;
    if (query.page === 1) {
      fetch();
    } else {
      p = 1;
    }

    if (query.memberType === "driver") {
      setQuery({
        ...query,
        // start: momnet().format("YYYY-MM-DD"),
        // end: momnet().format("YYYY-MM-DD"),
        start: "",
        end: "",
        keyword: "",
        postCodes: [],
        memberType: !driver ? "member" : "driver",
        page: p || 1,
        limit: 10
      });
    } else {
      setQuery({
        ...query,
        // start: momnet().format("YYYY-MM-DD"),
        // end: momnet().format("YYYY-MM-DD"),
        start: "",
        end: "",
        method: "",
        keyword: "",
        memberType: !member ? "driver" : "member",
        page: p || 1,
        address: "",
        limit: 10
      });
    }
  }, [query.memberType]);

  const handleClose = () => {
    onClose();
  };

  const fetch = useCallback(() => {
    requestGet({
      url:
        consts.apiUrl +
        (query.memberType === "member" ? "/users" : "/admins/drivers"),
      query: {
        ...query,
        fetchlevel: "m",
        postCodes:
          query.memberType === "driver" ? query.postCodes.join(",") : []
      }
    })
      .then(x => {
        setLength(x.totalCount);
        setList([...x.results]);
      })
      .catch(e => {
        alert(e.message || e);
      });
  }, [query]);

  useEffect(() => {
    fetch();
  }, [query.page, query.limit]);

  return (
    <Dialog
      fullScreen
      open
      onClose={handleClose}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="#374553"
            onClick={handleClose}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography
            style={{ fontFamily: fonts.spoqaHanSansNeoBold }}
            variant="h5"
            className={classes.title}>
            회원검색
          </Typography>
        </Toolbar>
      </AppBar>
      <ControlBox
        hideMemberCheck={hideMemberCheck}
        driver={driver}
        member={member}
        onSearch={d => {
          if (query.page > 1) {
            setQuery({ ...query, page: 1 });
          } else {
            fetch();
          }
        }}
        data={query}
        onChange={v => {
          setQuery({ ...query, ...v });
        }}
      />
      <TableSheet
        data={query}
        onListInit={() => {
          setList([]);
        }}
        onChange={v => {
          setQuery({ ...query, ...v });
        }}
        type={query.memberType}
        total={length}
        list={list}
        value={value}
        onValueChange={onChange}
      />
      <Button
        onClick={onClose}
        style={{
          alignSelf: "center",
          width: "20%",
          marginBottom: 100,
          backgroundColor: "#40cf66",
          color: "#fff",
          fontFamily: fonts.spoqaHanSansNeoBold,
          fontSize: 14
        }}>
        <Typography
          variant="subtitle2"
          style={{ fontFamily: fonts.spoqaHanSansNeoBold, fontSize: 14 }}>
          확인
        </Typography>
      </Button>
    </Dialog>
  );
}
