/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Paper,
  FormControlLabel,
  Radio,
  Dialog,
  Checkbox,
  Icon,
  IconButton,
} from "@material-ui/core";
import { requestGet, requestPost, requestPut } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  addBtn: {
    marginLeft: "auto",
    backgroundColor: "#656565",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "10px",
  },
  textfield: {
    "& div": {
      backgroundColor: "#fff",
    },
  },
  dialogTitleCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "lightgrey",
  },
  dialogContentCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "2px solid #656565",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none",
    },
  },
});

export default function CouponUpdateDialog({ onClose, onUpdate, data }) {
  const classes = useStyles();
  const [couponType, setCouponType] = useState("delivery");
  const [selGrades, setSelGrades] = useState([]);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [giveType, setGiveType] = useState("condition");
  const [useType, setUseType] = useState("used");
  const [prevDate, setPrevDate] = useState(moment().format("YYYY-MM-DD"));
  const [nextDate, setNextDate] = useState(moment().format("YYYY-MM-DD"));
  const [validDate, setValidDate] = useState(moment().format("YYYY-MM-DD"));
  const [grades, setGrades] = useState([]);
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("1");
  const dispatch = useDispatch();

  useEffect(() => {
    setQuantity(data.quantity || 1);
    setSelGrades((data.grades || "").split(",").filter((x) => x));
    setName(data.name);
    setCouponType(data.type);
    setDiscountPercent(data.discountPercent);
    setPrevDate(moment(data.start).format("YYYY-MM-DD"));
    setNextDate(moment(data.end).format("YYYY-MM-DD"));
    setValidDate(moment(data.last).format("YYYY-MM-DD"));
    setUseType(data.privacy === 1 ? "unused" : "used");
    setGiveType(data.setting);
  }, [data]);

  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/grades" })
      .then((x) => {
        setGrades([...x]);
      })
      .catch((e) => {
        onClose();
        dispatch(popupError(e));
      });
  }, []);
  const handleCouponAddDialog = () => {
    onClose(false);
  };

  const handleCouponType = (e) => {
    setDiscountPercent(0);
    setCouponType(e.target.value);
  };

  const handlePrevDate = (e) => {
    setPrevDate(e.target.value);
    if (giveType === "now") {
      setNextDate(e.target.value);
    }
  };
  const handleNextDate = (e) => {
    setNextDate(e.target.value);
  };
  const handleValidDate = (e) => {
    setValidDate(e.target.value);
  };

  const handleCheckValue = (e) => {
    if (selGrades.includes(e.target.value)) {
      setSelGrades([...selGrades.filter((x) => x !== e.target.value)]);
    } else {
      setSelGrades([...selGrades, e.target.value]);
    }
  };

  const handleGiveType = (e) => {
    if (e.target.value === "now") {
      setPrevDate(moment().format("YYYY-MM-DD"));
      setNextDate(moment().format("YYYY-MM-DD"));
    }
    setGiveType(e.target.value);
  };
  const handleUseType = (e) => {
    setUseType(e.target.value);
  };

  const onClickRegisterBtn = () => {
    onClose(false);
  };

  const onClickCancleBtn = () => {
    onClose(false);
  };

  const insertCoupon = async () => {
    dispatch(loadingStart);
    try {
      const params = {
        start: prevDate,
        end: nextDate,
        last: validDate,
        name,
        type: couponType,
        discountPercent: discountPercent,
        quantity,
        grades: selGrades,
        setting: giveType,
        privacy: useType === "unused",
      };

      await requestPut({
        url: consts.apiUrl + "/coupons/" + data.couponId,
        body: params,
      });
      onClose();
      onUpdate();
    } catch (error) {
      dispatch(popupError(error));
    }
    dispatch(loadingStop);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      onClose={handleCouponAddDialog}
      className={classes.dialog}
    >
      <Paper elevation={0}>
        <Grid item xs={12} className={classes.dialogHeader}>
          <Typography variant="subtitle1">쿠폰 수정</Typography>
          <IconButton
            onClick={() => onClose(false)}
            style={{ marginLeft: "auto" }}
          >
            <Icon>close</Icon>
          </IconButton>
        </Grid>
        <Divider
          style={{ width: "100%", height: "2px", marginBottom: "20px" }}
        />

        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid
            item
            xs={3}
            className={classes.dfsc}
            style={{ marginLeft: "20px" }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              등록일
            </Typography>
          </Grid>
          <Grid
            item
            xs={9}
            className={classes.dfsc}
            style={{ paddingRight: "20px" }}
          >
            {moment(data.createdAt).format("YYYY-MM-DD")}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid
            item
            xs={3}
            className={classes.dfsc}
            style={{ marginLeft: "20px" }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              쿠폰종류
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.dfsc}>
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  value="delivery"
                  checked={couponType === "delivery"}
                  onChange={handleCouponType}
                />
              }
              label="무료배송"
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  value="discount"
                  checked={couponType === "discount"}
                  onChange={handleCouponType}
                />
              }
              label="할인쿠폰"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid
            item
            xs={3}
            className={classes.dfsc}
            style={{ marginLeft: "20px" }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              쿠폰명
            </Typography>
          </Grid>
          <Grid
            item
            xs={9}
            className={classes.dfsc}
            style={{ paddingRight: "20px" }}
          >
            <TextField
              value={name}
              onChange={(t) => {
                setName(t.target.value);
              }}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid
            item
            xs={3}
            className={classes.dfsc}
            style={{ marginLeft: "20px" }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              지급기간
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.dfsc}>
            <input
              min={moment().format("YYYY-MM-DD")}
              type="date"
              value={prevDate}
              onChange={handlePrevDate}
            />
            <span> ~ </span>
            <input
              disabled={giveType === "now"}
              min={moment().format("YYYY-MM-DD")}
              type="date"
              value={nextDate}
              onChange={handleNextDate}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid
            item
            xs={3}
            className={classes.dfsc}
            style={{ marginLeft: "20px" }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              유효기간
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.dfsc}>
            <input
              min={
                prevDate
                  ? moment(prevDate, "YYYY-MM-DD").format("YYYY-MM-DD")
                  : ""
              }
              type="date"
              value={validDate}
              onChange={handleValidDate}
            />
          </Grid>
        </Grid>
        {couponType === "discount" && (
          <Grid
            item
            xs={12}
            className={classes.df}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <Grid
              item
              xs={3}
              className={classes.dfsc}
              style={{ marginLeft: "20px" }}
            >
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                할인률
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.dfsc}>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={discountPercent}
                onChange={(e) => {
                  setDiscountPercent(e.target.value);
                }}
              />
              <div style={{ width: "12px" }} />%
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid
            item
            xs={3}
            className={classes.dfsc}
            style={{ marginLeft: "20px" }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              쿠폰수
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.dfsc}>
            <TextField
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
              type="number"
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid
            item
            xs={3}
            className={classes.dfsc}
            style={{ marginLeft: "20px" }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              적용대상
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.dfsc}>
            {grades.map((x, i) => {
              return (
                <FormControlLabel
                  key={i.toString()}
                  control={
                    <Checkbox
                      color="default"
                      value={x.gradeId.toString()}
                      checked={selGrades.includes(x.gradeId.toString())}
                      onChange={handleCheckValue}
                    />
                  }
                  label={x.name}
                  labelPlacement="end"
                />
              );
            })}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid
            item
            xs={3}
            className={classes.dfsc}
            style={{ marginLeft: "20px" }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              지급설정
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.dfsc}>
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  value="condition"
                  checked={giveType === "condition"}
                  onChange={handleGiveType}
                />
              }
              label="등급 달성시"
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  value="now"
                  checked={giveType === "now"}
                  onChange={handleGiveType}
                />
              }
              label="바로 지급"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.df}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid
            item
            xs={3}
            className={classes.dfsc}
            style={{ marginLeft: "20px" }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              사용여부
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.dfsc}>
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  value="used"
                  checked={useType === "used"}
                  onChange={handleUseType}
                />
              }
              label="사용"
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  value="unused"
                  checked={useType === "unused"}
                  onChange={handleUseType}
                />
              }
              label="미사용"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfcc}
          style={{ marginTop: "10px", marginBottom: "30px" }}
        >
          <Button onClick={insertCoupon} variant="outlined" size="small">
            <Typography variant="subtitle1" onClick={onClickRegisterBtn}>
              수정하기
            </Typography>
          </Button>
          <Button
            variant="outlined"
            size="small"
            style={{ marginLeft: "10px" }}
          >
            <Typography variant="subtitle1" onClick={onClickCancleBtn}>
              취소하기
            </Typography>
          </Button>
        </Grid>
      </Paper>
    </Dialog>
  );
}
