import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import { formatPhoneNumber, numFormat } from "../../services/utils.js";
import InventoryDetail from "./InventoryDetail.js";
import { ButtonBase, Grid, Icon, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
  popupAction,
  popupClose,
  popupError,
} from "../../redux/popup/PopupActions.js";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts.js";
import { requestGet, requestPatch } from "../../services/network.js";
import consts from "../../libs/consts.js";
import Search2 from "./Search2.js";
import TableLayout2 from "./TableLayout2.js";
import moment from "moment";
import useListPayload2 from "../../../hooks/useListPayload2.js";
import useMember from "../../../hooks/useMember.js";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#fff",
    borderRadius: 2,
    border: "1px solid #dddddd",
    fontSize: 13,
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoMedium,
    padding: "5px",
    cursor: "pointer",
  },
});

export default function Container({ history, location }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [distributions] = useDistribution();
  const { isPartner, distributionId } = useMember();
  const [categories, setCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const { data, loading, dataLength, fetchData, fetchExcel } = useListPayload2({
    excelName: "스토어 재고관리",
  });

  const [sel, setSel] = useState(null);
  const [num, setNum] = useState();
  const [show, setShow] = useState(false);
  const [storeWorkProductId, setStoreWorkProductId] = useState();

  useEffect(() => {
    requestGet({
      url: consts.apiUrl + "/products/categories",
      query: { privacy: "all" },
    })
      .then((x) => {
        setCategories([
          ...x.categories.map((y) => {
            y.categories = x.childCategories.filter(
              (z) => y.productCategoryId === z.parentId
            );
            return {
              ...y,
              label: y.name,
              value: y.productCategoryId.toString(),
            };
          }),
        ]);
        setChildCategories([
          ...x.childCategories.map((x) => ({
            ...x,
            label: x.name,
            value: x.productCategoryId.toString(),
          })),
        ]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  }, []);

  const onClickConfirm = () => {
    requestPatch({
      url:
        consts.apiUrl +
        `/stores/storeworks/products/${storeWorkProductId}/quantity`,
      body: { quantity: parseInt(num) },
    })
      .then((x) => {
        fetchData();
      })
      .catch((e) => dispatch(popupError(e)));
  };

  return (
    <>
      {sel && (
        <InventoryDetail
          {...sel}
          onClose={() => {
            setSel(null);
          }}
        />
      )}
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">수량 변경</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ color: "black" }}
            id="alert-dialog-description"
          >
            <Grid
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                value={num}
                onChange={(e) => {
                  setNum(e.target.value);
                }}
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShow(false);
            }}
          >
            취소
          </Button>
          <Button
            onClick={() => {
              setShow(false);
              onClickConfirm();
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <TableLayout2
        label="스토어 재고관리"
        onSearch={fetchData}
        data={[...data]}
        dataLoading={loading}
        onExcel={fetchExcel}
        dataLength={dataLength}
        onClick={(item) => {
          setSel({ ...item });
        }}
        defaultQuery={{
          page: 1,
          limit: 10,
          date: moment().format("YYYY-MM-DD"),
          keyword: "",
          distributionId: isPartner ? distributionId : "",
          state: "",
        }}
        search={[
          [
            {
              label: "재고 동기화 일자",
              type: "date",
              key: "date",
            },
          ],
          [
            {
              label: "카테고리",
              type: "menu2",
              key: "categoryId",
              key2: "childCategoryId",
              data: categories,
              data2: childCategories,
            },

            {
              label: "판매상태",
              type: "menu",
              key: "state",
              data: [
                { label: "판매중", value: "on" },
                { label: "판매중지", value: "off" },
                { label: "품절", value: "soldout" },
              ],
            },
          ],
          !isPartner
            ? [
                {
                  label: "검색",
                  type: "input",
                  key: "keyword",
                  placeholder: "상품명",
                },
                {
                  label: "유통사",
                  type: "menu",
                  key: "distributionId",
                  data: distributions,
                },
              ]
            : [
                {
                  label: "검색",
                  type: "input",
                  key: "keyword",
                  placeholder: "상품명",
                },
              ],
        ]}
        columns={[
          {
            label: "NO",
            render: (col, i) => i + 1,
          },
          {
            label: "유통사",
            render: (col) => col.companyName,
          },
          {
            label: "상품명",
            render: (col) => col.name,
          },
          {
            label: "카테고리",
            render: (col) => col.category,
          },
          {
            label: "판매 가격",
            render: (col) => numFormat(col.price),
          },
          {
            label: "현재 수량",
            render: (col) => col.leftQuantity,
          },
          {
            label: "판매상태",
            render: (col) =>
              col.state === "on"
                ? "판매중"
                : col.state === "off"
                ? "판매중지"
                : "품절",
          },
          {
            label: "수량변경",
            render: (col) => (
              <ButtonBase
                onClick={() => {
                  setNum(col.leftQuantity);
                  setShow(true);
                  setStoreWorkProductId(col.storeWorkProductId);
                }}
                className={classes.button}
                variant="outlined"
              >
                수량 변경
              </ButtonBase>
            ),
          },
        ]}
      />
    </>
  );
}
