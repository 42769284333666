import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import SignInContainer from "./admin/auth/Container.js";
import DashboardContainer from "./admin/dashboard/Container.js";
import OrderSearchContainer from "./admin/delivery/orderSearch/Container.js";
import DeliverySearchContainer from "./admin/delivery/deliverySearch/Container.js";
import PaymentContainer from "./admin/delivery/payment/Container.js";
import ProducListContainer from "./admin/product/list/Container.js";
import ProducAddContainer from "./admin/product/add/Container.js";
import CategoryMangementContainer from "./admin/product/category/Container.js";
import TodayReceivingContainer from "./admin/receiving/today/Container.js";
import InventoryContainer from "./admin/receiving/inventory/Container.js";
import StoreInventoryContainer from "./admin/receiving/store-inventory/Container.js";
import ReceivingOrderContainer from "./admin/receiving/order/Container.js";
import ReceivingOrderHistoryContainer from "./admin/receiving/order-history/Container.js";
import ReceivingSetContainer from "./admin/receiving/set/Container.js";
import ReceivingSetEditorContainer from "./admin/receiving/setEditor/Container.js";
import InventoryMonitoringOrderContainer from "./admin/receiving/inventory-monitoring/Container.js";
import CustomerMemberContainer from "./admin/member/customer_member/Container.js";
import CustomerDetailMemberContainer from "./admin/member/customer_member_detail/Container.js";
import DriverMemberContainer from "./admin/member/driver_member/Container.js";
import DriverMemberDetailContainer from "./admin/member/driver_member_detail/Container.js";
import DriverMemberAuthContainer from "./admin/member/driver_auth/Container.js";
import WithDrawalMemberContainer from "./admin/member/drawal_member/Container.js";
import WithDrawalMemberDetailContainer from "./admin/member/drawal_member_detail/Container.js";
import DistributorContainer from "./admin/partner/distributor/Container.js";
import DistributorAddContainer from "./admin/partner/distributor_add/Container.js";
import SalesDetailsContainer from "./admin/sales/Container.js";
import SalesDeliveryDetailsContainer from "./admin/sales_delivery/Container.js";
import NoticeContainer from "./admin/customer_service/notice/Container.js";
import NoticeDetailContainer from "./admin/customer_service/notice_detail/Container.js";
import NoticeAddContainer from "./admin/customer_service/notice_add/Container.js";
import CustomerEnquiryContainer from "./admin/customer_service/enquiry/Container.js";
import CustomerDetailEnquiryContainer from "./admin/customer_service/enquiry_detail/Container.js";
import NotificationContainer from "./admin/customer_service/notification/Container.js";
import CouponManagementContainer from "./admin/customer_service/coupon/Container.js";
import RecommendContainer from "./admin/customer_service/recommend/Container.js";
import InquiryTypeContainer from "./admin/setting/inquiry/Container.js";
import DeliveryChargeContainer from "./admin/setting/delivery_charge/Container.js";
import PopupContainer from "./admin/customer_service/popup/Container";
import GradeContainer from "./admin/setting/grade/Container.js";
import TermsContainer from "./admin/setting/terms/Container.js";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { initUser } from "./admin/redux/user/UserActions.js";
import { useEffect, useState } from "react";
import Loading from "./admin/redux-components/loading/Loading.js";
import Popup from "./admin/redux-components/popup/Popup.js";
import CarType from "./admin/setting/car-type/Container.js";
import Car from "./admin/setting/car/Container.js";
import PopupAddContainer from "./admin/customer_service/popup_add/Container";
import OtherOrders from "./admin/othersorder/Container";
import OtherOrderList from "./admin/othersorderlist/Container";
import OtherOrdersAdd from "./admin/othersorder/othersorderadd/Container";
import { makeStyles } from "@material-ui/core";
import SideBar from "./drawer/SideBar.js";
import useMember from "./hooks/useMember.js";
import PartnerInfo from "./admin/partner-info/PartnerInfo.js";
import PartnerInfoTemp from "./admin/partner-info/PartnerInfoTemp.js";
import StoreCategoryManagementContainer from "./admin/product/store_category/Container.js";
import DefaultAddr from "./admin/setting/defaultaddr/DefaultAddr.js";
import Review from "./admin/review/Container.js";

function App() {
  const classes = useStyle();

  const dispatch = useDispatch();
  const { init, signed } = useSelector(s => s.user, shallowEqual);
  const { partnerHost } = useMember();

  useEffect(() => {
    if (partnerHost !== null) {
      dispatch(initUser(partnerHost));
    }
  }, [partnerHost]);

  if (!init) {
    return null;
  }
  return (
    <BrowserRouter>
      <>
        <Loading />
        <Popup />
        <div className={classes.root}>
          {signed && <SideBar />}
          <div className={classes.main}>
            {!signed ? (
              <Switch>
                <Route exact path={"/signin"} component={SignInContainer} />
                <Redirect to="/signin" />
              </Switch>
            ) : (
              <Switch>
                <Route
                  exact
                  path={"/dashboard/current_situation"}
                  component={DashboardContainer}
                />

                <Route
                  exact
                  path={"/delivery_management/order_search"}
                  component={OrderSearchContainer}
                />
                <Route
                  exact
                  path={"/delivery_management/delivery_search"}
                  component={DeliverySearchContainer}
                />
                <Route
                  exact
                  path={"/delivery_management/payment"}
                  component={PaymentContainer}
                />
                <Route
                  exact
                  path={"/others_order/orders/payment"}
                  component={PaymentContainer}
                />
                <Route
                  exact
                  path={"/product_management/product_list"}
                  component={ProducListContainer}
                />
                <Route
                  exact
                  path={"/product_management/product_add"}
                  component={ProducAddContainer}
                />
                <Route
                  exact
                  path={"/product_management/category_management"}
                  component={CategoryMangementContainer}
                />
                <Route
                  exact
                  path={"/product_management/store_category_management"}
                  component={StoreCategoryManagementContainer}
                />

                <Route
                  exact
                  path={"/receiving_management/today_receiving_management"}
                  component={TodayReceivingContainer}
                />
                <Route
                  exact
                  path={"/receiving_management/inventory_control"}
                  component={InventoryContainer}
                />
                <Route
                  exact
                  path={"/receiving_management/store_inventory_control"}
                  component={StoreInventoryContainer}
                />
                <Route
                  exact
                  path={"/receiving_management/order_management"}
                  component={ReceivingOrderContainer}
                />
                   <Route
                  exact
                  path={"/receiving_management/order_history"}
                  component={ReceivingOrderHistoryContainer}
                />



                <Route
                  exact
                  path={"/receiving_management/inventory_monitoring"}
                  component={InventoryMonitoringOrderContainer}
                />

                <Route
                  exact
                  path={"/receiving_management/receiving_set"}
                  component={ReceivingSetContainer}
                />
                <Route
                  exact
                  path={"/receiving_management/seteditor"}
                  component={ReceivingSetEditorContainer}
                />
                <Route exact path={"/reviews"} component={Review} />
                <Route
                  exact
                  path={"/others_order/orders/detail"}
                  component={OtherOrdersAdd}
                />
                <Route
                  exact
                  path={"/others_order/orders"}
                  component={OtherOrders}
                />
                <Route
                  exact
                  path={"/others_order/list"}
                  component={OtherOrderList}
                />
                <Route
                  exact
                  path={"/member_management/customer_member"}
                  component={CustomerMemberContainer}
                />
                <Route
                  exact
                  path={"/member_management/customer_member_detail"}
                  component={CustomerDetailMemberContainer}
                />
                <Route
                  exact
                  path={"/member_management/driver_member"}
                  component={DriverMemberContainer}
                />
                <Route
                  exact
                  path={"/member_management/driver_member_detail"}
                  component={DriverMemberDetailContainer}
                />
                <Route
                  exact
                  path={"/member_management/driver_member_auth"}
                  component={DriverMemberAuthContainer}
                />
                <Route
                  exact
                  path={"/member_management/withdrawal_member"}
                  component={WithDrawalMemberContainer}
                />
                <Route
                  exact
                  path={"/member_management/withdrawal_member_detail"}
                  component={WithDrawalMemberDetailContainer}
                />

                <Route
                  exact
                  path={"/partner_management/distributor_management"}
                  component={DistributorContainer}
                />

                <Route
                  exact
                  path={"/partner_management/distributor_add"}
                  component={DistributorAddContainer}
                />

                <Route
                  exact
                  path={"/sales_details/sales_details"}
                  component={SalesDetailsContainer}
                />
                <Route
                  exact
                  path={"/sales_details/sales_delivery_details"}
                  component={SalesDeliveryDetailsContainer}
                />

                <Route
                  exact
                  path={"/customer_service/notice"}
                  component={NoticeContainer}
                />
                <Route
                  exact
                  path={"/customer_service/popup_management"}
                  component={PopupContainer}
                />
                <Route
                  exact
                  path={"/customer_service/popup_add"}
                  component={PopupAddContainer}
                />
                <Route
                  exact
                  path={"/customer_service/popup_management/login"}
                  component={PopupContainer}
                />
                <Route
                  exact
                  path={"/customer_service/popup_add/login"}
                  component={PopupAddContainer}
                />

                <Route
                  exact
                  path={"/customer_service/notice_add"}
                  component={NoticeAddContainer}
                />
                <Route
                  exact
                  path={"/customer_service/notice_detail"}
                  component={NoticeDetailContainer}
                />
                <Route
                  exact
                  path={"/customer_service/customer_enquiry"}
                  component={CustomerEnquiryContainer}
                />
                <Route
                  exact
                  path={"/customer_service/detail"}
                  component={CustomerDetailEnquiryContainer}
                />
                <Route
                  exact
                  path={"/customer_service/notification"}
                  component={NotificationContainer}
                />
                <Route
                  exact
                  path={"/customer_service/coupon_management"}
                  component={CouponManagementContainer}
                />
                <Route
                  exact
                  path={"/customer_service/recommend"}
                  component={RecommendContainer}
                />
                <Route exact path={"/setting/car"} component={Car} />
                <Route exact path={"/setting/car_type"} component={CarType} />
                <Route
                  exact
                  path={"/setting/inquiry_type"}
                  component={InquiryTypeContainer}
                />
                <Route
                  exact
                  path={"/setting/customer_grade_management"}
                  component={GradeContainer}
                />
                <Route
                  exact
                  path={"/setting/terms"}
                  component={TermsContainer}
                />
                <Route
                  exact
                  path={"/setting/defaultaddr"}
                  component={DefaultAddr}
                />

                <Route exact path={"/info"} component={PartnerInfoTemp} />
                <Redirect to={"/delivery_management/order_search"} />
              </Switch>
            )}
          </div>
        </div>
      </>
    </BrowserRouter>
  );
}

const useStyle = makeStyles({
  root: {
    margin: 0,
    width: "100%",
    minWidth: "100%",
    minHeight: "100%",
    height: "100%",
    display: "flex",
    alignItems: "stretch"
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    flex: 1,
    overflowY: "auto",
    overflowX: "auto",
    minWidth: "1024px",
    maxHeight: "100%",
    paddingLeft: "240px"
  }
});
export default App;
