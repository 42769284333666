import React, { useState } from "react";
import useReactRouter from "use-react-router";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button, Typography, InputBase } from "@material-ui/core";
import { signIn } from "../redux/user/UserActions";
import fonts from "../libs/fonts";
import logo from "../../assets/logo.png";
import useMember from "../../hooks/useMember";
const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  paper: {
    padding: "80px 60px",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginTop: "10px",
    marginBottom: "30px",
  },
  icon: {
    marginRight: "10px",
  },
  loginBtn: {
    backgroundColor: "#40cf66",
    borderRadius: 3,
    padding: "22px",
    height: "60px",
    color: "white",
    fontSize: "18px",
    fontFamily: fonts.notoSansCJKkrBold,
    marginTop: "30px",
  },
  //
  input2: {
    borderRadius: "3px",
    backgroundColor: "#f2f2f2",
    padding: "14px",
    "&:last-child": {
      marginTop: "10px",
    },
  },
  wrap: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    marginBottom: 40,
    alignSelf: "center",
    "& img": {
      width: 34,
      height: 34,
      marginRight: 18,
      objectFit: "contain",
    },
  },
  label: {
    fontSize: "36px",
    color: "#00ec9d",
    fontFamily: fonts.nanumSquareRoundEB,
  },
});

export default function Container() {
  const classes = useStyles();
  const { partnerHost } = useMember();

  const [username, setUsername] = useState("");
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");

  const handleChangeId = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onClickLoginBtn = async () => {
    dispatch(signIn(username, password, partnerHost));
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.grid}>
        {
          ///			<div style={{ position: "relative", bottom: "100px" }}>LOGO</div>
        }
        <Paper className={classes.paper}>
          <div className={classes.wrap}>
            <img src={logo} />
            <span className={classes.label}>후드딜리버리</span>
          </div>
          <form>
            <InputBase
              placeholder="아이디"
              value={username}
              onChange={handleChangeId}
              fullWidth
              className={classes.input2}
            />

            <InputBase
              type="password"
              placeholder="비밀번호"
              value={password}
              onChange={handleChangePassword}
              fullWidth
              className={classes.input2}
            />
          </form>
          <Button
            variant="contained"
            size="large"
            fullWidth
            className={classes.loginBtn}
            onClick={onClickLoginBtn}
          >
            <Typography variant="subtitle1">로그인</Typography>
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
