/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Divider } from "@material-ui/core";
import TableSheet from "./TableSheet";
import useRouter from "use-react-router";
import { parseSearch } from "../../services/utils";
import { useDispatch } from "react-redux";
import { requestGet } from "../../services/network";
import { popupError } from "../../redux/popup/PopupActions";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";

const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px"
  },
  bold: {
    fontWeight: "bold"
  },
  addBtn: {
    marginLeft: "auto",
    backgroundColor: "#40cf66",
    color: "#fff",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#40cf66"
    },
    alignSelf: "flex-end",
    width: "120px"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignContent: "center"
  }
});

export default function Popup() {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const { location, history } = useRouter();
  const query = parseSearch(location);
  const dispatch = useDispatch();
  const isLoginPopup = location.pathname.split("/").pop().startsWith("login");

  const onClickAdd = () => {
    history.push(
      "/customer_service/popup_add" + (isLoginPopup ? "/login" : "")
    );
  };
  const fetchData = () => {
    requestGet({
      url: consts.apiUrl + "/popups",
      query: { ...query, type: isLoginPopup ? "login" : "home" }
    })
      .then(x => {
        setTotal(x.totalCount);
        setData([...x.results]);
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  };

  useEffect(() => {
    query.page && fetchData();
  }, [query.page]);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid item xs={12} style={{ marginBottom: "30px" }}>
          <Grid
            item
            xs={12}
            className={classes.dfsc}
            style={{ marginBottom: "10px" }}>
            <Typography
              variant="h5"
              style={{
                fontSize: "28px",
                lineHeight: "32px",
                fontFamily: fonts.spoqaHanSansNeoBold,
                color: "#374553",
                paddingTop: "40px"
              }}>
              {isLoginPopup ? "로그인 " : ""}팝업관리
            </Typography>
            <Button onClick={onClickAdd} className={classes.addBtn}>
              <Typography variant="subtitle1">등록하기</Typography>
            </Button>
          </Grid>
          <Divider
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#c5c8ce",
              marginBottom: "40px",
              marginTop: "20px"
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TableSheet
            setListData={setData}
            listData={data}
            length={total}
            page={Math.ceil(total / parseInt(query.limit))}
            onFetch={fetchData}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
