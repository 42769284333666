import React, { useState } from "react";
import useReactRouter from "use-react-router";

import { Grid, Icon, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { signOut } from "../redux/user/UserActions";
import { useDispatch } from "react-redux";
import Logo from "../libs/logo.png";
const useStyles = makeStyles({
  header: {
    backgroundColor: "black",
    width: "100%",
    height: "70px"
  },
  nav: {
    display: "flex"
  },
  logoDiv: {
    width: "200px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    },
    color: "white"
  },
  navItem: {
    width: "120px",
    color: "#efefef",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logoutDiv: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "150px",
    cursor: "pointer"
  },
  logout: {
    color: "#fff",
    marginRight: "5px"
  }
});

export default function NavPanel(props) {
  const classes = useStyles();
  const { history } = useReactRouter();
  const dispatch = useDispatch();

  const { setSelectNavItem } = props;
  const navItem = [
    /*{
      title: "대쉬보드",
      value: "dashboard",
    },*/
    {
      title: "배송관리",
      value: "delivery_management"
    },
    {
      title: "상품관리",
      value: "product_management"
    },
    {
      title: "입고관리",
      value: "receiving_management"
    },

    {
      title: "수기주문",
      value: "others_order"
    },

    {
      title: "회원관리",
      value: "member_management"
    },
    {
      title: "파트너관리",
      value: "partner_management"
    },

    {
      title: "매출관리",
      value: "sales_details"
    },
    {
      title: "고객센터",
      value: "customer_service"
    },
    {
      title: "설정",
      value: "setting"
    }
  ];

  const logoutBtnClick = () => {
    dispatch(signOut);
  };

  const navItemClick = value => {
    setSelectNavItem(value);
    if (value === "dashboard") {
      history.push(`../${value}/current_situation`);
    } else if (value === "delivery_management") {
      history.push(`../${value}/order_search`);
    } else if (value === "product_management") {
      history.push(`../${value}/product_list`);
    } else if (value === "receiving_management") {
      history.push(`../${value}/today_receiving_management`);
    } else if (value === "member_management") {
      history.push(`../${value}/customer_member`);
    } else if (value === "partner_management") {
      history.push(`../${value}/distributor_management`);
    } else if (value === "sales_details") {
      history.push("../" + value);
    } else if (value === "customer_service") {
      history.push(`../${value}/notice`);
    } else if (value === "setting") {
      history.push(`../${value}/car_type`);
    } else if (value === "others_order") {
      history.push(`../${value}/orders`);
    } else if (value === "reviews") {
      history.push(`../${value}/reviews`);
    }
  };

  return (
    <Grid container className={classes.header}>
      <Grid item xs={12} className={classes.nav}>
        <div className={classes.logoDiv}>
          <img alt="logo" src={Logo} />
        </div>
        {navItem.map((item, idx) => {
          return (
            <div
              className={classes.navItem}
              key={item.value}
              onClick={() => navItemClick(item.value)}>
              {item.title}
            </div>
          );
        })}
        <div className={classes.logoutDiv} onClick={logoutBtnClick}>
          <Icon className={classes.logout}>exit_to_app_outlined</Icon>
          <Typography variant="subtitle1" className={classes.logout}>
            Logout
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
