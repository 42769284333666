import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import consts from "../../admin/libs/consts";
import { requestDelete, requestGet } from "../../admin/services/network";

const StyledTableCell = withStyles(theme => ({
  head: {
    border: "1px solid #ddd",
    backgroundColor: "#f7f8f9",
    color: "#333"
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    borderBottom: "1px solid #ddd"
  }
}))(TableRow);

const initialQuery = {
  page: 1,
  limit: 10
};
const initialData = {
  list: [],
  total: 0
};
const initialDel = {
  open: false,
  userId: null,
  reviewId: null
};

const useStyle = makeStyles({
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  saveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B"
    }
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff"
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column"
  }
});

export default function SharedReview({ open, onClose, type, id }) {
  const classes = useStyle();
  const [query, setQuery] = useState(initialQuery);
  const [data, setData] = useState(initialData);
  const [del, setDel] = useState(initialDel);

  const fetchProductReviews = async (productId, query) => {
    try {
      const data = await requestGet({
        url: consts.apiUrl + "/products/" + productId + "/reviews",
        query
      });
      setData({
        list: data.results,
        total: data.totalCount
      });
    } catch (error) {
      alert(error.message || error);
    }
  };
  const fetchDeliveryReviews = async (distributionId, query) => {
    try {
      const data = await requestGet({
        url: consts.apiUrl + "/distributions/" + distributionId + "/reviews",
        query
      });
      setData({
        list: data.results,
        total: data.totalCount
      });
    } catch (error) {
      alert(error.message || error);
    }
  };

  useEffect(() => {
    if (open) {
      setQuery(initialQuery);
      setData(initialData);
    }
  }, [open]);

  useEffect(() => {
    if (type && id && open) {
      if (type === "distribution") {
        fetchDeliveryReviews(id, query);
      } else {
        fetchProductReviews(id, query);
      }
    }
  }, [query, type, id, open]);

  const handleDelete = async (reviewId, userId) => {
    try {
      await requestDelete({
        url: consts.apiUrl + "/reviews/" + reviewId,
        query: {
          userId
        }
      });
      alert("리뷰 삭제 되었습니다.");
      if (type === "distribution") {
        fetchDeliveryReviews(id, query);
      } else {
        fetchProductReviews(id, query);
      }
    } catch (error) {
      alert(error.message || error);
    }
  };

  const handleDelClose = () => {
    setDel(initialDel);
  };
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle>
          리뷰 팝업
          <IconButton
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
            onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/** */}
          <TableContainer>
            <Table style={{ minWidth: 700 }} aria-label="review table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width={20} align="left">
                    NO
                  </StyledTableCell>
                  <StyledTableCell width={150} align="center">
                    작성자
                  </StyledTableCell>
                  <StyledTableCell align="center">이미지</StyledTableCell>
                  <StyledTableCell width={500} align="center">
                    후기
                  </StyledTableCell>
                  <StyledTableCell width={50} align="center">
                    평점
                  </StyledTableCell>
                  <StyledTableCell width={150} align="center">
                    작성일
                  </StyledTableCell>
                  <StyledTableCell width={50} align="center">
                    삭제
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.list.map((row, index) => (
                  <StyledTableRow key={row.reivewId}>
                    <StyledTableCell align="left">
                      {data.total - (query.page - 1) * query.limit - index}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.images.length ? (
                        <a
                          style={{ cursor: "pointer" }}
                          href={consts.fileApiUrl + "/" + row.images[0].path}
                          target="_blank">
                          <img
                            alt="review image"
                            src={consts.fileApiUrl + "/" + row.images[0].path}
                            width={150}
                            height={150}
                            style={{ objectFit: "cover" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        style={{
                          fontSize: 14,
                          maxWidth: "100%"
                        }}>
                        {row.description}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.rating}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {moment(row.createdAt).format("YYYY-MM-DD HH:mm")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setDel({
                            open: true,
                            userId: row.userId,
                            reviewId: row.reviewId
                          });
                        }}>
                        삭제
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Box
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              justifyContent: "center"
            }}>
            <Pagination
              count={Math.ceil(data.total / query.limit)}
              page={query.page}
              color="primary"
              variant="outlined"
              onChange={(e, p) => setQuery(prev => ({ ...prev, page: p }))}
            />
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={del.open} onClose={handleDelClose}>
        <Box>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">삭제처리</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column"
              }}>
              <Typography variant="subtitle1">
                삭제처리 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={handleDelClose}
                className={classes.cancelBtn}>
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  handleDelete(del.reviewId, del.userId);
                  handleDelClose();
                }}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}>
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
}
