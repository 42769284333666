import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import { CardMedia, Checkbox } from "@material-ui/core";
import { getFileUri, numFormat } from "../../services/utils.js";
import { popupError, popupMessage } from "../../redux/popup/PopupActions.js";
import { useDispatch } from "react-redux";
import { requestGet, requestPut } from "../../services/network.js";
import consts from "../../libs/consts.js";
import useMember from "../../../hooks/useMember.js";
import InventoryDetail from "./InventoryDetail.js";
import Sorter from "./Sorter.js";
import SharedReview from "../../../components/review/SharedReview";

const useStyles = makeStyles({ cb: { color: "#40cf66 !important" } });

export default function Container({ history, location }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isPartner, distributionId } = useMember();
  const [categories, setCategories] = useState([]);
  const [distributions] = useDistribution();
  const { data, loading, query, dataLength, fetchData, fetchExcel, setData } =
    useListPayload({
      excelName: "상품조회",
      endpoint: "/products",
      triggers: ["sort"],
    });
  const [sel, setSel] = useState(null);

  useEffect(() => {
    requestGet({ url: consts.apiUrl + "/products/categories" })
      .then((x) => {
        setCategories([
          ...x.categories.map((y) => {
            y.categories = x.childCategories.filter(
              (z) => y.productCategoryId === z.parentId
            );
            return y;
          }),
        ]);
      })
      .catch((x) => {
        dispatch(popupError(x));
      });
  }, []);

  const handleStateClick = (state) => {
    const checked = data.filter((x) => x.checked);
    if (!checked.length) {
      dispatch(popupMessage({ message: "아이템을 선택해주세요." }));
      return;
    }
    requestPut({
      url: consts.apiUrl + "/products/state",
      body: {
        productIds: checked.map((x) => x.productId),
        state,
      },
    })
      .then(fetchData)
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  let filtered =
    query.state ||
    query.childCategoryId ||
    query.categoryId ||
    query.start ||
    query.end ||
    query.keyword ||
    query.distributionId;
  return (
    <>
      {sel && (
        <SharedReview
          open
          onClose={() => {
            setSel(null);
          }}
          type="product"
          id={sel.productId}
        />
      )}

      <TableLayout
        label="상품조회"
        onSearch={fetchData}
        data={[...data]}
        dataLoading={loading}
        insertUrl="/product_management/product_add"
        onExcel={fetchExcel}
        dataLength={dataLength}
        onClick={(col) => {
          window.open(
            `/product_management/product_add?productId=${col.productId}`,
            "_blank"
          );
        }}
        buttons={[
          { label: "판매중지", onClick: () => handleStateClick("off") },
          { label: "품절", onClick: () => handleStateClick("out") },
          { label: "판매중", onClick: () => handleStateClick("on") },
        ]}
        renderHeaderComponent={
          filtered ? (
            3 === 3 ? null : (
              <span style={{ color: "#444", fontSize: "0.7rem" }}>
                노출 순서 변경은 초기화 버튼울 눌러 전체 상품 조회에서
                가능합니다.
              </span>
            )
          ) : (
            ""
          )
        }
        option={{
          key: "sort",
          data: [
            {
              value: "sort",
              label: "기본 노출순",
            },
            { value: "", label: "최신순" },
            { value: "sales", label: "판매량 높은순" },
          ],
        }}
        defaultQuery={{
          page: 1,
          limit: 10,
          start: "",
          end: "",
          sort: "sort",
          distributionId: isPartner ? distributionId : "",
          keyword: "",
        }}
        search={[
          [
            {
              label: "조회기간",
              type: "date",
              key: "start",
              key2: "end",
            },
          ],
          [
            {
              label: "카테고리",
              type: "menu2",
              key: "categoryId",
              key2: "childCategoryId",
              data: categories.map((x) => ({
                label: x.name,
                value: x.productCategoryId.toString(),
              })),
              data2:
                categories.filter(
                  (x) => String(x.productCategoryId) === query.categoryId
                )[0] &&
                categories
                  .filter(
                    (x) => String(x.productCategoryId) === query.categoryId
                  )[0]
                  .categories.map((x) => ({
                    label: x.name,
                    value: x.productCategoryId.toString(),
                  })),
            },
            {
              label: "판매상태",
              type: "menu",
              key: "state",
              data: [
                {
                  label: "판매중",
                  value: "on",
                },
                {
                  label: "판매중지",
                  value: "off",
                },
                {
                  label: "품절",
                  value: "out",
                },
              ],
            },
          ],

          [
            {
              label: "검색",
              type: "input",
              key: "keyword",
              placeholder: "상품코드, 상품명, 유통사",
            },
            !isPartner && {
              label: "유통사",
              type: "menu",
              key: "distributionId",
              data: distributions,
            },
          ],
        ]}
        columns={[
          {
            label: "체크",
            width: 50,
            labelRender: (
              <Checkbox
                className={classes.cb}
                color="default"
                checked={
                  data.length >= 1 &&
                  data.filter((x) => x.checked).length === data.length
                }
                onChange={(e) => {
                  setData({ checked: e.target.checked }, -1);
                }}
              />
            ),
            render: (col, index) => (
              <Checkbox
                className={classes.cb}
                color="default"
                checked={col.checked || false}
                onChange={(e) => {
                  setData({ checked: e.target.checked }, index);
                }}
              />
            ),
          },
          query.sort === "sort" && {
            label: "노출순서",
            width: 70,
            render: (col, i) =>
              filtered && 3 === 2 ? (
                "-"
              ) : (
                <Sorter
                  order={
                    i + 1 + (parseInt(query.page) - 1) * parseInt(query.limit)
                  }
                  {...col}
                  onUpdate={fetchData}
                />
              ),
          },
          {
            label: "유통사\n유통사코드",
            render: (col) => (
              <span>
                {col.dName}
                <br />
                {col.dCode}
              </span>
            ),
          },
          {
            label: "상품코드",
            key: "code",
          },
          {
            label: "이미지",
            render: (col) => (
              <CardMedia
                component="img"
                height="70"
                image={getFileUri(col.path)}
              />
            ),
          },
          {
            label: "카테고리",
            key: "category",
          },
          {
            label: "상품명",
            clickable: true,
            flex: 2.5,
            key: "name",
            width: 250,
          },
          {
            label: "상품가격",
            render: (col) => numFormat(col.price) + "원",
          },
          {
            label: "판매가격",
            render: (col) => numFormat(col.price - col.discountPerc) + "원",
          },
          {
            label: "판매",
            key: "salesQuantity",
            width: 50,
          },
          {
            label: "판매상태",
            width: 100,
            render: (col) => (
              <span
                style={{
                  color:
                    col.state === "on"
                      ? "#39c15d"
                      : col.state === "out"
                      ? "rgb(255, 8, 8)"
                      : "rgb(255, 126, 0)",
                }}
              >
                {col.state === "on"
                  ? "판매중"
                  : col.state === "out"
                  ? "품절"
                  : "판매중지"}
              </span>
            ),
          },
          {
            label: "리뷰 수",
            // key: "review",
            width: 50,
            render: (col) => (
              <span
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => {
                  if (Boolean(col.review)) {
                    setSel({ ...col });
                  }
                }}
              >
                {col.review}
              </span>
            ),
          },
          {
            label: "최종수정 일시",
            key: "lastUpdatedAt",
          },
        ]}
      />
    </>
  );
}
