import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableLayout from "../../components/table-layout/TableLayout.js";
import useDistribution from "../../../hooks/useDistribution.js";
import useListPayload from "../../../hooks/useListPayload.js";
import useMember from "../../../hooks/useMember.js";

const useStyles = makeStyles({});

export default function Container({ history, location }) {
  const classes = useStyles();
  const [distributions] = useDistribution();
  const { isPartner, distributionId } = useMember();
  const { data, loading, dataLength, fetchData, fetchExcel } = useListPayload({
    excelName: "배송조회",
    endpoint: "/drivers/works",
  });

  return (
    <TableLayout
      label="배송조회"
      onSearch={fetchData}
      data={[...data]}
      dataLoading={loading}
      onExcel={fetchExcel}
      dataLength={dataLength}
      // onClick={(col, index) => {
      //   // window.open(
      //   //   `/delivery_management/payment?orderId=${
      //   //     col.orderId || ""
      //   //   }&orderActionId=${col.orderActionId || ""}`,
      //   //   "_blank"
      //   // );
      //   history.push(
      //     `/delivery_management/payment?orderId=${
      //       col.orderId || ""
      //     }&orderActionId=${col.orderActionId || ""}`,
      //     "_blank"
      //   );
      // }}
      defaultQuery={{
        page: 1,
        limit: 10,
        start: "today",
        end: "today",
        keyword: "",
        distributionId: isPartner ? distributionId : "",
      }}
      search={[
        [
          {
            label: "조회기간",
            type: "date",
            key: "start",
            key2: "end",
          },
        ],
        [
          {
            label: "검색",
            type: "input",
            key: "keyword",
            placeholder: "이름, 배송기사 ID, 차량번호, 핸드폰번호",
          },
          !isPartner && {
            label: "유통사",
            type: "menu",
            key: "distributionId",
            data: distributions,
          },
        ],
      ]}
      columns={[
        {
          label: "일자",
          key: "date",
        },
        {
          label: "배송기사명",
          key: "name",
          // clickable: true,
        },
        {
          label: "배송기사ID",
          key: "username",
        },
        {
          label: "차량번호",
          key: "carNumber",
        },
        {
          label: "총 주문건수",
          key: "totalOrderLength",
        },
        {
          label: "배송완료건수",
          key: "deliveryCompleteLength",
        },
        {
          label: "교환완료건수",
          key: "exchangeCompleteLength",
        },
        {
          label: "반품완료건수",
          key: "refundCompleteLength",
        },
      ]}
    />
  );
}
