/* eslint-disable no-unreachable-loop */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  CardMedia,
  Dialog,
  Slide,
  Icon,
  IconButton,
  Button,
  TableCell,
  TextField,
  Radio,
  FormControlLabel,
  Paper,
  Checkbox
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { requestDelete, requestGet } from "../../services/network";
import consts from "../../libs/consts";
import { parseSearch, replaceQuery } from "../../services/utils";
import useRouter from "use-react-router";
import moment from "moment";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import TableContainer from "../../components/table/TableContainer";
import Td from "../../components/table/Td";
import Th from "../../components/table/Th";
import fonts from "../../libs/fonts";
import ReceiveUserPopup from "./ReceiveUserPopup";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "50%",
    marginBottom: "50px"
  },
  bold: {
    fontWeight: "bold"
  },
  titleGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  searchResultCountText: {
    color: "red",
    fontWeight: "bold"
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC"
  },
  detailCell: {
    cursor: "pointer",
    textDecoration: "underline"
  },
  btn: {
    borderRadius: 0,
    marginLeft: "auto"
  }
});

export default function TableSheet(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { history, location, match } = useRouter();
  const query = parseSearch(location);
  const [popup, setPopup] = useState(false);
  const {
    setCouponEditDialogOpen,
    listData,
    length,
    page,
    setListData,
    onFetch
  } = props;

  const deleteData = item => () => {
    requestDelete({ url: consts.apiUrl + "/coupons/" + item.couponId })
      .then(x => {
        onFetch();
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  };

  return (
    <Grid container className={classes.container}>
      {Boolean(popup) && (
        <ReceiveUserPopup
          key={Boolean(popup)}
          {...popup}
          onClose={() => {
            setPopup(false);
          }}
        />
      )}

      <Grid
        item
        xs={12}
        className={classes.dfsc}
        style={{ marginBottom: "10px" }}>
        <Typography
          variant="h5"
          style={{
            fontSize: "28px",
            lineHeight: "32px",
            fontFamily: fonts.spoqaHanSansNeoBold,
            color: "#374553",
            paddingTop: "20px"
          }}>
          발송내역
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TableContainer first last={listData.length === 0 ? true : false}>
            <Th
              topleft
              bottomleft={listData.length === 0 ? true : false}
              center
              xs={1}>
              NO
            </Th>
            <Th xs={4} center>
              쿠폰명
            </Th>
            <Th xs={2} center>
              쿠폰종류
            </Th>
            <Th xs={2} center>
              발송대상
            </Th>
            <Th xs={4} center>
              지급기간
            </Th>
            <Th xs={3} center>
              유효기간
            </Th>
            <Th
              xs={3}
              topright
              bottomright={listData.length === 0 ? true : false}
              center>
              등록일
            </Th>
          </TableContainer>
        </Grid>
        {listData.map((col, idx) => {
          return (
            <Grid
              item
              xs={12}
              style={{ cursor: col.target === "all" ? "normal" : "pointer" }}>
              <TableContainer
                onClick={() => {
                  if (col.target !== "all") {
                    setPopup({
                      couponId: col.couponId
                    });
                  }
                }}
                last={listData.length === idx + 1 ? true : false}
                key={idx}>
                <Td
                  bottomleft={listData.length === idx + 1 ? true : false}
                  xs={1}
                  center>
                  {length -
                    (idx + 1) +
                    1 -
                    (parseInt(query.page) - 1) * parseInt(query.limit)}
                </Td>
                <Td xs={4} center>
                  {col.name}
                </Td>
                <Td xs={2} center>
                  {col.type === "discount" ? "할인쿠폰" : "무료배송"}
                </Td>

                <Td xs={2} center>
                  {col.target === "all" ? "전체회원" : "개별회원"}
                </Td>
                <Td xs={4} center>
                  {moment(col.start).format("YYYY-MM-DD")}~
                  {moment(col.end).format("YYYY-MM-DD")}
                </Td>
                <Td xs={3} center>
                  {moment(col.last).format("YYYY-MM-DD")}
                </Td>
                <Td
                  xs={3}
                  bottomright={listData.length === idx + 1 ? true : false}
                  center>
                  {moment(col.createdAt).format("YYYY-MM-DD")}
                </Td>
                {/* <Td
                  xs={2}
                  bottomright={listData.length === idx + 1 ? true : false}
                  center
                >
                  {col.privacy ? "미사용" : "사용"}
                </Td> */}
                {/* <Td
                  xs={4}
                  bottomright={listData.length === idx + 1 ? true : false}
                  center
                >
                  {["progress", "complete"].includes(col.paymentStatus) ? (
                    <span>지급완료</span>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setCouponEditDialogOpen(col)}
                      >
                        <Typography variant="subtitle2">수정</Typography>
                      </Button>
                      <Button
                        onClick={deleteData(col)}
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: "10px" }}
                      >
                        <Typography variant="subtitle2">삭제</Typography>
                      </Button>
                    </>
                  )}
                </Td> */}
              </TableContainer>
            </Grid>
          );
        })}

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px"
          }}>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={page}
            onChange={(e, p) => {
              replaceQuery(history, location, { page: p });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
