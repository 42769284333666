import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  Avatar,
  Icon,
  Dialog,
  CardMedia,
} from "@material-ui/core";
import { formatPhoneNumber, formatTime, numFormat } from "../../services/utils";
import useRouter from "use-react-router";
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
} from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { popupError, popupMessage } from "../../redux/popup/PopupActions";
import fonts from "../../libs/fonts";
import TableTitle from "../../components/table/TableTitle";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import qs from 'query-string'
import Td from "../../components/table/Td";
const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dfss: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#c5c8ce",
    marginBottom: "40px",
    marginTop: "27px",
  },
  dashboardItem: {
    marginBottom: "30px",
  },
  bold: {
    fontWeight: "bold",
  },
  detailInfoPaper: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  cell: {
    height: "50px",
    paddingLeft: "10px",
    borderBottom: "1px solid #7C7878",
  },
  cellTop: {
    borderTop: "1px solid #7C7878",
  },
  lastCell: {
    height: "100px",
    paddingLeft: "10px",
    borderBottom: "1px solid #7C7878",
  },
  firstCell: {
    borderRight: "1px solid #7C7878",
  },
  attributeCell: {
    backgroundColor: "#e9e9e9",
  },
  postalCodeField: {
    width: "200px",
    "& input": {
      textAlign: "center",
    },
  },
  detailInfoBtnGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  detailInfoSaveBtn: {
    width: "120px",
    borderRadius: 0,
    border: "1px solid #7C7878",
    backgroundColor: "#747474",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#747474",
    },
  },
  detailInfoListBtn: {
    width: "120px",
    marginLeft: "10px",
    borderRadius: 0,
    backgroundColor: "#e9e9e9",
    border: "1px solid #7C7878",
  },
  withDrawalBtn: {
    // backgroundColor: "#e9e9e9",
    width: "100px",
    height: "36px",
    // marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#e9e9e9",
    },
    borderRadius: "2px",
    border: "1px solid #ddd",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    backgroundColor: "#1C1B1B",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1C1B1B",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  card: {
    borderBottom: "1px solid #eee",
  },
  resize: { fontSize: "13px" },
});

export default function CustomerDetailInfo(props) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [withDrawalDialogOpen, setWithDrawalDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const {
    history,
    location: { state, search },
  } = useRouter();

  const query = qs.parse(search);


  useEffect(() => {
    if (state?.userId || query?.userId) {
      requestGet({ url: consts.apiUrl + "/users/" + (state?.userId || query?.userId) })
        .then((x) => {
          setData({ ...x });
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    }
  }, [state]);

  const resetPassword = () => {
    if (password.length < 6 || password.length > 20) {
      dispatch(
        popupError({ message: "비밀번호는 6~20자리 사이로 입력해주세요." })
      );
    } else {
      requestPut({
        url: consts.apiUrl + "/users/" + data.userId,
        body: { columns: ["password"], values: [password] },
      })
        .then((x) => {
          setPassword("");
          dispatch(popupMessage({ title: "비밀번호 변경 완료." }));
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    }
  };

  const onClickWithDrawalBtn = () => {
    setWithDrawalDialogOpen(true);
  };
  const handleWithDrawalDialog = () => {
    setWithDrawalDialogOpen(false);
  };

  const onClickWithDrawalDialogSaveBtn = () => {
    if (data.isDeleted) {
      requestPost({ url: consts.apiUrl + "/users/" + data.userId + "/restore" })
        .then((x) => {
          dispatch(popupMessage({ title: "복구 완료." }));
          query?.userId ? window.close() : history.goBack();
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    } else {
      requestDelete({ url: consts.apiUrl + "/users/" + data.userId })
        .then((x) => {
          dispatch(popupMessage({ title: "탈퇴 완료." }));
          query?.userId ? window.close() : history.goBack();
        })
        .catch((e) => {
          dispatch(popupError(e));
        });
    }

    setWithDrawalDialogOpen(false);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.detailInfoPaper} elevation={0} square>
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={10} className={classes.df}>
                <Grid item xs={5} className={classes.dfsc}>
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: "28px",
                      lineHeight: "32px",
                      fontFamily: fonts.spoqaHanSansNeoBold,
                      color: "#374553",
                      paddingTop: "40px",
                    }}
                  >
                    고객회원 상세보기
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={classes.df}
                  style={{ justifyContent: "flex-end", alignSelf: "flex-end" }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.withDrawalBtn}
                    onClick={onClickWithDrawalBtn}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: fonts.spoqaHanSansNeoMedium,
                        fontSize: "13px",
                        color: "#555",
                      }}
                    >
                      {data.isDeleted ? "복구처리" : "탈퇴처리"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.dashboardItem}>
              <Grid item xs={12}>
                <TableTitle>기본정보</TableTitle>
              </Grid>
              <Grid container>
                <Grid item xs={10} className={classes.df}>
                  <Grid item xs={12}>
                    <Grid item xs={12} className={classes.df}>
                      <TableContainer first>
                        <Th topleft xs={4}>
                          가입일시
                        </Th>
                        <Td xs={8}>
                          {formatTime(data.createdAt, "YYYY-MM-DD HH:mm")}
                        </Td>
                        {/* <Th>프로필</Th>
                        <Td>{data.profilePath ? (
                        <span style={{ width: "70px", height: "70px" }}>
                          <CardMedia
                            component="img"
                            height="70"
                            image={consts.fileApiUrl + "/" + data.profilePath}
                          />
                        </span>
                      ) : (
                        <Avatar>
                          <Icon>person_outline</Icon>
                        </Avatar>
                      )}</Td> */}
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12} className={classes.df}>
                      <TableContainer>
                        <Th xs={4}>가입유형</Th>
                        <Td xs={8}>
                          {(() => {
                            switch (data.method) {
                              case "email":
                                return "이메일";
                              case "google":
                                return "구글";
                              case "naver":
                                return "네이버";
                              case "kakaotalk":
                                return "카카오톡";
                              case "apple":
                                return "애플";
                            }
                          })()}
                        </Td>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.df}>
                    <Th xs={4}>이미지</Th>
                    <Td topright xs={8}>
                      {data.profilePath ? (
                        <span style={{ width: "70px", height: "70px" }}>
                          <CardMedia
                            component="img"
                            height="70"
                            image={consts.fileApiUrl + "/" + data.profilePath}
                          />
                        </span>
                      ) : (
                          <Avatar>
                            <Icon>person_outline</Icon>
                          </Avatar>
                        )}
                    </Td>
                  </Grid>
                </Grid>
                <Grid item xs={10} className={classes.df}>
                  <TableContainer>
                    <Th>이름/성별/나이</Th>
                    <Td>{`${data.name} / ${data.gender === "male" ? "남" : "여"
                      } / ${data.age || ""}`}</Td>
                    <Th>이메일(ID)</Th>
                    <Td>{data.email}</Td>
                  </TableContainer>
                </Grid>
                <Grid item xs={10} className={classes.df}>
                  <Grid item xs={12} className={classes.df}>
                    <TableContainer>
                      {data.method === "email" ? (
                        <>
                          <Th>비밀번호</Th>
                          <Td textfield>
                            <TextField
                              InputProps={{
                                classes: {
                                  input: classes.resize,
                                },
                              }}
                              type="password"
                              variant="outlined"
                              size="small"
                              value={password}
                              onChange={handlePassword}
                            />
                            <Button
                              onClick={resetPassword}
                              variant="outlined"
                              // color="default"
                              style={{ marginLeft: "16px" }}
                            >
                              변경
                            </Button>
                          </Td>
                          <Th>등급</Th>
                          <Td>
                            <img
                              style={{
                                width: 24,
                                height: 24,
                                marginRight: 16,
                                objectFit: "contain",
                              }}
                              src={
                                consts.fileApiUrl +
                                "/" +
                                data?.grade?.split(",")[1]
                              }
                            />
                            {data?.grade?.split(",")[0]}
                          </Td>
                        </>
                      ) : (
                          <>
                            <Th>등급</Th>
                            <Td xs={10}>
                              <img
                                style={{
                                  width: 24,
                                  height: 24,
                                  marginRight: 16,
                                  objectFit: "contain",
                                }}
                                src={
                                  consts.fileApiUrl +
                                  "/" +
                                  data?.grade?.split(",")[1]
                                }
                              />
                              {data?.grade?.split(",")[0]}
                            </Td>
                          </>
                        )}
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid item xs={10} className={classes.df}>
                  <TableContainer>
                    <Th>전화번호</Th>
                    <Td>{formatPhoneNumber(data.phone)}</Td>
                    <Th>카드</Th>
                    <Td column>
                      {data.cards
                        ? data.cards.split(",").map((x, i) => {
                          if (i !== 0) {
                            return (
                              <div>
                                <br />
                                {i + 1}. {x}
                              </div>
                            );
                          }
                          return i + 1 + ". " + x;
                        })
                        : ""}
                    </Td>
                  </TableContainer>
                </Grid>
                <Grid item xs={10} className={classes.df}>
                  <TableContainer>
                    <Th>기본배송지</Th>
                    <Td xs={10}>{data.address}</Td>
                  </TableContainer>
                </Grid>
                <Grid item xs={10} className={classes.df}>
                  <TableContainer>
                    <Th>주문건수</Th>
                    <Td xs={10}>{numFormat(data.orderCount)}</Td>
                  </TableContainer>
                </Grid>
                <Grid item xs={10} className={classes.df}>
                  <TableContainer>
                    <Th>총 주문금액</Th>
                    <Td xs={10}>{numFormat(data.orderAmount)}원</Td>
                  </TableContainer>
                </Grid>
                <Grid item xs={10} className={classes.df}>
                  <TableContainer last>
                    <Th bottomleft>최근 주문일시</Th>
                    <Td bottomright xs={10}>
                      {formatTime(data.lastOrderAt, "YYYY-MM-DD HH:mm")}
                    </Td>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={10} className={[classes.detailInfoBtnGrid, classes.dashboardItem].join(' ')}>
							<Button variant='contained' onClick={handleDetailInfoSave} className={classes.detailInfoSaveBtn}>저장</Button>
							<Button variant='contained' onClick={handleDetailInfoClose} className={classes.detailInfoListBtn}>목록</Button>
						</Grid> */}
          </Grid>
        </Paper>
      </Grid>
      <Dialog open={withDrawalDialogOpen} onClose={handleWithDrawalDialog}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">
              {data.isDeleted ? "복구처리" : "탈퇴처리"}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                {data.isDeleted ? "복구처리" : "탈퇴처리"} 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setWithDrawalDialogOpen(false)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={onClickWithDrawalDialogSaveBtn}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </Grid>
  );
}
