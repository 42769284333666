/* eslint-disable multiline-ternary */
import React, { Fragment, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Dialog,
  Paper,
  ButtonBase,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { requestDelete, requestPost, requestPut } from "../../services/network";
import consts from "../../libs/consts";
import { popupError } from "../../redux/popup/PopupActions";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import fonts from "../../libs/fonts";

export default function CategoryFirst({ categories, setCategories, onFetch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const addParent = async () => {
    let name = "대분류 " + (categories.length + 1);
    requestPost({
      url: consts.apiUrl + "/products/categories",
      body: {
        name,
      },
    })
      .then(({ productCategoryId }) => {
        setCategories([
          ...categories.map((x) => ({ ...x, select: false })),
          {
            name,
            select: true,
            productCategoryId: productCategoryId,
            parentId: null,
            items: [],
            privacy: 1,
            file: null,
            path: "",
          },
        ]);
        ref.current.scrollTop = ref.current.offsetHeight;
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const deleteParent = async () => {
    const p = categories.filter((x) => x.select)[0];
    if (!p) {
      return null;
    }

    requestDelete({
      url: consts.apiUrl + "/products/categories/" + p.productCategoryId,
    })
      .then((x) => {
        setDeleteDialogOpen(false);
        setCategories([
          ...categories.filter(
            (x) => x.productCategoryId !== p.productCategoryId
          ),
        ]);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const setSort = async (id, direct) => {
    requestPut({
      url: consts.apiUrl + "/products/categories/" + id + "/direct/" + direct,
    })
      .then((data) => {
        onFetch();
      })
      .catch((e) => {
        alert(e.message || e);
      });
  };

  return (
    <Grid item xs={4} style={{ marginRight: "30px" }}>
      <Grid
        item
        xs={12}
        className={classes.dfsc}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Typography variant="subtitle1">카테고리 설정</Typography>
      </Grid>
      <Grid item xs={12} className={classes.boxHeader}>
        <ButtonBase onClick={addParent} className={classes.button}>
          대분류추가
        </ButtonBase>

        <ButtonBase
          onClick={() => {
            if (categories.filter((x) => x.select).length) {
              setDeleteDialogOpen(true);
            }
          }}
          style={{ color: "#ff0808" }}
          className={classes.button}
        >
          삭제
        </ButtonBase>

        <div />
      </Grid>
      <Grid item xs={12} className={classes.boxContent}>
        <Grid item xs={10} style={{ marginLeft: (1 / 13) * 100 + "%" }}>
          <List ref={ref}>
            {categories.map((x, i) => {
              return (
                <Fragment key={i.toString() + x.sortOrder}>
                  <ListItem
                    style={{
                      backgroundColor: x.select ? "#eee" : "#fff",
                      borderTopLeftRadius: i === 0 ? 5 : 0,
                      borderTopRightRadius: i === 0 ? 5 : 0,
                      borderBottomLeftRadius:
                        categories.length === i + 1 ? 5 : 0,
                      borderBottomRightRadius:
                        categories.length === i + 1 ? 5 : 0,
                    }}
                    button
                    className={classes.listItem}
                    onClick={() => {
                      setCategories([
                        ...categories.map((z, k) => {
                          if (k === i) {
                            z.select = !z.select;
                          } else {
                            z.select = false;
                          }
                          return z;
                        }),
                      ]);
                    }}
                  >
                    <ListItemIcon>
                      <Icon>folder</Icon>
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          fontSize: "13px",
                          fontFamily: fonts.malgunGothicSemiLight,
                        },
                      }}
                      primary={x.name}
                    />
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSort(x.productCategoryId, "up");
                      }}
                    >
                      <ArrowUpward />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSort(x.productCategoryId, "down");
                      }}
                    >
                      <ArrowDownward />
                    </IconButton>
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        </Grid>
      </Grid>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      >
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">삭제처리</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                삭제하시면 하위분류와 상품도 삭제됩니다.
              </Typography>
              <Typography variant="subtitle1">
                삭제처리 하시겠습니까?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setDeleteDialogOpen(false)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                onClick={deleteParent}
                className={classes.DialogSaveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </Grid>
  );
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  divider: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#fff",
    height: 40,
    borderRadius: 2,
    border: "1px solid #dddddd",
    marginRight: "10px",
    width: "100px",
    fontSize: 13,
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoMedium,
  },
  boxHeader: {
    border: "1px solid #dddddd",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f7f8f9",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  boxContent: {
    height: "600px",
    borderLeft: "1px solid #dddddd",
    borderRight: "1px solid #dddddd",
    borderBottom: "1px solid #dddddd",
    overflowY: "auto",
    padding: "10px",
    paddingRight: "0px",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  addBtn: {
    borderRadius: 0,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  deleteBtn: {
    borderRadius: 0,
    color: "red",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#656565",
    "&:hover": {
      backgroundColor: "#656565",
    },
  },
  listItem: {
    display: "flex",
    border: "1px solid #dddddd",
    height: 50,
  },
  secondListItem: {
    marginTop: "10px",
    marginLeft: "20px",
    border: "1px solid #dddddd",
  },
  thirdListItem: {
    marginTop: "10px",
    marginLeft: "40px",
    border: "1px solid #dddddd",
  },
  radioBtn: {
    marginLeft: "30px",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  DialogSaveBtn: {
    backgroundColor: "rgb(57, 193, 93)",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "rgb(57, 193, 93)",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(57, 193, 93)",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
});
