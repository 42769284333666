/* eslint-disable no-unreachable-loop */
import React, { useEffect, useState, useRef } from "react";
import useReactRouter from "use-react-router";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Grid,
  Dialog,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Icon,
  IconButton,
  Button,
  TableCell,
  Checkbox,
  CardMedia,
  TextField,
  Radio,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import SetEditorProduct from "./SetEditorTableProduct";
import { getFileUri, numFormat } from "../../services/utils";
import { useDispatch } from "react-redux";
import { popupError } from "../../redux/popup/PopupActions";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import fonts from "../../libs/fonts";

const useStyles = makeStyles({
  container: {
    marginTop: "30px",
    width: "100%",
    height: "50%",
    marginBottom: "100px",
  },
  bold: {
    fontWeight: "bold",
  },
  titleGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid #B7ACAC",
    height: "50px",
    padding: "10px",
  },
  tableGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid #B7ACAC",
    padding: "10px",
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC",
  },
  headerBtn: {
    backgroundColor: "#fff",
    color: "#555555",
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
  initializationBtn: {
    width: "120px",
    backgroundColor: "#fff",
    border: "1px solid #e9e9e9",
    borderRadius: 0,
  },
  searchBtn: {
    width: "120px",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  searchBox: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  divider: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  divider2: {
    width: "100%",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  btn: {
    width: "100px",
    border: "1px solid #B7ACAC",
    borderRadius: 0,
    marginRight: "10px",
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#000",
    width: "100px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  saveBtn: {
    backgroundColor: "#40cf66",
    marginLeft: "10px",
    width: "100px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#40cf66",
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C1B1B",
    color: "#fff",
  },
  dialogContent: {
    display: "flex",
    width: "400px",
    height: "200px",
    flexDirection: "column",
  },
  select: {
    width: "150px",
    "& div": {
      backgroundColor: "#fff",
    },
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  cb: { color: "#40cf66 !important" },
  resize: { fontSize: "13px" },
});

export default function SetEditTable({
  products,
  setProducts,
  onSave,
  distributionId,
  receiptSetId,
}) {
  const classes = useStyles();
  const { history } = useReactRouter();
  const [countSettingDialogOpen, setCountSettingDialogOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [renderProducts, setRenderProducts] = useState([]);
  const [query, setQuery] = useState({ page: 1, limit: 5 });
  const sels = renderProducts.filter((x) => x.checked);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);

  useEffect(() => {
    setRenderProducts([
      ...products.filter((x, i) => {
        if (
          (query.page - 1) * query.limit <= i &&
          i < query.page * query.limit
        ) {
          return true;
        } else {
          return false;
        }
      }),
    ]);
  }, [query, products]);

  const handleCountSettingDialog = () => {
    setCountSettingDialogOpen(false);
  };
  const handleSaveDialog = () => {
    setSaveDialogOpen(false);
  };

  const onClickCountSettingDialogSaveBtn = () => {
    if (count < 1) {
      dispatch(popupError({ message: "입고수량을 1개이상 설정해주세요." }));
      return;
    } else if (count > 10000000) {
      dispatch(popupError({ message: "올바른 수량을 입력해주세요." }));
      return;
    }
    setProducts([
      ...products.map((x) => {
        if (x.productId === sels[0].productId) {
          x.quantity = parseInt(count);
        }
        return x;
      }),
    ]);
    setCountSettingDialogOpen(false);
  };
  const onClickSaveDialogSaveBtn = () => {
    setSaveDialogOpen(false);
  };

  const onClickSaveBtn = () => {
    if (products.filter((x) => !x.quantity).length) {
      setSaveDialogOpen(true);
    } else if (products.filter((x) => x.state !== "on").length) {
      dispatch(
        popupError({ message: "판매중이지 않은 상품을 목록에서 지워주세요." })
      );
    } else {
      onSave();
    }
  };
  const onClickCancelAdd = () => {
    history.goBack();
  };

  const deleteProducts = () => {
    if (sels) {
      setProducts([
        ...products.filter((x) => {
          if (sels.filter((y) => y.productId === x.productId)[0]) {
            return false;
          } else {
            return true;
          }
        }),
      ]);
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        className={classes.dfsc}
        style={{ marginBottom: "10px" }}
      >
        <Button
          onClick={deleteProducts}
          variant="outlined"
          className={classes.headerBtn}
        >
          <Typography
            variant="subtitle2"
            style={{ fontFamily: fonts.spoqaHanSansNeoBold, fontSize: "13px" }}
          >
            상품 삭제
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TableContainer first>
            <Th cb topleft center xs={1}>
              <Checkbox
                className={classes.cb}
                color="default"
                checked={
                  renderProducts.length &&
                  renderProducts.filter((x) => x.checked).length ===
                    renderProducts.length
                }
                onChange={(e) => {
                  const checked = e.target.checked;
                  setRenderProducts([
                    ...renderProducts.map((x) => ({ ...x, checked })),
                  ]);
                }}
              />
            </Th>
            <Th center>상품코드</Th>
            <Th center>이미지</Th>
            <Th center>카테고리</Th>
            <Th center>상품명</Th>
            <Th center>판매가격</Th>
            <Th center>1일입고수량</Th>
            <Th center>보관위치</Th>
            <Th topright={!receiptSetId ? true : false} center>
              판매상태
            </Th>
            {receiptSetId ? (
              <Th center topright>
                등록일자
                <br />
                수정일자
              </Th>
            ) : null}
          </TableContainer>
          {renderProducts.map((col, idx) => {
            return (
              <TableContainer
                checked={col.checked ? true : false}
                last={renderProducts.length === idx + 1 ? true : false}
                key={idx}
              >
                <Td
                  cb
                  bottomleft={renderProducts.length === idx + 1 ? true : false}
                  center
                  xs={2}
                >
                  <Checkbox
                    className={classes.cb}
                    color="default"
                    checked={col.checked || false}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setRenderProducts([
                        ...renderProducts.map((x, i) => ({
                          ...x,
                          checked: idx === i ? checked : x.checked,
                        })),
                      ]);
                    }}
                  />
                </Td>
                <Td center>{col.code}</Td>
                <Td img center>
                  <CardMedia
                    component="img"
                    height="70"
                    image={getFileUri(col.path)}
                  />
                </Td>
                <Td center>
                  {col.category}{" "}
                  {col.childCategory ? " / " + col.childCategory : ""}
                </Td>
                <Td center>{col.name}</Td>
                <Td center>{numFormat(col.price)}원</Td>
                <Td center textfield>
                  <TextField
                    inputProps={{
                      style: { textAlign: "center" },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    variant="outlined"
                    value={col.quantity}
                    onChange={(e) => {
                      let count = e.target.value;
                      if (count && isNaN(count)) {
                        return;
                      }

                      if (count !== "") {
                        count = parseInt(count);
                      }
                      if (count < 1) {
                        dispatch(
                          popupError({
                            message: "입고수량을 1개이상 설정해주세요.",
                          })
                        );
                        return;
                      } else if (count > 10000000) {
                        dispatch(
                          popupError({
                            message: "올바른 수량을 입력해주세요.",
                          })
                        );
                        return;
                      }
                      products[
                        products.findIndex((x) => x.productId === col.productId)
                      ].quantity = count;
                      setProducts([...products]);
                    }}
                  />
                </Td>
                <Td center textfield>
                  <TextField
                    inputProps={{
                      style: { textAlign: "center" },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                    variant="outlined"
                    value={col.store}
                    onChange={(e) => {
                      let store = e.target.value;

                      products[
                        products.findIndex((x) => x.productId === col.productId)
                      ].store = store;
                      setProducts([...products]);
                    }}
                  />
                </Td>
                <Td
                  bottomright={
                    !receiptSetId && renderProducts.length === idx + 1
                      ? true
                      : false
                  }
                  center
                >
                  {col.state === "on"
                    ? "판매중"
                    : col.state === "out"
                    ? "품절"
                    : "판매중지"}
                </Td>
                {receiptSetId ? (
                  <Td
                    center
                    bottomright={
                      renderProducts.length === idx + 1 ? true : false
                    }
                  >
                    {col.createdAt}
                    <br />
                    {col.lastUpdatedAt}
                  </Td>
                ) : null}
              </TableContainer>
            );
          })}
          <Grid item xs={12} className={classes.df}>
            <Grid
              item
              xs={7}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Pagination
                variant="outlined"
                shape="rounded"
                count={Math.ceil(products.length / query.limit)}
                onChange={(e, page) => {
                  setQuery({ ...query, page });
                }}
              />
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Button
                variant="outlined"
                className={classes.cancelBtn}
                onClick={onClickCancelAdd}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: fonts.spoqaHanSansNeoMedium,
                    fontSize: "13px",
                  }}
                >
                  취소
                </Typography>
              </Button>
              <Button onClick={onClickSaveBtn} className={classes.saveBtn}>
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: fonts.spoqaHanSansNeoMedium,
                    fontSize: "13px",
                  }}
                >
                  저장
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        style={{
          marginTop: "50px",
          marginBottom: "40px",
          height: "2px",
          width: "100%",
          backgroundColor: "#c5c8ce",
        }}
      />
      <SetEditorProduct
        products={products}
        setProduts={setProducts}
        distributionId={distributionId}
      />
      <Dialog open={countSettingDialogOpen} onClose={handleCountSettingDialog}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">1일 입고 수량 설정</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {/*
                 <Typography variant="subtitle1">
                1일 입고수량 미등록 상품이 있습니다.
              </Typography>
              <Typography variant="subtitle1">
                상품 입고수량을 설정해주세요.
              </Typography>
                */}
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                size="small"
                variant="outlined"
                value={count}
                type="number"
                onChange={(e) => {
                  setCount(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setCountSettingDialogOpen(false)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={onClickCountSettingDialogSaveBtn}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
      <Dialog open={saveDialogOpen} onClose={handleSaveDialog}>
        <Paper elevation={0}>
          <Grid item xs={12} className={classes.dialogHeader}>
            <Typography variant="subtitle1">1일 입고 수량 설정</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dialogContent}>
            <Grid
              item
              xs={12}
              className={classes.df}
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                1일 입고수량 미등록 상품이 있습니다.
              </Typography>
              <Typography variant="subtitle1">
                상품 입고수량을 설정해주세요.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dfcc}>
              <Button
                variant="outlined"
                onClick={() => setSaveDialogOpen(false)}
                className={classes.cancelBtn}
              >
                <Typography varinat="subtitle2">취소</Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={onClickSaveDialogSaveBtn}
                className={classes.saveBtn}
                style={{ marginLeft: "10px" }}
              >
                <Typography varinat="subtitle2">확인</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </Grid>
  );
}
