/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardMedia,
  Paper,
  IconButton,
  Icon,
  Dialog,
  FormControlLabel,
  Radio,
  InputBase,
  ButtonBase,
} from "@material-ui/core";
import { requestGet } from "../../services/network";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import {
  popupAction,
  popupError,
  popupClose,
} from "../../redux/popup/PopupActions";
import { formatPhoneNumber, formatTime, numFormat } from "../../services/utils";
import fonts from "../../libs/fonts";
import TableContainer from "../../components/table/TableContainer";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
  },
  titleGrid: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  bold: {
    fontSize: 32,
    color: "#374553",
    fontFamily: fonts.spoqaHanSansNeoBold,
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  dfcb: {
    display: "flex",
    justifyContent: "center",
    alignItems: "base-line",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      padding: "20px 25px",
      maxWidth: "1140px",
      border: "3px solid #b4bac3",
      borderRadius: 15,
    },
  },
  infoGrid: {
    border: "1px solid #DFDFDF",
    backgroundColor: "#efefef",
    marginBottom: "20px",
  },
  infoContent: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
  },
  infocell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  select: {
    "& div": {
      backgroundColor: "#fff",
    },
  },
  searchBox: {
    "& input": {
      backgroundColor: "#fff",
    },
  },
  initializationBtn: {
    width: "120px",
    backgroundColor: "#fff",
    border: "1px solid #e9e9e9",
    borderRadius: 0,
  },
  divider: {
    width: "100%",
  },
  searchBtn: {
    width: "120px",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  driverInfoGrid: {},
  receivingTimeTextField: {
    "& input": {
      textAlign: "center",
    },
  },
  receivingStatusTextField: {
    textAlign: "center",
    "& input": {
      color: "blue",
      textAlign: "center",
    },
  },
  popUpTableGrid: {
    height: "500px",
  },
  popupTableCell: {
    textAlign: "center",
    border: "1px solid #dddddd",
    color: "#374553",
    fontSize: 13,
    fontFamily: fonts.malgunGothicBold,
    "& span": {
      color: "#374553",
      fontSize: 13,
      fontFamily: fonts.malgunGothicBold,
    },
  },
  radioBtn: {
    marginLeft: "20px",
  },
  label2: {
    fontFamily: fonts.malgunGothicSemiLight,
    fontSize: 13,
    color: "#374553",
  },
  th: {
    "& th": { padding: 0, height: 50, borderTop: "0px" },
  },
  menu: {
    borderRadius: 2,
    border: "1px solid #e9ebee",
    height: 40,
    marginBottom: 10,
    padding: "10px",
    backgroundColor: "#fff",
  },
  label3: {
    fontSize: 13,
    color: "#374553",
    fontFamily: fonts.malgunGothicBold,
  },
  ib: {
    flex: 1,
    borderRadius: 2,
    display: "flex",
    paddingLeft: 14,
    border: "1px solid #e9ebee",
    height: 36,
    alignItems: "center",
    backgroundColor: "#f7f8f9",
    fontSize: 13,
    color: "#2069e3",
    fontFamily: fonts.malgunGothicBold,
  },
  button: {
    backgroundColor: "#fff",
    // height: 40,
    borderRadius: 2,
    border: "1px solid #dddddd",
    // marginRight: "10px",
    // width: "100px",
    fontSize: 13,
    color: "#555555",
    fontFamily: fonts.spoqaHanSansNeoMedium,
    padding: "5px",
  },
});

export default function InventoryDetail({
  onClose,
  // driverId,
  // driverWorkId,
  name,
  // companyName,
  // companyAddres,
  // carNumber,
  // phone,
  productId,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    requestGet({
      url: consts.apiUrl + `/products/${productId}/reviews`,
    })
      .then((x) => {
        setData([...x.results]);
        setTotal(x.totalCount);
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  }, []);

  const onClickClose = () => {
    onClose();
  };

  return (
    <Dialog
      className={[classes.dfcc, classes.dialog].join(" ")}
      open
      onClose={onClickClose}
    >
      <Grid container xs={12} justify="flex-end">
        <Icon
          onClick={onClickClose}
          style={{ color: "#b4bac3", cursor: "pointer" }}
        >
          close
        </Icon>
      </Grid>
      <Grid
        item
        xs={12}
        className={[classes.titleGrid, classes.df].join(" ")}
        style={{ justifyContent: "start", alignItems: "base-line" }}
      >
        <span className={classes.bold}>{name}</span>
      </Grid>
      <Grid item xs={12}>
        <TableContainer first>
          <Th topleft center xs={1}>
            NO
          </Th>
          <Th center xs={2}>
            작성자
          </Th>
          <Th center xs={2}>
            이미지
          </Th>
          <Th center xs={4}>
            후기
          </Th>
          <Th center xs={1}>
            평점
          </Th>
          <Th topright center xs={2}>
            작성일
          </Th>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        {data.map((col, idx) => {
          return (
            <>
              <TableContainer
                key={idx}
                last={data.length === idx + 1 ? true : false}
              >
                <Td
                  xs={1}
                  center
                  bottomleft={data.length === idx + 1 ? true : false}
                >
                  {idx + 1}
                </Td>
                <Td xs={2} center>
                  {col.name.substring(0, 1)}**
                </Td>
                <Td xs={2} center>
                  {Boolean(col.images?.length) && (
                    <div className={classes.dfcc} style={{ width: 100 }}>
                      <CardMedia
                        component="img"
                        image={consts.fileApiUrl + "/" + col.images[0].path}
                      />
                    </div>
                  )}
                </Td>
                <Td xs={4} center>
                  {col.description}
                </Td>
                <Td xs={1} center>
                  {col.rating}
                </Td>
                <Td xs={2} center>
                  {formatTime(col.createdAt, "YYYY. MM. DD")}
                </Td>
              </TableContainer>
            </>
          );
        })}
      </Grid>
    </Dialog>
  );
}
