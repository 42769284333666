/* eslint-disable multiline-ternary */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import TableSheet from "./TableSheet";
import CouponAddDialog from "./CouponAddDialog";
import useRouter from "use-react-router";
import { formatPhoneNumber, parseSearch } from "../../services/utils";
import { requestGet, requestPost } from "../../services/network";
import consts from "../../libs/consts";
import { popupAction, popupError } from "../../redux/popup/PopupActions";
import { useDispatch } from "react-redux";
import CouponUpdateDialog from "./CouponUpdateDialog";
import fonts from "../../libs/fonts";
import MemberPopup from "../../components/editor/member_popup/MemberPopup";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import TableContainer from "../../components/table/TableContainer";
import { loadingStart, loadingStop } from "../../redux/loading/LoadingActions";

const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "50px",
    paddingRight: "30px",
    paddingBottom: "50px"
  },
  bold: {
    fontWeight: "bold"
  },
  addBtn: {
    marginLeft: "auto",
    backgroundColor: "#40cf66",
    color: "#fff",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#40cf66"
    },
    alignSelf: "flex-end",
    width: "100px"
  },
  df: {
    display: "flex"
  },
  dfcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "10px"
  },
  textfield: {
    "& div": {
      backgroundColor: "#fff"
    }
  },
  dialogTitleCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "lightgrey"
  },
  dialogContentCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "2px solid #656565"
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none"
    }
  },
  cb: { color: "#40cf66 !important" },
  resize: { fontSize: "13px" }
});

export default function Coupon() {
  const classes = useStyles();

  const [couponAddDialogOpen, setCouponAddDialogOpen] = useState(false);
  const [couponEditDialogOpen, setCouponEditDialogOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [selMode, setSelMode] = useState(false);
  const [checkValue, setCheckValue] = useState(["driver", "user"]);
  const [couponType, setCouponType] = useState("delivery");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [prevDate, setPrevDate] = useState(moment().format("YYYY-MM-DD"));
  const [nextDate, setNextDate] = useState(moment().format("YYYY-MM-DD"));
  const [validDate, setValidDate] = useState(moment().format("YYYY-MM-DD"));
  const [radioValue, setRadioValue] = useState("all");
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [memberIds, setMemberIds] = useState([]);
  const [user, setUser] = useState({});

  const { location } = useRouter();
  const query = parseSearch(location);
  const dispatch = useDispatch();

  useEffect(() => {
    setMemberIds([]);
  }, [radioValue]);

  const fetchData = () => {
    requestGet({ url: consts.apiUrl + "/coupons", query: { ...query } })
      .then(x => {
        setTotal(x.totalCount);
        setData([...x.results]);
      })
      .catch(e => {
        dispatch(popupError(e));
      });
  };

  useEffect(() => {
    query.page && fetchData();
  }, [query.page]);

  const onClickCouponAddBtn = () => {
    setCouponAddDialogOpen(true);
  };

  const handleCouponType = e => {
    setDiscountPercent(0);
    setCouponType(e.target.value);
  };
  const handleValidDate = e => {
    setValidDate(e.target.value);
  };

  const insertCoupon = () => {
    dispatch(
      popupAction({
        title: "쿠폰 등록",
        message: `${
          memberIds.length >= 1 ? memberIds.length + "명" : "전체회원"
        }에게 쿠폰을 보내시겠습니까?`,
        onClickConfirm: async () => {
          dispatch(loadingStart);
          try {
            if (couponType !== "deivery") {
              if (isNaN(discountPercent) || parseFloat(discountPercent) > 100) {
                throw "올바른 할인율을 입력해주세요.";
              }
            }

            const params = {
              start: prevDate,
              end: nextDate,
              last: validDate,
              name,
              type: couponType,
              discountPercent: discountPercent,
              quantity,
              userIds: memberIds.map(x => x.userId)
            };

            await requestPost({
              url: consts.apiUrl + "/coupons",
              body: params
            });

            fetchData();
          } catch (error) {
            dispatch(popupError(error));
          }
          dispatch(loadingStop);
        }
      })
    );
  };

  const handleRadioValue = e => {
    if (!checkValue.length) {
      setCheckValue(["driver", "user"]);
    }
    setRadioValue(e.target.value);
    setUser({});
  };
  const onClickChoiceBtn = () => {
    setSelMode(true);
  };
  const handleCheckValue = e => {
    if (checkValue.includes(e.target.value)) {
      setCheckValue([...checkValue.filter(x => x !== e.target.value)]);
    } else {
      setCheckValue([...checkValue, e.target.value]);
    }
    setUser({});
  };

  return (
    <Grid container className={classes.container}>
      {/* {couponAddDialogOpen && (
        <CouponAddDialog
          onAdd={() => {
            fetchData();
          }}
          onClose={() => {
            setCouponAddDialogOpen(false);
          }}
        />
      )} */}
      {Boolean(couponEditDialogOpen) && (
        <CouponUpdateDialog
          data={couponEditDialogOpen}
          onUpdate={() => {
            fetchData();
          }}
          onClose={() => {
            setCouponEditDialogOpen(false);
          }}
        />
      )}
      {selMode && (
        <MemberPopup
          driver={false}
          member={checkValue.includes("user")}
          hideMemberCheck={true}
          value={memberIds}
          key={checkValue.length === 2 ? "" : checkValue[0]}
          type={checkValue.length === 2 ? "" : checkValue[0]}
          onClose={() => {
            setSelMode(false);
          }}
          onChange={setMemberIds}
        />
      )}
      <Grid item xs={10}>
        <Grid item xs={12} style={{ marginBottom: "30px" }}>
          <Grid
            item
            xs={12}
            className={classes.dfsc}
            style={{ marginBottom: "10px" }}>
            <Typography
              variant="h5"
              style={{
                fontSize: "28px",
                lineHeight: "32px",
                fontFamily: fonts.spoqaHanSansNeoBold,
                color: "#374553",
                paddingTop: "40px"
              }}>
              쿠폰관리
            </Typography>
          </Grid>
          <Divider
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#c5c8ce",
              marginBottom: "40px",
              marginTop: "20px"
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.df}>
          <TableContainer first>
            <Th topleft xs={3}>
              쿠폰종류
            </Th>
            <Td xs={9} textfield topright>
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="delivery"
                    checked={couponType === "delivery"}
                    onChange={handleCouponType}
                  />
                }
                label={<Typography variant="subtitle2">무료배송</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="discount"
                    checked={couponType === "discount"}
                    onChange={handleCouponType}
                  />
                }
                label={<Typography variant="subtitle2">할인쿠폰</Typography>}
                labelPlacement="end"
              />
            </Td>
          </TableContainer>
        </Grid>
        <Grid item xs={12} className={classes.df}>
          <TableContainer>
            <Th xs={3}>발송대상</Th>
            <Td xs={9} textfield>
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="all"
                    checked={radioValue === "all"}
                    onChange={handleRadioValue}
                  />
                }
                label={<Typography variant="subtitle2">전체회원</Typography>}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Radio
                    className={classes.cb}
                    color="default"
                    value="individual"
                    checked={radioValue === "individual"}
                    onChange={handleRadioValue}
                  />
                }
                label={<Typography variant="subtitle2">개별회원</Typography>}
                labelPlacement="end"
              />
            </Td>
          </TableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          style={
            radioValue === "individual"
              ? { display: "flex" }
              : { display: "none" }
          }>
          <TableContainer>
            <Th xs={3}>회원선택</Th>
            <Td xs={9} textfield>
              <Button
                variant="outlined"
                onClick={onClickChoiceBtn}
                style={{ marginRight: "20px" }}>
                <Typography variant="subtitle2">회원선택</Typography>
              </Button>
              {user.memberId &&
                `(${user.type === "driver" ? "기사" : "고객"}) ${
                  user.type === "driver" ? user.dName : user.uName
                }`}
            </Td>
          </TableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          style={
            radioValue === "individual"
              ? { display: "flex" }
              : { display: "none" }
          }>
          <TableContainer>
            <Th xs={3}>선택회원</Th>
            <Td xs={9} textfield>
              <div className={classes.rows}>
                {memberIds
                  ? memberIds.map((x, i) => {
                      return (
                        <div
                          key={i.toString()}
                          style={{ fontSize: 13, margin: "10px" }}>
                          {(x.name ? x.name : "정보 없음") +
                            "(" +
                            (x.phone
                              ? formatPhoneNumber(x.phone)
                              : "정보 없음") +
                            ")" +
                            (x.driverId ? "-기사" : "-고객")}
                        </div>
                      );
                    })
                  : null}
              </div>
            </Td>
          </TableContainer>
        </Grid>
        <Grid item xs={12} className={classes.df}>
          <TableContainer>
            <Th xs={3}>쿠폰명</Th>
            <Td xs={9} textfield>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
                value={name}
                onChange={t => {
                  setName(t.target.value);
                }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Td>
          </TableContainer>
        </Grid>
        <Grid item xs={12} className={classes.df}>
          <TableContainer>
            <Th xs={3}>유효기간</Th>
            <Td xs={9} textfield>
              <input
                min={
                  prevDate
                    ? moment(prevDate, "YYYY-MM-DD").format("YYYY-MM-DD")
                    : ""
                }
                type="date"
                value={validDate}
                onChange={handleValidDate}
              />
            </Td>
          </TableContainer>
        </Grid>
        {couponType === "discount" && (
          <Grid
            item
            xs={12}
            className={classes.df}
            // style={{ padding: "0px 20px" }}
          >
            <TableContainer>
              <Th xs={3}>할인률</Th>
              <Td xs={9} textfield>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={discountPercent}
                  onChange={e => {
                    setDiscountPercent(e.target.value);
                  }}
                />
                <div style={{ width: "12px" }} />%
              </Td>
            </TableContainer>
          </Grid>
        )}
        <Grid item xs={12} className={classes.df}>
          <TableContainer last>
            <Th xs={3}>쿠폰수</Th>
            <Td bottomright xs={9} textfield>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
                value={quantity}
                onChange={e => {
                  setQuantity(e.target.value);
                }}
                type="number"
                variant="outlined"
                size="small"
              />
            </Td>
          </TableContainer>
        </Grid>
        <Grid item xs={12} className={classes.dfcc} style={{ padding: "20px" }}>
          <Button
            onClick={insertCoupon}
            size="small"
            style={{
              marginLeft: "10px",
              width: "120px",
              backgroundColor: "#40cf66",
              color: "#fff",
              borderRadius: 2
            }}>
            <Typography variant="subtitle1">등록하기</Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableSheet
            setListData={setData}
            listData={data}
            length={total}
            onFetch={fetchData}
            page={Math.ceil(total / parseInt(query.limit))}
            setCouponEditDialogOpen={data => {
              setCouponEditDialogOpen(data);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
