import { IconButton, InputBase } from "@material-ui/core";
import { Check, Update, Edit } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import consts from "../../libs/consts";
import { popupError } from "../../redux/popup/PopupActions";
import { requestPut } from "../../services/network";

export default function Sorter({ productId, sort, onUpdate }) {
  const dispatch = useDispatch();
  const [num, setNum] = useState("");
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSort = () => {
    if (!num || isNaN(num)) {
      return;
    }

    setLoading(true);
    requestPut({
      url: consts.apiUrl + "/products/" + productId,
      body: {
        action: "sort",
        to: num
      }
    })
      .then(() => {
        setLoading(false);
        setUpdate(false);
        onUpdate();
      })
      .catch(e => {
        setLoading(false);
        dispatch(popupError(e));
      });
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: "auto",
        justifyContent: "center"
      }}>
      {update ? (
        <InputBase
          value={num}
          onChange={e => {
            setNum(e.target.value);
          }}
          inputProps={{
            style: {
              padding: "2px 4px"
            }
          }}
          style={{
            marginLeft: "0.5rem",
            border: "1px solid #ddd"
          }}
          type="number"
        />
      ) : (
        <span style={{ fontSize: 15, marginRight: "5px" }}>{sort}</span>
      )}
      {update ? (
        <IconButton
          fontSize="small"
          size="small"
          onClick={handleSort}
          disabled={!num || loading}>
          <Check color={!num ? "inherit" : "primary"} />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          onClick={() => {
            setUpdate(true);
          }}>
          <Edit fontSize="small" color="primary" />
        </IconButton>
      )}
    </div>
  );
}
