import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import TableContainer from "../../components/table/TableContainer";
import Td from "../../components/table/Td";
import Th from "../../components/table/Th";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import { requestGet } from "../../services/network";
import { formatPhoneNumber } from "../../services/utils";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "50%",
    marginBottom: "50px"
  },
  bold: {
    fontWeight: "bold"
  },
  titleGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  searchResultCountText: {
    color: "red",
    fontWeight: "bold"
  },
  tableCell: {
    textAlign: "center",
    border: "2px solid #B7ACAC"
  },
  detailCell: {
    cursor: "pointer",
    textDecoration: "underline"
  },
  btn: {
    borderRadius: 0,
    marginLeft: "auto"
  },
  dfsc: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  }
});

export default function ReceiveUserPopup({ couponId, onClose }) {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [createdAt, setCreatedAt] = useState(0);
  const [query, setQuery] = useState({ page: 1, limit: 10 });
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (!couponId) {
      return;
    }
    requestGet({
      url: consts.apiUrl + "/coupons/" + couponId + "/receiveusers",
      query
    })
      .then(data => {
        setTotal(data.totalCount);
        setCreatedAt(data.createdAt);
        setListData([...data.results]);
      })
      .catch(e => {
        alert(e.message || e);
      });
  }, [couponId, query.limit, query.page]);

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle id="alert-dialog-slide-title">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          {"발송고객 정보 조회"}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}>
            <Typography variant="subtitle2" style={{ marginRight: "5px" }}>
              검색결과
            </Typography>
            <Typography variant="subtitle2">{total}</Typography>

            <Typography
              variant="subtitle2"
              style={{ marginLeft: 16, marginRight: "5px" }}>
              발송일자
            </Typography>
            <Typography variant="subtitle2">{createdAt}</Typography>
          </div>
          <div
            style={{
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
              fontFamily: fonts.malgunGothicSemiLight,
              fontSize: 14
            }}>
            보기
            <select
              value={query.limit}
              onChange={e => {
                setQuery({ ...query, page: 1, limit: e.target.value });
              }}
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                marginLeft: 8,
                width: 100
              }}>
              <option value="10" label="10개" />
              <option value="25" label="25개" />
              <option value="50" label="50개" />
              <option value="75" label="75개" />
              <option value="100" label="100개" />
              <option value="200" label="200개" />
              <option value="500" label="500개" />
            </select>
          </div>
        </div>

        <Grid item xs={12}>
          <TableContainer first>
            <Th center topleft xs={2}>
              NO
            </Th>
            <Th center xs={5}>
              이름
            </Th>
            <Th center topright xs={5}>
              휴대폰 번호
            </Th>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          {listData.map((col, idx) => {
            return (
              <TableContainer
                last={listData.length === idx + 1 ? true : false}
                key={idx}>
                <Td
                  center
                  xs={2}
                  bottomleft={listData.length === idx + 1 ? true : false}>
                  {total -
                    (idx + 1) +
                    1 -
                    (parseInt(query.page) - 1) * parseInt(query.limit)}
                </Td>
                <Td center xs={5}>
                  {col.name}
                </Td>
                <Td
                  center
                  xs={5}
                  bottomright={listData.length === idx + 1 ? true : false}>
                  {formatPhoneNumber(col.phone)}
                </Td>
              </TableContainer>
            );
          })}
          <Table>
            <TableBody></TableBody>
          </Table>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px"
            }}>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(total / parseInt(query.limit))}
              page={parseInt(query.page)}
              onChange={(e, p) => {
                setQuery({ ...query, page: p });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
