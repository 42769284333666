import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import consts from "../admin/libs/consts";
import { popupError } from "../admin/redux/popup/PopupActions";
import { requestGet } from "../admin/services/network";
import qs from "query-string";
import { saveAs } from "file-saver";
import useMember from "./useMember";

function useListPayload({ endpoint, excelName, triggers = [] }) {
  const dispatch = useDispatch();
  const query = qs.parse(useLocation().search);
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState([]);
  const [loading, setLoading] = useState([]);
  const { isPartner, distributionId } = useMember();

  useEffect(() => {
    query.page && fetchData();
  }, [query.page, query.limit, ...triggers.map((x) => query[x])]);

  const fetchData = () => {
    setLoading(true);
    requestGet({
      url: consts.apiUrl + endpoint,
      query: {
        ...query,
        distributionId: isPartner ? distributionId : query.distributionId,
      },
    })
      .then((x) => {
        setDataLength(x.totalCount);
        setData([...x.results]);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        dispatch(popupError(e));
      });
  };

  const fetchExcel = () => {
    requestGet({
      url: consts.apiUrl + endpoint,
      query: {
        ...query,
        download: "excel",
        excel: "1",
        distributionId: isPartner ? distributionId : query.distributionId,
      },
    })
      .then((x) => {
        const unit8arr = new Buffer.from(x.data);
        saveAs(new Blob([unit8arr]), `${excelName}_${Date.now()}` + ".xlsx");
      })
      .catch((e) => {
        dispatch(popupError(e));
      });
  };

  const handleSetData = (item, index) => {
    setData([
      ...data.map((x, i) => {
        if (index < 0) {
          return {
            ...x,
            ...item,
          };
        }
        if (index === i) {
          return {
            ...x,
            ...item,
          };
        }
        return x;
      }),
    ]);
  };

  if (distributionId) {
    query.distributionId = distributionId;
  }
  return {
    data,
    dataLength,
    fetchData,
    fetchExcel,
    query,
    loading,
    setData: handleSetData,
    clearData: () => {
      setData([]);
      setDataLength(0);
    },
  };
}

export default useListPayload;
